import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { collapseAnimation } from 'angular-animations';
import { Observable } from 'rxjs';
import { TermsAndConditionsService } from 'src/app/project/services/terms-and-conditions.service';
import { Project } from 'src/app/shared/models/project.model';
import { Section } from 'src/app/shared/models/section.model';
import { Step } from 'src/app/shared/models/step.model';
import { ProjectUserRole } from '../../../../shared/enums';
import { Events } from '../../../../shared/enums/events';
import { EventsService } from '../../../../shared/services/events.service';
import { ProjectService } from '../../../../shared/services/project.service';

@Component({
  selector: 'custom-toc-items',
  templateUrl: './custom-toc-items.component.html',
  styleUrls: [ './custom-toc-items.component.scss' ],
  animations: [
    collapseAnimation(),
  ],
})
export class CustomTocItemsComponent implements OnInit {
  constructor(private tacService: TermsAndConditionsService,
              private eventsService: EventsService,
              private projectService: ProjectService) {}

  @Input()
  currentProject: Project;

  @Input()
  currentSection: Section;

  @Input()
  currentStep: Step;

  @Input()
  cascading: boolean;

  @Input()
  items: Array<any>;

  @Input()
  entity: string;

  @Input()
  entityId: number;

  @Input()
  isCollapsed: boolean = false;

  @Output()
  onCollapseChange = new EventEmitter<boolean>();

  @Output()
  dataChanged = new EventEmitter<boolean>();

  tacData: any;

  @Input()
  headers?: any;
  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  ngOnInit(): void {
    if (!this.items) {
      this.items = [];
    }
    this.tacService.tocData.subscribe((data) => (this.tacData = data));
  }

  update(item: any, key: string) {
    this.eventsService.setValue(Events.STEP_DATA_SAVING, true);

    if (!item.id) {
      this.createItem(item);
      return;
    }
    const updateItem = {
      id: item.id,
      terms_and_conditions_step_id: item.step_id,
      model_id: item.model_id,
      model: item.model,
    };
    updateItem[key] = item[key];
    this.tacService.updateCustomItem(this.currentProject, updateItem).then(resp => {
      this.tacService.loadTocData(this.currentProject.id);

      this.dataChanged.emit();

    });
  }

  createItem(item: any) {
    this.eventsService.setValue(Events.STEP_DATA_SAVING, true);

    const stepId = this.tacData[this.currentSection.name][this.currentStep.name]
      .id;
    this.tacService.createCustomItem(
      this.currentProject,
      stepId,
      this.entity,
      this.entityId,
      item,
    ).subscribe(response => {
      item = response.data.termsandcond;
      item.step_id = stepId;
      item.model_id = this.entityId;
      item.model = this.entity;
      this.tacService.loadTocData(this.currentProject.id);
    });
  }

  deleteItem(item: any) {
    this.tacService.deleteCustomItem(this.currentProject, item);
  }

  addItem() {
    this.items.push({value: null, percentage: null, is_editable: true});
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.items.forEach((item, index) => {
      item.position = index;
      this.tacService.updateCustomItem(this.currentProject, item).then(resp => {
        this.tacService.loadTocData(this.currentProject.id);
      });
    });
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.onCollapseChange.emit(this.isCollapsed);
  }
}
