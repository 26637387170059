<div kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
    <div [title]="tooltip ? tooltip : ''">
        <svg version="1.1" id="Component_266_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
            style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <path fill="#585858" d="M12,3c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S17,3,12,3z" />
            <g>
                <path fill="#FEFEFE" d="M12,6.1l3,4.8H9L12,6.1z" />
                <path fill="#FEFEFE" d="M12,17.9l-3-4.8h6L12,17.9z" />
            </g>
        </svg>
    </div>
</div>