<div class="project-detail-step">
    <div class="input-text">
        <mat-form-field class="is-fullwidth">
            <mat-label>{{component.placeholder | translate}}</mat-label>
            <textarea  style="overflow:hidden" cdkTextareaAutosize matInput [(ngModel)]="component.answer.value" [maxlength]="component.max_chars"
                (change)="onValueChange()" *ngIf="component.type =='textarea'" autocomplete="off"></textarea>
            <input type="text" matInput [(ngModel)]="component.answer.value" autocomplete="off"
                [maxlength]="component.max_chars" (change)="onValueChange()" mask="separator.3" thousandSeparator=","
                *ngIf="component.type == 'integer'">
            <input type="text" matInput [(ngModel)]="component.answer.value" autocomplete="off"
                [maxlength]="component.max_chars" (change)="onValueChange()" mask="separator.3" thousandSeparator=","
                *ngIf="component.type == 'percentage'">
        </mat-form-field>
    </div>
</div>
