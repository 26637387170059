<div class="ebp-dialog">
  <ng-container [ngSwitch]="text?.title_icon">
    <svg *ngSwitchCase="'warning'" version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg" width="32px"
      height="32px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
      style="enable-background:new 0 0 24 24;" xml:space="preserve" ngClass="{{text?.title_icon_color}}-title-icon">
      <style type="text/css">
        .st012 {
          fill: #333333;
        }
      </style>
      <path class="st012" d="M1,21.5h22l-11-19L1,21.5z M13,18.5h-2v-2h2V18.5z M13,14.5h-2v-4h2V14.5z" />
    </svg>

    <svg *ngSwitchCase="'confirmation'" version="1.1" id="Icon_-_checkcircle" xmlns="http://www.w3.org/2000/svg"
      width="32px" height="32px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
      style="enable-background:new 0 0 24 24;" xml:space="preserve" ngClass="{{text?.title_icon_color}}-title-icon">
      <style type="text/css">
        .st023 {
          fill: #333333;
        }
      </style>
      <path id="Icon_Check_Circle_Purple" class="st023" d="M10.7,17l-4.5-4.5l1.3-1.3l3.2,3.2l6.8-6.8l1.3,1.3L10.7,17z M12.5,3.5
	c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C21.5,7.5,17.5,3.5,12.5,3.5z" />
    </svg>

    <svg *ngSwitchCase="'ebp'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"   width="44px" height="44px"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  x="0px" y="0px" viewBox="0 0 44 44"
      style="enable-background:new 0 0 24 24;" xml:space="preserve" ngClass="{{text?.title_icon_color}}-title-icon"
     >
      <path d="M36.667,22l-3.253-3.72l0.453-4.92l-4.813-1.093L26.534,8L22,9.947L17.467,8l-2.52,4.253l-4.813,1.08
	l0.453,4.933L7.333,22l3.253,3.72l-0.453,4.934l4.813,1.094L17.467,36L22,34.039l4.534,1.948l2.52-4.254l4.813-1.093l-0.453-4.917
	L36.667,22z M19.453,28.294l-5.066-5.08l1.973-1.974l3.093,3.106l7.8-7.827l1.974,1.973L19.453,28.294z" />
    </svg>
  </ng-container>

  <h2 mat-dialog-title ngClass="{{text?.labels?.title_color}}-title">{{text?.labels?.title  | translate}}</h2>
  <p class="subtitle-normal" *ngIf="text?.labels.text" [innerHTML]="text?.labels.text | translate"></p>
  <p class="subtitle-normal" *ngIf="text?.labels.text_1" [innerHTML]="replaceText(text?.labels.text_1 | translate)"></p>
  <p class="subtitle-normal" *ngIf="text?.labels.text_2" [innerHTML]="text?.labels.text_2 | translate"></p>
  <p class="subtitle-normal" *ngIf="text?.labels?.text_rejected_steps_donotallow_ebp_submit" [innerHTML]="text?.labels.text_rejected_steps_donotallow_ebp_submit | translate"></p>

  <button class="ebp-action-button submit" [mat-dialog-close]="true" cdkFocusInitial *ngIf="text?.labels?.cta_submit_ebp">
    <span>{{text?.labels?.cta_submit_ebp | translate | replacePlaceholder: data?.currentProject?.replace}}</span>
    <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
      style="enable-background:new 0 0 38 24;" xml:space="preserve">
      <style type="text/css">
        .st0001 {
          fill: none;
        }
  
        .st00011 {
          fill: #ffffff;
        }
      </style>
      <path id="Path_2483" class="st0001" d="M0,0h38v24H0V0z" />
      <g>
        <path class="st00011" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
              M12,11L4,6h16L12,11z" />
        <polygon class="st00011" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
      </g>
    </svg>
  </button>

  <button class="ebp-action-button validate" [mat-dialog-close]="true" cdkFocusInitial *ngIf="text?.labels?.cta_validate_ebp">
    <span>{{text?.labels?.cta_validate_ebp | translate | replacePlaceholder: data?.currentProject?.replace}}</span>
    <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
      style="enable-background:new 0 0 38 24;" xml:space="preserve">
      <style type="text/css">
        .st0001 {
          fill: none;
        }
  
        .st00011 {
          fill: #ffffff;
        }
      </style>
      <path id="Path_2483" class="st0001" d="M0,0h38v24H0V0z" />
      <g>
        <path class="st00011" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
              M12,11L4,6h16L12,11z" />
        <polygon class="st00011" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
      </g>
    </svg>
  </button>

  <button class="ebp-button button-transparent" ngClass="{{text?.labels?.cta_ok_color}}" [mat-dialog-close]="true"
    cdkFocusInitial *ngIf="text?.labels.cta_ok"><span>{{text?.labels.cta_ok  | translate}}</span></button>
  <button class="ebp-button button-transparent" [mat-dialog-close]="false"
    *ngIf="text?.labels.cta_cancel"><span>{{text?.labels.cta_cancel  | translate}}</span></button>


  <button class="ebp-button button-transparent" [mat-dialog-close]="'dashboard'"
    *ngIf="text?.labels?.cta_goto_dashboard"><span>{{text?.labels.cta_goto_dashboard  | translate}}</span></button>
  <button class="ebp-button button-transparent" [mat-dialog-close]="'cover'" cdkFocusInitial
    *ngIf="text?.labels?.cta_goto_ebpcover"><span>{{text?.labels.cta_goto_ebpcover  | translate}}</span></button>
</div>
