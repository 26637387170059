<div class="dashboard-project-card">
    <div style="cursor: pointer; outline: none;" [routerLink]="['/projects/', project.id]">
        <header>
            <div class="header-row">
                <h2 class="project-title">{{project.full_name | translate}}</h2>
                <div class="project-status">
                    <shared-status-icon [status]="project.status" [width]="33"></shared-status-icon>
                </div>
            </div>
            <p class="text-muted">creation date: {{project.created_at | date}}</p>
            <div class="card-properties">
                <p><span class="text-muted" style="display: block;">{{'OWNER' | translate}}: </span>{{project.owner.given_name}} {{project.owner.family_name | uppercase}}
                </p>
            </div>
        </header>
        <mat-divider></mat-divider>
        <div class="card-properties">
            <p><span class="text-muted">{{'EBP_COVER_ZONE' | translate}} </span>{{project.zones | asList }}</p>
            <p><span class="text-muted">{{'EBP_COVER_COUNTRY' | translate}} </span>{{project.clusters | asList }}</p>
            <p><span class="text-muted">{{'CREATE_EBP_LANGUAGE' | translate}} </span>{{project.language.name_en  }}</p>
        </div>
        <div class="card-properties">
            <p><span class="text-muted">{{'EBP_COVER_DIVISIONS' | translate}} </span>{{project.divisions | asList : "code" }}</p>
            <p><span class="text-muted">{{'EBP_COVER_BRANDS' | translate}} </span>{{project.brands | asList }}</p>
        </div>
        <div class="card-properties">
            <p><span class="text-muted">{{'DASHBOARD_FILTER_RETAILER'}}: </span>{{project.retailer.name }}</p>
            <p><span class="text-muted">{{'DASHBOARD_FILTER_YEAR' | translate}}: </span>{{project.year }}</p>
        </div>
    </div>
    <mat-divider class="last"></mat-divider>
    <div class="project-modules">
        <ng-container  *ngFor="let module of project.modules">
            <shared-module-icon *ngIf="module.status == 'online' && module.version.is_active" [routerLink]="['/projects', project.id, module.alias]" [type]="module.alias" [status]="module.version.status"></shared-module-icon>
        </ng-container>
    </div>
    <div class="footer">
        <mat-divider></mat-divider>
        <div class="project-actions">
            <span class="material-icons" [routerLink]="['/projects', project.id]">edit</span>
            <ng-container *ngIf="(user$ | async)?.id === project.owner.id">
              <span class="material-icons" [routerLink]="['/projects/edit', project.id]">settings</span>
              <span (click)="deleteEbp(project.id)" class="material-icons">delete</span>
            </ng-container>
            <span class="material-icons">library_add</span>
        </div>
    </div>
</div>
