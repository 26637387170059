import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { differenceBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Product } from '../../models/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'shared-product-autocomplete',
  templateUrl: './product-autocomplete.component.html',
  styleUrls: [ './product-autocomplete.component.scss' ],
})
export class ProductAutocompleteComponent implements OnInit {
  constructor(private productService: ProductService) {}

  formControl = new FormControl();
  filteredProducts: Observable<Product[]>;

  @Input()
  products: Product[] = [];

  @Input()
  selectedProducts: Product[] = [];

  @Output()
  selected = new EventEmitter<Product>();

  ngOnInit(): void {
    this.filteredProducts = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
    );
  }

  productSelected(p) {
    this.selected.emit(p);
    this.formControl.setValue('');
  }

  private _filter(value: string): Product[] {
    const filterValue = (typeof value === 'string') ? value.toLowerCase() : '';
    const filteredProducts = this.products.filter(product => ((product.ean + product.name).toLowerCase()).includes(filterValue));
    return differenceBy(filteredProducts, null, 'ean'); //this.selectedProducts
  }

}
