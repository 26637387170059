import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Brand } from 'src/app/shared/models/brand.model';
import { Project } from 'src/app/shared/models/project.model';
import { Section } from 'src/app/shared/models/section.model';
import { Set } from 'src/app/shared/models/set.model';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'project-set',
  templateUrl: './project-set.component.html',
  styleUrls: [ './project-set.component.scss' ],
})
export class ProjectSetComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  currentSection: Section;

  @Input()
  currentSet: Set;

  @Input()
  currentBrand: Brand;

  @Input()
  ebpStatus: string;

  @Input() brandStepSlug: string;
  @Input() brandRepeat = false;

  public showStep: boolean = false;

  public setIsVisible: boolean = true;

  public currentProject: Project;

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        this.currentProject = project;
        if (this.currentSet.retailer_specific) {
          this.showStep = this.currentSet.retailer_specific == this.currentProject.retailer.alias;
        } else {
          this.showStep = true;
        }
      }),
      
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleSet() {
    this.setIsVisible = !this.setIsVisible;
  }

  trackByFn(index, item) {
    return item.name;
  }
}
