<div class="project-detail-step">
    <table class="table ebp-table">
        <thead>
            <tr>
                <th *ngFor="let header of component.header" [width]="header.width">{{header.text  | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of component.answer.values; index as i; trackBy: trackByFn">
                <td class="no-border-right">
                    <mat-form-field>
                        <input type="text" autocomplete="off" matInput [(ngModel)]="component.answer.values[i]" (ngModelChange)="onModelChange()" (keydown.enter)="addItem()" (click)="shouldFocus = !shouldFocus" autofocus>
                    </mat-form-field>
                </td>
                <td class="no-border-left">
                    <shared-exclude-icon (onAlertProceed)="removeItemAtIndex(i)" [alert]="'alert_deletion'"></shared-exclude-icon>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <shared-add-button (click)="addItem()"></shared-add-button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
