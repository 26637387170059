<label>{{'M_ACTIVATION_ACTIVITIES_LABEL_ACTIVITY_NAME' | translate}}</label>
<div class="input-row">
  <activity-autocomplete [activityId]="activationEvent.activity_id"
                         (activityChange)="onActivityChange($event)"></activity-autocomplete>
  <shared-exclude-icon (onAlertProceed)="delete()" [alert]="'alert_deletion'"
                       *ngIf="activationEvent.id"></shared-exclude-icon>
</div>
<div class="date-picker-row">
  <shared-daterange-picker [startDate]="activationEvent.starts_at" [endDate]="activationEvent.ends_at"
                           (dateChange)="onDateChange($event)"></shared-daterange-picker>
</div>
