<section class="ebp-expandable-item">
    <header (click)="collapse()">
        <shared-expand-icon [rotate]="!isCollapsed"></shared-expand-icon>
        <span>{{'M_SERVICES_SERVICES_TABLE_EXTRA_OPTIONS' | translate}}</span>
    </header>
    <div class="ebp-expandable-content" [@collapse]="(isCollapsed && optionGroups != null)">
        <div class="columns">
            <div class="column" *ngFor="let optionGroup of optionGroups">
                <table class="ebp-expandable-table">
                    <thead>
                        <th colspan="2">{{optionGroup.title | translate}}</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let option of optionGroup.items">
                            <td>
                                {{option.label | translate}}
                            </td>
                            <td>
                                <mat-slide-toggle [checked]="option.selected" (change)="onOptionChange($event, option)">
                                </mat-slide-toggle>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
