<div class="project-detail-step">
    <div class="content">
        <mat-radio-group [(ngModel)]="selectedValue" (ngModelChange)="onSelection()">
            <div class="option-group" *ngFor="let option of component.options">
                <mat-radio-button [value]="option.value">
                    <span *ngIf="option.label">
                        {{option.label | translate}}
                    </span>
                </mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</div>
