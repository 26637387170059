<div class="activity-edit-dialog">
  <div class="activity-edit-logo">
    <shared-module-icon [type]="'activation'" [status]="'edited'"></shared-module-icon>
  </div>

  <div class="activity-edit-title">
    {{configData?.labels.title | translate}}
  </div>

  <div class="activity-edit-input">
    <mat-form-field>
      <input matInput class="activity-name" type="text" [(ngModel)]="activity.name"
             [placeholder]="configData?.labels.text_1 | translate" autocomplete="off">
    </mat-form-field>
  </div>

  <div class="activity-edit-checkbox" kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
    <mat-slide-toggle class="activity-switcher"
                      [checked]="!activity.is_active"
                      (change)="archiveActivity($event)"
                      [title]="configData?.labels.text_2 | translate">      
    </mat-slide-toggle>
    <span class="slide-label">{{configData?.labels.text_2 | translate}}</span>
  </div>
  <div class="activity-edit-actions">
    <button class="ebp-button button-transparent" [mat-dialog-close]="activity" cdkFocusInitial>
      <span>{{configData?.labels.cta_save | translate}}</span></button>
      
    <button class="ebp-button button-transparent" [mat-dialog-close]="false">
      <span>{{configData?.labels.cta_close | translate}}</span></button>
  </div>
  <div class="activity-edit-note">
    {{configData?.labels.text_3 | translate}}
  </div>
</div>
