<div class="ebp-dialog">
    <div class="header">
        <div class="material-icons">settings</div>
        <h2 mat-dialog-title>{{ configData.labels?.title | translate }}</h2>
    </div>

    <div class="actions">
        <button class="ebp-button button-transparent full-width" [mat-dialog-close]="false" [routerLink]="[ '/projects/edit', data.ebpId ]">
            <span>{{ configData.labels?.cta_edit_settings | translate }}</span>
        </button>

        <button class="ebp-button button-transparent orange full-width" [mat-dialog-close]="'duplicate'">
            <span>{{ configData.labels?.cta_duplicate_ebp | translate }}</span>
        </button>

        <button class="ebp-button button-transparent" [mat-dialog-close]="false">
            <span>{{ configData.labels?.cta_cancel | translate }}</span>
        </button>
    </div>
</div>
