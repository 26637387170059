import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: [ './add-button.component.scss' ],
})
export class AddButtonComponent implements OnInit {
  @Input()
  label = 'Add Item';

  @Input()
  className: string;

  constructor() {}

  ngOnInit(): void {}
}
