import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaderResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { GenericErrorDialogComponent } from 'src/app/shared/components/generic-error-dialog/generic-error-dialog.component';
import { UploadDialogComponent } from 'src/app/shared/components/upload-dialog/upload-dialog.component';
import { Events } from 'src/app/shared/enums/events';
import { Brand } from 'src/app/shared/models/brand.model';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { Step } from 'src/app/shared/models/step.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EventsService } from 'src/app/shared/services/events.service';
import { FileService } from 'src/app/shared/services/file.service';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { environment } from 'src/environments/environment';
import { ProjectUserRole } from '../../../shared/enums';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'project-up-and-downloader',
  templateUrl: './up-and-downloader.component.html',
  styleUrls: ['./up-and-downloader.component.scss'],
})
export class UpAndDownloaderComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  component: StepComponent;

  @Input()
  componentIndex: number;

  @Input()
  brand: Brand;

  @Input()
  currentStep: Step;

  @Output()
  onChange = new EventEmitter<string>();

  public percentage: number = 0;
  public downloadUrl: string;

  public currentProject: Project;
  public currentModule: Module;

  public modules: Module[] = [];

  title: string;

  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  export: any = {};

  constructor(
    public dialog: MatDialog,
    private moduleService: ModuleService,
    private projectService: ProjectService,
    private httpClient: HttpClient,
    private httpService: GenericHttpService,
    private authenticationService: AuthenticationService,
    private eventsService: EventsService,
    private fileService: FileService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.projectService.currentProject.subscribe(p => {
        this.currentProject = p;
        if (this.currentProject) {
          if (this.component.type == 'downloader') {
            if (!this.component.title) {
              this.component.title = 'label missing';
            }
            this.title = this.component.title;
            if (this.brand) {
              this.title = this.title.replace('{{brand_name}}', this.brand.name);
              this.downloadUrl = environment.api.base_url + '/api/' + environment.api.version + this.component.endpoint.replace('{{EBP_ID}}', this.currentProject.id.toString()).replace('{{brand_id}}', this.brand.id.toString());
            } else {
              if (this.component.endpoint) {
                this.downloadUrl = environment.api.base_url + '/api/' + this.component.endpoint.replace('{{EBP_ID}}', this.currentProject?.id.toString());
              }
              if (this.component.value) {
                this.downloadUrl = environment.api.base_url + '/api/' + environment.api.version + this.component.value;
              }
            }
          }
          if (this.component.type == 'uploader' && this.component.answer?.files) {
            for (let i = 0; i < this.component.answer.files.length; i++) {
              if (this.component.answer.files[i].uuid) {
                this.component.answer.files[i]['downloadUrl'] = environment.api.base_url + '/api/' + environment.api.version + '/download/' + this.component.answer.files[i].uuid + '/' + this.component.answer.files[i].filename_original;
              }
            }
          }

        }
      }),
    );
    this.subscription.add(
      this.moduleService.currentModule.subscribe(m => {
        this.currentModule = m;
      }),
    );
    this.subscription.add(
      this.moduleService.projectModules.subscribe(m => this.modules = m),
    );
    this.subscription.add(
      this.projectService.currentProjectJobs.subscribe((jobs) => {
        this.checkJobStatus(jobs);
      })
    );

  }

  ngAfterViewInit() {
    if ((!this.component.answer || this.component.answer?.files.length == 0) && this.currentModule && this.currentModule.alias.toLocaleLowerCase() == 'legal' && this.component.type == 'uploader') {
      this.component['answer'] = {};
      this.component['answer']['files'] = [
        {
          "filename_original": "DUMMY_FILENAME_0"
        }
      ];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  startDownload(event: any, downloadURL: string) {

    let downloadType = (downloadURL) ? downloadURL.substring(downloadURL.lastIndexOf("/") + 1, downloadURL.length) : "";

    if (downloadType && downloadType.length > 0) {

      switch (downloadType.toLowerCase()) {

        case 'all':
        case 'catalogues':

          this.httpClient.get(downloadURL).subscribe((response: any) => {
            if (response.data && response.data.job && response.data.job.status == 'queued') {

              this.export[downloadType] = true;

              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = {
                dialog_key: 'alert_export_job_enqueued',
              };

              this.dialog
                .open(ConfirmDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((res) => {
                  const currentUser = this.authenticationService.currentUserValue;
                  this.projectService.startPollingJobs(this.currentProject.id, currentUser.id);
                });
            }
          }, (errorResponse: any) => {
            //console.error(errorResponse);
            if (errorResponse instanceof HttpErrorResponse && errorResponse.status != 200) {
              if (errorResponse.error && errorResponse.error.data && errorResponse.error.data.errors) {

                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = {
                  error: errorResponse.error.data.errors,
                  title: 'DIALOGS_WARNING_TITLE'
                };

                this.dialog
                  .open(GenericErrorDialogComponent, dialogConfig)
                  .afterClosed()
                  .subscribe((rs) => {

                  });
              }

            } else {
              if (this.percentage == 0) {
                this.triggerDownload(downloadURL);
              }
            }
          });

          break;

        default:
          if (this.percentage == 0) { //start only if  download is not in progress
            this.httpClient.get(downloadURL).subscribe((response: any) => { }, (errorResponse: any) => {
              //console.error(errorResponse);
              if (errorResponse instanceof HttpErrorResponse && errorResponse.status != 200) {
                if (errorResponse.error && errorResponse.error.data && errorResponse.error.data.errors) {

                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.data = {
                    error: errorResponse.error.data.errors,
                    title: 'DIALOGS_WARNING_TITLE'
                  };

                  this.dialog
                    .open(GenericErrorDialogComponent, dialogConfig)
                    .afterClosed()
                    .subscribe((rs) => {

                    });
                }

              } else {
                this.triggerDownload(downloadURL);
              }
            });
          }
      }

    }

  }

  triggerDownload(downloadURL: string) {
    let link = document.createElement("a");
    link.href = downloadURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
    this.percentage = 0;
    let interval = setInterval(() => {
      if (this.percentage <= 100) {
        this.percentage++;
      } else {
        clearInterval(interval);
        this.percentage = 0;
      }
    }, 80);
  }

  checkJobStatus(jobs: any) {
    if (jobs && jobs.length > 0 && this.currentProject && this.currentProject.id) {

      const currentUser = this.authenticationService.currentUserValue;

      let exportTypesToCheck = ['all', 'catalogues'];

      for (let exportType of exportTypesToCheck) {
        let filteredJobs = jobs.filter(item => item.parameters.ebp_id == this.currentProject.id && item.user_id == currentUser.id && item.parameters.export_type == exportType);

        if (filteredJobs && filteredJobs.length > 0) {
          this.export[exportType] = filteredJobs[filteredJobs.length - 1].status != 'finished';
        }
      }
    }
  }

  isDownloadDisabled() {
    let component = this.component;
    if (component && component.type == 'downloader' && component.endpoint != "") {

      let endpoint = component.endpoint;
      let downloadType = endpoint.substring(endpoint.lastIndexOf("/") + 1, endpoint.length);

      if (this.currentProject && this.currentProject.catalogue_country === null && (downloadType == 'virtual_bundles' || downloadType == 'catalogues')) {
        return true;
      } else {
        return this.export[downloadType] ? this.export[downloadType] : false;
      }
    }

    return false;
  }

  showMissingCatalogueMsg() {
    let component = this.component;
    if (component && component.type == 'downloader' && component.endpoint != "") {
      let endpoint = component.endpoint;
      let downloadType = endpoint.substring(endpoint.lastIndexOf("/") + 1, endpoint.length);
      if (this.currentProject && this.currentProject.catalogue_country === null && (downloadType == 'virtual_bundles' || downloadType == 'catalogues')) {
        return true;
      }
    }
    return false;
  }

  openUploader(index: number = null) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    if (!this.brand) {
      let other_files_metadata  = [];
      let filesCopy = (this.currentStep.components[0].answer && this.currentStep.components[0].answer.files.length > 0) ? this.currentStep.components[0].answer.files.slice() : []
      if (filesCopy) {
        if (index !== null) {
          if (filesCopy[index]) {
            filesCopy.splice(index, 1);
          }
        }
        other_files_metadata = filesCopy.filter(x => x.uuid);
      }

      dialogConfig.data = {
        ebp_id: this.currentProject.id,
        module_alias: this.currentModule.alias,
        step_name: this.currentStep.name,
        step_id: this.currentStep.id,
        componentIndex: this.componentIndex,
        other_files_metadata: other_files_metadata
      };
    }
    else {
      dialogConfig.data = {
        ebp_id: this.currentProject.id,
        module_alias: this.currentModule.alias,
        brand_id: this.brand.id,
        isCatalogueUpload: true,
      };
    }

    this.dialog.open(UploadDialogComponent, dialogConfig);
  }

  deleteFile() {
    this.httpService.delete('/step/' + this.currentStep.id).subscribe(response => {
      this.component.answer = false;
      this.currentStep.id = null;

      this.updateStatusOnCTAUpdate(this.currentProject.id);
    });
  }

  updateStatusOnCTAUpdate(ebp_id: number) {
    if (this.currentModule) {
      this.moduleService.getCurrentModule(ebp_id, this.currentModule.alias);
    }
    this.moduleService.loadModulesForProject(ebp_id); // Refresh rejection count on top bar
    this.projectService.refreshCurrentProject(ebp_id).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_PROJECT_STATUS, response.data.ebp);  //refresh ebp status
    });
  }

  isActive(component: any) {
    if (this.modules) {
      let source_module = component.source_module;

      if (!source_module) return true;

      let index = this.modules.findIndex((m) => m.alias == source_module && m.version.is_active);
      return index > -1;
    } else {
      return true;
    }
  }

  addMoreAttachments(component) {
    console.log(component);
    if (!component.answer) {
      component['answer'] = {};
      component['answer']['files'] = [
        {
          "filename_original": "DUMMY_FILENAME_0"
        }
      ];
    } else {
      let dummyObj = {
          "filename_original": "DUMMY_FILENAME_" + component['answer']['files'].length
        };
      component['answer']['files'].push(dummyObj);
    }
  }

  removeAttachment(component, index) {
    let removedFile = component.answer.files.splice(index, 1);

    if (!removedFile[0].uuid) return;

    let other_files_metadata  = [];
    if (component.answer && component.answer.files.length > 0) {
      other_files_metadata = component.answer.files.filter(x => x.uuid);
    }

    let data = {
      ebp_id: this.currentProject.id,
      module_alias: this.currentModule.alias,
      step_name: this.currentStep.name,
      step_id: this.currentStep.id,
      componentIndex: this.componentIndex,
      other_files_metadata: other_files_metadata
    };


    if (!component.answer.files || component.answer.files.length == 0) {
      this.component['answer'] = {};
      this.component['answer']['files'] = [
        {
          "filename_original": "DUMMY_FILENAME_0"
        }
      ];
    }

    this.fileService
    .upload(null, data)
    .subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:

          break;
        case HttpEventType.Response:

          break;
        default:
          if (event instanceof HttpErrorResponse) {

          } else if (event instanceof HttpHeaderResponse) {

          }
      }
    });
  }

}
