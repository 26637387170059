import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { Section } from 'src/app/shared/models/section.model';
import { Step } from 'src/app/shared/models/step.model';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';
import { Events } from '../../shared/enums/events';
import { EventsService } from '../../shared/services/events.service';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsService {
  constructor(private http: GenericHttpService,
              private eventsService: EventsService) {}

  private tocDataSource = new BehaviorSubject<any>(null);
  public tocData = this.tocDataSource.asObservable();

  isLoadingData: boolean = false;

  loadTocData(ebpId: number) {
    if (this.isLoadingData) {
      return;
    }
    this.isLoadingData = true;
    this.http.get('/termsandcond/' + ebpId).subscribe((response) => {
      this.tocDataSource.next(response.data.termsandcond);
      this.eventsService.setValue(Events.STEP_DATA_SAVING, false);
      this.isLoadingData = false;
    });
  }

  initStep(project: Project, section: Section, step: Step) {
    const url = [ 'termsandcond', project.id, 'step' ].join('/');
    const data = {
      section_name: section.name,
      step_name: step.name,
    };
    this.http.post('/' + url, {}).subscribe((response) => {
      this.loadTocData(project.id);
    });
  }

  updateModelSelection(
    project: Project,
    section: Section,
    step: Step,
    model: string,
  ): Promise<any> {
    return new Promise<any>(resolve => {
      const data = {
        section_name: section.name,
        step_name: step.name,
        current_model: model,
      };
      const url = [ 'termsandcond', project.id, 'step' ].join('/');
      this.http.post('/' + url, data).subscribe((response) => {
        resolve(response);
      });
    });
  }

  updateCascade(
    project: Project,
    section: Section,
    step: Step,
    entity_type: string,
    entity: any,
    cascading: boolean,
  ) {
    const data = {
      section_name: section.name,
      step_name: step.name,
      model: entity_type,
      model_id: entity.id,
      is_cascading: cascading,
    };
    const url = [ 'termsandcond', project.id, 'step', 'cascade' ].join('/');
    this.http.post('/' + url, data).subscribe((response) => {
      this.loadTocData(project.id);
    });
  }

  createCustomItem(project: Project, stepId: number, entity: string, entityId: number, item: any) {
    const url = [ 'termsandcond', project.id, 'step', stepId, entity, entityId ].join('/');
    return this.http.post('/' + url, item);
  }

  updateCustomItem(project: Project, item: any): Promise<any> {
    return new Promise<any>(resolve => {
      const url = [ 'termsandcond', project.id, 'step', item.terms_and_conditions_step_id, 'item', item.id ].join('/');
      this.http.put('/' + url, item).subscribe(response => {
        resolve();
      });
    });
  }

  deleteCustomItem(project: Project, item: any) {
    const url = [ 'termsandcond', project.id, 'step', item.terms_and_conditions_step_id, 'item', item.id ].join('/');
    this.http.delete('/' + url).subscribe(response => {
      this.loadTocData(project.id);
    });
  }
}
