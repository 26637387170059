import { ReturnStatement } from '@angular/compiler';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ebpTitle'
})
export class EbpTitlePipe implements PipeTransform {

  transform(input: any, key: any = null): string {
    if(typeof input == "string") {
      return input.replace(" ","")
    }

    return input.map(i => i[key]).join("-").replace(" ", "");
  }

}
