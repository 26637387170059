<div class="project-detail-step" *ngIf="currentProject && !isHidden && answers">
  <table class="table ebp-table">
    <thead>
    <tr class="headers">
      <th *ngFor="let header of component.headers"
          [width]="header.width">{{header.label | translate | replacePlaceholder: currentProject.replace}}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="entity == 'brands'">
      <ng-container *ngFor="let brand of currentProject.brands">
        <tr class="align-items-inside">
          <td *ngFor="let column of component.rows.columns">
            <div class="d-flex" [ngSwitch]="column.name">
              <div *ngSwitchCase="'brand_name'">
                {{brand.name}}
              </div>
              <div *ngSwitchCase="'brand_total_sell_in'">
                {{tocData['brand'][brand.id].header.total_target | mask:'separator.2':','}}
              </div>
              <div *ngSwitchCase="'brand_termsandcond_step_total'">
                {{tocData['brand'][brand.id].header.value | mask:'separator.2':','}}
              </div>
              <div *ngSwitchCase="'brand_termsandcond_step_perc'">
                {{tocData['brand'][brand.id].header.percentage | mask:'separator.2':','}} %
              </div>
              <span class="ml-1"
                    *ngIf="column.type === ROW_COLUMN_TYPES.currency">{{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</span>
              <div *ngSwitchDefault>{{column.name}}</div>
            </div>
          </td>
          <td class="text-left">
            <mat-slide-toggle [(ngModel)]="tocData['brand'][brand.id].header.is_cascading"
                              (change)="onCascadingChange($event, brand, 'brand')"></mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="component.headers.length + 1 " class="pr-0"
              *ngIf="tocData['brand'][brand.id].header.total_target > 0">
            <custom-toc-items [items]="tocData['brand'][brand.id].items" [entity]="'brand'" [entityId]="brand.id"
                              [currentProject]="currentProject" [currentSection]="currentSection"
                              [currentStep]="currentStep" [headers]="component.headers"
                              [cascading]="tocData['brand'][brand.id].header.is_cascading"
                              [isCollapsed]="collapsedData.brands[brand.id]"
                              (dataChanged)="dataChange()"
                              (onCollapseChange)="onCollapseChange(brand.id, $event)"></custom-toc-items>
          </td>

          <shared-inpage-step-dialog [dialog_key]="'alert_undefined_sellin'"
                                     (onHideInPageDialog)="navigateToStep('target','sell_in_brands')"
                                     *ngIf="tocData['brand'][brand.id].header.total_target == 0"></shared-inpage-step-dialog>
        </tr>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="entity == 'divisions'">
      <ng-container *ngFor="let division of currentProject.divisions">
        <tr class="align-items-inside">
          <td *ngFor="let column of component.rows.columns">
            <div class="d-flex" [ngSwitch]="column.name">
              <div *ngSwitchCase="'division_name'">
                {{division.name}}
              </div>
              <div *ngSwitchCase="'division_total_sell_in'">
                {{tocData['division'][division.id].header.total_target | mask:'separator.2':','}}
              </div>
              <div *ngSwitchCase="'division_termsandcond_step_total'">
                {{tocData['division'][division.id].header.value | mask:'separator.2':','}}
              </div>
              <div *ngSwitchCase="'division_termsandcond_step_perc'">
                {{tocData['division'][division.id].header.percentage | mask:'separator.2':','}} %
              </div>
              <span class="ml-1"
                    *ngIf="column.type === 'currency'">{{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</span>
              <div *ngSwitchDefault>{{column.name}}</div>
            </div>
          </td>
          <td class="text-left">
            <mat-slide-toggle [(ngModel)]="tocData['division'][division.id].header.is_cascading"
                              (change)="onCascadingChange($event, division, 'division')"></mat-slide-toggle>
          </td>
        </tr>
        <tr>
          <td [attr.colspan]="component.headers.length + 1 " class="pr-0"
              *ngIf="tocData['division'][division.id].header.total_target > 0">
            <custom-toc-items [items]="tocData['division'][division.id].items" [entity]="'division'"
                              [entityId]="division.id" [currentProject]="currentProject"
                              [currentSection]="currentSection" [currentStep]="currentStep"
                              [cascading]="tocData['division'][division.id].header.is_cascading"
                              [isCollapsed]="collapsedData.divisions[division.id]"
                              (dataChanged)="dataChange()"
                              (onCollapseChange)="onCollapseChange(division.id, $event)"></custom-toc-items>
          </td>
          <shared-inpage-step-dialog [dialog_key]="'alert_undefined_sellin'"
                                     (onHideInPageDialog)="navigateToStep('target','sell_in_brands')"
                                     *ngIf="tocData['division'][division.id].header.total_target == 0"></shared-inpage-step-dialog>

        </tr>
      </ng-container>
    </ng-container>

    </tbody>
  </table>
</div>
