<div kendoTooltip tooltipClass="kendoTooltipOverride" position="right" class="ebp-interface-button"
    (click)="showAlert()">
    <div [title]="tooltip ? tooltip : ''">
        <svg version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
            style="enable-background:new 0 0 24 24;" xml:space="preserve">
            <style type="text/css">
                .st045 {
                    fill: none;
                }

                .st144 {
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: #333333;
                }
            </style>
            <rect id="Rectangle_14592" class="st045" width="24" height="24" />
            <path class="st144" d="M18.4,5.6c-3.5-3.5-9.2-3.5-12.7,0s-3.5,9.2,0,12.7s9.2,3.5,12.7,0S21.9,9.2,18.4,5.6z M17.4,12.9H6.6v-1.8
	h10.9V12.9z" />
        </svg>
    </div>
</div>