<div class="project-detail-step">
  <table class="table ebp-table reset-table">
    <thead>
    <tr>
      <th class="sticky-element" *ngFor="let header of component.headers"
          [width]="header.width">{{header.label | translate}}</th>
      <th class="sticky-element"></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let service of component?.answer?.values; let i = index">

      <tr class="table-item-header">
        <td class="text-is-16">{{ service.name | translate}}</td>
        <td class="align-middle">{{ service.availability | translate}}</td>
        <td class="align-middle">
          <shared-date-picker [date]="service.launch_date" (changed)="onLauchDateChange($event, service)">
          </shared-date-picker>
        </td>
        <td class="align-middle">
          <mat-slide-toggle [(ngModel)]="service.is_exclusive" (change)="onDataChange()">
          </mat-slide-toggle>
        </td>
        <td class="align-middle">
          <!-- <shared-daterange-picker [startDate]="service.custom_launch_date"
              [endDate]="service.exclusivity_end"
              (dateChange)="onExclusivityPeriodChange($event,service)"></shared-daterange-picker> -->

          <shared-date-picker [date]="service.exclusivity_end" (changed)="onExclusivityDateChange($event, service)">
          </shared-date-picker>

        </td>
        <td class="align-middle">
          <shared-info-icon class="float-left" [tooltip]="component.services[i]?.guideline | translate"></shared-info-icon>
        </td>
      </tr>
      <tr class="table-item-subheader">
        <td [attr.colspan]="component.headers.length + 1">
          <div class="ebp-chips">
            <span class="ebp-chip-sm" *ngFor="let chip of service.properties">{{chip | translate}}</span>
          </div>
      </tr>
      <tr class="table-content">
        <td [attr.colspan]="component.headers.length + 1">
          <bundler-brand-toggle-table [brands]="service.brands"
                                      [subCategories]="service.product_sub_categories"
                                      (onChange)="onBrandChange($event, service)"
                                      [isCollapsed]="service.brands_collapsed"
                                      (onCollapseChange)="collapse('brand',service, $event)">
          </bundler-brand-toggle-table>

          <bundler-extra-options [extraOptions]="service.extra_options"
                                 [options]="service.extra_options_key"
                                 (onChange)="onExtraOptionChange($event, service)"
                                 [isCollapsed]="service.extra_options_collapsed"
                                 (onCollapseChange)="collapse('extraOptions',service, $event)">

          </bundler-extra-options>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
