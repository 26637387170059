<div class="project-detail-step">
    <div class="content">
        <p>{{component?.guideline | translate}}</p>
        <mat-radio-group [(ngModel)]="component.answer.values" (ngModelChange)="onValueChange()">
            <div class="option-group" *ngFor="let option of component.options">
                <mat-radio-button [value]="option.value">
                    <span *ngIf="option.label">
                        {{option.label | translate}}
                    </span>
                </mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</div>
