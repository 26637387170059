import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { collapseAnimation } from 'angular-animations';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { Section } from 'src/app/shared/models/section.model';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'project-section',
  templateUrl: './project-section.component.html',
  styleUrls: ['./project-section.component.scss'],
  animations: [
    collapseAnimation(),
  ],

})
export class ProjectSectionComponent implements OnInit, OnDestroy {
  constructor(private projectService: ProjectService,
    private route: ActivatedRoute) { }

  private subscription = new Subscription();

  @Input()
  section: Section;

  @Input()
  ebpStatus: string;

  @Input()
  ebpId: string;

  currentProject: Project;
  toggledBrands: Array<number>;
  module: any;


  ngOnInit(): void {
    if (this.section.repeat_source) {
      this.toggledBrands = [];
    }

    //section collapse init
    let collapsedItem = `${this.ebpId}.${this.section.name}.collapsedSection`;
    if (localStorage.getItem(collapsedItem) == null && this.section && this.section.collapsed_at_start) {
      this.section.isActivated = false;
    } else if (localStorage.getItem(collapsedItem) === "true") {
      this.section.isActivated = false;
    } else {
      this.section.isActivated = true;
    }


    this.projectService.currentProject.subscribe((p) => {
      this.currentProject = p;
      if (this.currentProject) {

        const collapsedBrandItem = `${this.ebpId}.collapsedBrandSection`;
        if (localStorage.getItem(collapsedBrandItem) !== null) {
          this.toggledBrands = JSON.parse(localStorage.getItem(collapsedBrandItem));
        } else if (this.section.collapsed_at_start && this.section.collapsed_at_start === true) {
          this.toggledBrands = this.currentProject.brands.map(brand => brand.id);
        }

      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    let stepId = localStorage.getItem('nav-stepId');
    if (stepId && stepId.length) {
      localStorage.removeItem('nav-stepId'); //remove immediately so that it only triggers once.
      setTimeout(() => {
        let el = document.getElementById(stepId);
        if (el) {
          const y = el.getBoundingClientRect().top + window.pageYOffset - 220;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 1000);
    }
  }

  toggle() {
    this.section.isActivated = !this.section.isActivated;
    this.updateSectionCollapseStateInStorage(this.section.name, this.section.isActivated);
  }

  toggleBrand(id: number) {
    const i = this.toggledBrands.indexOf(id);
    if (i == -1) {
      this.toggledBrands.push(id);
      this.updateBrandSectionCollapseStateInStorage();

    } else {
      this.toggledBrands.splice(i, 1);
      this.updateBrandSectionCollapseStateInStorage();

    }


  }

  updateBrandSectionCollapseStateInStorage() {
    const baseKey = `${this.ebpId}.collapsedBrandSection`;
    localStorage.setItem(baseKey, JSON.stringify(this.toggledBrands));
  }

  isCollapsedForBrand(id: number) {

    return this.toggledBrands.indexOf(id) != -1;
  }

  trackByFn(index, item) {
    return item.name;
  }


  updateSectionCollapseStateInStorage(sectionName: string, state: boolean) {
    state = !state;
    const baseKey = `${this.ebpId}.${sectionName}.collapsedSection`;
    localStorage.setItem(baseKey, state.toString());
  }
}
