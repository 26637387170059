import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-zap-icon',
  templateUrl: './zap-icon.component.html',
  styleUrls: ['./zap-icon.component.scss']
})
export class ZapIconComponent implements OnInit {

  @Input()
  tooltip: string;

  showTooltip:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
