import { EventsService } from './../../../shared/services/events.service';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ModuleService } from 'src/app/project/services/module.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ProjectUserRole } from 'src/app/shared/enums';
import { Events } from 'src/app/shared/enums/events';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Section } from './../../../shared/models/section.model';

@Component({
  selector: 'project-management',
  templateUrl: './project-management.component.html',
  styleUrls: [ './project-management.component.scss' ],
})
export class ProjectManagementComponent implements OnInit {
  @Input()
  currentSection: Section;

  constructor(private projectService: ProjectService, private datePipe: DatePipe,
              public dialog: MatDialog, private moduleService: ModuleService, private eventsService: EventsService) { }

  project: Project;

  PROJECT_USER_ROLE = ProjectUserRole;
  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;

  @Input()
  component: StepComponent;

  ngOnInit(): void {

    this.projectService.currentProject.subscribe(project => {
      if (project) {
        this.project = project;
      }
    });

  }

  /*
    public replaceTxt(text: string) {

      if (text) {
        return text.replace('%u', this.project.owner.display_name)
          .replace('%d', this.datePipe.transform(this.project.submitted_at, 'yyyy.MM.dd'))
          .replace('%u1', this.project.validator.display_name)
          .replace('%d1', this.datePipe.transform(this.project.validated_at, 'yyyy.MM.dd'))
      }
      return text;
    } */

  public submitEBP() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dialog_key: 'submit_ebp',
    };

    this.dialog
    .open(ConfirmDialogComponent, dialogConfig)
    .afterClosed()
    .subscribe((result) => {
      if (result) {

        this.changeStatus('submit').toPromise().then(response => {
          if (response && response.status == 'success') {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.data = {
              dialog_key: 'submit_ebp_success',
              replaceText: {
                'term': 'VALIDATOR',
                'value': this.project.validator.display_name,
              },
            };

            this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              this.updateStatusOnCTAUpdate(response.data.ebp.id);
            });
          }
        }).catch(err => {

          if (err && err.status) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.data = {
              dialog_key: '403',
            };

            this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              if (result) {

              }
            });
          }
        });
      }
    });

  }

  public editEBP() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dialog_key: 'edit_ebp',
    };

    this.dialog
    .open(ConfirmDialogComponent, dialogConfig)
    .afterClosed()
    .subscribe((result) => {
      if (result) {

        this.changeStatus('edit').toPromise().then(response => {
          if (response && response.status == 'success') {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.data = {
              dialog_key: 'edit_ebp_success',
              replaceText: {
                'term': 'VALIDATOR',
                'value': this.project.validator.display_name,
              },
            };

            this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              this.updateStatusOnCTAUpdate(response.data.ebp.id);
            });
          }
        });

      }
    });

  }

  public validateEBP() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dialog_key: 'validate_ebp',
    };

    this.dialog
    .open(ConfirmDialogComponent, dialogConfig)
    .afterClosed()
    .subscribe((result) => {
      if (result) {
        this.changeStatus('validate').toPromise().then(response => {
          if (response && response.status == 'success') {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.data = {
              dialog_key: 'validate_ebp_success',
              replaceText: {
                'term': 'OWNER',
                'value': this.project.owner.display_name,
              },
            };

            this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              this.updateStatusOnCTAUpdate(response.data.ebp.id);
            });
          }
        });

      }
    });

  }

  public rejectEBP() {
    this.changeStatus('reject').toPromise().then(response => {
      if (response && response.status == 'success') {
        this.updateStatusOnCTAUpdate(response.data.ebp.id);
      }
    });
  }

  private changeStatus(status: string) {
    return this.projectService.updateStatus(this.project, status);
  }

  updateStatusOnCTAUpdate(ebp_id: number) {
    this.moduleService.loadModulesForProject(ebp_id); // Refresh rejection count on top bar 
    this.projectService.refreshCurrentProject(ebp_id).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_PROJECT_STATUS, response.data.ebp);  //refresh ebp status
    });
  }
}
