import { EventsService } from './../../services/events.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Project } from '../../models/project.model';
import { Module } from '../../models/module.model';
import { ProjectService } from '../../services/project.service';
import { ModuleService } from 'src/app/project/services/module.service';
import { User } from '../../models/user.model';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectUserRole } from '../../enums';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Events } from '../../enums/events';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  animations: [
    trigger('hamburguerX', [
      state('hamburguer', style({})),
      state('topX', style({
        transform: 'rotate(45deg)',
        transformOrigin: 'left',
        margin: '0px 0px 4px 5px'
      })),
      state('hide', style({
        opacity: 0
      })),
      state('bottomX', style({
        transform: 'rotate(-45deg)',
        transformOrigin: 'left',
        margin: '8px 0px 0px 5px'
      })),
      transition('* => *', [
        animate('0s')
      ]),
    ]),
  ],
})
export class TopBarComponent implements OnInit {
  public currentProject: Project;
  public currentModule: Module;
  public currentUser: User;
  public showRejectedList: boolean = false;

  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  isTopBarCTAEnabled$: Observable<Boolean> = this.projectService.enableTopBarCTA;

  public rejectedSteps: { [key: string]: Array<any> } = {};
  public PROJECT_USER_ROLE = ProjectUserRole;

  public isSaving: boolean;
  public lastSaved: Date;

  constructor(
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    this.projectService.currentProject.subscribe(
      (project) => (this.currentProject = project)
    );

    this.moduleService.currentModule.subscribe(
      (module) => {
        this.currentModule = module;
      }
    );

    this.moduleService.projectModules.subscribe((modules) => {
      if (modules) {
        this.rejectedSteps = {};
        for (let module of modules) {
          if (module.rejected_steps && module.rejected_steps.length > 0) {
            module.rejected_steps.forEach(item => item['modulealias'] = module.alias);
            this.rejectedSteps[module.name] = module.rejected_steps;
          }
        }
      }
    });

    this.authService.currentUser.subscribe(user => {
      this.currentUser = user
    })
    this.moduleService.stepIsSaving.subscribe(state => {

      if (this.isSaving === false && state === true) {
        this.lastSaved = new Date();
      }
      this.isSaving = state
    })

    this.eventsService.on(Events.UPDATE_CURRENT_PROJECT_STATUS).subscribe((currentProject) => {
      this.currentProject.status = currentProject.status;
      this.projectService.setCurrentProject(this.currentProject);
    });
  }

  logout() {
    //this.authService.logout()
  }


  totalRejectedCount() {
    let count = 0;

    Object.keys(this.rejectedSteps).forEach(key => {
      count += this.rejectedSteps[key].length;
    });

    return count;
  }

  public submitEBP() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dialog_key: 'submit_ebp',
    };

    this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {

          this.changeStatus('submit').toPromise().then(response => {
            if (response && response.status == "success") {
              const dialogConfig = new MatDialogConfig();

              dialogConfig.data = {
                dialog_key: 'submit_ebp_success',
                replaceText: {
                  'term': 'VALIDATOR',
                  'value': this.currentProject.validator.display_name
                }
              };

              this.dialog
                .open(ConfirmDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((result) => {
                  this.updateStatusOnCTAUpdate(response.data.ebp.id);

                });
            }
          }).catch(err => {

            if (err && err.status) {
              const dialogConfig = new MatDialogConfig();

              dialogConfig.data = {
                dialog_key: '403'
              };

              this.dialog
                .open(ConfirmDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((result) => {
                  if (result) {

                  }
                });
            }
          });
        }
      });

  }

  public editEBP() {


    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dialog_key: 'edit_ebp',
    };

    this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {

          this.changeStatus('edit').toPromise().then(response => {
            if (response && response.status == "success") {
              const dialogConfig = new MatDialogConfig();

              dialogConfig.data = {
                dialog_key: 'edit_ebp_success',
                replaceText: {
                  'term': 'VALIDATOR',
                  'value': this.currentProject.validator.display_name
                }
              };

              this.dialog
                .open(ConfirmDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((result) => {

                  this.updateStatusOnCTAUpdate(response.data.ebp.id);
                });
            }
          });

        }
      });

  }

  public validateEBP() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      dialog_key: 'validate_ebp',
    };

    this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.changeStatus('validate').toPromise().then(response => {
            if (response && response.status == "success") {
              const dialogConfig = new MatDialogConfig();

              dialogConfig.data = {
                dialog_key: 'validate_ebp_success',
                replaceText: {
                  'term': 'OWNER',
                  'value': this.currentProject.owner.display_name
                }
              };

              this.dialog
                .open(ConfirmDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((result) => {

                  this.updateStatusOnCTAUpdate(response.data.ebp.id);
                });
            }
          });

        }
      });

  }

  private changeStatus(status: string) {
    return this.projectService.updateStatus(this.currentProject, status);
  }

  public sendNotification() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      dialog_key: 'rejected_steps_email_to_owner',
    };

    this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.projectService.sendRejectionEmail(this.currentProject.id).subscribe(res => {
            if (res && res.status == 'success') {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.data = {
                dialog_key: 'rejected_steps_email_to_owner_success',
              };

              this.dialog
                .open(ConfirmDialogComponent, dialogConfig)
                .afterClosed()
                .subscribe((res) => {
                });
            }
          });
        }
      });

  }

  public navigateToStep(module: string, stepId: string) {
    this.showRejectedList = !this.showRejectedList;
    if (this.currentModule && this.currentModule.alias && module == this.currentModule.alias) {
      if (stepId && stepId.length) {
        let el = document.getElementById(stepId);
        setTimeout(() => {
          const y = el.getBoundingClientRect().top + window.pageYOffset - 220;
          window.scrollTo({ top: y, behavior: 'smooth' });
          stepId = null;
        }, 200);
      }
    } else {
      localStorage.setItem('nav-stepId', stepId);
      this.router.navigate(['/projects', this.currentProject.id, module]);
    }
  }

  updateStatusOnCTAUpdate(ebp_id: number) {

    if (this.currentModule) {
      this.moduleService.loadCurrentModule(ebp_id, this.currentModule.alias).subscribe(response => {
        this.eventsService.setValue(Events.UPDATE_CURRENT_MODULE_DATA, response.data.module); //refresh step status
      });
    }
    this.moduleService.loadModulesForProject(ebp_id); // Refresh rejection count on top bar 
    this.projectService.refreshCurrentProject(ebp_id).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_PROJECT_STATUS, response.data.ebp);  //refresh ebp status
    });

  }

}
