<div class="users-container">
  <div class="users-container--header">
    <shared-module-icon [type]="'settings'" [status]="null"></shared-module-icon>

    <div class="divider"></div>
    <h1 class="users-container--header_title">{{'CMS_USERS_TITLE' | translate}}</h1>
  </div>
</div>
<section class="module-section ng-tns-c566-2 is-collapsed is-fullwidth">
  <div class="project-detail-step">
    <kendo-grid [data]="filteredUsers"
                [filter]="state.filter"
                [filterable]="true"
                (filterChange)="filterChange($event)"
                (dataStateChange)="dataStateChange($event)"
                [pageSize]="state.take"
                [sortable]="{
                    allowUnsort: true,
                    mode: 'single'
                  }"
                (sortChange)="sortChange($event)"
                [sort]="state.sort"
                [pageable]="{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true
                  }"
                [skip]="state.skip"
                (pageChange)="pageChange()"
                *ngIf="defaultConfig">
      <ng-template kendoGridToolbarTemplate>
        <div class="clear-filters-container">
          <input type="button" class="k-button" value="Clear Filters" (click)="clearFilters()"/>
        </div>
      </ng-template>
      <ng-container *ngFor="let columnSet of defaultConfig.column_sets">
        <ng-container *ngFor="let columnSubSet of columnSet.sets">

          <ng-container *ngFor="let actColumn of columnSubSet.columns;let i = index;">
            <kendo-grid-column [field]="actColumn.property" [headerClass]="'grid-header'"
                               [footerStyle]="{'padding-bottom':'11px;','padding-top':'11px;'}"
                               [sortable]="actColumn.sortable"
                               [width]="actColumn.width || '120'"
                               [filterable]="actColumn.filter">

              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">{{actColumn.label | translate}}
              </ng-template>

              <ng-container [ngSwitch]="actColumn.type">


                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'date'">
                  {{dataItem[actColumn.property] | date :'d/MM/YYYY'}}
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-dataItem *ngSwitchCase="'permissions'">
                  <kendo-switch
                      #permissionSwitchers
                      [(ngModel)]="permissionInputModels[actColumn.permissionKey]"
                      (valueChange)="switchChange($event,actColumn.property)">
                  </kendo-switch>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'permissions'">
                  <ng-container *ngIf="dataItem?.mappedPermissions[actColumn.permissionKey]">
                    <span class="material-icons permission-icon">done</span>
                  </ng-container>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'superAdminToggle'">
                  <mat-slide-toggle [ngModel]="dataItem.is_superadmin" (click)="superAdminToggle(dataItem); $event.preventDefault();"></mat-slide-toggle>
                </ng-template>
              </ng-container>

            </kendo-grid-column>
          </ng-container>
        </ng-container>
      </ng-container>

      <kendo-grid-column-group *ngIf="noActiveColumn()" [headerClass]="'grid-header grid-main-header'">
        <kendo-grid-column [field]="" [headerClass]="'grid-header'" width="4000"></kendo-grid-column>
      </kendo-grid-column-group>

    </kendo-grid>

  </div>
</section>
