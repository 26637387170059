import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepComponent } from '../../models/step-component.model';

@Component({
  selector: 'shared-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {

  public value:string;

  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>(); 

  constructor() { }


  ngOnInit(): void {
    if(!this.component.answer){
      this.component.answer = {value : ""}
    }
  }

  onValueChange() {
    this.onChange.emit()
  }

}
