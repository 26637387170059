import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-table-string-list',
  templateUrl: './table-string-list.component.html',
  styleUrls: ['./table-string-list.component.scss'],
})
export class TableStringListComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  shouldFocus: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { values: [] };
    }
  }

  addItem() {
    this.shouldFocus = true;
    this.component.answer.values.push('');
  }

  removeItemAtIndex(itemIndex: number) {
    this.component.answer.values.splice(itemIndex, 1);
    this.onModelChange();
  }

  onModelChange() {
    this.onChange.emit();
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
