import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
})
export class StatusIconComponent implements OnInit {
  @Input()
  status: string;

  @Input()
  width: number;
  
  constructor() {}

  ngOnInit(): void {}
}
