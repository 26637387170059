import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'orderByDate',
})
export class OrderByDatePipe implements PipeTransform {

  transform<T>(elements: T[], key: string): T[] {
    return elements.sort((a: any, b: any) => moment(a[key]).diff(moment(b[key])));
  }

}
