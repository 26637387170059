<div class="project-detail-step">
  <div class="type-download" *ngIf="component.type == 'downloader' && !component?.hidden">
    <div class="content">
      <p [innerHTML]="component.text | translate"></p>
    </div>

    <ng-container
      *ngIf="(!component.is_disabled && currentProject?.status !== 'validated') ||  (!component.is_disabled && currentProject?.status === 'validated' && component?.name == 'download_powerpoint_ebp')">
      <div class="module-download-item">
        <a [ngClass]="{'xl-button': component.XL_button, 'xxl-button': component.XXL_button}"
          (click)="startDownload($event, downloadUrl)" [class.inactive]="isDownloadDisabled() || !isActive(component)">

          <div class="d-flex flex-column">
            <span *ngIf="currentModule; else coverUploadDownload">{{title | translate | replacePlaceholder:
              currentProject && currentProject.replace | textReplace: {"\{\{MODULE_NAME\}\}":
              currentModule?.name_compact} | textReplace: {"\{\{brand_name\}\}": brand?.name} }}</span>

            <ng-template #coverUploadDownload>
              <span>{{title | translate | replacePlaceholder: currentProject && currentProject.replace }}</span>
            </ng-template>
            <ng-container *ngIf="currentProject?.status === 'validated' && percentage == 0">
              <small class="text-muted text-small"> {{'EBP_DOWNLOADS_COMMENT_GENERATED_AT' | translate |
                replacePlaceholder: currentProject && currentProject.replace}}</small>
            </ng-container>

            <ng-container *ngIf="percentage > 0">
              <small class="text-muted text-small"> {{'EBP_DOWNLOADS_THE_FILE_IS_GETTING_GENERATED' |
                translate}}</small>
            </ng-container>

          </div>
          <circle-progress [percent]="percentage" [radius]="15" [outerStrokeWidth]="2" [maxPercent]="100"
            [showImage]="true" [outerStrokeColor]="'#E0E0E0'"
            [imageSrc]="(percentage > 0  || isDownloadDisabled() || !isActive(component)) ? '/assets/logos/downloading-incomplete.svg' : '/assets/logos/downloading.svg'"
            [imageHeight]="24" [imageWidth]="24" [showBackground]="false" [startFromZero]="false" [renderOnClick]="true"
            [showZeroOuterStroke]="false">
          </circle-progress>
        </a>
      </div>

      <p *ngIf="showMissingCatalogueMsg()" class="no-catalogue-label">{{'EBP_DOWNLOADS_WARNING_MISSING_CATALOGUE' |
        translate}}</p>
      <p *ngIf="!isActive(component)" class="warning-module-inactive">{{'EBP_DOWNLOADS_WARNING_MODULE_INACTIVE' |
        translate}}</p>
    </ng-container>
  </div>

  <div *ngIf="currentModule && currentModule.alias.toLowerCase() == 'legal'">
    <div  *ngIf="component.type == 'uploader' && !component?.hidden && ((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor)">
          
      <div *ngIf="component.answer && component.answer.files">
        <div  class="type-upload-2"  *ngFor="let file of component.answer.files;  let i = index">
  
          <div class="left-container">
            <div class="last-upload" *ngIf="file.created_at">
              <div>
                <p>{{'UPLOADER_LAST_FILE_UPLOADED' | translate}}: <a [href]="file.downloadUrl">{{file.filename_original}}</a></p>
                <p>{{'UPLOADER_LAST_UPLOAD' | translate}}: {{ file.created_at | date : 'medium'}}</p>
              </div>
              
            </div>
            <ng-container>
              <button mat-stroked-button (click)="openUploader(i)" class="upload-button">
                <span class="icon icon-upload"></span>
                {{'UPLOADER_UPLOAD' | translate}}
              </button>
            </ng-container>
          </div>
          
          <div class="right-container" >
            <shared-exclude-icon [hidden]= "component.answer.files.length == 1 && !file.created_at" (onAlertProceed)="removeAttachment(component, i)" [alert]="'alert_deletion'"></shared-exclude-icon>
          </div>
          
        </div>
        
      </div>
    </div>
   
  
    <div *ngIf="component.type == 'uploader' && !component?.hidden && (currentUserRole$ | async) !== PROJECT_USER_ROLE.owner && (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor && component.answer && component.answer.file">
  
      <div *ngIf="component.answer && component.answer.files">
        <div  class="type-upload-2"  *ngFor="let file of component.answer.files;  let i = index">
  
          <div class="left-container">
            <div class="last-upload" *ngIf="file.created_at">
              <div>
                {{file.downloadUrl}}
                <p>{{'UPLOADER_LAST_FILE_UPLOADED' | translate}}: <a [href]="file.downloadUrl">{{file.filename_original}}</a></p>
                <p>{{'UPLOADER_LAST_UPLOAD' | translate}}: {{ file.created_at | date : 'medium'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  
    <div class="add-more" *ngIf="component.type == 'uploader'  && !component?.hidden && ((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor)">
      <shared-add-button  [label]="'M_LEGAL_ADD_NEW_ATTACHMENT' | translate" (click)="addMoreAttachments(component)">
      </shared-add-button>
    </div>
  </div>

  <div *ngIf="currentModule && currentModule.alias != 'legal'">
  
    <div class="type-upload"
      *ngIf="component.type == 'uploader' && !component?.hidden && ((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor)">
  
      <div class="last-upload" *ngIf="component.answer && component.answer.file">
        <div>
          <p>{{'UPLOADER_LAST_FILE_UPLOADED' | translate}}: <a
              [href]="downloadUrl">{{component.answer.file.filename_original}}</a></p>
          <p>{{'UPLOADER_LAST_UPLOAD' | translate}}: {{ component.answer.file.created_at | date : 'medium'}}</p>
        </div>
        <shared-exclude-icon (onAlertProceed)="deleteFile()" [alert]="'alert_deletion'"></shared-exclude-icon>
      </div>
  
      <ng-container>
        <button mat-stroked-button (click)="openUploader()" class="upload-button">
          <span class="icon icon-upload"></span>
          {{'UPLOADER_UPLOAD' | translate}}
        </button>
      </ng-container>
  
    </div>
  
    <div class="type-upload"
      *ngIf="component.type == 'uploader' && !component?.hidden && (currentUserRole$ | async) !== PROJECT_USER_ROLE.owner && (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor && component.answer && component.answer.file">
  
      <div class="last-upload">
        <div>
          <p>{{'UPLOADER_LAST_FILE_UPLOADED' | translate}}: <a
              [href]="downloadUrl">{{component.answer.file.filename_original}}</a></p>
          <p>{{'UPLOADER_LAST_UPLOAD' | translate}}: {{ component.answer.file.created_at | date : 'medium'}}</p>
        </div>
      </div>
    </div>
  </div>


</div>