import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../services/config.service';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';
import { User } from 'src/app/shared/models/user.model';

@Component({
    selector: 'app-ebp-duplicate-dialog',
    templateUrl: './ebp-duplicate-dialog.component.html',
    styleUrls: [ './ebp-duplicate-dialog.component.scss' ],
})
export class EbpDuplicateDialogComponent implements OnInit {
    data: any;
    configData: any = {};
    newEbp: any = {};
    newEbpName: string = '-';
    validatorsList: User[] = [];
    currentYear: number = null;
    requiredErrMsg: any = null;
    duplicationPhase: string = 'form';
    duplicateData: any = {
        newEbpYear: null,
        newValidatorId: null,
        targetPreviousYear: null,
        targetCurrentYear: null
    };
    yearPlaceholders: any = {
        '{{YEAR}}': '',
        '{{YEAR-1}}': ''
    };

    constructor(
        private dialog: MatDialog,
        private configService: ConfigService,
        private http: GenericHttpService,
        @Inject(MAT_DIALOG_DATA) data)
    {
        this.data = data;
        this.currentYear = (new Date()).getFullYear();
        this.yearPlaceholders = {
            '{{YEAR}}': String(data.ebp.year),
            '{{YEAR-1}}': String(data.ebp.year - 1)
        };

        this.configService.getEntity('users').subscribe(response => {
            // this.ownerInfo = response.data.users.find((user) => user.id == this.currentUser.id);
            // return response.data.users.filter((user) => user.id !== this.currentUser.id);
            this.validatorsList = response.data.users;
        });
    }

    ngOnInit(): void {
        this.configService.dialogConfig.subscribe(config => {
            this.configData = config.find(c => c.name == this.data.dialog_key);
            this.requiredErrMsg = config.find(c => c.name == 'alert_required_field');
        });
    }

    updateNewEbpName(event): void {
        let newYear = event.value - 2000;
        this.newEbpName = this.data.ebp.full_name.replace(/\d+\.ebp/, newYear + ".ebp");
    }

    // updateTargetYear(choice, event): void {
    //     if (event.value != '-') {
    //         if (choice == 'previous') {
    //             this.duplicateData.targetPreviousYear = event.value;
    //             this.duplicateData.targetCurrentYear = (event.value == this.duplicateData.newEbpYear) ? (this.duplicateData.newEbpYear - 1) : this.duplicateData.newEbpYear
    //         }
    //         else {
    //             this.duplicateData.targetCurrentYear = event.value;
    //             this.duplicateData.targetPreviousYear = (event.value == this.duplicateData.newEbpYear) ? (this.duplicateData.newEbpYear - 1) : this.duplicateData.newEbpYear
    //         }
    //     }
    // }

    isFormInvalid(): boolean {
        return (this.duplicateData.newEbpYear == null ||
            this.duplicateData.newValidatorId == null ||
            this.duplicateData.targetPreviousYear == null ||
            this.duplicateData.targetCurrentYear == null);
    }

    startDuplication(): void {
        this.duplicationPhase = 'duplicating';

        this.http.post('/ebp/' + this.data.ebp.id + '/duplicate', {
            year: this.duplicateData.newEbpYear,
            validator_id: this.duplicateData.newValidatorId,
            target_previous_year: (this.duplicateData.targetPreviousYear != '-' ? this.duplicateData.targetPreviousYear : null),
            target_year: (this.duplicateData.targetCurrentYear != '-' ? this.duplicateData.targetCurrentYear : null)
        }).subscribe((response) => {
            if (response.status == 'success') {
                this.duplicationPhase = 'success';
                this.newEbp = response.data.ebp;
            }
            else {
                this.duplicationPhase = 'error';
            }
        });
    }
}
