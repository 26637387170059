<div class="module-card"
     [ngClass]="'status-'+ module?.status + ' version-status-' + module.version?.status"
     [class.version-status-not_selected]="!module.version?.is_active && !module.version?.status"
     [class.version-status-selected]="module.version?.is_active && !module.version?.status"(click)="editModule()">

    <div class="icon-box">
        <shared-module-icon [type]="module.alias" [status]="'card'"></shared-module-icon>
    </div>
    <h4 class="module-title">{{module.name_compact}}</h4>
    <ng-container *ngIf="module.version && (module.version?.is_active  || module.version == 'offline') ">
        <project-module-progressbar [progress]="module.progress"></project-module-progressbar>
        <shared-status-icon [status]="module.version.status" [width]="33"></shared-status-icon>
    </ng-container>
</div>
