import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from 'src/app/shared/models/project.model';
import { SkuSet } from 'src/app/shared/models/sku_set.model';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class SkuSetService {
  constructor(private http: GenericHttpService) {}

  private skuSetsSource = new BehaviorSubject<any>(null);
  public skuSets = this.skuSetsSource.asObservable();

  loadSkuSets(ebpId: number) {
    // GET https://{{api_domain}}/api/{{api_version}}/sku_set
    this.http.get('/sku_set/' + ebpId).subscribe((response) => {
      var skuSets = response.data.sku_sets.sort((a,b) => a.id - b.id)
      this.skuSetsSource.next(skuSets);
    });
  }

  getSkuSet(project: Project, id: number) {
    var url = ['/sku_set', project.id, id].join('/');
    return this.http.get(url);
  }

  createSkuSet(skuSet: SkuSet, project: Project) {
    // POST https://{{api_domain}}/api/{{api_version}}/sku_set/14
    var url = ['/sku_set', project.id].join('/');
    var data = this.mapProducts(skuSet)
    return this.http.post(url, data).pipe(
      map( response => response.data.sku_set)
    )
  }

  updateSkuSet(skuSet: SkuSet, project: Project) {
    // PUT https://{{api_domain}}/api/{{api_version}}/sku_set/14/1
    var url = ['/sku_set', project.id, skuSet.id].join('/');
    var data = this.mapProducts(skuSet)
    return this.http.put(url, data).pipe(
      map( response => response.data.sku_set)
    )
  }

  deleteSkuSet(skuSet: SkuSet, project: Project) {
    // DELETE https://{{api_domain}}/api/{{api_version}}/sku_set/14/1
    var url = ['/sku_set', project.id, skuSet.id].join('/');
    return this.http.delete(url)
  }

  private mapProducts(skuSet:SkuSet){
    let set = JSON.parse(JSON.stringify(skuSet))
    set.products = set.products.map(p => (typeof p == 'number') ? p : p.id)
    return set
  }
}
