import { Component, Input, OnInit } from '@angular/core';
import { StepComponent } from '../../models/step-component.model';

@Component({
  selector: 'shared-image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.scss']
})
export class ImagePlaceholderComponent implements OnInit {

  constructor() { }

  @Input()
  component: StepComponent

  ngOnInit(): void {
  }

}
