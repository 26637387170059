<div class="ebp-item"
     [class.ebp-item--normal]="layoutType === DASHBOARD_LAYOUT.NORMAL"
     [class.ebp-item--compact]="layoutType === DASHBOARD_LAYOUT.COMPACT">

  <div class="ebp-information d-flex cursor-pointer justify-content-between"
       [routerLink]="['/projects/', project.id]">
    <div class="ebp-data">
      <div class="ebp-data-title">
        <b>{{project.retailer.name}}</b> - <span>{{project?.year}}</span>
      </div>
      <div class="ebp-geo"  [class.ebp-compact-size] = "layoutType === DASHBOARD_LAYOUT.COMPACT">
        <p [class.ebp-clamp-compact] = "layoutType === DASHBOARD_LAYOUT.COMPACT">{{project?.geographical_setup}}</p>
      </div>

      <div class="ebp-data-division"  [class.ebp-compact-size-low-margin] = "layoutType === DASHBOARD_LAYOUT.COMPACT">
          <div class="ebp-data-division-item"  [class.ebp-compact-size] = "layoutType === DASHBOARD_LAYOUT.COMPACT">

            <span class="division-span"  [class.ebp-compact-no-bold] = "layoutType === DASHBOARD_LAYOUT.COMPACT"  *ngFor="let division of project?.mapped_divisions; let i = index">{{division.code}}<ng-container *ngIf="i+1 != project?.mapped_divisions?.length">,&nbsp;</ng-container></span>

            <br class="break-style"/>

            <p class="ebp-brands" [class.ebp-clamp-compact]="layoutType === DASHBOARD_LAYOUT.COMPACT">
              <ng-container *ngIf="!project.is_full_division">
                <ng-container *ngFor="let division of project?.mapped_divisions; let h = index">
                  <ng-container class="brand-span" *ngFor="let brand of division.brands; let i = index">
                    {{brand.name}}<ng-container *ngIf="i+1 != division.brands?.length">,&nbsp;</ng-container>
                  </ng-container>
                  <ng-container *ngIf="h+1 != project.mapped_divisions?.length">,&nbsp;</ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="project.is_full_division">
                {{'EBP_IS_FULL_DIVISION_NOTE' | translate}}
              </ng-container>
            </p>
          </div>
      </div>
      <div class="ebp-data-type" *ngIf="layoutType === DASHBOARD_LAYOUT.NORMAL">
        <span class="ebp-type-header">{{'EBP_COVER_TYPE' | translate }} </span><span class="ebp-type-value">{{project.type | projectTypeLabel | translate}}</span>
      </div>
      <div class="ebp-data-owner">
        <div><span class="ebp-type-header">{{'OWNER' | translate }}: </span> {{project.owner.display_name}} </div>
      </div>
      <div class="ebp-data-created" *ngIf="layoutType === DASHBOARD_LAYOUT.NORMAL">
        <div><span class="ebp-type-header">{{'Created' | translate }}: </span> {{project.created_at | date:'yyyy.MM.dd (HH:mm)'}} </div>
      </div>
      <div class="ebp-data-validator" *ngIf="layoutType === DASHBOARD_LAYOUT.NORMAL">
        <div><span class="ebp-type-header">{{'VALIDATOR' | translate }}: </span> {{project?.validator?.display_name}} </div>
      </div>
    </div>
    <div class="ebp-action-icons justify-content-between text-center">
      <div class="ebp-action-icons__status">
        <shared-status-icon [status]="project?.status" [width]="33"></shared-status-icon>
      </div>
      <ng-container *ngIf="user.id === project.owner.id">
        <div class="ebp-action-icons__bottom d-flex flex-column">

          <div kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
            <div appStopPropagation (click)="dialogSettings()" class="material-icons grey5 turn-dark">settings</div>
          </div>

          <div appStopPropagation (click)="deleteEbp(project.id)" class="material-icons grey5 turn-dark">delete</div>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="ebp-modules ">
    <div class="d-flex justify-content-center" *ngIf="!loadedModules[project.id]">
      <mat-spinner [strokeWidth]="2" [diameter]="30"></mat-spinner>
    </div>

    <div class="d-flex">
      <ng-container *ngFor="let module of loadedModules[project.id]">
        <shared-module-icon *ngIf="module?.status == 'online' && module?.version?.is_active"
                            [routerLink]="['/projects', project.id, module.alias]" [type]="module.alias"
                            [status]="module?.version?.status"></shared-module-icon>
      </ng-container>
    </div>
  </div>

</div>
