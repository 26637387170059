import { Activation } from 'src/app/project/entities/activation';
import { Product } from './product';

export class SkuSet {
  id: number;
  name: string;
  add_to_activation: boolean = false;
  discount_percentage: number = 0;
  discounted_price: number = 0;
  ebp_id: number;
  products: Array<any> = [];
  activation: Activation;
  starts_at: Date;
  ends_at: Date;
  launch_at: Date;
  mono_from_grid: boolean;
}
