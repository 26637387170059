import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';
import { Activation } from '../entities/activation';

@Injectable({
  providedIn: 'root',
})
export class ActivationService {
  constructor(private http: GenericHttpService) {}

  getActivations(ebpId: number) {
    return this.http.get('/activation/' + ebpId);
  }

  getActivation(ebpId:number, activationId:number) {
    var url = ['/activation', ebpId, activationId].join('/');
    return this.http.get(url).pipe(
      map(response => response.data.activation)
    )
  }

  createActivation(activation: Activation) {
    var url = ['/activation', activation.ebp_id].join('/');
    return this.http.post(url, activation).pipe(map(response => {
      return response.data.activation
    }))
  }



  updateActivation(activation: Activation) {
    const url = ['/activation', activation.ebp_id, activation.id].join('/');
    const data = JSON.parse(JSON.stringify(activation));
    delete data.sku_set;
    this.http
      .put(url, data)
      .subscribe((response) => {
        this.getActivations(activation.ebp_id);
      });
  }

  deleteActivation(activation: Activation) {
    activation.status = false;
    this.updateActivation(activation);
  }
}
