import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';

import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EbpItemComponent } from './components/ebp-item/ebp-item.component';
import { GenericPagesComponent } from './components/generic-pages/generic-pages.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardComponent,
    ProjectCardComponent,
    GenericPagesComponent,
    EbpItemComponent ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatDividerModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    TooltipModule
  ],
})
export class DashboardModule {}
