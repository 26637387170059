import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Project } from '../../models/project.model';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'shared-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: [ './daterange-picker.component.scss' ],
})
export class DaterangePickerComponent implements OnInit {
  constructor(private projectService: ProjectService) {}

  @Input() startDate: Date;
  @Input() endDate: Date;

  @Output() dateChange = new EventEmitter<any>();
  @Input() appearanceNone = false;
  @Input() disabled = false;

  currentProject: Project;
  minDate: Date;
  maxDate: Date;

  ngOnInit(): void {
    this.projectService.currentProject.subscribe((p) => {
      if (p) {
        const momentDate = moment([p.year]);
        this.currentProject = p;
        this.minDate = new Date(momentDate.startOf('year').format('YYYY-MM-DD'));
        this.maxDate = new Date(momentDate.endOf('year').format('YYYY-MM-DD'));

      }
    });
  }

  change() {
    if (!this.startDate || !this.endDate) {
      return;
    }

    this.dateChange.emit({
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

}
