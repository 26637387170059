import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { TargetService } from '../../components/table-target/target.service';

@Component({
  selector: 'target-total-table',
  templateUrl: './target-total-table.component.html',
  styleUrls: ['./target-total-table.component.scss'],
})
export class TargetTotalTableComponent implements OnInit,OnDestroy {
  private subscription  = new Subscription();

  constructor(
    private projectService: ProjectService,
    private targetService: TargetService
  ) {}

  @Input()
  component: StepComponent;

  targetData: any;
  currentProject: Project;

  ngOnInit(): void {
    this.subscription.add(
    this.projectService.currentProject.subscribe((project) => {
      if (project) {
        this.currentProject = project;
      }
    })
    );
    this.subscription.add(
    this.targetService.data.subscribe((data) => {
      this.targetData = data;
    })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
