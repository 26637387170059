<div class="set-wrapper" [ngClass]="currentSet.label" *ngIf="showStep">
    <div class="set-indicator" [ngClass]="'border-right-'+currentSet.color">
        <div class="set-label" [ngClass]="'background-'+currentSet.color" (click)="toggleSet()">{{ currentSet.label | translate }}</div>
    </div>
    <app-project-step *ngFor="let step of currentSet.steps"
                      [ebpStatus] = "ebpStatus"
                      [brandStepStatus]="brandRepeat && step['status'] && step['status'][brandStepSlug]"
                      [brandRepeat]="brandRepeat"
                      [currentStep]="step"
                      [currentSet]="currentSet"
                      [currentSection]="currentSection"
                      [replaceTranslation]="currentProject?.replace"
                      [currentBrand]="currentBrand" ></app-project-step>
</div>
