import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { EbpSettingsDialogComponent } from '../../../shared/components/ebp-settings-dialog/ebp-settings-dialog.component';
import { EbpDuplicateDialogComponent } from '../../../shared/components/ebp-duplicate-dialog/ebp-duplicate-dialog.component';
import { DashboardLayoutType } from '../../../shared/enums/dashboardLayoutType';
import { Project } from '../../../shared/models/project.model';
import { User } from '../../../shared/models/user.model';
import { ProjectService } from '../../../shared/services/project.service';

@Component({
  selector: 'ebp-item',
  templateUrl: './ebp-item.component.html',
  styleUrls: [ './ebp-item.component.scss' ],
})
export class EbpItemComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public showTooltip:boolean = false;

  @Input() project: Project;
  @Input() user: User;
  @Input() loadedModules: any;
  @Input() layoutType: DashboardLayoutType;
  @Output() projectDeleted: EventEmitter<any> = new EventEmitter<any>();
  DASHBOARD_LAYOUT = DashboardLayoutType;

  constructor(private projectService: ProjectService,
              private cdr: ChangeDetectorRef,
              public dialog: MatDialog) { }

  ngOnInit(): void {
  }



  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteEbp(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'alert_ebp_deletion',
    };
    this.subscription.add(
      this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((shouldDelete) => {
        if (!shouldDelete) {
          return;
        }
        this.subscription.add(
          this.projectService.deleteProject(id).subscribe((response) => {
            this.projectDeleted.emit(id);
          }, (e) => {
            console.log(e);
          }),
        );
      }));
  }

  dialogSettings() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'ebp_settings_dialog',
      ebpId: this.project.id,
    };

    this.subscription.add(
      this.dialog
        .open(EbpSettingsDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((return_value) => {
          if (return_value == 'duplicate') {
            this.dialogDuplicate();
          }
        })
    );
  }

  dialogDuplicate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      maxHeight: '70vh',
      dialog_key: 'ebp_duplicate_dialog',
      ebp: this.project,
    };

    this.subscription.add(
      this.dialog
        .open(EbpDuplicateDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((return_value) => {
          if (return_value == 'back_to_settings') {
            this.dialogSettings()
          }
          else {
            console.log(return_value);
          }
        })
    );
  }

}
