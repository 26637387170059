<project-step-title *ngIf="currentStep.title !== false && currentStep?.hide_step !== true"
                    [step]="currentStep"
                    [stepStatus]="getStepStatus()"
                    [brand]="currentBrand"
                    [ebpStatus]="ebpStatus">
</project-step-title>

<div *ngIf="currentStep?.hide_step !== true" [class.mask-step]="!currentStep.hide_status_icon
&& ((((currentUserRole$ | async) === PROJECT_USER_ROLE.validator) && (getStepStatus() == 'edited' || !getStepStatus()))
|| (((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor) && (getStepStatus() == 'submitted' || getStepStatus() == 'validated')))
|| (currentUserRole$ | async) === PROJECT_USER_ROLE.viewer">

  <div class="project-detail-step">
    <div class="content">
      <p  *ngIf="currentStep.text && !currentStep.exclude" class="currentStep-text" [class.small_text]="currentStep?.text_small"
         [innerHTML]="currentStep.text | translate | replacePlaceholder: replaceTranslation"></p>

      <!-- text to show when a step is excluded -->
      <p *ngIf="currentStep.exclude" class="currentStep-text small_text">{{ 'STATUS_CTA_WARNING_EXCLUDED_STEP' | translate }}</p>
      <p *ngIf="!getStepStatus() && !currentStep.hide_exclude_icon && (currentUserRole$ | async) === PROJECT_USER_ROLE.validator" class="currentStep-text small_text">{{ 'STATUS_CTA_WARNING_NULL_STEP' | translate }}</p>
    </div>
  </div>

  <!-- <debug [content]="currentStep.components"></debug> -->

  <div class="step-component" *ngFor="let component of currentStep.components; let i = index ; trackBy:trackByFn "
       [ngSwitch]="component.type">
    <fieldset [disabled]="(currentUserRole$ | async) !== PROJECT_USER_ROLE.owner && (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor || dataIsSaving">
      <div *ngIf="!displayStepComponent(currentUserRole$ | async)">
        <shared-text *ngSwitchCase="'text'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </shared-text>

        <shared-input-repeater *ngSwitchCase="'string_repeater'" [(component)]="currentStep.components[i]"
                               (onChange)="update()"></shared-input-repeater>
        <project-table-input *ngSwitchCase="'table'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </project-table-input>
        <project-table-target *ngSwitchCase="'table_target'"
                              [(component)]="currentStep.components[i]"
                              (onChange)="update()"
                              (onCollapseRow)="updateCollapseState($event)"></project-table-target>
        <project-table-string-list *ngSwitchCase="'strings_table'" [(component)]="currentStep.components[i]"
                                   (onChange)="update()"></project-table-string-list>
        <project-radio-with-chips *ngSwitchCase="'radio_with_chips'" [(component)]="currentStep.components[i]"
                                  (onChange)="update()"></project-radio-with-chips>
        <project-table-with-graph *ngSwitchCase="'ambition_table_with_graph'" [(component)]="currentStep.components[i]"
                                  (onChange)="update()"></project-table-with-graph>
        <project-radio-list *ngSwitchCase="'radio'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </project-radio-list>
        <radio-input *ngSwitchCase="'radio_plus_input'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </radio-input>
        <project-radio-nested *ngSwitchCase="'nested_radios'" [(component)]="currentStep.components[i]"
                              (onChange)="update()"></project-radio-nested>
        <project-chips-list *ngSwitchCase="'chips'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </project-chips-list>


        <project-toggle *ngSwitchCase="'toggle'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </project-toggle>
        <project-toggle-input *ngSwitchCase="'toggle_plus_string'" [(component)]="currentStep.components[i]"
                              (onChange)="update()"></project-toggle-input>
        <project-toggle-table *ngSwitchCase="'toggle_list'" [(component)]="currentStep.components[i]"
                              (onChange)="update()"></project-toggle-table>

        <project-select-simple *ngSwitchCase="'select'" [(component)]="currentStep.components[i]" (onChange)="update()">
        </project-select-simple>
        <shared-input-text *ngSwitchCase="'textarea'" [(component)]="currentStep.components[i]" (onChange)="update()"
                           type=""></shared-input-text>
        <shared-input-text *ngSwitchCase="'percentage'" [(component)]="currentStep.components[i]" (onChange)="update()"
                           type=""></shared-input-text>
        <shared-input-text *ngSwitchCase="'integer'" [(component)]="currentStep.components[i]" (onChange)="update()"
                           type=""></shared-input-text>

        <project-up-and-downloader *ngSwitchCase="'downloader'" [(component)]="currentStep.components[i]"
                                   [componentIndex]="i" [currentStep]="currentStep" (onChange)="update()"
                                   [brand]="currentBrand">
        </project-up-and-downloader>
        <project-up-and-downloader *ngSwitchCase="'uploader'" [(component)]="currentStep.components[i]"
                                   [componentIndex]="i" [currentStep]="currentStep" (onChange)="update()"
                                   [brand]="currentBrand">
        </project-up-and-downloader>


        <digital-shelf *ngSwitchCase="'table_digital_shelf'" [(component)]="currentStep.components[i]"
                       (onChange)="update()"></digital-shelf>

        <services-table *ngSwitchCase="'services_table'"
                        [(component)]="currentStep.components[i]"
                        [stepName]="currentStep.name"
                        (onChange)="update()"
                        [ebpId]="ebpId"
                        (onCollapse)="updateCollapseStateInStorage('services',$event)"></services-table>

        <activation-table *ngSwitchCase="'activation_table'" [(component)]="currentStep.components[i]"
                          (onChange)="reloadModule()"
                          (onToggleChange)="updateToggleState($event)"
                          [stepName]="currentStep.name"
                          [ebpId]="ebpId"
                          (onCollapseRow)="updateCollapseStateInStorage('activationsTable',$event)"></activation-table>
        <activation-full-calendar *ngSwitchCase="'activation_full_calendar'" [(component)]="currentStep.components[i]">
        </activation-full-calendar>
        <skuset-table *ngSwitchCase="'virtual_bundle'" [(component)]="currentStep.components[i]"
                      (onChange)="reloadModule()">
        </skuset-table>
        <activities-editor [(component)]="currentStep.components[i]" *ngSwitchCase="'activities_editor'"
                           (updateStep)="update()"></activities-editor>


        <target-total-table *ngSwitchCase="'table_target_total'" [(component)]="currentStep.components[i]">
        </target-total-table>

        <toc-radio-list *ngSwitchCase="'toc_radio'" [(component)]="currentStep.components[i]"
                        [currentSection]="currentSection" [currentSet]="currentSet" [currentStep]="currentStep"
                        (updateStep)="reloadModule()"></toc-radio-list>
        <terms-condition-table *ngSwitchCase="'table_termsandcond_brand'" [(component)]="currentStep.components[i]"
                               [currentSection]="currentSection"
                               [currentSet]="currentSet"
                               [currentStep]="currentStep"
                               [ebpId]="ebpId"
                               (onCollapse)="updateCollapseStateInStorage('termsAndConditionTable',$event)"
                               (onChange)="reloadModule()">
        </terms-condition-table>
        <terms-condition-table *ngSwitchCase="'table_termsandcond_division'" [(component)]="currentStep.components[i]"
                               [currentSection]="currentSection"
                               [currentSet]="currentSet"
                               [currentStep]="currentStep"
                               [ebpId]="ebpId"
                               (onCollapse)="updateCollapseStateInStorage('termsAndConditionTable',$event)"
                               (onChange)="reloadModule()">
        </terms-condition-table>
        <terms-condition-total-table *ngSwitchCase="'termsandcond_total_table'"
                                     [(component)]="currentStep.components[i]"></terms-condition-total-table>

        <product-grid *ngSwitchCase="'product_grid'" [(component)]="currentStep.components[i]" [brand]="currentBrand"
                      (updateStatus)="reloadModule()">
        </product-grid>
        <shared-image-placeholder *ngSwitchCase="'image_placeholder'" [(component)]="currentStep.components[i]">
        </shared-image-placeholder>

        <input-with-comment *ngSwitchCase="'values_with_comment'" [(component)]="currentStep.components[i]"
                            (onChange)="update()"></input-with-comment>

        <in-page-dialog *ngSwitchCase="'dialog_in_page'" [(component)]="currentStep.components[i]"></in-page-dialog>

        <project-module-management *ngSwitchCase="'module_settings'"></project-module-management>
        <debug *ngSwitchDefault [title]="'Missing Component'" [content]="currentStep.components[i]"></debug>
      </div>

    </fieldset>
    <project-management *ngSwitchCase="'ebp_settings'" [(component)]="currentStep.components[i]"
                        [currentSection]="currentSection"></project-management>
  </div>
</div>
