import { Pipe, PipeTransform } from '@angular/core';


const PROJECT_TYPE = {
  "single-country" : "EBP_TYPE_SINGLE_COUNTRY",
  "multi-country" : "EBP_TYPE_MULTI_COUNTRY",
  "zone" : "EBP_TYPE_ZONE",
  "global" : "EBP_TYPE_GLOBAL"
}

@Pipe({
  name: 'projectTypeLabel'
})
export class ProjectTypeLabelPipe implements PipeTransform {

  transform(label: string): string {
    return PROJECT_TYPE[label] || label
  }

}
