import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { ModuleNamePipe } from './pipes/module-name.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Design Imports
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxMaskModule } from 'ngx-mask';
import { ActivityEditDialogComponent } from './components/activity-edit-dialog/activity-edit-dialog.component';
import { EbpSettingsDialogComponent } from './components/ebp-settings-dialog/ebp-settings-dialog.component';
import { EbpDuplicateDialogComponent } from './components/ebp-duplicate-dialog/ebp-duplicate-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DaterangePickerComponent } from './components/daterange-picker/daterange-picker.component';
import { DebugComponent } from './components/debug/debug.component';
import { ErrorpageTemplateComponent } from './components/errorpage-template/errorpage-template.component';
import { GenericErrorDialogComponent } from './components/generic-error-dialog/generic-error-dialog.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { ImagePlaceholderComponent } from './components/image-placeholder/image-placeholder.component';
import { ImportDetailDialogComponent } from './components/import-detail-dialog/import-detail-dialog.component';
import { InpageStepDialogComponent } from './components/inpage-step-dialog/inpage-step-dialog.component';
import { InputRepeaterComponent } from './components/input-repeater/input-repeater.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { ModuleCardComponent } from './components/module-card/module-card.component';
import { ModuleIconComponent } from './components/module-icon/module-icon.component';
import { ModuleProgressbarComponent } from './components/module-progressbar/module-progressbar.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProductAutocompleteComponent } from './components/product-autocomplete/product-autocomplete.component';
import { ProjectStatusComponent } from './components/project-status/project-status.component';
import { TextComponent } from './components/text/text.component';

import { TitleComponent } from './components/title/title.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { AddButtonComponent } from './icons/add-button/add-button.component';
import { AddIconComponent } from './icons/add-icon/add-icon.component';
import { CheckIconComponent } from './icons/check-icon/check-icon.component';
import { DragIconComponent } from './icons/drag-icon/drag-icon.component';
import { ExcludeIconComponent } from './icons/exclude-icon/exclude-icon.component';
import { ExpandIconComponent } from './icons/expand-icon/expand-icon.component';
import { InfoIconComponent } from './icons/info-icon/info-icon.component';
import { StatusIconComponent } from './icons/status-icon/status-icon.component';
import { AsListPipe } from './pipes/as-list.pipe';
import { EbpTitlePipe } from './pipes/ebp-title.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { OrderByIdPipe } from './pipes/order-by-id.pipe';
import { OrderByKeyPipe } from './pipes/order-by-key.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PaginationNumbersPipe } from './pipes/pagination-numbers.pipe';
import { ProjectTypeLabelPipe } from './pipes/projecttype-label.pipe';
import { ReplacePlaceholderPipe } from './pipes/replace-placeholder.pipe';
import { StatusLabelPipe } from './pipes/status-label.pipe';
import { TextReplacePipe } from './pipes/text-replace.pipe';
import { TotalPagesPipe } from './pipes/total-pages.pipe';
import { StepExcludeIconComponent } from './icons/step-exclude-icon/step-exclude-icon.component';
import { StepStatusButtonComponent } from './icons/step-status-button/step-status-button.component';
import { TextareaAutoresize } from './directives/textarea-auto-resize.directive';
import { ZapIconComponent } from './icons/zap-icon/zap-icon.component';

@NgModule({
  declarations: [
    TitleComponent,
    HamburgerComponent,
    TextComponent,
    InputTextComponent,
    ModuleCardComponent,
    ModuleIconComponent,
    TopBarComponent,
    ProductAutocompleteComponent,
    DaterangePickerComponent,
    DatePickerComponent,
    InputRepeaterComponent,
    AsListPipe,
    TextReplacePipe,
    UploadDialogComponent,
    StatusIconComponent,
    InfoIconComponent,
    CheckIconComponent,
    ExcludeIconComponent,
    ZapIconComponent,
    ImagePlaceholderComponent,
    AddIconComponent,
    ModuleProgressbarComponent,
    DebugComponent,
    EbpTitlePipe,
    AddButtonComponent,
    ExpandIconComponent,
    DragIconComponent,
    ConfirmDialogComponent,
    OrderByIdPipe,
    OrderByKeyPipe,
    OrderByPipe,
    OrderByDatePipe,
    NavigationComponent,
    ErrorpageTemplateComponent,
    StatusLabelPipe,
    ActivityEditDialogComponent,
    EbpSettingsDialogComponent,
    EbpDuplicateDialogComponent,
    StopPropagationDirective,
    InpageStepDialogComponent,
    PaginationNumbersPipe,
    ProjectStatusComponent,
    TotalPagesPipe,
    ClickOutsideDirective,
    ImportDetailDialogComponent,
    ReplacePlaceholderPipe,
    GenericErrorDialogComponent,
    ProjectTypeLabelPipe,
    StepExcludeIconComponent,
    StepStatusButtonComponent,
    TextareaAutoresize,
    ZapIconComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    TranslateModule,
    NgxMaskModule.forChild(),
    Ng2SearchPipeModule,
    TooltipModule
  ],
  exports: [
    TitleComponent,
    HamburgerComponent,
    TextComponent,
    InputTextComponent,
    ModuleCardComponent,
    ModuleIconComponent,
    TopBarComponent,
    ProductAutocompleteComponent,
    DaterangePickerComponent,
    DatePickerComponent,
    MatDividerModule,
    InputRepeaterComponent,
    AsListPipe,
    StatusIconComponent,
    InfoIconComponent,
    CheckIconComponent,
    ExcludeIconComponent,
    ZapIconComponent,
    StepExcludeIconComponent,
    StepStatusButtonComponent,
    ImagePlaceholderComponent,
    AddIconComponent,
    ModuleProgressbarComponent,
    DebugComponent,
    EbpTitlePipe,
    AddButtonComponent,
    ExpandIconComponent,
    DragIconComponent,
    OrderByIdPipe,
    OrderByKeyPipe,
    OrderByPipe,
    StopPropagationDirective,
    NavigationComponent,
    ErrorpageTemplateComponent,
    StatusLabelPipe,
    OrderByDatePipe,
    InpageStepDialogComponent,
    PaginationNumbersPipe,
    ProjectStatusComponent,
    TotalPagesPipe,
    ClickOutsideDirective,
    TextReplacePipe,
    ReplacePlaceholderPipe,
    Ng2SearchPipeModule,
    ProjectTypeLabelPipe,
    TextareaAutoresize
    // ModuleNamePipe
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    TextReplacePipe,
  ],
})
export class SharedModule {}
