import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-chips-list',
  templateUrl: './chips-list.component.html',
  styleUrls: ['./chips-list.component.scss'],
})
export class ChipsListComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { values: [] };
    }
  }

  toggleOption(option:any){
    const index = this.component.answer.values.indexOf(option.value)
    if(index == -1) {
      this.component.answer.values.push(option.value)
    } else {
      this.component.answer.values.splice(index, 1)
    }
    this.onChange.emit()
  }

  isSelected(option:any) {
    return (this.component.answer.values.indexOf(option.value) != -1)
  }
}
