import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Service } from 'src/app/project/entities/service.model';
import { ExtraOptionGroup } from 'src/app/shared/models/extra-option-group';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'services-table',
  templateUrl: './services-table.component.html',
  styleUrls: [ './services-table.component.scss' ],
})
export class ServicesTableComponent implements OnInit {
  constructor() {}

  @Input()
  component: StepComponent;

  @Input()
  stepName: string;

  @Input()
  ebpId: number;

  @Output()
  onChange = new EventEmitter<any>();

  @Output() onCollapse = new EventEmitter<any>();

  collapsedStates: any;

  ngOnInit(): void {
    this.collapsedStates = JSON.parse(localStorage.getItem(`${this.ebpId}.services.collapsedStates`));
    this.component.answer = this.component.answer
      ? this.component.answer
      : {values: []};

    var servicesMap: Array<string> = this.component.answer.values.map((item: Service) => item.name);

    this.component.services.forEach((service) => {
      var s: Service = new Service();
      s.name = service.name;
      s.properties = service.properties;
      s.availability = service.availability;
      s.extra_options_key = service.extra_options;
      s.product_sub_categories = service.product_sub_categories;
      if (this.collapsedStates) {
        const baseKey = `${this.stepName}.${s.name}`;
        s.brands_collapsed = this.collapsedStates[`${baseKey}.brand`] || false;
        s.extra_options_collapsed = this.collapsedStates[`${baseKey}.extraOptions`] || false;
      }
      if (!servicesMap.includes(s.name)) {
        this.component.answer.values.push(s);
      }
    });
  }

  onLauchDateChange(date: Date, service: Service) {
    service.launch_date = date;
    this.onDataChange();
  }

  onExclusivityDateChange(date: Date, service: Service) {
    service.exclusivity_end = date;
    this.onDataChange();
  }

  onExclusivityPeriodChange(data: any, service: Service) {
    service.custom_launch_date = data.startDate;
    service.exclusivity_end = data.endDate;
    this.onDataChange();
  }

  onExtraOptionChange(options: Array<ExtraOptionGroup>, service: Service) {
    service.extra_options = options;
    this.onDataChange();
  }

  onBrandChange(brands: number[], service: Service) {
    service.brands = brands;
    this.onChange.emit();
  }

  onDataChange() {
    this.onChange.emit();
  }

  collapse(component: string, service: any, collapse: boolean) {
    this.onCollapse.emit({path: `${service.name}.${component}`, collapse});

  }
}
