import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-select-simple',
  templateUrl: './select-simple.component.html',
  styleUrls: ['./select-simple.component.scss'],
})
export class SelectSimpleComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { values: '' };
    }
  }

  onValueChange() {
    this.onChange.emit();
  }
}
