import { EventsService } from './../../../shared/services/events.service';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import { Events } from 'src/app/shared/enums/events';
import { Brand } from 'src/app/shared/models/brand.model';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SelectionColumnProperty } from '../../../shared/enums';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
})
export class ProductGridComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    private productService: ProductService,
    private configService: ConfigService,
    private router: Router,
    private element: ElementRef,
    private eventsService: EventsService,
    private moduleService: ModuleService
  ) { }

  @Input()
  component: StepComponent;

  @Input()
  brand: Brand;

  @Output()
  updateStatus: EventEmitter<any> = new EventEmitter<unknown>();

  public currentProject: Project;

  public products: any[];
  public productsRequested: boolean = false;
  public filteredProducts: GridDataResult;
  public defaultConfig: any;
  public extraProductKey = 'extra_product_info';
  public extraProductLocalStKey = 'extra_product_key_is_visible';
  public activeColumnSets: Array<any> = [];
  public activeColumns: Object = {};

  public state: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    take: 10,
    skip: 0,
  };

  ngOnInit(): void {
    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        if (project) {
          this.currentProject = project;
          this.getProducts();
        }
      }),
    );
    this.subscription.add(
      this.configService.productGridConfig.subscribe((config) => {
        if (config) {
          this.setConfig(config);
        } else {
          this.configService.getProductGridConfig();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.productService.resetProducts();
    this.subscription.unsubscribe();
  }

  public getProducts() {
    this.productService.allProducts.subscribe((brandProductMap) => {
      if (
        !brandProductMap ||
        !brandProductMap.hasOwnProperty(
          this.currentProject.id + '_' + this.brand.id,
        )
      ) {
        if (!this.productsRequested) {
          this.productsRequested = true;
          this.productService.loadProductsForBrand(
            this.currentProject.id,
            this.brand.id,
          );
        }
      } else {
        this.products =
          brandProductMap[this.currentProject.id + '_' + this.brand.id];
        this.products.map((product) => {
          if (product) {
            const normalizedCategories = Array.isArray(product.categories) ? product.categories.map(category => category.name) : product.categories;
            const normalizedSubCategories = Array.isArray(product.sub_categories) ? product.sub_categories.map(subCategory => subCategory.name) : product.sub_categories;
            product.categories = normalizedCategories.toString();
            product.sub_categories = normalizedSubCategories.toString();
            return product;
          }

        });
        this.filteredProducts = process(this.products, this.state);
        this.loadProducts();
      }
    });
  }

  public setConfig(config: any) {
    var that = this;
    config.column_sets.forEach((columnSet) => {
      that.activeColumns[columnSet.name] = config.active_columnsets.includes(
        columnSet.name,
      );
      if (columnSet.name === this.extraProductKey && !localStorage.getItem(this.extraProductLocalStKey)) {
        localStorage.setItem(this.extraProductLocalStKey, JSON.stringify(columnSet.active_by_default));
      }
    });
    this.defaultConfig = config;
    if (this.component) {
      if (this.component.hasOwnProperty('show_catalogue_link')) {
        this.defaultConfig.catalogue_link.show = this.component.show_catalogue_link;
      }
      if (this.component.hasOwnProperty('show_columnset_toggles')) {
        this.defaultConfig.show_columnset_toggles = this.component.show_columnset_toggles;
      }
      if (this.component.active_columnsets) {
        config.column_sets.forEach((columnSet) => {
          that.activeColumns[
            columnSet.name
          ] = this.component.active_columnsets.includes(columnSet.name);
          if (columnSet.name === this.extraProductKey) {
            that.activeColumns[columnSet.name] = JSON.parse(localStorage.getItem(this.extraProductLocalStKey));
          }
        });
      }

    }
    this.productService.forceColumn.subscribe((activeColumns) => {
      if (activeColumns) {
        activeColumns.forEach((column) => {
          that.activeColumns[column.name] = column.active;
        });
        var self = this;
        setTimeout(function () {
          self.productService.setForceColumn(null);
        }, 500);
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.filteredProducts = process(this.products, this.state);
    this.loadProducts;
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    const products = cloneDeep(this.products);
    const slicedProductLength = products.slice(this.state.skip, this.state.skip + this.state.take).length;
    if (slicedProductLength < take) {
      const yOffset = -100;
      const element = this.element.nativeElement;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    this.loadProducts();
  }

  private loadProducts(): void {
    this.filteredProducts = {
      data: this.products.slice(
        this.state.skip,
        this.state.skip + this.state.take,
      ),
      total: this.products.length,
    };
  }

  public onColumnSetChange(event: any, set: string) {
    if (set === this.extraProductKey) {
      localStorage.setItem(this.extraProductLocalStKey, event.checked);
    }
    this.activeColumns[set] = event.checked;
  }

  public onProductChange(event: any, product: any, columnProperty?: string) {
    if (columnProperty && columnProperty === SelectionColumnProperty.isHero) {
      product[SelectionColumnProperty.addToActivation] = event;
    }
    this.productService.updateProduct(
      this.currentProject.id,
      product,
      this.brand.id,
    );

    setTimeout(() => {
      this.updateStatus.emit();
      if (columnProperty == 'add_to_activation' || columnProperty == 'is_hero') {
        this.moduleService.loadCurrentModule(this.currentProject.id, 'activation').subscribe(response => {
          this.eventsService.setValue(Events.UPDATE_ACTIVATION_MODULE_DATA, response.data.module); //refresh step status
        });
      }
    }, 500);

  }

  public onDatePickerChange(date: any, key: string, product: any) {
    if (key === 'custom_launch_date' && !product.exclusivity_end && product.is_exclusive) {
      product.exclusivity_end = date;
    }
    product[key] = date.toDate();
    this.onProductChange(null, product);
  }

  public onDateRangePickerChange(date: any, key: string, product: any) {
    product[key + '_start'] = date.startDate;
    product[key + '_end'] = date.endDate;
    this.onProductChange(null, product);
  }

  public noActiveColumn() {
    let activeValues = Object.values(this.activeColumns);
    activeValues = activeValues.filter(
      (item, index) => activeValues.indexOf(item) === index,
    );
    return activeValues.length == 1 && activeValues[0] === false;
  }

  public goToCatalogue() {
    this.productService.setForceColumn([
      { name: 'selection', active: true },
      { name: 'extra_product_info', active: false },
    ]);
    this.router.navigate(['/projects', this.currentProject.id, 'catalogue']);
  }
}
