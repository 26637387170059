import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Brand } from 'src/app/shared/models/brand.model';
import { Section } from 'src/app/shared/models/section.model';
import { Set } from 'src/app/shared/models/set.model';
import { Step } from 'src/app/shared/models/step.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectUserRole } from '../../../shared/enums';
import { Events } from '../../../shared/enums/events';
import { EventsService } from '../../../shared/services/events.service';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-project-step',
  templateUrl: './project-step.component.html',
  styleUrls: [ './project-step.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectStepComponent implements OnInit {
  private subscription: Subscription = new Subscription();

  @Input()
  replaceTranslation: any;

  @Input()
  currentSection: Section;

  @Input()
  currentSet: Set;

  @Input()
  currentStep: Step;

  @Input()
  currentBrand: Brand;

  @Input()
  ebpStatus: string;

  dataIsSaving = false;

  @Input() brandStepStatus: string;
  @Input() brandRepeat = false;

  @HostBinding('class.step-not-full-width') public stepNotFullWidth: boolean = false;

  updateTimer: any;

  moduleAlias: string;
  ebpId: number;
  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  moduleStatus: string;

  constructor(
    private moduleService: ModuleService,
    private activatedRoute: ActivatedRoute,
    private eventsService: EventsService,
    private projectService: ProjectService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.eventsService.on(Events.STEP_DATA_SAVING).subscribe(isSaving => this.dataIsSaving = isSaving),
    );
    this.activatedRoute.parent.params.subscribe((p) => {
      this.ebpId = p.id;
    });
    this.subscription.add(
      this.moduleService.currentModule.subscribe((module) => {
        if (module) {
          this.moduleAlias = module.alias;
          this.moduleStatus = module.version.status;
        }
      }),
    );
    this.stepNotFullWidth = (this.currentStep && this.currentStep.fullwidth === false) ? true : false;
  }

  update() {
    this.updateComponentInStep();
    // var self = this;
    // clearTimeout(self.updateTimer);
    // self.updateTimer = setTimeout(function () {
    // }, 500);
  }

  updateCollapseState(data) {
    const payload = [ {collapsedState: data} ];
    this.moduleService.toggleStepState(this.currentStep.id, payload).subscribe((res) => {});
  }

  updateCollapseStateInStorage(componentName: string, data: any) {
    const baseKey = `${this.ebpId}.${componentName}.collapsedStates`;
    const localStorageItem = localStorage.getItem(baseKey);
    const itemPath = `${this.currentStep.name}.${data.path}`;
    if (localStorageItem) {
      const localStorageObjects = JSON.parse(localStorageItem);
      localStorageObjects[itemPath] = data.collapse;
      localStorage.setItem(baseKey, JSON.stringify(localStorageObjects));
    } else {
      const object = {};
      object[itemPath] = data.collapse;
      localStorage.setItem(baseKey, JSON.stringify(object));
    }
  }

  updateComponentInStep() {
    this.currentStep.is_saving = true;
    this.moduleService
    .saveOrCreateStep(
      this.ebpId,
      this.moduleAlias,
      this.currentStep,
      this.currentStep.components.filter(item => item.answer).map((c) => c.answer),
    )
    .subscribe((response) => {
      // this.currentStep = response.data.step
      this.currentStep.id = response.data?.step.id;
      this.currentStep.status = response.data?.step.status;

      if (this.currentStep.reload_module_after_save) {
        this.moduleService.getCurrentModule(this.ebpId, this.moduleAlias);
      }
      this.reloadModule();

      this.currentStep.is_saving = false;
      this.moduleService.setStepIsSaving(false);
    });
  }

  reloadModule() {
    this.currentStep.is_saving = true;
    this.moduleService.loadCurrentModule(this.ebpId, this.moduleAlias).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_MODULE_DATA, response.data.module);
      this.currentStep.is_saving = false;
    });
    this.projectService.refreshCurrentProject(this.ebpId).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_PROJECT_STATUS, response.data.ebp); //refresh ebp status
    });
  }


  updateToggleState(event: any) {
    let data = {
      "brands": event.brands && event.brands.length > 0 ? event.brands.map(x => x.id) : [],
      "product_categories": event.product_categories && event.product_categories.length > 0 ? event.product_categories.map(x => x.id) : [],
      "product_sub_categories": event.product_sub_categories && event.product_sub_categories.length > 0 ? event.product_sub_categories.map(x => x.id) : [],
      "description": ""
    };
   
    this.moduleService.updateToggleState(event.ebp_id, event.id, data);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  displayStepComponent(userRole) {
    const stepStatus = this.brandRepeat ? this.brandStepStatus : this.currentStep.status;
    if (!this.currentStep.components) {
      return true;
    }
    if (this.currentStep.exclude) {
      return true;
    }
    if (userRole === this.PROJECT_USER_ROLE.validator &&
      !stepStatus
      && !this.currentStep.hide_exclude_icon
      && !this.currentStep.hide_status_icon) {
      return true;
    }
  }

  /* updateStatusWithoutReload() {
    this.eventsService.setValue('reloadStepsStatuses', true);
    this.moduleService.loadModulesForProject(this.ebpId);
  } */

  getStepStatus() {
    return this.brandRepeat ? this.brandStepStatus : this.currentStep.status;
  }

  
}
