<div class="ebp-dialog">

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" enable-background="new 0 0 32 32"
        xml:space="preserve">
        <circle id="Ellipse_75" fill="#FF4E00" cx="16" cy="16" r="16" />
        <path id="Path_2219" fill="#FEFEFE" d="M22,20.5H10.5c-2.209,0-4-1.791-4-4s1.791-4,4-4H23c1.381,0,2.5,1.119,2.5,2.5
        s-1.119,2.5-2.5,2.5H12.5c-0.552,0-1-0.447-1-1c0-0.552,0.448-1,1-1H22V14h-9.5c-1.381,0-2.5,1.119-2.5,2.5s1.119,2.5,2.5,2.5H23
        c2.209,0,4-1.791,4-4s-1.791-4-4-4H10.5C7.462,11,5,13.462,5,16.5c0,3.037,2.462,5.5,5.5,5.5H22V20.5z" />
    </svg>

    <h2 mat-dialog-title *ngIf="!isUploadComplete">{{ ( data.title1 || 'DIALOGS_UPLOADER_TITLE1' ) | translate }}</h2>
    <h2 mat-dialog-title *ngIf="isUploadComplete">{{ ( data.title2 || 'DIALOGS_UPLOADER_TITLE2' ) | translate }}</h2>
    <p class="subtitle">{{ ( data.text1 || 'DIALOGS_UPLOADER_TEXT1' ) | translate }}</p>

    <input id="upload-file" (change)="handleFileInput($event.target.files)" type="file" />
    <label *ngIf="!isUploading && !isUploadComplete && !this.fileToUpload" for="upload-file" class="ebp-button button-transparent">{{'DIALOGS_UPLOADER_CTA_BROWSE' | translate}}...</label>

    <ng-container *ngIf="fileName">
        <p class="filename">{{fileName}}</p>

        <ng-container *ngIf="isUploading">
            <p>{{'DIALOGS_UPLOADER_UPLOAD_IN_PROGRESS' | translate}} {{progress}} %</p>
            <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
        </ng-container>

        <ng-container *ngIf="!isUploading && !isUploadComplete">
            <button (click)="upload()" class="upload-button ebp-button button-transparent">{{'DIALOGS_UPLOADER_UPLOAD_AND_OVERWRITE' | translate}}</button>
        </ng-container>

        <p class="upload-success" *ngIf="isUploadComplete && !uploadError">{{'UPLOADER_SUCCESS' | translate}}</p>
        <p class="upload-error" *ngIf="isUploadComplete && uploadError">{{uploadErrorMsg | translate }}</p>

    </ng-container>

    <button class="ebp-button button-transparent" *ngIf="!isUploading" (click)="cancel()">
        <span *ngIf="!isUploadComplete">{{'DIALOGS_CTA_CANCEL' | translate}}</span>
        <span *ngIf="isUploadComplete">{{'DIALOGS_CTA_OK' | translate}}</span>
    </button>

</div>
