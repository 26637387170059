<div class="horizontal-progress" *ngIf="!isVertical">
    <span class="edited" [ngStyle]="{width: 100 * progress?.edited / progress?.total + '%'}"></span>
    <span class="submitted" [ngStyle]="{width: 100 * progress?.submitted / progress?.total + '%'}"></span>
    <span class="validated" [ngStyle]="{width: 100 * progress?.validated / progress?.total + '%'}"></span>
    <span class="rejected"  [ngStyle]="{width: 100 * progress?.rejected / progress?.total + '%'}"></span>
</div>
<div class="vertical-progress" *ngIf="isVertical">
    <span class="edited" [ngStyle]="{height: 100 * progress?.edited / progress?.total + '%'}"></span>
    <span class="submitted" [ngStyle]="{height: 100 * progress?.submitted / progress?.total + '%'}"></span>
    <span class="validated" [ngStyle]="{height: 100 * progress?.validated / progress?.total + '%'}"></span>
    <span class="rejected"  [ngStyle]="{height: 100 * progress?.rejected / progress?.total + '%'}"></span>
</div>