import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Brand } from 'src/app/shared/models/brand.model';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Devision } from 'src/app/shared/models/devision.model';
//import { GraphTableService } from './graph-table.service';

@Component({
  selector: 'project-table-with-graph',
  templateUrl: './table-with-graph.component.html',
  styleUrls: ['./table-with-graph.component.scss'],
})
export class TableWithGraphComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    //private graphTableService: GraphTableService,
  ) { }

  @Input()
  component: StepComponent;
  @Output()
  onChange = new EventEmitter();

  public project: Project;
  public isForecast: boolean = false;
  public columns: Array<number> = [];
  public displayColumns: Array<number> = [];
  public values: Object = {};
  public totals: Array<any> = [];

  public serviceData: any;

  public isComponentReady: boolean = false;

  public barChartOptions = {
    maintainAspectRatio: false,
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    scales: {
      xAxes: [
        {
          display: false,
          stacked: true,
        },
      ],
      yAxes: [
        {
          display: false,
          stacked: true,
        },
      ],
    },
  };
  public barChartLabels = ['', '', ''];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartData = [{ data: [], label: '' }];

  public barChartColors: Array<string> = [
    '#EAEAEA',
    '#D3D3D3',
    '#A0A0A0',
    '#656565',
    '#EAEAEA',
    '#D3D3D3',
    '#A0A0A0',
    '#656565',
  ];

  ngOnInit(): void {
    this.isForecast = this.component.timespan == 'future';
    this.subscription.add(
      this.projectService.currentProject.subscribe((p) => {
        if (p) {
          this.project = p;

          const y = this.project.year;
          this.columns = [y - 3, y - 2, y - 1, y, y + 1, y + 2];
          this.displayColumns = this.isForecast
            ? [y, y + 1, y + 2]
            : [y - 3, y - 2, y - 1];

          this.component.answer = this.component.answer || {};
          this.values = this.component.answer.values || {};
          this.values['cagr'] = this.values['cagr'] || {};
          this.values['cagr'][this.component.timespan] = this.values['cagr'][this.component.timespan] || {};
          this.values['cagr'][this.component.timespan]['total'] = this.values['cagr'][this.component.timespan]['total'] || 0;


         /*  if (this.project.is_full_division) {
            this.project.divisions.forEach((division) => {
              this.values[division.id] = this.values[division.id] || {};
              this.columns.forEach((year) => {
                this.values[division.id][year] = this.values[division.id][year] || {
                  value: 0,
                  evol: 0,
                };
              });
              this.values['cagr'][this.component.timespan][division.id] = this.values['cagr'][this.component.timespan][division.id] || 0;
            });
          }
 */
          if (!this.project.is_full_division) {

            this.project.divisions.forEach((division) => {
              this.values['cagr'][this.component.timespan][division.id] = this.values['cagr'][this.component.timespan][division.id] || 0;
            });

            this.project.brands.forEach((brand) => {
              this.values[brand.id] = this.values[brand.id] || {};
              this.columns.forEach((year) => {
                this.values[brand.id][year] = this.values[brand.id][year] || {
                  value: 0,
                  evol: 0,
                };
              });
              this.values['cagr'][this.component.timespan][brand.id] = this.values['cagr'][this.component.timespan][brand.id] || 0;
            });

          }

          this.values['divisions'] = this.values['divisions'] || {};
          this.columns.forEach((year) => {
            this.project.divisions.forEach((division) => {
              this.values['divisions'][division.id] = this.values['divisions'][division.id] || {};
              this.values['divisions'][division.id][year] = this.values['divisions'][division.id][year] || {
                value: 0,
                evol: 0,
              };
            });
          });

          this.values['totals'] = this.values['totals'] || {};
          this.columns.forEach((year) => {
            this.values['totals'][year] = this.values['totals'][year] || {
              value: 0,
              evol: 0,
            };
          });

          this.component.answer = { values: this.values };
          this.onDataChange(true);
        }
      }),
    );
    /* this.subscription.add(
      this.graphTableService.ambitionTableData.subscribe((response) => {
        if (response && response.data) {

        }
      }),
    ); */
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onDataChange(silentUpdate = false) {
    this.setAmbitionTableData(
      this.project.brands,
      this.project.divisions,
      this.values,
      this.component.timespan,
      this.displayColumns[0],
      this.displayColumns[this.displayColumns.length - 1],
      silentUpdate,
    );
  }


  public setAmbitionTableData(
    brands: Array<Brand>,
    divisions: Array<Devision>,
    newValues: Object,
    timespan: string,
    firstYear: number,
    lastYear: number,
    silentUpdate = false
  ) {

    if (this.project.is_full_division) {
      const divisionIds = divisions.map((d) => d.id.toString());
      let allYears = [];
      Object.keys(newValues['divisions']).forEach((key) => {
        if (divisionIds.includes(key)) {
          allYears = Object.keys(newValues['divisions'][key]);
        }
      });

      allYears.forEach((year) => {
        let yearTotal = 0;
        divisions.forEach((division) => {
          yearTotal += newValues['divisions'][division.id][year].value;
        });
        newValues['totals'][year].value = yearTotal;
      });


      // Update all Evolutions
      divisions.forEach((division) => {
        let lastYearsValue = 0;
        allYears.forEach((year) => {
          const currentYearsValue = newValues['divisions'][division.id][year].value;
          var evol = (currentYearsValue - lastYearsValue) / lastYearsValue;
          newValues['divisions'][division.id][year].evol = isFinite(evol) ? evol : 0;
          lastYearsValue = currentYearsValue;
        });
      });
      // TOTAL Evolutions
      let lastYearsValue = 0;
      allYears.forEach((year) => {
        const currentYearsValue = newValues['totals'][year].value;
        var evol = (currentYearsValue - lastYearsValue) / lastYearsValue;
        newValues['totals'][year].evol = isFinite(evol) ? evol : 0;
        lastYearsValue = currentYearsValue;
      });

      //CAGR Rates
      var rates = newValues['cagr'] || {};
      rates[timespan] = {};
      divisions.forEach((division) => {
        rates[timespan][division.id] = this.compoundAnnualGrowthRate(
          newValues['divisions'][division.id][firstYear].value,
          newValues['divisions'][division.id][lastYear].value
        );
      });
      rates[timespan]['total'] = this.compoundAnnualGrowthRate(
        newValues['totals'][firstYear].value,
        newValues['totals'][lastYear].value
      );

      newValues['cagr'] = rates;
      const data = newValues;

      this.isComponentReady = true;
      let chartData = [];
      this.project.divisions.forEach((division, i) => {
        let chartDataItem = {
          data: [],
          label: division.name,
          backgroundColor: this.barChartColors[i],
          hoverBackgroundColor: this.barChartColors[i],
          borderColor: this.barChartColors[i],
          hoverBorderColor: this.barChartColors[i],
          maxBarThickness: 60,
        };
        this.displayColumns.forEach((year) => {
          const currentYearsValue = this.values['divisions'][division.id][year].value;
          chartDataItem.data.push(currentYearsValue);
        });
        chartData.push(chartDataItem);
      });
      this.barChartData = chartData && chartData.length > 0 ? chartData.reverse() : [];
      this.values = data;
      this.component.answer.values = this.values;

      if (!silentUpdate) {
        this.onChange.emit();
      }

    } else {

      const brandIds = brands.map((b) => b.id.toString());

      let allYears = [];
      Object.keys(newValues).forEach((key) => {
        if (brandIds.includes(key)) {
          allYears = Object.keys(newValues[key]);
        }
      });

      allYears.forEach((year) => {
        let yearTotal = 0;
        brands.forEach((brand) => {
          yearTotal += newValues[brand.id][year].value;
        });
        newValues['totals'][year].value = yearTotal;
      });

      allYears.forEach((year) => {
        divisions.forEach((division) => {
          let divisionYearTotal = 0;
          division.brands.forEach((brand) => {
            divisionYearTotal += newValues[brand.id][year].value;
          });
          newValues['divisions'][division.id][year].value = divisionYearTotal;
        })
      });

      // Update all Evolutions
      brands.forEach((brand) => {
        let lastYearsValue = 0;
        allYears.forEach((year) => {
          const currentYearsValue = newValues[brand.id][year].value;
          var evol = (currentYearsValue - lastYearsValue) / lastYearsValue;
          newValues[brand.id][year].evol = isFinite(evol) ? evol : 0;
          lastYearsValue = currentYearsValue;
        });
      });

      // TOTAL Evolutions
      let lastYearsValue = 0;
      allYears.forEach((year) => {
        const currentYearsValue = newValues['totals'][year].value;
        var evol = (currentYearsValue - lastYearsValue) / lastYearsValue;
        newValues['totals'][year].evol = isFinite(evol) ? evol : 0;
        lastYearsValue = currentYearsValue;
      });

      let _lastYearsValue = 0;
      divisions.forEach((division) => {
        allYears.forEach((year) => {
          const currentYearsValue = newValues['divisions'][division.id][year].value;
          var evol = (currentYearsValue - _lastYearsValue) / _lastYearsValue;
          newValues['divisions'][division.id][year].evol = isFinite(evol) ? evol : 0;
          _lastYearsValue = currentYearsValue;
        });
      });

      //CAGR Rates
      var rates = newValues['cagr'] || {};
      rates[timespan] = {};
      brands.forEach((brand) => {
        rates[timespan][brand.id] = this.compoundAnnualGrowthRate(
          newValues[brand.id][firstYear].value,
          newValues[brand.id][lastYear].value
        );
      });

      divisions.forEach((division) => {
        rates[timespan][division.id] = this.compoundAnnualGrowthRate(
          newValues['divisions'][division.id][firstYear].value,
          newValues['divisions'][division.id][lastYear].value
        );
      });

      rates[timespan]['total'] = this.compoundAnnualGrowthRate(
        newValues['totals'][firstYear].value,
        newValues['totals'][lastYear].value
      );

      newValues['cagr'] = rates;
      const data = newValues;

      this.isComponentReady = true;
      let chartData = [];
      this.project.brands.forEach((brand, i) => {
        let chartDataItem = {
          data: [],
          label: brand.name,
          backgroundColor: this.barChartColors[i],
          hoverBackgroundColor: this.barChartColors[i],
          borderColor: this.barChartColors[i],
          hoverBorderColor: this.barChartColors[i],
          maxBarThickness: 60,
        };
        this.displayColumns.forEach((year) => {
          const currentYearsValue = this.values[brand.id][year].value;
          chartDataItem.data.push(currentYearsValue);
        });
        chartData.push(chartDataItem);
      });
      this.barChartData = chartData && chartData.length > 0 ? chartData.reverse() : [];
      this.values = data;
      this.component.answer.values = this.values;

      if (!silentUpdate) {
        this.onChange.emit();
      }
    }

  }

  private compoundAnnualGrowthRate(startValue: number, endValue: number) {
    var result = Math.pow(endValue / startValue, 1 / 3) - 1;
    result = Math.round(result * 1000) / 1000;
    return isFinite(result) ? result : 0;
  }
}
