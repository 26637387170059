import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { IConfig, NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoverModule } from './cover/cover.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ProjectModule } from './project/project.module';
import { AuthenticationService } from './shared/services/authentication.service';
import { HttpCancelInterceptor } from './shared/services/cancelInterceptor';
import { GenericHttpService } from './shared/services/generic-http.service';
import { HttpCancelService } from './shared/services/httpCancel.service';
import { JwtInterceptor } from './shared/services/jwt-interceptor';
import { TranslationLoader } from './shared/services/translation-loader.service';
import { SharedModule } from './shared/shared.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const maskConfig: Partial<IConfig> = {
  // validation: false,
};

export function HttpLoaderFactory(http: GenericHttpService) {
  return new TranslationLoader(http);
}


@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    CoverModule,
    DashboardModule,
    ProjectModule,
    SharedModule,
    GridModule,
    DragDropModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [ GenericHttpService ],
      },
    }),
    TooltipModule,
  ],
  providers: [
    AuthenticationService,
    HttpCancelService,
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
    },
    DatePipe,
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule {

}
