import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { groupBy, sortBy } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { Activation } from 'src/app/project/entities/activation';
import { ActivationService } from 'src/app/project/services/activation.service';
import { Brand } from 'src/app/shared/models/brand.model';
import { Project } from 'src/app/shared/models/project.model';
import { SkuSet } from 'src/app/shared/models/sku_set.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectUserRole } from '../../../../shared/enums';
import { Events } from '../../../../shared/enums/events';
import { EventsService } from '../../../../shared/services/events.service';
import { SkuSetService } from '../sku-set-service.service';

@Component({
  selector: 'activation-table',
  templateUrl: './activation-table.component.html',
  styleUrls: [ './activation-table.component.scss' ],
})
export class ActivationTableComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  component: StepComponent;

  @Input()
  stepName: string;
  @Input()
  ebpId: number;
  @Output() onChange = new EventEmitter<any>();
  @Output() onCollapseRow = new EventEmitter<any>();
  @Output() onToggleChange = new EventEmitter<any>();

  currentProject: Project;
  activations: Activation[] = [];
  groupedActivations: { [key: string]: Activation[] } = {};
  brands: Brand[];

  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;
  collapsedStates: any;

  constructor(
    private projectService: ProjectService,
    private activationService: ActivationService,
    private configService: ConfigService,
    private skuSetService: SkuSetService,
    public dialog: MatDialog,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {
    this.collapsedStates = JSON.parse(localStorage.getItem(`${this.ebpId}.activationsTable.collapsedStates`));
    this.subscription.add(
      this.configService.getEntity('brands').subscribe((response) => {
        this.brands = response.data.brands;
        this.loadActivations();
      }),
    );
    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        if (project) {
          this.currentProject = project;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadActivations(fireCalendarUpdate = false) {
    this.activationService
    .getActivations(this.currentProject.id)
    .subscribe((response) => {
      this.activations = response.data.activations.filter(activation => activation.status);
      this.activations.forEach((activation) => {
        this.collapsedStates = JSON.parse(localStorage.getItem(`${this.ebpId}.activationsTable.collapsedStates`));

        if (this.collapsedStates) {
          const baseKey = `${this.stepName}.${activation.id}`;
          activation.calendarCollapsed = this.collapsedStates[`${baseKey}.calendar`] || false;
          activation.productsCollapsed = this.collapsedStates[`${baseKey}.products`] || false;
          activation.brandsCollapsed = this.collapsedStates[`${baseKey}.brands`] || false;
          activation.categoriesCollapsed = this.collapsedStates[`${baseKey}.categories`] || false;
          activation.subCategoriesCollapsed = this.collapsedStates[`${baseKey}.subcategories`] || false;
        }
        if (activation.activation_events.length) {
          const startDates = [];
          const endDates = [];
          activation.activation_events.forEach((activationEvent) => {
            startDates.push(new Date(activationEvent.starts_at));
            endDates.push(new Date(activationEvent.ends_at));
          });
          activation.activationPeriod = {
            startDate: startDates.sort((a, b) => a - b)[0],
            endDate: endDates.sort((a, b) => b - a)[0],
          };
        }

        this.skuSetService
        .getSkuSet(this.currentProject, activation.sku_set.id)
        .subscribe((response) => {
          activation.sku_set = response.data.sku_set;

          if (fireCalendarUpdate) {
            this.eventsService.setValue(Events.UPDATE_ACTIVATIONS, this.activations);
          }
        });

        this.setupAnswers();
      });
      const groupedActivations = groupBy(this.activations, 'sku_set.mono_from_grid');
      const heroActivations = sortBy(groupedActivations[true]
        , [ (item) => {
          return [ item.sku_set.products[0].brand.name.toLowerCase(), item.sku_set.products[0].name.toLowerCase() ];
        } ]);
      const bundleActivations = sortBy(groupedActivations[null], [ 'created_at' ]);
      this.activations = [ ...heroActivations, ...bundleActivations ];
    });
  }

  addActivation() {
    var activation = new Activation();
    activation.status = true;
    activation.ebp_id = this.currentProject.id;
    activation.sku_set = new SkuSet();
    activation.sku_set.add_to_activation = true;
    this.activations.push(activation);
  }

  removeActivation(index: number) {
    this.reloadFullCalendar();
    var activation = this.activations[index];
    if (activation.id) {
      this.activationService.deleteActivation(activation);
    } else {
      this.activations.splice(index, 1);
    }
  }

  onNameChange(activation: Activation, index: number) {

    if (activation.id) {
      this._updateSkuSet(activation.sku_set).then(() =>
        this.reloadFullCalendar(),
      );
    } else {
      this._createActivation(activation, index).then(() =>
        this.reloadFullCalendar(),
      );
    }
  }

  onDateChange(date: any, activation: Activation) {
    if (!date) {
      return;
    }
    activation.sku_set.launch_at = date;
    this._updateSkuSet(activation.sku_set);
    this.reloadFullCalendar();
  }

  onPeriodChange(event: any, activation: Activation) {
    if (!event.startDate || !event.endDate) {
      return;
    }
    activation.sku_set.starts_at = event.startDate;
    activation.sku_set.ends_at = event.endDate;
    this._updateSkuSet(activation.sku_set);
  }

  onProductsSelected(event: any, activation: Activation) {
    var sku_set: SkuSet = JSON.parse(JSON.stringify(activation.sku_set));
    sku_set.products = event;
    if (sku_set.id) {
      this.skuSetService.updateSkuSet(sku_set, this.currentProject);
    } else {
      this.skuSetService.createSkuSet(sku_set, this.currentProject);
    }

    setTimeout(() => {
      this.reloadFullCalendar();
    }, 1000)

  }

  private _updateSkuSet(_skuSet: SkuSet) {
    return new Promise(resolve => {
      const sku_set: SkuSet = JSON.parse(JSON.stringify(_skuSet));
      sku_set.products = sku_set.products.map((p) => p.id);
      this.skuSetService.updateSkuSet(sku_set, this.currentProject).subscribe(r =>
        resolve(r),
      );
    });
  }

  private _createActivation(activation: Activation, index: number) {
    return new Promise(resolve => {
      this.activationService
      .createActivation(activation)
      .subscribe((serverActivation: Activation) => {
        this.activationService
        .getActivation(this.currentProject.id, serverActivation.id)
        .subscribe((act) => {
          this.activations[index] = act;
          resolve();
        });
      });
    });
  }

  onActivitiesChanged() {
    this.loadActivations(true);
  }

  brandsAndCategories(skuSet: SkuSet) {
    const uniqueBrandIds = skuSet.products
    .map((product) => {
      return this.brands.filter((brand) => brand.id == product.brand_id)[0]
        .name;
    })
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();
    return uniqueBrandIds.join(', ');
  }

  activationLength() {
    return this.activations.filter((a) => a.status).length;
  }

  setupAnswers() {
    this.component.answer = this.component.answer || {};
    this.component.answer.collapsedState = this.component.answer.collapsedState || {};
    // this.activations.forEach(activation => {
    //   this.component.answer.collapsedState[activation.id] = this.component.answer.collapsedState[activation.id] || {
    //     products: false,
    //     calendar: false,
    //     navigation: 0,
    //   };
    // });
  }

  onCollapseChange(activation: Activation, collapse: any, type: string) {

    this.onCollapseRow.emit({path: `${activation.id}.${type}`, collapse});

    // if (!this.component.answer.collapsedState) {
    //   return;
    // }
    // if (!this.component.answer.collapsedState[activation.id]) {
    //   this.component.answer.collapsedState[activation.id] = {
    //     products: false,
    //     calendar: false,
    //     navigation: 0,
    //   };
    // }
    //
    // if (type == 'navigation') {
    //   this.component.answer.collapsedState[activation.id]['navigation'] = $event;
    // } else {
    //   this.component.answer.collapsedState[activation.id][type] = !this.component.answer?.collapsedState[activation.id][type];
    // }
    // this.onCollapseRow.emit(this.component.answer.collapsedState);
  }

  reloadFullCalendar() {
    this.eventsService.setValue(Events.UPDATE_ACTIVATIONS, this.activations);
    this.onChange.emit();
  }

  onFeatureChange(featureType: any, feature: number[], activation: Activation) {

    
    if (featureType == 'brands') {
      activation.brands = feature;
    } else if (featureType == 'categories') {
      activation.product_categories = feature;
    } else if (featureType == 'sub_categories') {
      activation.product_sub_categories = feature;
    }
    this.onToggleChange.emit(activation);
  }

  getFeatureIds(feature: any) {
    return feature && feature.length > 0 ? feature.map(x => x.id) : []
  }


}
