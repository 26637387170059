import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { GenericHttpService } from './generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private currentUserSource = new BehaviorSubject<User>(null);
  public currentUser = this.currentUserSource.asObservable();

  constructor(private http: GenericHttpService) {
    this.currentUserSource.next(new User());
  }

  public setUser(user: User) {
    this.currentUserSource.next(user);
  }

  public toggleSuperadmin(userId) {
    return this.http.post(`/user/${userId}/toggle_superadmin`, {});
  }
}
