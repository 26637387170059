import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-expand-icon',
  templateUrl: './expand-icon.component.html',
  styleUrls: ['./expand-icon.component.scss']
})
export class ExpandIconComponent implements OnInit {

  constructor() { }

  @Input()
  rotate: boolean = true

  ngOnInit(): void {
  }

}
