import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 *  Generic HTTP service
 *  For centralizing all error handling and minimizing code repetition.
 *
 *  For custom error handling, inject '@angular/core' HttpClient into service.
 * */

@Injectable({
  providedIn: 'root',
})
export class GenericHttpService {
  constructor(
    public http: HttpClient,
    public router: Router,
  ) {
    // super();
  }

  API_URL: string = environment.api.base_url + '/api/' + environment.api.version;

  // For each request, we create a subject in order to be able to subscribe here and catch the error,
  // yet still return an observable containing data/error to which ever service uses the method.

  // Generic POST request

  public post = (path: string, item: any, options?: any): Observable<any> => {
    let response = new Subject();

    this.http.post(this.API_URL + path, item, options).subscribe(
      success => {
        response.next(success);
        if (options && options.markComplete) {
          response.complete(); // this is required to convert to promise when used toPromise() method.
        }
      },
      error => {
        this.handleError(error);
        response.next(error);
      },
    );

    return response;
  };

  // Generic PUT request

  public put = (path: string, item: any, options?: any): Observable<any> => {
    let response = new Subject();

    this.http.put(this.API_URL + path, item, options).subscribe(
      success => {
        response.next(success);
      },
      error => {
        this.handleError(error);
        response.error(error);
      },
    );

    return response;
  };

  // Generic DELETE request

  public delete = (path: string, options?: any): Observable<any> => {
    let response = new Subject();

    this.http.delete(this.API_URL + path, options).subscribe(
      success => {
        response.next(success);
      },
      error => {
        this.handleError(error);
        response.error(error);
      },
    );

    return response;
  };

  // Generic GET request

  public get = (path: string, options?: any): Observable<any> => {
    let response = new Subject();
    this.http.get(this.API_URL + path, options).subscribe(
      success => {
        response.next(success);
        response.complete();
      },
      error => {
        this.handleError(error);
        response.error(error);
      },
    );

    return response;
  };

  private handleError = (error: HttpErrorResponse): void => {

    switch (error.status) {
      case 404:
        this.router.navigate([ '/404' ]);
        break;
      case 403:
        this.router.navigate([ '/403' ]);
        break;
    }

    //  return error;
    // // switch (error.status) {
    // //   case 401:
    // //     this.router.navigate(["/login"]);
    // //     return error
    // //   case 404:
    // //     return this.alertFail(
    // //       "The requested page/endpoint could not be found",
    // //       "ERROR 404"
    // //     );
    // //   case 403:
    // //     return this.alertFail("This page/endpoint is FORBIDDEN", "ERROR 403");
    // //   case 500:
    // //     return this.alertFail(
    // //       "Sorry! An internal server error has occured.",
    // //       "ERROR 500"
    // //     );
    // //   case 422:
    // //     return this.alertFail(
    // //       "Sorry! You have sent an unproccessable entity.",
    // //       "ERROR 422"
    // //     );
    // //   case 0:
    // //     return this.alertFail(
    // //       "Sorry! There appears to be no response from the server.",
    // //       "ERROR 0"
    // //     );
    // //   default:
    // //     return this.alertFail("Oops! An unknown error has occured", "UNKNOWN");
    // // }
  };

}
