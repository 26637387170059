import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { Section } from 'src/app/shared/models/section.model';
import { Set } from 'src/app/shared/models/set.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { Step } from 'src/app/shared/models/step.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { RowColumnTypes } from '../../../shared/enums';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';

@Component({
  selector: 'terms-condition-table',
  templateUrl: './terms-condition-table.component.html',
  styleUrls: [ './terms-condition-table.component.scss' ],
})
export class TermsConditionTableComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    private tocService: TermsAndConditionsService,
    private router: Router,
  ) { }

  @Input()
  component: StepComponent;

  @Input()
  currentSection: Section;

  @Input()
  currentSet: Set;

  @Input()
  currentStep: Step;

  @Input()
  ebpId: number;

  currentProject: Project;
  entity: string;
  tocData: any;
  answers: any;

  @Output()
  onChange = new EventEmitter<any>();


  @Output()
  onCollapse = new EventEmitter<any>();

  isHidden = true;
  ROW_COLUMN_TYPES = RowColumnTypes;
  collapsedStates: any;
  collapsedData: any;

  ngOnInit(): void {
    this.subscription.add(
      this.tocService.tocData.subscribe((data) => {
        if (data) {

          this.tocData = data[this.currentSection.name];
          if (this.tocData && this.tocData[this.currentStep.name]) {
            this.tocData = this.tocData[this.currentStep.name];
          }
          if (
            this.tocData &&
            this.component.rows.method.indexOf(this.tocData.current_model) != -1
          ) {
            this.isHidden = false;
          } else {
            this.isHidden = true;
          }
          this.setupAnswers();
          this.setUpCollapsedData();
        }
      }),
    );
    this.subscription.add(
      this.projectService.currentProject.subscribe((p) => {
        if (p) {
          this.currentProject = p;
          this.tocService.loadTocData(this.currentProject.id);
          if (this.component.rows.method == 'brandsList') {
            this.entity = 'brands';
          }
          if (this.component.rows.method == 'divisionsList') {
            this.entity = 'divisions';
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCascadingChange(data: any, entity: any, type: string) {
    this.tocService.updateCascade(
      this.currentProject,
      this.currentSection,
      this.currentStep,
      type,
      entity,
      data.checked,
    );
  }

  setupAnswers() {
    this.answers = this.answers || {
      values: {brands: {}, divisions: {}},
    };
    this.collapsedData = {
      brands: {},
      divisions: {},
    };
    this.currentProject?.brands.forEach((brand) => {
      this.answers.values.brands[brand.id] = this.answers.values.brands[brand.id] || false;
    });
    this.currentProject?.divisions.forEach((division) => {
      this.answers.values.divisions[division.id] = this.answers.values.divisions[division.id] || false;
    });
  }

  onCollapseChange(entityId, collapse) {
    this.onCollapse.emit({path: `${entityId}`, collapse});
    this.setUpCollapsedData();
  }
  dataChange(){
    this.onChange.emit();
  }

  setUpCollapsedData(){
    this.collapsedStates = JSON.parse(localStorage.getItem(`${this.ebpId}.termsAndConditionTable.collapsedStates`));
    this.collapsedData = {
      brands: {},
      divisions: {},
    };

    this.currentProject?.brands.forEach((brand) => {
      this.collapsedData.brands[brand.id] = this.collapsedStates?.[`${this.currentStep.name}.${brand.id}`] || false;
    });
    this.currentProject?.divisions.forEach((division) => {
      this.collapsedData.divisions[division.id] = this.collapsedStates?.[`${this.currentStep.name}.${division.id}`] || false;
    });
  }
  navigateToStep(module: string, stepId: string) {
    localStorage.setItem('nav-stepId', stepId);
    this.router.navigate([ '/projects', this.currentProject.id, module ]);
  }

}
