import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { collapseAnimation } from 'angular-animations';
import { cloneDeep, flatten, isEqual, uniqWith } from 'lodash-es';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Brand } from 'src/app/shared/models/brand.model';
import { Country } from 'src/app/shared/models/country.model';
import { CreateProject } from 'src/app/shared/models/createProject.model';
import { Currency } from 'src/app/shared/models/currency.model';
import { Devision } from 'src/app/shared/models/devision.model';
import { EbpEditor } from 'src/app/shared/models/ebpEditor';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { Retailer } from 'src/app/shared/models/retailer.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectType } from '../../../shared/enums';
import { EbpZone } from '../../../shared/models/ebpZone';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: [ './project-edit.component.scss' ],
  animations: [
    collapseAnimation(),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectEditComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  project: CreateProject;
  editProject: Project;
  entireDivisionIsSelected = false;
  entireDivisionBlackListModules = [ 'target', 'termsandcond', 'selection', 'activation' ];
  ebpId: number;

  config: any;
  //activatedModulesConfig: any;

  years: Array<number>;
  zones: any[];
  countries: any[];
  clusters: any[];
  languages: any[];
  brands: Brand[];
  currencies: Currency[];
  availableCurrencies: Currency[];
  catalogueCountries: any[];
  availableBrands: Brand[];
  divisions: Devision[];
  retailers: Retailer[];
  modules: Module[];
  currentUser: User;
  validatorUsers: User[];
  editorUsers: User[];

  isEditmode = false;
  isLoading = false;
  requiredErrMsg: any = '';
  saveLoading = false;
  ebpTypes = ProjectType;
  ebpType: ProjectType = ProjectType.COUNTRY;
  ebpZoneAndCountry: Array<EbpZone> = [];
  selectedClusterCountries: any[] = [];
  hideTitle: boolean = false;
  brandSelectionLimit = 8;
  ownerInfo: any;

  ebpEditors: Array<EbpEditor> = [];
  hasCatalogueSet: boolean = true;

  previouslySavedModules: Module[] = [];
  previouslySavedBrands: Brand[] = [];

  constructor(
    public projectService: ProjectService,
    public moduleService: ModuleService,
    public configService: ConfigService,
    public activatedRoute: ActivatedRoute,
    public authService: AuthenticationService,
    public router: Router,
    public dialog: MatDialog,
  ) { }

  private async fetchAll() {

    this.isLoading = true;
    this.zones = await this.configService.getEntity('zones').toPromise().then(response => response.data.zones);
    this.clusters = await this.configService.getEntity('clusters').toPromise().then(response => response.data.clusters);
    this.languages = await this.configService.getEntity('languages').toPromise().then(response => response.data.languages);
    this.divisions = await this.configService.getEntity('divisions').toPromise().then(response => response.data.divisions);
    this.validatorUsers = await this.configService.getEntity('users').toPromise().then(response => {
      this.ownerInfo = response.data.users.find((user) => user.id == this.currentUser.id);
      return response.data.users.filter((user) => user.id !== this.currentUser.id);
    });
    this.editorUsers = await this.configService.getEntity('users').toPromise().then(response => {
      return response.data.users.filter((user) => user.id !== this.currentUser.id);
    });
    this.modules = await this.configService.getEntity('modules').toPromise().then(response => response.data.modules.sort((a, b) => a.id - b.id));

    this.config = await this.configService.getConfig('ebp_create').toPromise().then(response => response.data.ebp_create);
    this.currencies = await this.configService.getEntity('currencies').toPromise().then(response => response.data.currencies);

    /*
      await this.configService.getConfig('ebp_types_modules').toPromise().then(response => {
        this.activatedModulesConfig = response.data.ebp_types_modules;
        this.ebpTypeModulesConfig = this.activatedModulesConfig[this.ebpType];
      });
    */

    //this.brands = await this.configService.getEntity('brands').toPromise().then(response => response.data.brands);
    //this.productCategories = await this.configService.getEntity('product_categories').toPromise().then(response => response.data.product_categories);

    this.initProject();
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.fetchAll();
    });
    this.configService.dialogConfig.subscribe(config => {
      if (config) {
        this.requiredErrMsg = config.filter(c => c.name == 'alert_required_field')[0];
      }
    });

    this.projectService.setTopBarCTAEnabled(false);
  }

  private initProject() {
    this.isLoading = false;
    const y = new Date().getFullYear();
    this.years = [ y, y + 1 ];

    if (this.activatedRoute.snapshot.params.id) {
      this.isEditmode = true;
      this.projectService.loadCurrentProject(this.activatedRoute.snapshot.params.id);
      this.subscription.add(
        this.projectService.currentProject.subscribe((p: Project) => {
          if (p) {
            if (this.currentUser?.id !== p.owner?.id) {
              this.router.navigate([ '/403', p.id ]);
              return;
            }
            this.moduleService.loadModulesForProject(p.id);
            this.subscription.add(
              this.moduleService.projectModules.subscribe(async modules => {
                if (modules && !this.ebpId) {

                  if (p.type == 'multi-country') {
                    this.ebpType = ProjectType.MULTI_COUNTRY;
                  } else if (p.type == 'single-country') {
                    this.ebpType = ProjectType.COUNTRY;
                  } else if (p.type == 'zone') {
                    this.ebpType = ProjectType.ZONE;
                  } else {
                    this.ebpType = ProjectType.GLOBAL;
                  }
                  this.entireDivisionIsSelected = p.is_full_division;
                  const createProject = new CreateProject();
                  this.ebpId = p.id;
                  createProject.id = p.id;
                  createProject.ebpType = this.ebpType;
                  createProject.legal_country = p.legal_country;
                  createProject.legal_country_id = p.legal_country_id;
                  createProject.created_at = p.created_at;
                  createProject.is_full_division = p.is_full_division;
                  createProject.created_by = p.created_by;
                  createProject.is_best_practice = p.is_best_practice;
                  createProject.status = p.status;
                  createProject.catalogue_country = p.catalogue_country;
                  createProject.catalogue_country_id = p.catalogue_country_id;
                  if (!p.catalogue_country_id) {
                    this.hasCatalogueSet = false;
                  }
                  createProject.type = p.type;

                  this.modules = modules.slice();
                  createProject.modules = [];
                  modules.forEach(m => {
                    if (m.version && m.version.is_active) {
                      createProject.modules.push(m);
                      this.previouslySavedModules.push(m);
                    }
                  });
                  this.previouslySavedBrands = p.brands.slice();

                  createProject.owner_id = p.owner_id;
                  createProject.owner = p.owner;
                  createProject.validator = p.validator;
                  createProject.creator = p.creator;
                  createProject.geographical_setup = p.geographical_setup;
                  createProject.currency_id = p.currency_id;
                  createProject.retailer_id = p.retailer_id;
                  createProject.language_id = p.language_id;
                  createProject.validator_id = p.validator_id;
                  createProject.retailer = p.retailer;
                  createProject.clusters_ids = [];
                  createProject.countries_ids = [];
                  createProject.zones_ids = [];
                  createProject.year = p.year;
                  //createProject.editors = p.editors;

                  this.project = createProject;
                  await this.generateEbpZoneAndCountry(p);
                  await this.generateEditors(p);
                  p.divisions.forEach(d => {
                    this.divisions.forEach(division => {
                      if (d.id === division.id) {
                        this.toggleDivision(division);
                      }
                    });
                  });
                  p.brands.forEach(b => {
                    this.brands.forEach(brand => {
                      if (b.id === brand.id) {
                        this.toggleBrand(brand);
                      }
                    });
                  });

                }

              }),
            );
          }
        }),
      );
    } else {
      this.ebpZoneAndCountry.push(new EbpZone());
      this.project = new CreateProject();
      this.project.modules = this.modules.slice();
      let selectionModule = this.project.modules.find(m => m.alias == 'selection');
      if (selectionModule) {
        selectionModule.is_active = false;
      }

      this.project.language_id = 'en';
      this.project.full_name = 'New eBP';
      this.projectService.setCurrentProject(this.project);
      this.project.type = ProjectType.COUNTRY;
      this.project.owner = this.ownerInfo;
      this.project.editors = {};
    }

    this.configService
    .getConfig('ebp_create')
    .subscribe((conf) => (this.config = conf.data.ebp_create));
  }

  addNewEditor(module: Module) {
    let ebpEditor = new EbpEditor();
    ebpEditor.module = module;
    this.ebpEditors.push(ebpEditor);
  }

  removeEditor(i: number) {
    this.ebpEditors.splice(i, 1);
  }

  isCountryTaken(country: Country) {
    if (this.ebpType == ProjectType.MULTI_COUNTRY && this.ebpZoneAndCountry && this.ebpZoneAndCountry.length > 0) {
      return this.ebpZoneAndCountry.findIndex(o => o.country == country) > -1;
    }
    return false;
  }

  isEditorTaken(user: User, module: Module) {
    if (this.ebpEditors && this.ebpEditors.length > 0) {
      return this.ebpEditors.findIndex(o => (o.editorId == user.id && module.id == o.module.id) || this.project.validator_id == user.id) > -1;
    }
    return false;
  }

  isValidatorTaken(user: User) {
    if (this.ebpEditors && this.ebpEditors.length > 0) {
      return this.ebpEditors.findIndex(o => o.editorId == user.id) > -1;
    }
    return false;
  }

  ngOnDestroy() {
    this.projectService.setCurrentProject(null);
    this.moduleService.setProjectModules(null);
    this.subscription.unsubscribe();
  }

  toggleBrand(brand: Brand) {
    if (!brand.is_selected && this.project.brands?.length >= this.brandSelectionLimit) {
      return;
    }

    brand.is_selected = !brand.is_selected;
    this.project.brands = this.availableBrands.filter(b => b.is_selected);
  }

  toggleDivision(devision: Devision) {

    devision.is_selected = !devision.is_selected;

    // unselect all the brands that are not in the division (to remove previously selected brands)
    if (!devision.is_selected && this.project.brands && this.project.brands.length > 0) {
      let selectedBrands = [];
      this.project.brands.forEach(b => {
        let div_brands = this.brands.filter(b => this.project.divisions.map(d => devision.id).indexOf(b.division_id) != -1);
        div_brands.forEach(br => {
          if (b.id == br.id) {
            selectedBrands.push(br);
          }
        });
      });

      for (var brand of selectedBrands) {
        this.project.brands = this.project.brands.filter(function(el) { return el.id != brand.id; });
        this.availableBrands.forEach(element => {
          if (element.id == brand.id) {
            element.is_selected = false;
          }
        });
      }
    }

    this.project.divisions = this.divisions.filter(d => d.is_selected);

    if (this.brands) {
      this.availableBrands = this.brands.filter(b => this.project.divisions.map(d => d.id).indexOf(b.division_id) != -1);
    } else {
      this.availableBrands = [];
    }
  }

  isFormInvalid() {
    let status = false;
    if (this.ebpType == ProjectType.COUNTRY || this.ebpType == ProjectType.MULTI_COUNTRY) {

      let validCount = this.ebpZoneAndCountry.filter(item => item && item.zone && item.cluster && item.country);

      if (this.ebpZoneAndCountry.length != validCount.length) {
        status = true;
      }

      if (
        !this.project.year
        || !this.currentUser.id
        || (!this.project.retailer_id)
        || !this.project.validator_id
        || !this.project.language_id
        || (!this.project.is_full_division && (!this.project.brands || !this.project.brands.length))
        || (!this.project?.clusters_ids.length) || !this.project.currency_id
        || (!this.project.legal_country || !this.project.legal_country.id)
        || (!this.project?.countries_ids.length)
        || (!this.project.modules || !this.project.modules.length)
      ) {
        status = true;
      }

    } else if (this.ebpType == ProjectType.ZONE) {

      if (!this.project.year || (!this.project?.zones_ids.length) || !this.currentUser.id
        || (!this.project.retailer_id) || !this.project.validator_id || !this.project.language_id
        || (!this.project.brands || !this.project.brands.length) || !this.project.currency_id
        || (!this.project.modules || !this.project.modules.length)) {
        status = true;
      }

    } else if (this.ebpType == ProjectType.GLOBAL) {

      if (!this.project.year || !this.currentUser.id
        || (!this.project.retailer_id) || !this.project.validator_id || !this.project.language_id
        || (!this.project.brands || !this.project.brands.length) || !this.project.currency_id
        || (!this.project.modules || !this.project.modules.length)) {
        status = true;
      }

    }
    return status;
  }

  createProject() {
    if (this.saveLoading) {
      return;
    }

    this.project.retailer = this.retailers.find(r => r.id == this.project.retailer_id);

    if (this.ebpEditors && this.ebpEditors.length > 0) {
      this.project.editors = {};
      this.ebpEditors.forEach(editor => {
        this.project.editors[editor.module.alias] = (this.project.editors && this.project.editors[editor.module.alias]) ? this.project.editors[editor.module.alias] : [];
        this.project.editors[editor.module.alias].push(editor.editorId);
      });
    }

    this.saveLoading = true;
    /* const modules = this.project.modules.map((module) => {
      if (this.ebpTypeModulesConfig.indexOf(module) > -1) {
        return module;
      }
    }).filter(a => a); */

    const moduleAliases = this.project.modules.filter(item => item && this.isModuleSelected(item)).map(item => item.alias);

    if (this.entireDivisionIsSelected) {
      this.project.brands = [];
    }

    if (this.project.id) {
      if (this.hasBrandsChanged() || this.hasModulesChanged()) {
        this.saveLoading = false;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          dialog_key: 'brandslist_or_moduleslist_edit_implies_ebp_statuses_reset',
        };

        this.dialog
        .open(ConfirmDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.saveLoading = true;
            this.updateProject(moduleAliases, true);
          }
        });
      } else {
        this.updateProject(moduleAliases, false);
      }
    } else {
      this.project.owner_id = this.currentUser.id;
      this.projectService.createProject(this.project, moduleAliases, this.ebpType).subscribe(response => {
        this.saveLoading = false;

        if (response && response.data && response.data.ebp) {

          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            dialog_key: 'success_ebp_created',
          };

          this.dialog
          .open(ConfirmDialogComponent, dialogConfig)
          .afterClosed()
          .subscribe((res) => {
            if (res == 'cover') {
              this.router.navigate([ '/projects/', response.data.ebp.id ]);
            } else if (res == 'dashboard') {
              this.router.navigate([ '/dashboard' ]);
            }
          });

        } else {
          console.log('Error creating project.' + response.error.code + ':' + response.error.data.error);
        }
      });
    }
  }

  updateProject(moduleAliases: string[], resetEbp: boolean) {

    this.projectService.updateProject(this.project, moduleAliases, this.ebpType, resetEbp).subscribe(response => {
      this.saveLoading = false;
      if (response && response.status == 'success') {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          dialog_key: 'success_ebp_edit',
        };

        this.dialog
        .open(ConfirmDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((res) => {
          if (res == 'cover') {
            this.router.navigate([ '/projects/', this.project.id ]);
          } else if (res == 'dashboard') {
            this.router.navigate([ '/dashboard' ]);
          }
        });

      }
    });
  }

  zoneChanged(zone: { id: string, name: string }, zoneItem: EbpZone) {
    zoneItem.clustersData = this.filterClusters(zone.id);
    zoneItem.cluster = null;
    zoneItem.country = null;
    this.project.legal_country_id = null;
    this.project.currency_id = null;
    this.project.catalogue_country = null;
    this.project.catalogue_country_id = null;
    this.filterCatalogueCountries();

    if (this.ebpType == ProjectType.ZONE) {
      this.selectedClusterCountries = flatten(zoneItem.clustersData.filter(item => item.countries).map(item => item.countries));
      this.project.geographical_setup = zoneItem?.zone?.name;
      this.filterRetailers();
      this.filterBrands();
    }

    this.filterCurrency();
    this.resetDivisionBrandSelection();
  }

  async filterCurrency() {
    this.currencies = await this.configService.getEntity('currencies').toPromise().then(response => response.data.currencies);
  }

  countryChanged(countryId: number, zoneItem?: EbpZone) {

    let countries = [];

    if (this.ebpType == ProjectType.GLOBAL) {
      countries = flatten(this.clusters.filter(item => item.countries && item.countries.length).map(item => item.countries));
    } else if (this.ebpType == ProjectType.ZONE) {
      countries = flatten(this.ebpZoneAndCountry[0].clustersData.filter(item => item.countries).map(item => item.countries));
    } else {
      countries = flatten(this.ebpZoneAndCountry.filter(item => item.cluster && item.cluster.countries).map(item => item.cluster.countries));
    }

    const selectedCountry = countries.find(country => country.id === countryId);
    if (zoneItem) {
      zoneItem.country = selectedCountry;
      this.resetDivisionBrandSelection();
    }

    if (!zoneItem) {
      this.project.legal_country = selectedCountry;
      this.project.legal_country_id = selectedCountry.id;
    }

    if (this.ebpType === ProjectType.COUNTRY && zoneItem) {
      this.project.catalogue_country_id = selectedCountry.id; //auto-select
      this.project.legal_country = selectedCountry; //auto-select
      this.project.legal_country_id = selectedCountry.id; //auto-select
      this.catalogueCountryChanged(selectedCountry.id);
      this.project.geographical_setup = selectedCountry?.name_en;
    } else if (this.ebpType == ProjectType.MULTI_COUNTRY) {
      if (this.project.geographical_setup) {
        this.project.geographical_setup += ', ' + selectedCountry?.name_en;
      } else {
        this.project.geographical_setup = selectedCountry?.name_en;
      }
    }

    if (this.ebpType != ProjectType.ZONE && this.ebpType != ProjectType.GLOBAL && zoneItem) {
      this.filterRetailers();
    }

    if (!this.project.catalogue_country_id && zoneItem) {
      this.filterBrands();
    }

  }

  resetDivisionBrandSelection() {
    this.divisions?.map(division => division.is_selected = false);
    this.availableBrands?.map(brand => brand.is_selected = false);
    this.project.brands = [];
    this.project.divisions = [];
  }

  filterClusters(zoneId: string) {
    return this.clusters.filter(cluster => cluster.zone_id === zoneId);
  }

  async filterBrands() {
    this.brands = [];
    if (this.ebpType == ProjectType.COUNTRY) {
      this.brands = await this.configService.getEntity('brands', 'country_id=' + this.project.legal_country_id).toPromise().then(response => response.data.brands);
    } else if (this.ebpType == ProjectType.MULTI_COUNTRY) {
      let countries = flatten(this.ebpZoneAndCountry.filter(item => item && item.country).map(item => item.country.id))?.toString();
      this.brands = await this.configService.getEntity('brands', 'country_id=' + countries).toPromise().then(response => response.data.brands);
    } else if (this.ebpType == ProjectType.ZONE) {
      let zone = flatten(this.ebpZoneAndCountry.filter(item => item && item.zone).map(item => item.zone.id))[0];
      this.brands = await this.configService.getEntity('brands', 'zone_id=' + zone).toPromise().then(response => response.data.brands);
    } else {
      this.brands = await this.configService.getEntity('brands').toPromise().then(response => response.data.brands);
    }

  }

  async filterBrandsByCatalogueCountry() {
    this.brands = [];
    this.brands = await this.configService.getEntity('brands', 'country_id=' + this.project.catalogue_country.id).toPromise().then(response => response.data.brands);
  }

  async filterRetailers() {
    if (this.ebpType == ProjectType.GLOBAL) {
      this.retailers = await this.configService.getEntity('retailers').toPromise().then(response => response.data.retailers);
    } else if (this.ebpType == ProjectType.ZONE) {
      let zone_id = flatten(this.ebpZoneAndCountry.filter(item => item && item.zone).map(item => item.zone.id))?.toString();
      this.retailers = await this.configService.getEntity('retailers', 'zone_id=' + zone_id).toPromise().then(response => response.data.retailers);
    } else {
      const countryIds = this.ebpZoneAndCountry.filter(item => item.country && item.country.id).map((item) => item.country.id);
      this.retailers = await this.configService.getEntity('retailers', {country_id: countryIds.join(',')}).toPromise().then(response => response.data.retailers);
    }
  }

  clusterChanged(clusterId: number, zoneItem: EbpZone) {

    zoneItem.cluster = this.clusters.find((cluster) => cluster.id === clusterId);
    this.selectedClusterCountries = uniqWith(flatten(this.ebpZoneAndCountry.filter(item => item.cluster && item.cluster).map(item => item.cluster.countries)), isEqual);
    zoneItem.country = null;
    zoneItem.countryId = null;
    this.project.legal_country = null;
    this.project.legal_country_id = null;

    this.resetDivisionBrandSelection();
  }

  ebpTypeChange() {
    this.clearEbp();

    this.project.geographical_setup = null;
    this.selectedClusterCountries = [];
    this.currencies = [];
    this.brands = [];
    this.ebpZoneAndCountry = (this.ebpType == ProjectType.MULTI_COUNTRY) ? [ new EbpZone(), new EbpZone() ] : [ new EbpZone() ];
    this.retailers = [];

    this.resetDivisionBrandSelection();
    this.project.division_ids = [];
    this.project.divisions = [];
    this.project.modules = this.modules.slice();
    let selectionModule = this.project.modules.find(m => m.alias == 'selection');
    if (selectionModule) {
      selectionModule.is_active = false;
    }

    this.project.brands = [];

    if (this.ebpType == ProjectType.GLOBAL) {
      this.ebpZoneAndCountry = null;
      this.selectedClusterCountries = uniqWith(flatten(this.clusters.filter(item => item.countries && item.countries.length).map(item => item.countries)), isEqual);
      this.project.geographical_setup = 'Global';

      this.filterRetailers();
      this.filterBrands();
      this.filterCatalogueCountries();
      this.filterCurrency();

    }

    this.project.type = this.ebpType;
  }

  async filterCatalogueCountries() {
    if (this.ebpType == ProjectType.COUNTRY) {
      let zone_id = this.ebpZoneAndCountry[0]?.zone?.id;
      this.catalogueCountries = await this.configService.getEntity('country_catalogues', 'zone_id=' + zone_id).toPromise().then(response => response.data.country_catalogues);
    } else if (this.ebpType == ProjectType.MULTI_COUNTRY) {
      let zone_id = flatten(this.ebpZoneAndCountry.filter(item => item && item.zone).map(item => item.zone.id))?.toString();
      this.catalogueCountries = await this.configService.getEntity('country_catalogues', 'zone_id=' + zone_id).toPromise().then(response => response.data.country_catalogues);
    } else if (this.ebpType == ProjectType.ZONE) {
      let zone_id = this.ebpZoneAndCountry[0]?.zone?.id;
      this.catalogueCountries = await this.configService.getEntity('country_catalogues', 'zone_id=' + zone_id).toPromise().then(response => response.data.country_catalogues);
    } else {
      this.catalogueCountries = await this.configService.getEntity('country_catalogues').toPromise().then(response => response.data.country_catalogues);
    }
  }

  catalogueCountryChanged(catalogue_country_id) {
    this.resetDivisionBrandSelection();
    if (catalogue_country_id) {
      let allCountries = uniqWith(flatten(this.clusters.filter(item => item.countries && item.countries.length).map(item => item.countries)), isEqual);
      //let selectedCatalogueCountry = this.selectedClusterCountries.find(item => item && item.id == catalogue_country_id)
      let selectedCatalogueCountry = allCountries?.find(item => item && item.id == catalogue_country_id);
      this.project.catalogue_country = selectedCatalogueCountry;
      let currency_id = selectedCatalogueCountry?.currency?.code;
      this.project.currency_id = currency_id;
      this.filterBrandsByCatalogueCountry();
    } else {
      this.project.catalogue_country = null;
      this.project.currency_id = null;
      let selectionModule = this.project.modules.find(m => m.alias == 'selection');
      if (selectionModule) {
        selectionModule.is_active = false;
      }
      this.filterBrands();
    }
  }

  toggleModule(module: Module, event) {

    if (module.status == 'offline') {
      return;
    }

    const moduleAliases = this.project.modules.map(m => m.alias);
    const i = moduleAliases.indexOf(module.alias);
    const dialogConfig = new MatDialogConfig();
    if (i == -1) {
      this.project.modules.push(module);
    } else {
      dialogConfig.data = {
        dialog_key: 'alert_module_deselection',
      };
      this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((shouldDelete) => {
        if (shouldDelete) {
          this.project.modules.splice(i, 1);

          //remove all editors of the same module
          this.ebpEditors = this.ebpEditors.filter(item => item.module.id != module.id);

        } else {
          event.checked = true; //reset
          event.source.checked = true;
        }
      });
    }
  }

  isModuleSelected(module: Module) {
    if (module.alias == 'selection' && !this.project.catalogue_country_id) {
      return false;
    }
    const moduleIsDisabled = this.entireDivisionIsSelected && this.entireDivisionBlackListModules.indexOf(module.alias) > -1;
    return this.project.modules.map(m => m.alias).includes(module.alias) && module.status === 'online' && !moduleIsDisabled;
  }

  isModuleOnline(module: Module) {
    if (module.alias == 'selection' && !this.project.catalogue_country_id) {
      return false;
    }
    let status = module.status === 'online';

    if (this.entireDivisionIsSelected && this.entireDivisionBlackListModules.indexOf(module.alias) > -1) {
      status = false;
    }

    return status;
  }

  deleteZoneAndCountry(i: number) {
    this.clearEbp();

    if (this.project.geographical_setup) {
      this.project.geographical_setup = this.project.geographical_setup.replace(', ' + this.ebpZoneAndCountry[i]?.country?.name_en, '');
    }

    this.ebpZoneAndCountry.splice(i, 1);
    this.selectedClusterCountries = uniqWith(flatten(this.ebpZoneAndCountry.filter(item => item.cluster && item.cluster).map(item => item.cluster.countries)), isEqual);

    this.filterRetailers();
  }

  clearEbp() {
    this.project.legal_country = null;
    this.project.legal_country_id = null;
    this.project.catalogue_country_id = null;
    this.project.currency_id = null;
    this.project.year = null;
    this.project.retailer_id = null;
    this.project.retailer = null;
    this.project.cluster = null;
    this.project.clusters_ids = [];
    this.project.countries_ids = [];
    this.project.zones_ids = [];
    this.project.editors = {};
  }

  addNewZoneAndCountry() {
    this.ebpZoneAndCountry.push(new EbpZone());
  }

  ebpYearChange() {
    this.assignAdditionalData();
  }

  async generateEbpZoneAndCountry(p: Project) {
    p.zones.forEach((projectZone) => {
      const zoneItem = new EbpZone();
      zoneItem.zone = this.zones.find(zone => zone.id === projectZone.id);
      zoneItem.clustersData = this.filterClusters(projectZone.id);
      this.ebpZoneAndCountry.push(zoneItem);
    });
    const projectClusters = cloneDeep(p.clusters);
    this.ebpZoneAndCountry.forEach((zoneAndCountry) => {
      if (projectClusters) {
        const selectedClusterIndex = projectClusters.findIndex(pCluster => pCluster.zone_id === zoneAndCountry.zone.id);
        if (selectedClusterIndex > -1) {
          zoneAndCountry.cluster = projectClusters[selectedClusterIndex];
          zoneAndCountry.clusterId = projectClusters[selectedClusterIndex].id;

          if (p.countries && p.countries.length) {
            zoneAndCountry.country = p.countries.find((country) => country.cluster_id === zoneAndCountry.clusterId);
            zoneAndCountry.countryId = zoneAndCountry.country.id;
          }

          projectClusters.splice(selectedClusterIndex, 1);
        }
      }
    });

    //populating legislation dropdown
    if (this.ebpType == ProjectType.ZONE) {
      let availableClusters = this.clusters.filter(cluster => cluster.zone_id === p.zones[0]?.id);
      this.selectedClusterCountries = flatten(availableClusters.filter(item => item.countries).map(item => item.countries));
    } else if (this.ebpType == ProjectType.GLOBAL) {
      this.selectedClusterCountries = uniqWith(flatten(this.clusters.filter(item => item.countries && item.countries.length).map(item => item.countries)), isEqual);
    } else {
      this.selectedClusterCountries = uniqWith(flatten(this.ebpZoneAndCountry.filter(item => item.cluster && item.cluster.countries).map(item => item.cluster.countries)), isEqual);
    }

    this.assignAdditionalData();
    await this.filterCurrency();
    await this.filterRetailers();
    await this.filterCatalogueCountries();

    if (p.catalogue_country_id) {
      await this.filterBrandsByCatalogueCountry();
    } else {
      await this.filterBrands();
    }

  }

  async generateEditors(p: Project) {

    if (p.editors && Object.keys(p.editors).length > 0) {
      for (var key in p.editors) {
        if (p.editors.hasOwnProperty(key)) {
          if (p.editors[key] && p.editors[key].length > 0) {
            p.editors[key].forEach(element => {
              let ebpEditor = new EbpEditor();
              ebpEditor.module = this.modules.find(m => m.alias == key);
              ebpEditor.editorId = element.id;
              this.ebpEditors.push(ebpEditor);
            });
          }
        }
      }
    }
  }

  assignAdditionalData() {
    if (this.ebpZoneAndCountry) {
      this.project.clusters_ids = flatten(this.ebpZoneAndCountry.filter(item => item && item.cluster).map(item => item.cluster.id));
      this.project.countries_ids = flatten(this.ebpZoneAndCountry.filter(item => item && item.country).map(item => item.country.id));
      this.project.zones_ids = flatten(this.ebpZoneAndCountry.filter(item => item && item.zone).map(item => item.zone.id));
    }

  }

  validatorChanged(validator_id) {
    if (validator_id) {
      this.project.validator = this.validatorUsers.find(user => user.id == validator_id);
    }
  }

  onRetailerChange(retailer_id) {
    if (retailer_id) {
      this.project.retailer = this.retailers.find(item => item.id == retailer_id);
    }
  }

  hasBrandsChanged() {
    return JSON.stringify(this.project.brands.map((b) => b.slug)) != JSON.stringify(this.previouslySavedBrands.map((b) => b.slug));
  }

  hasModulesChanged() {
    return JSON.stringify(this.project.modules.map((b) => b.alias)) != JSON.stringify(this.previouslySavedModules.map((m) => m.alias));
  }

  entireDivisionToggle({checked}: MatSlideToggleChange) {
    this.entireDivisionIsSelected = checked;
    this.project.is_full_division = checked;
    this.availableBrands.map(b => b.is_selected = false);
    this.project.brands = checked ? [ {id: 2} ] : [];
  }
}

