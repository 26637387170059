import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-radio-list',
  templateUrl: './radio-list.component.html',
  styleUrls: ['./radio-list.component.scss'],
})
export class RadioListComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { values: '' };
    }
  }

  onValueChange() {
    this.onChange.emit();
  }
}
