import { Pipe, PipeTransform } from '@angular/core';
import { chunk } from 'lodash-es';

@Pipe({
  name: 'paginationNumbers',
})
export class PaginationNumbersPipe implements PipeTransform {

  transform<T>(elements: T[], currentPage: number, totalPages: number): T[] {
    const chunks = chunk(elements, 5);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < chunks.length; i++) {
      const chunkItem = chunks[i];
      if (currentPage >= (chunkItem[0] + 1) && currentPage <= (chunkItem[chunkItem.length - 1] + 1)) {
        elements = chunkItem;
        break;
      }
    }

    return elements;
  }

}
