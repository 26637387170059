import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../shared/services/config.service';
import { GenericHttpService } from '../../../shared/services/generic-http.service';

export interface TranslationItem {
  alias: string;
  branch: string;
  context: string;
  is_locked: boolean;
  position: number;
  unit: string;
  updated_at: Date;
  updated_by: string;

  [key: string]: any;
}

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: [ './translations.component.scss' ],
})
export class TranslationsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  activeColumns = {};
  sourceLanguages = [];
  targetLanguages = [];
  translations: TranslationItem[] = [];
  filter: CompositeFilterDescriptor;
  filteredTranslations: GridDataResult;
  defaultConfig: any;
  state: State = JSON.parse(localStorage.getItem('translationTableState')) || {
    take: 20,
    skip: 0,
    sort: [],
  };
  sourceLanguage;
  targetLanguage;

  constructor(private configService: ConfigService,
              private http: GenericHttpService,
              public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadLanguages();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setConfig(config: any) {
    if (!config) {
      return;
    }
    config?.column_sets?.forEach((columnSet) => {
      columnSet.sets[0].columns.map((column) => {
        if (column.type === 'sourceLanguage') {
          column.property = this.sourceLanguage.id;
        }
        if (column.type === 'targetLanguage') {
          column.property = this.targetLanguage.id;
        }
        return column;
      });
      this.activeColumns[columnSet.name] = config.active_columnsets.includes(
        columnSet.name,
      );
    });
    this.defaultConfig = config;
    this.defaultConfig.show_columnset_toggles = true;
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    localStorage.setItem('translationTableState', JSON.stringify(this.state));

    this.filteredTranslations = process(this.translations, this.state);
  }

  pageChange() {
    this.loadTranslations(this.sourceLanguage.id, this.targetLanguage.id);
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  noActiveColumn() {
    let activeValues = Object.values(this.activeColumns);
    activeValues = activeValues.filter(
      (item, index) => activeValues.indexOf(item) === index,
    );
    return activeValues.length === 1 && activeValues[0] === false;
  }

  loadTranslations(sourceLanguage: string, targetLanguage: string) {
    this.configService.getEntity(`translations/grid/${sourceLanguage}/${targetLanguage}`).subscribe(response => {
      this.translations = response.data.translations;
      this.filteredTranslations = process(this.translations, this.state);
      // console.log(this.filteredTranslations);
      // this.filteredTranslations = {
      //   data: this.translations.slice(
      //     this.state.skip,
      //     this.state.skip + this.state.take,
      //   ),
      //   total: this.translations.length,
      // };
      this.subscription.add(
        this.configService.translationsGridConfig.subscribe((config) => {
          if (config) {
            this.setConfig(config);
          } else {
            this.configService.getTranslationsGridConfig();
          }
        }),
      );
    });
  }

  targetLanguageChanged(lang: string) {
    this.loadTranslations(this.sourceLanguage.id, this.targetLanguage.id);
    localStorage.setItem('translationLanguages', JSON.stringify({source: this.sourceLanguage.id, target: this.targetLanguage.id}));
  }

  saveItem(dataItem: any) {
    this.http.put(`/translation/${dataItem.alias}/${this.targetLanguage.id}`, {
      target: dataItem[this.targetLanguage.id],
    }).subscribe((response) => {
    });
  }

  loadLanguages() {
    this.http.get('/translations/source_target').subscribe((response) => {
      this.sourceLanguages = response.data.source_languages;
      this.sourceLanguage = this.sourceLanguages && this.sourceLanguages.length ? this.sourceLanguages[0] : null;

      this.targetLanguages = response.data.target_languages.filter((item) => item.id.toLowerCase() != 'en'); // Target language dropdown cannot contain English.
      this.targetLanguage = this.targetLanguages && this.targetLanguages.length ? this.targetLanguages[0] : null;
      const savedLanguages = JSON.parse(localStorage.getItem('translationLanguages'));
      if (savedLanguages) {
        this.sourceLanguage = this.sourceLanguages.find((lang) => lang.id === savedLanguages.source);
        this.targetLanguage = this.targetLanguages.find((lang) => lang.id === savedLanguages.target);
      }

      this.loadTranslations(this.sourceLanguage.id, this.targetLanguage.id);
    });
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.filteredTranslations = {
      data: orderBy(this.translations, this.state.sort),
      total: this.translations.length,
    };
  }
}
