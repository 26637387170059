<div class="project-detail-step" *ngIf="component.answer">
    <div class="input-repeater">
        <div *ngFor="let response of component.answer.values; index as i; trackBy:trackByFn" class="input-item">
            <mat-form-field>
                <mat-label>{{component.placeholder | translate | textReplace: {"\{\{N\}\}":i+1} | textReplace: {"\{\{n\}\}":i+1} }}</mat-label>
                <textarea style="overflow:hidden" cdkTextareaAutosize matInput [ngModel]="component.answer.values[i]" [maxlength]="component.max_chars" (change)="onValueChange($event, i)" autocomplete="off"></textarea>
            </mat-form-field>
            <shared-exclude-icon *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor"
            (onAlertProceed)="removeItem(i)" [alert]="'alert_deletion'"></shared-exclude-icon>
        </div>
        <shared-add-button (click)="addItem()"
                           *ngIf="component.answer.values?.length < component.max_item && ((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor)" [label]="component.add_item_label | translate">
        </shared-add-button>
    </div>
</div>
