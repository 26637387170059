<div (ebclickOutside)="isMenuActive = false">

  <div *ngIf="isMenuActive" class="main-div">
    <div>
    <span *ngFor="let language of languages" class="language-span" (click)="changeLanguage(language.id)"
          [class.underline]="language.id == this.translate.currentLang">{{language?.name}}</span>
    </div>

    <div *ngFor="let navigationItem of navigationItems">
      <a *ngIf="navigationItem?.type != NavigationItemTypes.EXTERNAL" [routerLink]="[navigationItem.url]"
         (click)="closeMenu(navigationItem)" class="navigation-link">{{navigationItem.label |
          translate}}</a>
      <a *ngIf="navigationItem?.type == NavigationItemTypes.EXTERNAL" [href]="navigationItem.url" target="_blank"
         (click)="closeMenu(navigationItem)" class="navigation-link">{{navigationItem.label |
          translate}}</a>
    </div>


    <ng-container *ngIf="user.is_superadmin">
      <div>
        <label> - </label>
      </div>
      <div>
        <a [routerLink]="['/user/list']" (click)="closeMenu()"
           class="navigation-link">{{'CMS_USERS_TITLE' | translate}}</a>
      </div>
      <div>
        <a [routerLink]="['/import/logs']" (click)="closeMenu()"
           class="navigation-link">{{'CMS_IMPORT_LOGS_TITLE' | translate}}</a>
      </div>
      <div>
        <a [routerLink]="['/retailers']" (click)="closeMenu()"
           class="navigation-link">{{'CMS_RETAILERS_TITLE' | translate}}</a>
      </div>
      <div>
        <a [routerLink]="['/translations']" (click)="closeMenu()"
           class="navigation-link">{{'CMS_TRANSLATIONS_TITLE' | translate}}</a>
      </div>
    </ng-container>
  </div>
  <div (click)="isMenuActive = !isMenuActive" class="btn">
    <div class="icon-bar" [@hamburguerX]="!isMenuActive ? 'hamburguer' : 'topX'"></div>
    <div class="icon-bar" [@hamburguerX]="!isMenuActive ? 'hamburguer' : 'hide'"></div>
    <div class="icon-bar" [@hamburguerX]="!isMenuActive ? 'hamburguer' : 'bottomX'"></div>
  </div>
</div>
