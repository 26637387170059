import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ProjectType, ProjectUserRole } from '../enums';
import { DashboardType } from '../enums/dashboardType';
import { CreateProject } from '../models/createProject.model';
import { Project } from '../models/project.model';

import { GenericHttpService } from './generic-http.service';

interface CurrentUserRoleParameters {
  userId: number;
  ownerId: number;
  validatorId: number;
  editors: any
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: GenericHttpService) { }

  private currentProjectSource = new BehaviorSubject<any>(null);
  private currentUserRoleSource = new BehaviorSubject<ProjectUserRole>(null);
  private currentProjectBrandsSource = new BehaviorSubject<any>(null);
  private currentProjectJobsSource = new BehaviorSubject<any>(null);
  private enableTopBarCTASource = new BehaviorSubject<Boolean>(false);

  public currentProject = this.currentProjectSource.asObservable();
  public currentUserRole = this.currentUserRoleSource.asObservable();
  public currentProjectJobs = this.currentProjectJobsSource.asObservable();
  public enableTopBarCTA = this.enableTopBarCTASource.asObservable();
  public currentProjectBrands = this.currentProjectBrandsSource.asObservable();

  private PROJECT_TYPE = ProjectType;
  private projectsSource = new BehaviorSubject<{ [key in DashboardType]: { projects: Project[], pagination: any } }>({
    [DashboardType.NORMAL]: {
      projects: [],
      pagination: {},
    },
    [DashboardType.VALIDATION]: {
      projects: [],
      pagination: {},
    },
    [DashboardType.BEST_PRACTICE]: {
      projects: [],
      pagination: {},
    },
  });
  public projects = this.projectsSource.asObservable();

  private isLoadingSource = new BehaviorSubject<{ [key in DashboardType]: boolean }>({
    normal: true,
    validation: true,
    best_practice: true,
  });
  public isLoading = this.isLoadingSource.asObservable();
  private pollingInterval: any = null;

  public setCurrentProject(state) {
    this.currentProjectSource.next(state);
  }

  public setCurrentProjectBrands(state) {
    this.currentProjectBrandsSource.next(state);
  }

  public setCurrentProjectJobs(state) {
    this.currentProjectJobsSource.next(state);
  }

  public loadEbpModules(ebpId) {
    return this.http.get(`/ebp/${ebpId}/modules`);
  }

  public clearProject() {
    this.currentProjectSource.next(null);
  }

  public setTopBarCTAEnabled(enabled: Boolean) {
    this.enableTopBarCTASource.next(enabled);
  }

  public loadCurrentProject(id: number) {
    this.http.get('/ebp/' + id).subscribe((response) => {
      if (response.status == 'success') {
        this.setCurrentProject(response.data.ebp);
      } else {
        console.log('Error fetching project');
      }
    }, (e) => {
      this.setCurrentProject({notFound: true});
    });
  }


  public loadCurrentProjectJobs(projectId: number, userId: number) {
    this.http.get('/user/jobs').subscribe((response) => {
      if (response.status == 'success') {
        let jobs = response.data?.jobs;

        if (jobs && jobs.length > 0) {
          let currentProjectJobs = response.data.jobs.filter(item => item.name == 'EXPORT_EBP' && item.parameters.ebp_id == projectId && item.user_id == userId);
          let allFinishedJobs = currentProjectJobs.filter((item) => item.status == 'finished');
          this.setCurrentProjectJobs(currentProjectJobs);

          if (allFinishedJobs && allFinishedJobs.length == currentProjectJobs.length) { //all jobs complete stop polling
            this.stopPollingJobs();
          }
        } else {
          this.stopPollingJobs();
        }
      } else {
        console.log('Error fetching project');
      }
    });
  }

  public setCurrentUserRole(data: CurrentUserRoleParameters, moduleAlias: string) {
    let role;

    if (data.userId === data.ownerId) {
      role = ProjectUserRole.owner;
    } else if (data.userId === data.validatorId) {
      role = ProjectUserRole.validator;
    } else if (data.editors && data.editors[moduleAlias]) {
      const editorIds = data.editors[moduleAlias].map(user => user.id);
      if (editorIds.includes(data.userId)) {
        role = ProjectUserRole.editor;
      }
    } else {
      role = ProjectUserRole.viewer;
    }

    this.currentUserRoleSource.next(role);

  }

  public createProject(project: CreateProject, modules: Array<string>, ebpType: ProjectType) {
    const payload = this._mapProject(project, modules, ebpType);
    console.log('Request payload: \n' + JSON.stringify(payload));
    return this.http.post('/ebp', payload);
  }

  public deleteProject(projectId: number) {
    return this.http.delete(`/ebp/${projectId}`);
  }

  public updateProject(project: CreateProject, modules: Array<string>, ebpType: ProjectType, resetEbp: boolean = false) {
    const payload = this._mapProject(project, modules, ebpType, resetEbp);
    console.log('Request payload: \n' + JSON.stringify(payload));
    return this.http.put('/ebp/' + project.id, payload);
  }

  public updateStatus(project: Project, status: string) {
    return this.http.get('/ebp/' + project.id + '/' + status);
  }

  public getStepStatuses(ebpId: any, moduleName: string) {
    return this.http.get(`/ebp/${ebpId}/status/${moduleName}`);
  }

  public loadProjects(filters: any, type?: string) {
    const loadings = this.isLoadingSource.getValue();
    loadings[type] = true;
    this.isLoadingSource.next(loadings);
    let params = new HttpParams();
    for (const [ key, value ] of Object.entries(filters)) {
      params = params.append(key, value.toString());
    }
    if (type) {
      params = params.append('type', type);
    }
    this.http.get(`/ebp`, {params}).subscribe((response) => {
      if (response.status == 'success') {
        const projectsData = this.projectsSource.getValue();
        const ebps = response.data.ebps.map((ebp) => {
          return this.modifyEbp(ebp);
        });
        projectsData[type].projects = ebps;
        projectsData[type].pagination = response.data.pagination;
        this.projectsSource.next(projectsData);
      } else {
        console.log('Error fetching projects');
      }
      loadings[type] = false;
      this.isLoadingSource.next(loadings);
    });
  }

  public getYears() {
    return this.http.get('/years');
  }

  private _mapProject(project: CreateProject, modules: Array<string>, ebpType: ProjectType, resetEbp: boolean = false) {
    const mappedObject: { [key: string]: any } = {
      year: project.year,
      owner_id: project.owner_id,
      retailer_id: project.retailer.id,
      validator_id: project.validator_id,
      is_full_division: project.is_full_division,
      language_id: project.language_id,
      brands_ids: project.brands.map((b) => b.id) || [],
      divisions_ids: project.divisions.map((d) => d.id),
      currency_id: project.currency_id,
      legal_country_id: project.legal_country.id,
      modules,
      is_best_practice: project.is_best_practice,
      catalogue_country_id: (project.catalogue_country) ? project.catalogue_country.id : null,
      editors: (project.editors && Object.keys(project.editors).length > 0) ? project.editors : [],
      reset_ebp_statuses: resetEbp,
    };

    if (ebpType === this.PROJECT_TYPE.MULTI_COUNTRY || ebpType === this.PROJECT_TYPE.COUNTRY) {
      mappedObject.countries_ids = project.countries_ids;
    }

    if (ebpType === this.PROJECT_TYPE.ZONE) {
      mappedObject.zones_ids = project.zones_ids;
    }

    return mappedObject;
  }

  public loadCurrentPageTemplate(page: string) {
    return this.http.get('/pages/' + page);
  }

  modifyEbp(ebp: Project) {
    ebp.mapped_divisions = ebp.divisions.map((division) => {
      return {
        name: division.name,
        code: division.code,
        brands: ebp?.brands?.filter((brand) => brand.division_id === division.id),
      };
    });
    return ebp;
  }

  startPollingJobs(projectId: number, currentUserId: number) {
    if (!this.pollingInterval) {
      //console.log('Polling jobs started.');

      this.pollingInterval = setInterval(() => {
        this.loadCurrentProjectJobs(projectId, currentUserId);
      }, 60 * 1000);

      this.loadCurrentProjectJobs(projectId, currentUserId);
    }
  }

  stopPollingJobs() {
    if (this.pollingInterval) {
      //console.log('Polling jobs stopped.');
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  sendRejectionEmail(ebpId: number) {
    return this.http.get(`/ebp/${ebpId}/rejected_email_to_owner`);
  }

  refreshCurrentProject(ebpId: number) {
    return this.http.get('/ebp/' + ebpId);
  }

  projectContainsFullDivision(project:Project){
    return project.divisions.every(division => !division.brands.length);
  }
}
