<div class="project-detail-step">
  <div class="input-row">
    <mat-slide-toggle [(ngModel)]="component.answer.values.selected"
                      (ngModelChange)="onValueChange();slideToggle()"></mat-slide-toggle>
    <mat-form-field class="is-fullwidth">
      <mat-label class="txt-area-lbl">{{component.placeholder | translate}}</mat-label>
      <textarea matInput class="txt-area"
                [disabled]="!inputIsDisabled" [(ngModel)]="component.answer.values.value"
                [maxlength]="component.max_chars" (ngModelChange)="onValueChange()" autocomplete="off" appTextareaAutoresize></textarea>
      <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
    </mat-form-field>
  </div>
</div>
