import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from './generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: GenericHttpService, ) {}

  upload(fileToUpload: File, details: any): Observable<any> {
    if (details.isRetailerLogo) {
      return this.uploadRetailerLogo(fileToUpload, details);
    }
    if (details.isCatalogueUpload) {
      return this.uploadExcel(fileToUpload, details);
    }

    const formData: FormData = new FormData();
    formData.append('module_alias', details.module_alias);
    formData.append('ebp_id', details.ebp_id);
    formData.append('step_name', details.step_name);
    formData.append('index', details.componentIndex);

    if (details.other_files_metadata) {
     // details.other_files_metadata.push(fileToUpload);
      formData.append('files', JSON.stringify(details.other_files_metadata));
      if (fileToUpload != null) {
        formData.append('file', fileToUpload, fileToUpload.name);
      }
    } else if (fileToUpload != null) {
      formData.append('file', fileToUpload, fileToUpload.name);
    }


    if (details.step_id) {
      return this.http.post('/step/' + details.step_id, formData, {
        reportProgress: true,
        observe: 'events',
      });
    } else {
      return this.http.post('/step', formData, {
        reportProgress: true,
        observe: 'events',
      });
    }
  }

  uploadExcel(fileToUpload: File, details: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(
      '/ebp/' + details.ebp_id + '/import/excel/catalogue/' + details.brand_id,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  uploadRetailerLogo(fileToUpload: File, details: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('id', details.retailer.id);

    return this.http.post('/retailer/logo', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

}
