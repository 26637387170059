import { collapseAnimation } from 'angular-animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/shared/models/product';
import { Project } from 'src/app/shared/models/project.model';
import { ProductService } from 'src/app/shared/services/product.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectUserRole } from '../../../../../shared/enums';

@Component({
  selector: 'bundler-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations: [
    collapseAnimation()
  ]
})
export class BundlerProductListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  constructor(
    private projectService: ProjectService,
    private productService: ProductService
  ) { }
  currentProject: Project;

  @Input()
  set selectedProducts(value: Product[]) {
    if (!value) {
      return;
    }
    this._selectedProducts = value;
    this.getUniqueBrandCount();
  }

  @Input()
  public isMono: boolean = false;

  @Input()
  public normalItem: boolean = false;

  _selectedProducts: Product[] = [];

  products: Array<Product> = [];
  uniqueBrandCount: number = 0;

  @Output()
  productsSelected = new EventEmitter<any>();

  @Input()
  isCollapsed: boolean = false;

  @Output()
  onCollapseChange = new EventEmitter<boolean>();


  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  ngOnInit(): void {
    this.getProducts();
    this.getProject();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProject() {
    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        if (project) {
          this.currentProject = project;
          this.currentProject.brands.forEach((brand) => {
            this.productService.loadProductsForBrand(
              this.currentProject.id,
              brand.id
            );
          });
        }
      })
    )
  }

  getProducts() {
    this.subscription.add(
      this.productService.allProducts.subscribe((products) => {
        if (products) {
          var allProducts = [];
          var keys = Object.keys(products);
          keys.forEach((k) => {
            allProducts = allProducts.concat(products[k]);
          });
          this.products = allProducts;
        }
      })
    );
  }

  add(p) {
    this._selectedProducts.push(p);
    this.update();
  }

  remove(id: number) {
    let index = this._selectedProducts.findIndex((p) => p.id == id);    
    this._selectedProducts.splice(index, 1);
    this.update();
  }

  update() {
    this.getUniqueBrandCount();
    this.productsSelected.emit(this._selectedProducts.map((p) => p.id));
  }

  getUniqueBrandCount() {
    this.uniqueBrandCount = this._selectedProducts
      .map((p) => p.brand)
      .filter((v, i, a) => a.indexOf(v) === i).length;
  }

  getBrandName(brandId: number) {
    let brand = this.currentProject.brands.filter((b) => b.id == brandId);
    if (brand && brand.length > 0) {
      return brand[0].name;
    }
    return '';
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.onCollapseChange.emit(this.isCollapsed)
  }

}
