import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';
import { Events } from '../../../shared/enums/events';
import { EventsService } from '../../../shared/services/events.service';

@Injectable({
  providedIn: 'root',
})
export class TargetService {
  constructor(private http: GenericHttpService,
              private eventsService: EventsService) {}

  private dataSource = new BehaviorSubject<any>(null);
  public data = this.dataSource.asObservable();

  private isLoadingValues: boolean = false;

  getValues(ebpID: number, mode: string, interval: string) {
    if (this.isLoadingValues) {
      return;
    }
    this.isLoadingValues = true;
    this.http
    .get([ '/target', ebpID, mode, interval ].join('/'))
    .subscribe((response) => {
      this.isLoadingValues = false;
      this.dataSource.next(response.data.target);
      this.eventsService.setValue(Events.STEP_DATA_SAVING, false);
    });
  }

  public updateValues(
    ebpID: number,
    mode: string,
    data: any,
  ) {
    return this.http
    .post([ '/target', ebpID, mode ].join('/'), data)
    .subscribe((response) => {
      this.isLoadingValues = false;
      this.getValues(ebpID, mode, data.timespan);
    });
  }

  updateTopDownValues(
    ebpID: number,
    payload: any,
  ): Observable<any> {
    return this.http.post([ '/target', ebpID, 'top-down' ].join('/'), payload);
  }
}
