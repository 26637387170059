import { SkuSet } from 'src/app/shared/models/sku_set.model';
import { ActivationEvent } from './activation-event';
import { Activity } from './activity';

export class Activation {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  status: boolean = false;
  sku_set: SkuSet;
  activities: Activity[];
  activation_events?: ActivationEvent[];
  activationPeriod?: {
    startDate: Date,
    endDate: Date,
  };
  additional_info?: {
    brands_categories?: string
    total_sku?: number,
    hero_sku?: number
  };
  calendarCollapsed?:boolean;
  productsCollapsed?:boolean;
  brandsCollapsed?:boolean;
  categoriesCollapsed?: boolean;
  subCategoriesCollapsed?:boolean;
  brands: number[] = [];
  product_categories: number[] = [];
  product_sub_categories: number[] = [];
  ebp_id: number;
}
