import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericHttpService } from './generic-http.service';

export class TranslationLoader implements TranslateLoader {
  private langs = {};

  constructor(private http: GenericHttpService) { }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`/translations`).pipe(
      map((response) => {
        this.langs[lang] = response.data.translations;
        return response.data.translations[lang];
      }),
    );
  }
}
