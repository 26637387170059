import { ExtraOptionGroup } from 'src/app/shared/models/extra-option-group';

export class Service {
  [x: string]: any;

  availability: string;
  name: string;
  launch_date: Date = null;
  is_exclusive: boolean = false;
  custom_launch_date: Date = null;
  exclusivity_end: Date = null;
  properties: Array<string>;
  extra_options: Array<ExtraOptionGroup>;
  extra_options_key: string;
  extra_options_collapsed: boolean = false;
  brands: number[] = [];
  brands_collapsed: boolean = false;
  product_sub_categories: string;
}
