<div class="progress-sidebar right" *ngIf="currentProject" kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
  <div class="ebp-item " [title]="'SIDEBAR_TOOLTIP_COVER' | translate" [routerLink]="['/projects/', currentProject.id]"
       *ngIf="currentModule" >
  </div>
  <ng-container *ngFor="let module of currentModules">
    <a *ngIf="module?.status == 'online' && module?.version?.is_active"
       [routerLink]="['/projects', projectId, module.alias]"
       #rla="routerLinkActive" routerLinkActive="is-active" class="progress-sidebar-item">

      <shared-module-icon
          [title]="module?.name | translate"
          [type]="module?.alias" [status]="module?.version?.status">

      </shared-module-icon>

      <ng-container *ngIf="rla.isActive">
        <project-module-progressbar [progress]="module.progress" [isVertical]="true">
        </project-module-progressbar>
      </ng-container>
    </a>
  </ng-container>
</div>
