<div class="add-button ebp-interface-button {{className}}">
    <span class="onhover">
        <svg version="1.1" id="Component_12_3" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24"
            enable-background="new 0 0 24 24" xml:space="preserve">
            <path fill="#333333" d="M12,0C5.373,0,0,5.373,0,12c0,6.627,5.373,12,12,12c6.627,0,12-5.373,12-12C24,5.373,18.627,0,12,0z M19,13
        h-6v6h-2v-6H5v-2h6V5h2v6h6V13z" />
        </svg>
    </span>
    <span>{{label}}</span>
</div>
