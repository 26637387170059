import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/auth.guard';
import { SuperAdminGuard } from '../shared/guards/super-admin.guard';
import { ImportsReportLogComponent } from './components/imports-report-log/imports-report-log.component';
import { ProjectCoverComponent } from './components/project-cover/project-cover.component';

import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectModuleComponent } from './components/project-module/project-module.component';
import { RetailersListComponent } from './components/retailers-list/retailers-list.component';
import { TranslationsComponent } from './components/translations/translations.component';
import { UserListComponent } from './components/user-list/user-list.component';

const routes: Routes = [
  {
    path: 'projects',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
  {
    path: 'projects/create',
    component: ProjectEditComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: 'user/list',
    component: UserListComponent,
    canActivate: [ SuperAdminGuard ],
  },
  {
    path: 'import/logs',
    component: ImportsReportLogComponent,
    canActivate: [ SuperAdminGuard ],
  },
  {
    path: 'retailers',
    component: RetailersListComponent,
    canActivate: [ SuperAdminGuard ],
  },
  {
    path: 'translations',
    component: TranslationsComponent,
    canActivate: [ SuperAdminGuard ],
  },
  {
    path: 'projects/edit/:id',
    component: ProjectEditComponent,
    canActivate: [ AuthGuard ],
  },
  {
    path: 'projects/:id',
    component: ProjectDetailComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':module',
        component: ProjectModuleComponent,
      },
      {
        path: '',
        component: ProjectCoverComponent,
      },
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class ProjectRoutingModule {}
