import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ModuleService } from '../../services/module.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-cover',
  templateUrl: './project-cover.component.html',
  styleUrls: ['./project-cover.component.scss']
})
export class ProjectCoverComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public logoBaseUrl: string;
  public timestamp: number = (+ new Date);

  currentProject: Project;
  modules: Module[];

  legislation: any;

  coverConfig: any;
  pollingInterval: any;


  constructor(
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private configService: ConfigService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      timer(0, 300000).subscribe(() => {
        if (this.currentProject) {
          this.projectService.loadCurrentProject(this.currentProject.id);
        }
      }),
    );
    this.subscription.add(
      this.projectService.currentProject.subscribe(project => {
        this.currentProject = project;
        if (this.currentProject && this.currentProject.id) {
          const currentUser = this.authenticationService.currentUserValue;
          this.projectService.startPollingJobs(this.currentProject.id, currentUser.id);

          this.moduleService.projectModules.subscribe(modules => {
            if (!modules) {
              return;
            }
            this.modules = modules.filter(module => module?.version?.is_active);
          });

          if (this.currentProject.clusters.length)
            this.legislation = this.currentProject.clusters[0].countries.find(c => c.id == this.currentProject.legal_country_id)
        }
      })
    );


    this.configService.coverConfig.subscribe((config) => {
      if (config) {
        this.coverConfig = config
      } else {
        this.configService.getCoverConfig();
      }
    });

    this.logoBaseUrl = environment.api.base_url + '/retailers/logos';
  }

  ngOnDestroy() {
    this.projectService.stopPollingJobs();
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.projectService.setTopBarCTAEnabled(true); //enable topbar cta
  }

  public openLatestActiveModule() {
    if (this.modules && this.modules.length > 0) {
      let module = this.modules.find(module => module.version && module.version.is_active);
      if (module) {
        this.router.navigate(['/projects', this.currentProject.id, module.alias]);
      }
    }
  }

}
