<div class="project-detail-step">
  <div class="project-step-title"
       [ngClass]="{'is-excluded': step.exclude && (step.name != 'ebp_settings' && step.name != 'ebp_downloads') && (!step.hide_exclude_icon || !step.hide_status_icon), 'is-rejected': step?.status == 'rejected', 'is-validated': step?.status == 'validated', 'is-submitted' : step?.status == 'submitted'}">
    <ng-container *ngIf="step">
      <h4 class="step-title"
          [class.ebp-reviewer]="currentUserRole === PROJECT_USER_ROLE.viewer" [attr.id]="step?.name">
        {{ title | translate | textReplace: {'\{\{brand_name\}\}' : brand?.name} }}
      </h4>

      <mat-spinner *ngIf="step && step.is_saving" [strokeWidth]="1" [diameter]="16"></mat-spinner>
      <div class="spacer"></div>
      <shared-info-icon [excluded]="step.exclude" [tooltip]="step?.guideline | translate" [stepTitleIcon]="true"></shared-info-icon>

      <ng-container *ngIf="currentUserRole !== PROJECT_USER_ROLE.validator">        
        <ng-container *ngIf="!step.exclude && currentUserRole !== PROJECT_USER_ROLE.viewer">
          <shared-step-status-button *ngIf="stepStatus == 'edited'" [className]="'submit'" [buttonTitle]="'STATUS_CTA_SUBMIT_STEP' | translate"  (click)="onSubmitStep()"></shared-step-status-button>
          <shared-step-status-button *ngIf="stepStatus == 'submitted' || stepStatus == 'validated'" [className]="'edit'" [buttonTitle]="'STATUS_CTA_EDIT_STEP' | translate"  (click)="onEditStep()"></shared-step-status-button>
        </ng-container>

        <shared-check-icon [status]="stepStatus" [tooltip]="step.exclude ? 'Excluded' : stepStatus"
                           [excluded]="step.exclude" *ngIf="!step.hide_status_icon && stepStatus != 'rejected'"></shared-check-icon>

        <shared-exclude-icon [ngClass]="'is-owner '+ stepStatus" *ngIf="!step.hide_status_icon && stepStatus == 'rejected'" [status]="stepStatus" [active]="step.exclude" [tooltip]="stepStatus" ></shared-exclude-icon>

        <ng-container *ngIf="currentUserRole !== PROJECT_USER_ROLE.viewer && (!stepStatus || stepStatus == 'edited' || stepStatus == 'submitted') && !step.exclude">
          <shared-step-exclude-icon [active]="step.exclude" *ngIf="!step.hide_exclude_icon" (onAlertProceed)="onExcludeOrInclude()" [tooltip]="'STATUS_CTA_EXCLUDE_STEP' | translate"
                                [alert]="step.exclude ? '' : 'alert_exclude_step'"></shared-step-exclude-icon>
        </ng-container>

        <ng-container *ngIf="currentUserRole !== PROJECT_USER_ROLE.viewer && step.exclude">
          <shared-exclude-icon [active]="step.exclude" (click)="onExcludeOrInclude()" [tooltip]="'STATUS_CTA_INCLUDE_STEP' | translate" ></shared-exclude-icon>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="currentUserRole === PROJECT_USER_ROLE.validator ">

        <ng-container *ngIf="!step.exclude">
          <shared-step-status-button *ngIf="stepStatus == 'rejected' || stepStatus == 'submitted'" [className]="'validate'" [buttonTitle]="'STATUS_CTA_VALIDATE_STEP' | translate"  (click)="validateStep()"></shared-step-status-button>          
        </ng-container>

        <shared-check-icon [tooltip]="(step.exclude ? 'Excluded' : stepStatus) | translate" [ngClass]="'is-validator '+ stepStatus"
                           [status]="stepStatus" [excluded]="step.exclude" *ngIf="!step.hide_status_icon && stepStatus != 'rejected'"
                           (click)="(stepStatus == 'submitted' || stepStatus == 'rejected') && validateStep()"></shared-check-icon>

        <shared-exclude-icon [tooltip]="((stepStatus == 'validated' || stepStatus == 'submitted') ? 'STATUS_CTA_REJECT_STEP' : stepStatus) | translate"
            *ngIf="!step.exclude && (stepStatus  == 'submitted' || stepStatus == 'rejected' || stepStatus == 'validated')"
            [ngClass]="'is-validator '+ stepStatus" [active]="stepStatus=='rejected'" [validation]="true"
            (onAlertProceed)="rejectStep()" [alert]="'alert_reject_step'"></shared-exclude-icon>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="staticTitle">
      <h4 class="step-title">{{ staticTitle | translate}}</h4>
    </ng-container>
  </div>
</div>
