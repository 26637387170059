import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activity } from 'src/app/project/entities/activity';
import { ActivityService } from 'src/app/project/services/activity.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'activity-autocomplete',
  templateUrl: './activity-autocomplete.component.html',
  styleUrls: [ './activity-autocomplete.component.scss' ],
})
export class ActivityAutocompleteComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private activityService: ActivityService,
  ) {}

  activityModel: any;
  availableActivities: Activity[];

  @Input()
  activityId: number;

  activity: Activity;

  @Output()
  activityChange = new EventEmitter<Activity>();

  ngOnInit(): void {
    this.activityService.activities.subscribe((activities) => {
      if (activities) {
        this.availableActivities = activities;
        if (this.activityId) {
          this.activityModel = this.availableActivities.find(a => a.id === this.activityId)?.id;
        }
      }
    });

  }

  activitySelected(activityId: number) {
    const activity = this.availableActivities.find(a => a.id === activityId);
    this.activity = activity;
    this.activityModel = activity.id;
    this.activityChange.emit(this.activity);
  }

}
