import { EventsService } from './../../services/events.service';
import { Subscription } from 'rxjs';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FileService } from '../../services/file.service';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaderResponse,
} from '@angular/common/http';
import { ProductService } from '../../services/product.service';
import { ModuleService } from 'src/app/project/services/module.service';
import { Module } from '../../models/module.model';
import { Project } from '../../models/project.model';
import { Events } from '../../enums/events';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialog,
    private fileService: FileService,
    private productService: ProductService,
    private moduleService: ModuleService,
    private projectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) data,
    private eventsService: EventsService
  ) {
    this.data = data;
  }

  public data: any;
  public fileToUpload: File = null;
  public fileName: string = null;

  public progress: number;
  public isUploading: boolean = false;
  public isUploadComplete: boolean = false;
  public uploadError: boolean = false;
  public uploadStartedAt: Date;

  public currentModule: Module;
  public uploadErrorMsg: string;

  public project: Project;
  private subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(this.projectService.currentProject.subscribe(project => this.project = project));

    this.subscription.add(
      this.moduleService.currentModule.subscribe(module => {
        this.currentModule = module
      })
    );
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files[0];
    this.fileName = this.fileToUpload.name;
  }

  upload() {
    this.isUploading = true;
    this.uploadStartedAt = new Date();

    this.fileService
      .upload(this.fileToUpload, this.data)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {

          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;

          case HttpEventType.Response:
            this.isUploadComplete = true;
            this.isUploading = false;
            this.uploadError = false;
            this.progress = 100;
            if (this.data.isRetailerLogo) {
              this.eventsService.setValue(Events.REFRESH_RETAILER_LOGO, event.body.data.retailer);
              break;
            }
            if (this.data.isCatalogueUpload) {
              this.productService.loadProductsForBrand(this.data.ebp_id, this.data.brand_id, true)
            }
            this.updateStatusOnCTAUpdate(this.data.ebp_id);
            break;

          default:
            if (event instanceof HttpErrorResponse) {
              this.uploadErrorMsg = (event.error['data']) ? event.error['data']['error'] : "Something went wrong.";
              this.isUploadComplete = true;
              this.isUploading = false;
              this.uploadError = true;
            }
            else if (event instanceof HttpHeaderResponse) {
              this.uploadErrorMsg = (event.statusText) ? event.statusText : "Something went wrong.";
              this.isUploadComplete = true;
              this.isUploading = false;
              this.uploadError = true;
            }

        }
      });
  }

  cancel() {
    this.fileToUpload = this.fileName = this.uploadError = null;
    this.isUploading = this.isUploadComplete = false;
    if (this.fileToUpload == null || this.isUploadComplete) {
      this.dialogRef.closeAll();
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateStatusOnCTAUpdate(ebp_id: number) {
    if (this.currentModule) {
      //needed to refresh not only the step status but also the step file name info after upload.
      this.moduleService.getCurrentModule(ebp_id, this.currentModule.alias);
    }
    this.moduleService.loadModulesForProject(ebp_id); // Refresh rejection count on top bar
    this.projectService.refreshCurrentProject(ebp_id).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_PROJECT_STATUS, response.data.ebp);  //refresh ebp status
    });
  }
}
