import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { ImportDetailDialogComponent } from '../../../shared/components/import-detail-dialog/import-detail-dialog.component';
import { ConfigService } from '../../../shared/services/config.service';

@Component({
  selector: 'app-imports-report-log',
  templateUrl: './imports-report-log.component.html',
  styleUrls: [ './imports-report-log.component.scss' ],
})
export class ImportsReportLogComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  activeColumns = {};
  logs: any[] = [];
  filter: CompositeFilterDescriptor;
  filteredLogs: GridDataResult;
  defaultConfig: any;
  state: State = {
    take: 20,
    skip: 0,
    sort: [],
  };
  sort: SortDescriptor[] = [
    {
      field: 'updated_at',
      dir: 'desc',
    },
  ];

  constructor(private configService: ConfigService,
              public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.configService.importReportsGridConfig.subscribe((config) => {
        if (config) {
          this.setConfig(config);
        } else {
          this.configService.getImportReportsLogGridConfig();
        }
      }),
    );
    this.loadReports();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setConfig(config: any) {
    config.column_sets.forEach((columnSet) => {
      this.activeColumns[columnSet.name] = config.active_columnsets.includes(
        columnSet.name,
      );
    });
    this.defaultConfig = config;
    this.defaultConfig.show_columnset_toggles = true;
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.filteredLogs = process(this.logs, this.state);
  }

  pageChange() {
    this.loadReports();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  noActiveColumn() {
    let activeValues = Object.values(this.activeColumns);
    activeValues = activeValues.filter(
      (item, index) => activeValues.indexOf(item) === index,
    );
    return activeValues.length === 1 && activeValues[0] === false;
  }

  loadReports() {
    this.configService.getEntity('jobs').subscribe(response => {
      this.logs = response.data.jobs;
      this.filteredLogs = process(this.logs, this.state);
      this.filteredLogs = {
        data: this.logs.slice(
          this.state.skip,
          this.state.skip + this.state.take,
        ),
        total: this.logs.length,
      };
    });
  }

  openModal(dataItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'import_detail_log',
    };
    dialogConfig.data = dataItem;
    this.subscription.add(
      this.dialog
      .open(ImportDetailDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
      }));
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.filteredLogs = {
      data: orderBy(this.logs, this.state.sort),
      total: this.logs.length,
    };
  }
}
