import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'digital-shelf',
  templateUrl: './digital-shelf.component.html',
  styleUrls: [ './digital-shelf.component.scss' ],
})
export class DigitalShelfComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(private projectService: ProjectService) {}

  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<any>();

  currentProject: Project;

  sections: Array<any>;

  ngOnInit(): void {
    this.subscription.add(
      this.projectService.currentProject.subscribe((p) => {
        if (p) {
          this.currentProject = p;
          if (this.component.rows.method == 'brandsList') {
            this.sections = this.currentProject.brands;
          }
          // this.component.headers.forEach((header) => {
          //   header.label = header.label
          //   .replace('{{YEAR}}', this.currentProject.year)
          //   .replace('{{YEAR-1}}', this.currentProject.year - 1);
          // });

          this.createAndUpdateAnswers();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createAndUpdateAnswers() {
    this.component.answer = this.component.answer || {values: {}};
    var v = this.component.answer.values;

    this.sections.forEach((section) => {
      v[section.id] = v[section.id] || {};
      this.component.sub_rows.forEach((sr) => {
        v[section.id][sr.name] = v[section.id][sr.name] || {};
        v[section.id][sr.name].active = v[section.id][sr.name].active;
        sr.sub_sub_rows.forEach((ssr) => {
          v[section.id][sr.name][ssr.name] =
            v[section.id][sr.name][ssr.name] || {};

          this.component.headers.forEach((header) => {
            if (header.name) {
              v[section.id][sr.name][ssr.name][header.name] = v[section.id][sr.name][ssr.name][header.name] || 0;
            }
          });
        });
      });
    });
  }

  onInputChange(data: any, changedItem: String) {
    data.currentyear = parseFloat(data.currentyear);
    data.lastyear = parseFloat(data.lastyear);
    data.evol = parseFloat(data.evol);
    if (changedItem == 'lastyear' || changedItem == 'currentyear') {
      data.evol = ((data.currentyear - data.lastyear) / data.lastyear * 100).toFixed(2);
    } else {
      data.currentyear = data.lastyear * (1 + (data.evol / 100));
    }
    this.onChange.emit();
  }

  onToggleChange(id: any, name: any) {
    this.onChange.emit();
  }

  /*   setupAnswers() {
      this.component.answer = this.component.answer || {};
      this.component.answer.collapsedState = this.component.answer.collapsedState || {};
      this.rows.forEach(row => {
        this.component.answer.collapsedState[row.id] = this.component.answer.collapsedState[row.id] || false
      })
    } */

}
