<mat-toolbar>
    <div class="topbar-title" >
        <a [routerLink]="['/dashboard']">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="65px" height="24px"
                viewBox="0 0 65 24" enable-background="new 0 0 65 24" xml:space="preserve">
                <g>
                    <g>
                        <polygon fill="#1E1E1E" points="10.358,12 0,18 10.358,24 20.718,18 		" />
                        <polygon fill="#1E1E1E" points="10.358,6 0,12 10.358,18 20.718,12 		" />
                        <polygon fill="#FF005A" points="10.358,0 0,6 10.358,12 20.718,6 		" />
                        <polygon fill="#00FFEB" points="10.358,6 5.179,9 10.358,12 15.538,9 		" />
                        <polygon fill="#FFFFFF" points="10.358,12 5.179,15 10.358,18 15.538,15 		" />
                    </g>
                    <g>
                        <path fill="#1E1E1E" d="M39.036,15.614h-8.038c0.193,1.275,1.256,2.028,2.511,2.028c0.87,0,1.431-0.348,1.953-1.004h3.284
                    c-0.927,2.145-2.82,3.709-5.237,3.709c-3.129,0-5.68-2.55-5.68-5.68c0-3.111,2.474-5.76,5.623-5.76
                    c3.285,0,5.661,2.629,5.661,5.855C39.113,15.054,39.095,15.324,39.036,15.614z M33.472,11.614c-1.16,0-2.185,0.677-2.436,1.836
                    h4.869C35.654,12.291,34.63,11.614,33.472,11.614z" />
                        <path fill="#1E1E1E" d="M50.114,18.764C48.646,20.02,47.293,20,45.438,20h-4.541V5.701h4.406c1.72,0,3.342,0.02,4.579,1.393
                    c0.599,0.656,0.851,1.467,0.851,2.356c0,1.218-0.6,2.28-1.662,2.879c1.564,0.541,2.357,1.7,2.357,3.323
                    C51.429,16.793,51.003,18.011,50.114,18.764z M45.806,8.908h-1.41v2.223h1.391c0.793,0,1.449-0.231,1.449-1.14
                    S46.56,8.908,45.806,8.908z M46.23,14.184h-1.835v2.609h1.816c0.908,0,1.719-0.31,1.719-1.372
                    C47.931,14.396,47.081,14.184,46.23,14.184z" />
                        <path fill="#1E1E1E" d="M62.61,14.281c-1.236,1.082-2.569,1.236-4.174,1.236h-1.584V20h-3.497V5.701h4.677
                    c1.469,0,3.227,0.059,4.405,1.044c1.12,0.946,1.681,2.319,1.681,3.786C64.118,11.846,63.616,13.393,62.61,14.281z M58.205,8.908
                    h-1.353v3.402h1.488c1.198,0,2.279-0.271,2.279-1.721C60.62,9.104,59.403,8.908,58.205,8.908z" />
                    </g>
                </g>
            </svg>
        </a></div>
    <div class="topbar-nav d-flex align-items-center">
        <ng-container *ngIf="currentProject">

            <ng-container *ngIf="(isTopBarCTAEnabled$ | async) && currentProject?.status != null">
                <ng-container *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.validator">
                    <div class="ebp-mode">
                        <div class="mode-box validate"></div>
                        <div class="mode-text validate">{{'TOPNAV_MODE_VALIDATION' | translate}}</div>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="(currentUserRole$ | async) !== PROJECT_USER_ROLE.validator && (currentUserRole$ | async) !== PROJECT_USER_ROLE.owner && (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor">
                    <div class="ebp-mode">
                        <div class="mode-box readonly"></div>
                        <div class="mode-text readonly">{{'TOPNAV_MODE_READONLY' | translate}}</div>
                    </div>
                </ng-container>

                <ng-container>
                    <div (ebclickOutside)="showRejectedList = false">
                    <ng-container
                        *ngIf="((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor) && totalRejectedCount() > 0">
                        <button class="ebp-validation-count" (click)="showRejectedList = true" kendoTooltip tooltipClass="kendoTooltipOverride"
                            position="right" [title]="'TOPNAV_REJECTEDSTEPS_COUNTER_TOOLTIP' | translate">
                            <svg width="22" version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                <style type="text/css">
                                    .st2 {
                                        fill: none;
                                    }
                        
                                    .st3 {
                                        fill: #FF0000;
                                    }
                                </style>
                                <rect class="st2" width="24" height="24" />
                                <path class="st3" d="M1,21.5h22l-11-19L1,21.5z M13,18.5h-2v-2h2V18.5z M13,14.5h-2v-4h2V14.5z" />
                            </svg>
                            <span>{{totalRejectedCount()}}x</span>
                        </button>
                    </ng-container>

                    <ng-container
                        *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.validator && totalRejectedCount() > 0">
                        <div class="rejection-mail-container">
                            <div class="ebp-rejection-count" (click)="showRejectedList = true">
                                <svg width="22" version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                                    style="enable-background:new 0 0 24 24;" xml:space="preserve">
                                    <style type="text/css">
                                        .st2 {
                                            fill: none;
                                        }

                                        .st3 {
                                            fill: #FF0000;
                                        }
                                    </style>
                                    <rect class="st2" width="24" height="24" />
                                    <path class="st3"
                                        d="M1,21.5h22l-11-19L1,21.5z M13,18.5h-2v-2h2V18.5z M13,14.5h-2v-4h2V14.5z" />
                                </svg>
                                <span>{{totalRejectedCount()}}x</span>                         
                            </div>
                            <button class="ebp-mailout-btn" (click)="sendNotification()" kendoTooltip tooltipClass="kendoTooltipOverride"
                                position="right" [title]="'TOPNAV_REJECTEDSTEPS_EMAILER_TOOLTIP' | translate">
                                <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
                                    style="enable-background:new 0 0 38 24;" xml:space="preserve">
                                    <style type="text/css">
                                        .st04 {
                                            fill: none;
                                        }
                            
                                        .st5 {
                                            fill: #ffffff;
                                        }
                                    </style>
                                    <path id="Path_2483" class="st04" d="M0,0h38v24H0V0z" />
                                    <g>
                                        <path class="st5" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
                                                                                                                M12,11L4,6h16L12,11z" />
                                        <polygon class="st5" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </ng-container>

                    <div class="rejected-info-div" *ngIf="showRejectedList && totalRejectedCount() > 0">
                        <div class="container">
                            <div class="step-list">
                                <span class="title">{{'TOPNAV_REJECTEDSTEPS_TITLE' | translate}}</span>
                                <div *ngFor="let item of rejectedSteps | keyvalue" class="step-gap">
                                    <span class="header">{{'TOPNAV_REJECTEDSTEPS_MODULE' | translate}} </span><span
                                        class="value">{{item.key | translate}}</span> <br />
                                    <div *ngFor="let step of item?.value" class="rejected-step-container">
                                        <span class="header">{{'TOPNAV_REJECTEDSTEPS_STEP' | translate}} </span><span
                                            class="value">{{step?.title |
                                            translate}} [-> <u
                                                (click)="navigateToStep(step?.modulealias, step?.name)">{{'TOPNAV_REJECTEDSTEPS_CTA'| translate}}</u>]</span>
                                    </div>
                                </div>
                            </div>
                            <div (click)="showRejectedList = false" class="x-btn">
                                <div class="icon-bar" [@hamburguerX]="'topX'"></div>
                                <div class="icon-bar" [@hamburguerX]="'hide'"></div>
                                <div class="icon-bar" [@hamburguerX]="'bottomX'"></div>
                            </div>
                        </div>
                    </div>
                    </div>
                </ng-container>

                <ng-container>
                    <button class="topbar-action-button submit" (click)="submitEBP()"
                        *ngIf="((currentUserRole$ | async) === PROJECT_USER_ROLE.owner) && (currentProject?.status == 'edited' || !currentProject?.status)">
                        <span>{{'EBP_SUBMIT_CTA' | translate | replacePlaceholder:currentProject.replace}}</span>

                        <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
                            style="enable-background:new 0 0 38 24;" xml:space="preserve">
                            <style type="text/css">
                                .st0001 {
                                    fill: none;
                                }

                                .st00011 {
                                    fill: #ffffff;
                                }
                            </style>
                            <path id="Path_2483" class="st0001" d="M0,0h38v24H0V0z" />
                            <g>
                                <path class="st00011" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
                            M12,11L4,6h16L12,11z" />
                                <polygon class="st00011" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
                            </g>
                        </svg>
                    </button>

                    <button class="topbar-action-button edit" (click)="editEBP()"
                        *ngIf="((currentUserRole$ | async) === PROJECT_USER_ROLE.owner) && (currentProject?.status == 'submitted')">
                        <span>{{'EBP_EDIT_CTA' | translate | replacePlaceholder:currentProject.replace}}</span>
                    </button>

                    <button class="topbar-action-button validate" (click)="validateEBP()"
                        *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.validator && currentProject?.status == 'submitted'">
                        <span>{{'EBP_VALIDATE_CTA' | translate | replacePlaceholder:currentProject.replace}}</span>
                        <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
                            style="enable-background:new 0 0 38 24;" xml:space="preserve">
                            <style type="text/css">
                                .st054 {
                                    fill: none;
                                }

                                .st13 {
                                    fill: #ffffff;
                                }
                            </style>
                            <path id="Path_2483" class="st054" d="M0,0h38v24H0V0z" />
                            <g>
                                <path class="st13" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
                            M12,11L4,6h16L12,11z" />
                                <polygon class="st13" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
                            </g>
                        </svg>
                    </button>

                    <button class="topbar-action-button validated" *ngIf="currentProject?.status == 'validated'">
                        <span>{{'EBP_COVER_STATUS' | translate}} {{'STATUS_VALIDATED' | translate }}</span>
                    </button>

                    <button class="topbar-action-button edited"
                        *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.validator && currentProject?.status == 'edited'">
                        <span>{{'EBP_COVER_STATUS' | translate}} {{'STATUS_INPROGRESS' | translate }}</span>
                    </button>
                </ng-container>

            </ng-container>

            <a *ngIf="currentProject?.id" class="top-m"
                [routerLink]="['/projects', currentProject.id]">{{currentProject.full_name}}</a>
            <span *ngIf="!currentProject?.id">{{currentProject.full_name}}</span>
            <shared-status-icon *ngIf="currentProject?.id" [status]="currentProject?.status" [width]="33">
            </shared-status-icon>
        </ng-container>
        <ng-container *ngIf="currentModule">
            <span class="top-m"> / {{currentModule?.name | translate}}</span>
        </ng-container>
        <mat-spinner *ngIf="currentModule && isSaving" [strokeWidth]="1" [diameter]="18" color="#C3C3C3"
            style="margin-left:5px"></mat-spinner>

        <ng-container *ngIf="currentModule && lastSaved && !isSaving">
            <ng-template #template>
                <ng-container><span>{{'EBP_COVER_LAST_SAVED' | translate}}</span><br /></ng-container>
                <ng-container><span>{{lastSaved | date: 'Y.MM.dd, HH:mm'}}</span></ng-container>
            </ng-template>
            <div kendoTooltip class="cloud-save" #tooltip="kendoTooltip" [tooltipTemplate]="template" tooltipClass="kendoTooltipOverride" position="right" >
                <div title="dummy_string_to_invoke_tooltip_template">
                    <svg class="save-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zm-9-3.82l-2.09-2.09L6.5 13.5 10 17l6.01-6.01-1.41-1.41z"
                            fill="#C3C3C3" />
                    </svg>
                </div>
            </div>
        </ng-container>
    </div>

    <span class="spacer"></span>
    <div class="topbar-user" *ngIf="currentUser" (click)="logout()">
        <span class="name top-name">{{currentUser.given_name}} {{currentUser.family_name}}</span>
        <div class="initials">{{currentUser.initials}}</div>
    </div>
</mat-toolbar>