<section class="module-section" [ngClass]="{'is-collapsed': section.isActivated, 'is-fullwidth' : section.fullwidth}">


    <ng-container *ngIf="!section.repeat_section">
        <div class="project-detail-step" *ngIf="section.title !== false">
           
            <header class="section-title" matRipple (click)="toggle()" [class.no-click]="section?.collapsible === false">
                <div class="heading-container">
                    <h2>{{section.title | translate}}</h2>
                </div>
                
                <div class="foldable-icon" [class.invisible]="section?.collapsible === false" kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
                    <div [title]="'SECTION_UNFOLD_LESS_TOOLTIP' | translate" *ngIf="section.isActivated">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                            <path d="M24 0v24H0V0h24z" fill="none" opacity=".87" />
                            <path
                                d="M7.41 18.59L8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z" />
                        </svg>
                    </div>
                    <div [title]="'SECTION_UNFOLD_MORE_TOOLTIP' | translate" *ngIf="!section.isActivated" >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                            fill="#000000">
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z" />
                        </svg>
                    </div>
                </div>
            </header>
        </div>
  
        <div class="unfold-title" *ngIf="section.unfold_title && !section.isActivated && (section?.collapsible !== false)"><span>{{section?.unfold_title | translate}}</span></div>


        <div class="section-content" [class.unset-overflow]="section.isActivated && (section?.collapsible !== false)"
            [@collapse]="!section.isActivated && (section?.collapsible !== false)">
            <div class="project-detail-step" *ngIf="section.text">
                <p class="section-text"
                    [innerHTML]="section.text | translate | replacePlaceholder: currentProject.replace"></p>
            </div>
            <project-set [currentSet]="set" [currentSection]="section" *ngFor="let set of section.sets"
                [ebpStatus]="ebpStatus"></project-set>
        </div>
    </ng-container>

    <ng-container *ngIf="section.repeat_section && section.repeat_source == 'ebp_brands' && currentProject">
        <ng-container *ngFor="let brand of currentProject.brands">
            <div class="project-detail-step">
         
                <header class="section-title" matRipple (click)="toggleBrand(brand.id)">
                    <div class="heading-container"><h2>{{brand.name}}</h2></div>
                    <div class="foldable-icon" kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
                        
                        <div [title]="'SECTION_UNFOLD_LESS_TOOLTIP' | translate" *ngIf="!isCollapsedForBrand(brand.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                fill="#000000" >
                                <path d="M24 0v24H0V0h24z" fill="none" opacity=".87" />
                                <path d="M7.41 18.59L8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z" />
                            </svg>
                        </div>
                        <div [title]="'SECTION_UNFOLD_MORE_TOOLTIP' | translate" *ngIf="isCollapsedForBrand(brand.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                 fill="#000000">
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z" />
                            </svg>
                        </div>
                    </div>
                </header>
            </div>
            <div class="unfold-title" *ngIf="section.unfold_title && isCollapsedForBrand(brand.id) && (section?.collapsible !== false)"><span>{{section?.unfold_title | translate}}</span></div>
            <div class="section-content" [@collapse]="isCollapsedForBrand(brand.id) && (section?.collapsible !== false)">
                <div class="project-detail-step">
                    <p class="section-text"
                        [innerHTML]="section.text | translate | replacePlaceholder: currentProject.replace"></p>
                </div>
                <project-set *ngFor="let set of section.sets" [brandStepSlug]="brand.slug+'_catalogue'"
                    [brandRepeat]="true" [currentSet]="set" [currentBrand]="brand" [ebpStatus]="ebpStatus">
                </project-set>
            </div>
        </ng-container>
    </ng-container>

</section>
