import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '../../enums/events';
import { NavigationItem, NavigationItemType } from '../../models/navigation-item.model';
import { User } from '../../models/user.model';
import { EventsService } from '../../services/events.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('hamburguerX', [
      state('hamburguer', style({})),
      state('topX', style({
        transform: 'rotate(45deg)',
        transformOrigin: 'left',
        margin: '0px 0px 0px 8px',
      })),
      state('hide', style({
        opacity: 0,
      })),
      state('bottomX', style({
        transform: 'rotate(-45deg)',
        transformOrigin: 'left',
        margin: '9px 0px 0px 8px',
      })),
      transition('* => *', [
        animate('0.25s'),
      ]),
    ]),
  ],
})
export class NavigationComponent {
  @Input() user: User;

  @Input() languages: any;

  @Input() isMenuActive = false;

  NavigationItemTypes = NavigationItemType;
  navigationItems: NavigationItem[] = [
    {
      label: 'MENU_DASHBOARD',
      url: '/dashboard',
    },

    /* {
      label: 'MENU_LEGAL',
      url: '',
    }, */
    {
      label: 'MENU_BEST_PRACTICES',
      url: '/dashboard',
      clickEvent: () => this.scrollToBestPractice(),
    },
    {
      label: 'MENU_SUPPORT',
      url: 'https://google.com',
      type: NavigationItemType.EXTERNAL,
    },
    /*  {
       label: 'MENU_GLOSSARY',
       url: '',
     }, */
  ];

  constructor(public translate: TranslateService,
              private eventsService: EventsService) {}

  changeLanguage(id: string) {

    if (id) {
      this.translate.use(id);
    } else {
      this.translate.use('en');
    }
    this.closeMenu();
  }

  closeMenu(navItem?: NavigationItem) {
    if (this.isMenuActive) {
      this.isMenuActive = false;
    }
    if (navItem?.clickEvent) {
      navItem.clickEvent();
    }
  }

  scrollToBestPractice() {
    localStorage.setItem('dashboard-container', 'best_practice');
    this.eventsService.setValue(Events.SCROLL_TO_BEST_PRACTICE, true);
  }

}
