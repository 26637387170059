export enum Events {
  RELOAD_MODULE,
  UPDATE_CURRENT_MODULE_DATA,
  UPDATE_CURRENT_PROJECT_STATUS,
  UPDATE_ACTIVATION_MODULE_DATA,
  STEP_DATA_SAVING,
  SCROLL_TO_BEST_PRACTICE,
  UPDATE_ACTIVATIONS,
  REFRESH_RETAILER_LOGO
}
