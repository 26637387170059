import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { Section } from 'src/app/shared/models/section.model';
import { Set } from 'src/app/shared/models/set.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { Step } from 'src/app/shared/models/step.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';

@Component({
  selector: 'toc-radio-list',
  templateUrl: './terms-radio-list.component.html',
  styleUrls: [ './terms-radio-list.component.scss' ],
})
export class TOCRadioListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Output() private updateStep = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    private tocService: TermsAndConditionsService,
  ) {}

  @Input()
  component: StepComponent;

  @Input()
  currentSection: Section;

  @Input()
  currentSet: Set;

  @Input()
  currentStep: Step;

  currentProject: Project;

  tocData: any;

  selectedValue: any;

  ngOnInit(): void {
    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        if (project) {
          this.currentProject = project;
        }
      }),
    );
    this.subscription.add(
      this.tocService.tocData.subscribe((data) => {
        if (data) {
          this.tocData = data[this.currentSection.name];
          if (this.tocData && this.tocData[this.currentStep.name]) {
            this.tocData = this.tocData[this.currentStep.name];
            if (this.tocData.current_model) {
              this.selectedValue = this.tocData.current_model;
            }
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSelection() {
    this.tocService.updateModelSelection(
      this.currentProject,
      this.currentSection,
      this.currentStep,
      this.selectedValue,
    ).then((response) => {
      this.updateStep.emit();
      this.tocService.loadTocData(this.currentProject.id);
    });
  }
}
