import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Project } from '../../models/project.model';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'shared-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: [ './date-picker.component.scss' ],
})
export class DatePickerComponent implements OnInit {
  private CUSTOM_MIN_DATE: Date;

  @Input() date: Date;
  @Output() changed = new EventEmitter<any>();
  @Input() disabled = false;

  @Input() set customMinDate(date) {
    this.CUSTOM_MIN_DATE = date;
    this.minDate = new Date(this.CUSTOM_MIN_DATE);
  }

  minDate: Date;
  maxDate: Date;
  currentProject: Project;

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.currentProject.subscribe(p => {
      if (p) {
        const momentDate = moment([ p.year ]);
        this.currentProject = p;
        this.minDate = this.CUSTOM_MIN_DATE ? new Date(this.CUSTOM_MIN_DATE) : new Date(momentDate.startOf('year').format('YYYY-MM-DD'));
        this.maxDate = new Date(momentDate.endOf('year').format('YYYY-MM-DD'));
      }
    });
  }

  myFilter = (d: any | null): boolean => {
    if (!d) {
      return true;
    }

    const year = d.year();
    // Prevent Saturday and Sunday from being selected.
    return year == this.currentProject.year;
  };

  onDateChange(date: any) {
    this.changed.emit(date);
  }
}
