import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Project } from 'src/app/shared/models/project.model';
import { User } from '../../../shared/models/user.model';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ProjectService } from '../../../shared/services/project.service';

@Component({
  selector: 'dashboard-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: [ './project-card.component.scss' ],
})
export class ProjectCardComponent implements OnDestroy {
  private subscription = new Subscription();
  @Input()
  project: Project;

  @Output()
  projectDeleted: EventEmitter<any> = new EventEmitter<any>();
  user$: Observable<User> = this.authService.currentUser;

  constructor(private authService: AuthenticationService,
              private projectService: ProjectService,
              public dialog: MatDialog) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteEbp(projectId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'alert_ebp_deletion',
    };
    this.subscription.add(
      this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((shouldDelete) => {
        if (!shouldDelete) {
          return;
        }
        this.subscription.add(
          this.projectService.deleteProject(projectId).subscribe((response) => {
            this.projectDeleted.emit(projectId);
          }, (e) => {
            console.log(e);
          }),
        );
      }));
  }
}
