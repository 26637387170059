import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-generic-error-dialog',
  templateUrl: './generic-error-dialog.component.html',
  styleUrls: ['./generic-error-dialog.component.scss']
})
export class GenericErrorDialogComponent implements OnInit {


  public data: any;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data    
  ) {
    this.data = data;
  }


  ngOnInit(): void {

    
  }

}
