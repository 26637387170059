import { Component, Inject, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: [ './confirm-dialog.component.scss' ],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data;
  }

  public data: any;
  public text: any;

  ngOnInit(): void {
    this.configService.dialogConfig.subscribe(config => {
      if (config.filter(c => c.name == this.data.dialog_key).length == 0) {
        this.data.dialog_key = 'alert_deletion';
      }
      this.text = config.filter(c => c.name == this.data.dialog_key)[0];
    });
  }

  replaceText(text: string) {
    if (this.data && this.data.replaceText) {
      return text.replace('{{' + this.data.replaceText['term'] + '}}', this.data.replaceText['value']);
    }

    return text;
  }
}
