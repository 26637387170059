<div class="main">
    <div class="big-container">
        <div class="sub-container">
            <div class="title-container">
                <p class="header">{{'EBP_COVER_TYPE' | translate}}</p>
                <p class="m-bot" *ngIf="currentProject?.type">{{currentProject?.type | projectTypeLabel | translate}}</p>
                <p class="m-bot" *ngIf="!currentProject?.type">-</p>
            </div>
            <div class="title-container">
                <p class="header">{{'EBP_COVER_GEOGRAPHICAL_SETUP' | translate}}</p>
                <p class="m-bot" *ngIf="currentProject?.geographical_setup">{{currentProject?.geographical_setup}}</p>
                <p class="m-bot" *ngIf="!currentProject?.geographical_setup">-</p>
            </div>
            <div class="title-container">
                <p class="header">{{'EBP_COVER_LEGISLATION' | translate}}</p>
                <p class="m-bot" *ngIf="currentProject?.legal_country?.name_en">{{currentProject?.legal_country?.name_en}}</p>
                <p class="m-bot" *ngIf="!currentProject?.legal_country?.name_en">-</p>
            </div>
            <div class="title-container">
                <p class="header">{{'EBP_COVER_CATALOGUE' | translate}}:</p>
                <p class="m-bot" *ngIf="currentProject.catalogue_country?.name_en">{{currentProject.catalogue_country?.name_en}}</p>
                <p class="m-bot" *ngIf="!currentProject.catalogue_country?.name_en">-</p>
            </div>
        </div>
        <div class="sub-container">
            <div class="title-container">
                <p class="header">{{'EBP_COVER_RETAILER' | translate}}:</p>
                <p class="m-bot" *ngIf="currentProject?.retailer?.name">{{currentProject?.retailer?.name}}</p>
                <p class="m-bot" *ngIf="!currentProject?.retailer?.name">-</p>
            </div> 
            <div class="title-container">
                <p class="header">{{'CREATE_EBP_YEAR' | translate}}:</p>
                <p class="m-bot" *ngIf="currentProject?.year">{{currentProject?.year}}</p>
                <p class="m-bot" *ngIf="!currentProject?.year">-</p>
            </div>
            <div class="title-container">
                <p class="header">{{'EBP_COVER_DIVISIONS' | translate}}</p>
                <p class="m-bot" *ngIf="currentProject?.divisions">{{currentProject?.divisions | asList}}</p>
                <p class="m-bot" *ngIf="!currentProject?.divisions">-</p>
            </div>
            <div class="title-container">
                <p class="header">{{'EBP_COVER_BRANDS' | translate}}</p>
                <p class="m-bot" *ngIf="currentProject?.brands && !currentProject?.is_full_division">{{currentProject?.brands | asList}}</p>
                <p class="m-bot" *ngIf="!currentProject?.brands  && !currentProject?.is_full_division">-</p>
                <p class="m-bot" *ngIf="currentProject?.is_full_division">{{'EBP_IS_FULL_DIVISION_NOTE' | translate}}</p>
            </div>
        </div>

        <div class="sub-container">
            <div class="title-container">
                <p class="header">{{'EBP_COVER_CREATED' | translate}}</p>
                
                <p class="normal" *ngIf="currentProject?.creator?.display_name">{{currentProject?.creator?.display_name}}</p>
                                
                <p class="m-bot" *ngIf="currentProject?.created_at">{{currentProject?.created_at | date:'yyyy.MM.dd (HH:mm)'}}</p>
                <p class="m-bot" *ngIf="!currentProject?.created_at">-</p>
            </div>
            <div class="title-container">
                <p class="header">{{'OWNER' | translate}}:</p>
                <p class="normal" *ngIf="currentProject.owner?.display_name">{{currentProject.owner?.display_name}}</p>
                <p class="m-bot" *ngIf="!currentProject.owner?.display_name">-</p>

                <p class="m-bot" *ngIf="currentProject?.owner?.country?.name_en">{{currentProject?.owner?.country?.name_en}}</p>
            </div>
            <div class="title-container">
                <p class="header">{{'VALIDATOR' | translate}}:</p>
                <p class="normal" *ngIf="currentProject.validator?.display_name">{{currentProject.validator?.display_name}}</p>
                <p class="m-bot" *ngIf="!currentProject.validator?.display_name">-</p>

                <p class="m-bot" *ngIf="currentProject?.validator?.country?.name_en">{{currentProject?.validator?.country?.name_en}}</p>
                
            </div>
        </div>

    </div>


</div>