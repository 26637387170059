<div class="ebp-dialog">
    <svg  version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg" width="32px"
        height="32px" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
        style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <style type="text/css">
            .st0 {
                fill: #333333;
            }
        </style>
        <path class="st0" d="M1,21.5h22l-11-19L1,21.5z M13,18.5h-2v-2h2V18.5z M13,14.5h-2v-4h2V14.5z" />
    </svg>


    <h2 mat-dialog-title>{{data.title | translate}}</h2>
    <p class="subtitle-normal" *ngIf="data.error" [innerHTML]="data.error | translate"></p>

    <button class="ebp-button button-transparent" [mat-dialog-close]="true" cdkFocusInitial><span>{{'DIALOGS_CTA_OK' | translate}}</span></button>

</div>