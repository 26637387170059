import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericHttpService } from './generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: GenericHttpService) {}

  private allowedUpdateFields:Array<string> = ['is_hero', 'has_launch', 'launch_date', 'is_exclusive', 'custom_launch_date', 'exclusivity_end', 'has_large_format', 'add_to_activation', 'target_sell_out', 'target_sell_in', 'target_digital_shelf', 'terms_and_conditions'];

  private allProductsSource = new BehaviorSubject<Object>(null);
  public allProducts = this.allProductsSource.asObservable();

  forceColumnSource = new BehaviorSubject<any>(null);
  public forceColumn = this.forceColumnSource.asObservable();

  private loadingBrands: Array<number> = [];

  public loadProductsForBrand(projectId: number, brandId: any = '', force: boolean = false) {

    if(!this.loadingBrands.includes(brandId) || force) {
      this.loadingBrands.push(brandId);
      this.http.get('/catalogue/' + projectId + '/grid/' + brandId).subscribe(response => {
        let currentProducts = this.allProductsSource.value || {};
        currentProducts[projectId + "_" + brandId] = response.data.products;
        this.allProductsSource.next(currentProducts);
      });
    }
  }

  public updateProduct(projectId:number, product: any, brandId: any) {

    const payload = {
      ebp_id: projectId,
      product_id: product.id
    };
    this.allowedUpdateFields.forEach(field => {
      if(product.hasOwnProperty(field)  ) {
        payload[field] = product[field];
      }
    });
    this.http.post('/catalogue', payload).subscribe(response => {
      this.loadProductsForBrand(projectId, brandId);
    });
  }

  public setForceColumn(columns : any){
    this.forceColumnSource.next(columns)
  }

  public resetProducts(){
    this.allProductsSource.next(null);
    this.loadingBrands = [];
  }

}
