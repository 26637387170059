export interface NavigationItem {
  label: string;
  url: string;
  type?: NavigationItemType;
  clickEvent?: () => any;
}
export enum NavigationItemType{
  EXTERNAL,
  INTERNAL
}
