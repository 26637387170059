<section class="ebp-expandable-item">
    <header (click)="collapse()">
        <shared-expand-icon [rotate]="!isCollapsed"></shared-expand-icon>
        <span>{{'M_TERMSANDCOND_TERMSANDCOND_ITEMS' | translate}}</span>
    </header>
    <div class="ebp-expandable-content" [@collapse]="isCollapsed">
        <table class="ebp-expandable-table table-fixed">
            <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="boundary">
                <tr *ngFor="let item of items" cdkDrag cdkDragBoundary=".boundary" class="custom-item">
                    <td width="250">
                        <mat-form-field *ngIf="item.is_editable">
                            <input matInput autocomplete="off" [(ngModel)]="item.item" (change)="update(item, 'item')" [placeholder]="'M_TERMSANDCOND_ADD_TERMSANDCOND_ITEM_PLACEHOLDER' | translate" [disabled]="!item.is_editable">
                        </mat-form-field>
                        <span *ngIf="!item.is_editable">{{item.item | translate}}</span>
                    </td>
                    <td class="text-right" width="130">
                        <mat-form-field>
                            <input matInput autocomplete="off" type="text" class="text-right"  mask="separator.3" thousandSeparator="," [(ngModel)]="item.value" (change)="update(item, 'value')" [readonly]="cascading" [disabled]="cascading">
                            <span matSuffix  [class.input-text-muted]="">&nbsp;{{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</span>
                        </mat-form-field>

                    </td>
                    <td class="text-right" width="100">
                        <mat-form-field>
                            <input matInput autocomplete="off" type="text" class="text-right" mask="separator.3" thousandSeparator="," [(ngModel)]="item.percentage" (change)="update(item, 'percentage')" >
                            <span matSuffix [class.input-text-muted]="">&nbsp;%</span>
                        </mat-form-field>
                    </td>
                    <td class="actions" width="90">
                        <ng-container *ngIf="(currentUserRole$ | async) == PROJECT_USER_ROLE.owner || (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor">
                            <shared-drag-icon cdkDragHandle [tooltip]="'M_TERMSANDCOND_CASCADE_GUIDELINE'| translate"></shared-drag-icon>
                            <shared-exclude-icon
                            (onAlertProceed)="deleteItem(item)" [alert]="'alert_deletion'" *ngIf="item.is_editable"></shared-exclude-icon>
                        </ng-container>
                    </td>
                </tr>
                <tr class="add-item" *ngIf="(currentUserRole$ | async) == PROJECT_USER_ROLE.owner || (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor">
                <td colspan="3" >
                        <shared-add-button (click)="addItem()" [label]="'M_TERMSANDCOND_ADD_TERMSANDCOND_ITEM' | translate"></shared-add-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
