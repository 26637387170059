import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtraOptionGroup } from 'src/app/shared/models/extra-option-group';
import { ExtraOption } from 'src/app/shared/models/extra-option';
import { ConfigService } from 'src/app/shared/services/config.service';
import { collapseAnimation } from 'angular-animations';

@Component({
  selector: 'bundler-extra-options',
  templateUrl: './extra-options.component.html',
  styleUrls: ['./extra-options.component.scss'],
  animations: [
    collapseAnimation()
  ]
})
export class BundlerExtraOptionsComponent implements OnInit {
  constructor(private configService: ConfigService) {}

  @Input()
  options: any;

  @Input()
  extraOptions: ExtraOptionGroup[];

  @Output()
  onChange = new EventEmitter<any>();
  
  @Input()
  isCollapsed: boolean = false;
  @Output()
  onCollapseChange = new EventEmitter<boolean>();

  optionGroups: ExtraOptionGroup[] = null;

  ngOnInit(): void {
    if(this.extraOptions) {
      this.optionGroups = this.extraOptions;
      return
    }
    if (this.options == 'default_extra_options' && !this.extraOptions) {
      this.configService.defaultServiceOptions.subscribe(options => this.optionGroups = JSON.parse(JSON.stringify(options)))
      this.configService.loadDefaultServiceOptions()
    }
  }

  onOptionChange(slider: any, option: ExtraOption) {
    option.selected = slider.checked;
    this.onChange.emit(this.optionGroups)
  }


  collapse(){
    this.isCollapsed = !this.isCollapsed;
    this.onCollapseChange.emit(this.isCollapsed)
  }

}
