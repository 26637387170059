import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'shared-errorpage-template',
  templateUrl: './errorpage-template.component.html',
  styleUrls: [ './errorpage-template.component.scss' ],
})
export class ErrorpageTemplateComponent implements OnInit {

  public message: any;
  public projectId: number;
  commercialError = false;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      var errorType = this.router.url;
      if (params.pid) {
        this.projectId = params.pid;
        errorType = this.router.url.substring(this.router.url.indexOf('/') + 1, 4);
      }

      if (errorType) {
        this.configService.dialogConfig.subscribe(config => {
          if (config) {
            this.message = config.filter(c => c.name == errorType.replace('/', ''))[0];
          }
        });
      }
    });

    this.route.queryParams.subscribe(queryParams => {
      this.commercialError = queryParams?.commercial;
    });

  }

  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  goToEbpCoverPage() {
    if (this.projectId) {
      this.router.navigate([ '/projects/', this.projectId ]);
    }
  }

}
