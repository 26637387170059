import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-import-detail-dialog',
  templateUrl: './import-detail-dialog.component.html',
  styleUrls: [ './import-detail-dialog.component.scss' ],
})
export class ImportDetailDialogComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data;
  }

  data: any;
  configData: any = {};

  ngOnInit(): void {
    this.configService.dialogConfig.subscribe(config => {
      this.configData = config.filter(c => c.name === this.data.dialog_key)[0];
    });
  }
}
