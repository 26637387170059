<div class="project-detail-step">
    <table class="table ebp-table reset-table">
        <thead class="table-header">
            <tr>
                <th class="sticky-element" *ngFor="let header of component.headers" [width]="header.width">{{header.label | translate | replacePlaceholder: currentProject.replace}}</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let skuSet of skuSets | orderByDate:'created_At'; let i = index">
                <ng-container *ngIf="!skuSet.mono_from_grid">
                    <tr class="table-item-header">
                        <td>
                            <mat-form-field>
                                <input matInput autocomplete="off" [placeholder]="'M_SELECTION_VIRTUAL_BUNDLES_VIRTUAL_BUNDLE_NAME' | translate" [(ngModel)]="skuSet.name" (change)="onSkuSetChange(skuSet, i)">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput autocomplete="off" [(ngModel)]="skuSet.discount_percentage"
                                    (change)="onSkuSetChange(skuSet, i)" mask="separator.3" thousandSeparator=",">
                            </mat-form-field>
                        </td>
                        <td>
                            {{'\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}} {{ skuSet.discounted_price | mask:'separator.2':','}}
                        </td>
                        <td>
                            <mat-slide-toggle [(ngModel)]="skuSet.activation.status" (change)="onActivationStatusChange(skuSet)"></mat-slide-toggle>
                        </td>
                        <td>
                            <shared-date-picker [date]="skuSet.launch_at" (changed)="onLaunchDateChange($event, skuSet, i)"></shared-date-picker>
                        </td>
                        <td>
                            <shared-date-picker [customMinDate]="skuSet.launch_at"
                                                [date]="skuSet.ends_at"
                                                (changed)="onExclusivityPeriodChange($event, skuSet, i)"></shared-date-picker>
                        </td>
                        <td class="p-2">
                            <shared-exclude-icon *ngIf="(currentUserRole$ | async) == PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor" class="lighter-bg"
                                (onAlertProceed)="removeSkuSet(i)" [alert]="'alert_bundle_deletion'">
                            </shared-exclude-icon>
                        </td>
                    </tr>
                    <tr class="table-content" *ngIf="skuSet.id">
                        <td colspan="7" class="no-padding no-border">
                            <bundler-product-list [selectedProducts]="skuSet.products" [isMono]="skuSet.mono_from_grid"
                                (productsSelected)="onSelectedProductsChange(skuSet, $event, i)"
                                (onCollapseChange)="onCollapseChange(skuSet, $event)"
                                [isCollapsed]="this.component.answer.collapsedState[skuSet.id]">
                            </bundler-product-list>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr class="table-add-item" *ngIf="(currentUserRole$ | async) == PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor">
                <td colspan="7">
                    <shared-add-button [label]="'M_SELECTION_VIRTUAL_BUNDLES_CTA_ADD_VIRTUAL_BUNDLE' | translate" (click)="addSkuSet()"></shared-add-button>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr class="table-footer">
                <td colspan="7" align="right">
                    {{getSkuSetCount()}} {{'M_SELECTION_VIRTUAL_BUNDLES_CREATOR_FOOTER_ITEMS' | translate}}
                </td>
            </tr>
        </tfoot>
    </table>
</div>
