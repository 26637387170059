import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CoverRoutingModule } from './cover-routing.module';

import { CoverComponent } from './components/cover/cover.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CoverComponent, AuthenticationComponent],
  imports: [CommonModule, CoverRoutingModule, SharedModule, TranslateModule],
})

export class CoverModule {}
