<main>
  <div class="welcome-container-wrapper">
    <div class="welcome-container" *ngIf="!hideWelcomeContainer">

      <div class="welcome-logo">
        <img src="assets/logos/eBP_logo_notext.svg"/>
      </div>

<!--      <h1 class="welcome-title">{{message?.labels?.title_1?.en | textReplace :{"USERFIRSTNAME": (user$ | async)?.given_name} }}</h1>-->
      <h1 class="welcome-title">{{'DASHBOARD_WELCOME' | translate }}</h1>
      <p class="welcome-text" [innerHTML]="'DASHBOARD_WELCOME_MESSAGE' | translate"></p>

      <button class="ebp-button button-transparent welcome-button" (click)="hide()">
        <span>{{message?.labels.cta_1 | translate}}</span>
      </button>

    </div>
  </div>

  <ng-container *ngFor="let container of containers | keyvalue  | orderByKey :'value.order';let i = index;">
    <div class="ebp-container-wrapper" [id]="container.value.dataKey">
      <div class="project-title-wrapper d-flex justify-content-between align-items-center flex-wrap">
        <h1 class="title">{{container.value.title | translate}}</h1>
        <div class="create-ebp" [routerLink]="['/projects/create']"
             *ngIf="container.key === DASHBOARD_TYPE.NORMAL">
          {{'DASHBOARD_CTA_CREATE_NEW_EBP' | translate}}
        </div>
      </div>

      <div class="filters-area d-flex justify-content-between align-items-center" >
        <div class="filter filter-year">
          <mat-form-field appearance="standard">
            <mat-label class="filter-label">{{'CREATE_EBP_YEAR' | translate}}</mat-label>
            <mat-select [(ngModel)]="container.value.filters['year']"
                        (ngModelChange)="filterUpdate(container.key,'year')">
              <mat-option>
                <ngx-mat-select-search #searchYear noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}" placeholderLabel="{{'SEARCH' | translate}}" ></ngx-mat-select-search>
              </mat-option>
              <mat-option value="">
                  <span>
                    {{'DASHBOARD_FILTER_YEAR_ALL' | translate}}
                  </span>
              </mat-option>
              <ng-container *ngFor="let year of years ">
                <mat-option [value]="year"  *ngIf="year && year.toString().startsWith(searchYear.value)">
                {{ year }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container
            *ngFor="let filterItem of container.value.filtersData | keyvalue  | orderByKey :'value.order';let i = index;">
          <div class="filter filter-normal {{filterItem.key}}">
            <mat-form-field appearance="standard">
              <mat-label class="filter-label">{{filterItem.value.label | translate}}</mat-label>
              <mat-select [(ngModel)]="container.value.filters[filterItem.value.filterKey]"
                          (ngModelChange)="filterUpdate(container.key,filterItem.value.filterKey)"
                          [multiple]="filterItem.value.type === 'multiSelect'">

                <mat-option>
                  <ngx-mat-select-search #searchParam noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}" placeholderLabel="{{'SEARCH' | translate}}" ></ngx-mat-select-search>
                </mat-option>
                <mat-option value="" *ngIf="filterItem.key !== DASHBOARD_FILTERS.DIVISIONS">
                  <span>
                    {{filterItem.value.filterAllLabel | translate}}
                  </span>
                </mat-option>
                <mat-option [value]="item.id" *ngFor="let item of filterItem.value.data | filter:searchParam.value">
                  <span *ngIf="filterItem.key === DASHBOARD_FILTERS.DIVISIONS">
                    {{ item.code}}
                  </span>
                  <span *ngIf="filterItem.key !== DASHBOARD_FILTERS.DIVISIONS">
                    {{ item.name ? item.name : item.name_en }}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>

        <div class="layout-switcher">
          <div class="cursor-pointer"
               *ngFor="let layoutType of DASHBOARD_LAYOUTS"
               (click)="changeLayout(container.key,layoutType)"
          >
            <img class="layout-switcher-icon layout-switcher-icon__{{layoutType}}"
                 [class.active]="container.value.layout === layoutType">
          </div>
        </div>
      </div>

      <ng-container *ngIf="loading[container.key]">
        <div class="spinner">
          <mat-spinner [strokeWidth]="4" [diameter]="200"></mat-spinner>
        </div>
      </ng-container>

      <ng-container *ngIf="!loading[container.key] && container.value.data.length">

        <div class="project-list">
          <ng-container *ngFor="let project of container.value.data | orderByKey:'year':true">
            <ebp-item [project]="project"
                      [layoutType]="container.value.layout"
                      [loadedModules]="loadedEbpModules"
                      [user]="user$ | async"
                      (projectDeleted)="removeProject($event,container.key)">
            </ebp-item>
          </ng-container>
        </div>
      </ng-container>

      <div *ngIf="!loading[container.key] && (!container.value.data || container.value.data.length == 0)"><span class="label-no-search-result">{{'DASHBOARD_NO_SEARCH_RESULT' | translate }}</span></div>

      <ng-container *ngIf="container.value.data.length">
        <div class="paginate d-flex align-items-center justify-content-center">
          <div class="d-flex align-items-center"
               [class.pagination-arrow--disabled]="container.value.pagination.currentPage === 1">
            <span class="material-icons grey7 paginate-arrow"
                  (click)="changePage(container.key,1)">
              skip_previous
            </span>
            <span class="material-icons grey7 paginate-arrow paginate-prev-page"
                  (click)="changePage(container.key,container.value.pagination.currentPage -1)">
              play_arrow
            </span>
          </div>
          <div class="paginate-number-list d-flex justify-content-center">
            <span class="paginate-number-list-item"
                  *ngFor="let pageNumber of container.value.pagination.numbers |
                  paginationNumbers:container.value.pagination.currentPage
                  : container.value.pagination.total"
                  [class.active]="pageNumber+1 == container.value.pagination.currentPage"
                  (click)="changePage(container.key,pageNumber+1)">{{pageNumber + 1}}</span>
          </div>
          <div class="d-flex align-items-center"
               [class.pagination-arrow--disabled]="container.value.pagination.currentPage == container.value.pagination.lastPage">
            <span class="material-icons grey7 paginate-arrow"
                  (click)="changePage(container.key,container.value.pagination.currentPage +1)">
              play_arrow
            </span>
            <span class="material-icons grey7 paginate-arrow"
                  (click)="changePage(container.key,container.value.pagination.lastPage)">skip_next</span>
          </div>
          <div class="d-flex">
            <select (change)="changePage(container.key,1,$event.target.value)"
                    class="paginate-size">
              <option *ngFor="let pageSize of pageSizes"
                      [value]="pageSize"
                      [selected]="pageSize == container.value.pagination.perPage">
                {{pageSize}}
              </option>
            </select>
            <span class="paginate-size-text">{{'PAGINATION_ITEMS_PER_PAGE' | translate}}</span>
          </div>
          <div class="paginate-info ml-auto">
            {{container.value.pagination.currentPage  | totalPages:container.value.pagination.perPage :container.value.pagination.total }}
            {{'PAGINATION_OF' | translate}} {{container.value.pagination.total}} {{'PAGINATION_ITEMS' | translate}}
          </div>
        </div>
      </ng-container>

    </div>
  </ng-container>
</main>
