<div class="stat-container" [class.mb-0]="displayOnlyStatus" *ngIf="project">
  <div *ngIf="project.status">
    <p class="ebp-status">{{'EBP_COVER_STATUS'| translate}} <span>{{project?.status | statusLabel | translate}}</span></p>
    <ng-container *ngIf="!displayOnlyStatus">
      <p *ngIf="project.status == 'edited'" class="ebp-label-text">
        <span class="ebp-label-text-span">{{'EBP_COVER_EDITED' | translate}} </span>
        <span class="normal" *ngIf="project?.updater?.display_name">{{project?.updater?.display_name}}, {{project.updated_at | date:'yyyy.MM.dd (HH:mm)'}} </span>
        <!-- <span *ngIf="project.updated_by == project.owner_id">{{project.owner.display_name}}</span>
        <span *ngIf="project.updated_by == project.validator_id">{{project?.validator?.display_name}}</span> -->
      </p>
      
      <p *ngIf="(project.status == 'submitted' || project.status == 'validated' || project.status == 'rejected')"
        class="ebp-label-text">
        <span class="ebp-label-text-span">{{'STATUS_SUBMITTED' | translate | lowercase}}:</span> {{project.owner.display_name}}, {{project?.submitted_at | date:'yyyy.MM.dd (HH:mm)'}}
      </p>

      <p *ngIf="project.status == 'validated'" class="ebp-label-text mb-5">
        <span class="ebp-label-text-span">{{'STATUS_VALIDATED' | translate | lowercase}}:</span> {{project?.validator?.display_name}}, {{project?.validated_at | date:'yyyy.MM.dd (HH:mm)'}}
      </p>

      <p *ngIf="project.status == 'rejected'" class="ebp-label-text mb-5">
        <span class="ebp-label-text-span">{{'STATUS_REJECTED' | translate | lowercase}}:</span> {{project?.validator?.display_name}}, {{project?.validated_at | date:'yyyy.MM.dd (HH:mm)'}}
      </p>

    </ng-container>
  </div>
  <div *ngIf="!project.status">
    <p class="ebp-status">{{'EBP_COVER_STATUS'| translate}} {{'STATUS_INPROGRESS' | translate}}</p>
  </div>
</div>