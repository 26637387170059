import { Pipe, PipeTransform } from '@angular/core';


const EBP_STATUS = {
  "selected" : "STATUS_INPROGRESS",
  "edited" : "STATUS_INPROGRESS",
  "rejected" : "STATUS_REJECTED",
  "submitted" : "STATUS_SUBMITTED",
  "validated" : "STATUS_VALIDATED"
}

@Pipe({
  name: 'statusLabel'
})
export class StatusLabelPipe implements PipeTransform {

  transform(label: string): string {
    return EBP_STATUS[label] || label
  }

}
