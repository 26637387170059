<ng-container *ngIf="currentProject && currentModule">

  <div style="margin: 0 80px 0 76px;">
    <div class="project-detail-step">
      <div class="mininame-header">{{currentProject.full_name}}</div>
    </div>
    

  <div class="project-detail-step">
    <div class="module-header">
      <shared-module-icon [type]="currentModule.alias" [status]="moduleStatus"></shared-module-icon>
      <div class="module-divider"></div>
      <h1 class="module-title">{{ currentModule.name | translate }}</h1>
    </div>
  </div>
  </div>
  

  <div class="module-sections">
    <project-section [ebpStatus]="ebpStatus" [section]="section" [ebpId]="ebpId"
      *ngFor="let section of currentModule.sections; index as i">
    </project-section>
  </div>

  <div class="module-navigation">
    <div class="box-container">
      <div class="box left" (click)="prevModule()" [class.disabled]="isPrevModuleDisabled()">
        <div class="button-holder">
          <img src="/assets/logos/downloading.svg" class="prev" *ngIf="!isPrevModuleDisabled()" />
          <img src="/assets/logos/downloading-incomplete.svg" class="prev" *ngIf="isPrevModuleDisabled()" />

          <span>{{ 'PREV_MODULE' | translate}}</span>
        </div>
      </div>
      <div class="box right" (click)="nextModule()" [class.disabled]="isNextModuleDisabled()">
        <div class="button-holder">
          <span>{{ 'NEXT_MODULE' | translate}}</span>
          <img src="/assets/logos/downloading.svg" class="next"  *ngIf="!isNextModuleDisabled()"/>
          <img src="/assets/logos/downloading-incomplete.svg" class="next" *ngIf="isNextModuleDisabled()" />
        </div>
      </div>
    </div>
  </div>

</ng-container>