<div class="project-detail-step">
    <kendo-grid [data]="filteredProducts" [filter]="state.filter" [filterable]="true"
        (dataStateChange)="dataStateChange($event)" [pageSize]="state.take" [pageable]="{
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      }" [skip]="state.skip" (pageChange)="pageChange($event)" *ngIf="defaultConfig" [columnMenu]="{ columnChooser: false }" [sortable]="true" >
        <ng-template kendoGridToolbarTemplate>
            <div class="product-grid-header">
                <div>
                    <div class="column-set-switches" *ngIf="defaultConfig.show_columnset_toggles">
                        <span *ngFor="let columnSet of defaultConfig.column_sets">
                            <mat-slide-toggle *ngIf="columnSet.name != 'main_product_info' && !columnSet?.hidden"
                                [checked]="activeColumns[columnSet.name]" [disabled]="columnSet.disabled"
                                (change)="onColumnSetChange($event, columnSet.name)"
                                [matTooltip]="columnSet?.guideline | translate">
                                {{columnSet.label | translate}}</mat-slide-toggle>
                        </span>
                    </div>
                </div>
                <div class="toolbar-links" *ngIf="defaultConfig.catalogue_link.show">
                    <button mat-flat-button (click)="goToCatalogue()" >{{defaultConfig.catalogue_link.label | translate}}</button>
                </div>
            </div>
        </ng-template>

        <ng-container *ngFor="let columnSet of defaultConfig.column_sets">
            <ng-container *ngFor="let columnSubSet of columnSet.sets">
                <kendo-grid-column-group [title]="columnSubSet.label | translate | replacePlaceholder: currentProject.replace" [headerClass]="'grid-header grid-main-header'"
                    *ngIf="activeColumns[columnSet.name] || columnSet.name == 'main_product_info'" [locked]="columnSet.name == 'main_product_info'" [columnMenu]="false" >
                    <ng-container *ngFor="let ebpColumn of columnSubSet.columns">
                        <kendo-grid-column [field]="ebpColumn.property" [headerClass]="'grid-header'" [locked]="columnSet.name == 'main_product_info'" [width]="ebpColumn.width || '120'" [filterable]="ebpColumn.filter" > 
                            <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex" >
                                <div class="kendo-grid-info">
                                    {{ebpColumn.label | translate | replacePlaceholder: currentProject.replace}}
                                    <p><shared-info-icon [tooltip]="ebpColumn?.guideline | translate" *ngIf="ebpColumn?.guideline"></shared-info-icon></p>
                                </div>

                            </ng-template>
                            <ng-container [ngSwitch]="ebpColumn.type">
                                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'switch'">
                                    <mat-slide-toggle [(ngModel)]="dataItem[ebpColumn.property]"
                                        (ngModelChange)="onProductChange($event, dataItem,ebpColumn.property)"></mat-slide-toggle>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'date_picker'">
                                    <shared-date-picker

                                        [customMinDate]="ebpColumn.property === 'exclusivity_end' && dataItem?.custom_launch_date"
                                        [date]="dataItem[ebpColumn.property]"
                                        (changed)="onDatePickerChange($event,ebpColumn.property, dataItem)">

                                    </shared-date-picker>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'date_picker_period'">
                                    <shared-daterange-picker [startDate]="dataItem[ebpColumn.property + '_start']" [endDate]="dataItem[ebpColumn.property + '_end']" (dateChange)="onDateRangePickerChange($event, ebpColumn.property, dataItem)"></shared-daterange-picker>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchDefault>
                                    {{dataItem[ebpColumn.property]}}
                                </ng-template>
                            </ng-container>
                        </kendo-grid-column>
                    </ng-container>
                </kendo-grid-column-group>
            </ng-container>
        </ng-container>

        <kendo-grid-column-group *ngIf="noActiveColumn()"  [headerClass]="'grid-header grid-main-header'">
            <kendo-grid-column [field]="" [headerClass]="'grid-header'" width="4000"></kendo-grid-column>
        </kendo-grid-column-group>

    </kendo-grid>
</div>
