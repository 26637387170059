import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-drag-icon',
  templateUrl: './drag-icon.component.html',
  styleUrls: ['./drag-icon.component.scss']
})
export class DragIconComponent implements OnInit {

  @Input()
  tooltip: string;

  showTooltip:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
