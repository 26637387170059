import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../shared/services/config.service';
import { GenericHttpService } from '../../../shared/services/generic-http.service';
import { EventsService } from '../../../shared/services/events.service';
import { Events } from '../../../shared/enums/events';
import { environment } from 'src/environments/environment';
import { UploadDialogComponent } from 'src/app/shared/components/upload-dialog/upload-dialog.component';

@Component({
  selector: 'app-retailers-list',
  templateUrl: './retailers-list.component.html',
  styleUrls: [ './retailers-list.component.scss' ],
})
export class RetailersListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public logoBaseUrl: string;

  sort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];

  activeColumns = {};
  retailers: any[] = [];
  filter: CompositeFilterDescriptor;
  filteredRetailers: GridDataResult;
  defaultConfig: any;
  state: State = {
    take: 20,
    skip: 0,
    sort: this.sort,
  };

  constructor(public dialog: MatDialog,
              private configService: ConfigService,
              private eventsService: EventsService,
              private formBuilder: FormBuilder,
              private http: GenericHttpService) { }

  ngOnInit(): void {
    this.subscription.add(
      this.configService.retailersGridConfig.subscribe((config) => {
        if (config) {
          this.setConfig(config);
        }
        else {
          this.configService.getRetailersGridConfig();
        }
      }),
    );

    this.subscription.add(
      this.eventsService.on(Events.REFRESH_RETAILER_LOGO).subscribe((selected_retailer) => {
        for (let i in this.retailers) {
          if (this.retailers[i].alias == selected_retailer.alias) {
            for (let logo in selected_retailer.logos) {
              let filename = selected_retailer.logos[logo];
              const timestamp = +new Date();

              fetch(this.logoBaseUrl + '/' + filename, { method: 'POST', credentials: 'include' });
              this.retailers[i]['logos'][logo] = filename + '?' + timestamp;
            }
          }
        }
      }),
    );

    this.logoBaseUrl = environment.api.base_url + '/retailers/logos';
    this.loadRetailers();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setConfig(config: any) {
    config.column_sets.forEach((columnSet) => {
      this.activeColumns[columnSet.name] = config.active_columnsets.includes(
        columnSet.name,
      );
    });
    this.defaultConfig = config;
    this.defaultConfig.show_columnset_toggles = true;
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.filteredRetailers = process(this.retailers, this.state);
  }

  pageChange() {
    this.loadRetailers();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  noActiveColumn() {
    let activeValues = Object.values(this.activeColumns);
    activeValues = activeValues.filter(
      (item, index) => activeValues.indexOf(item) === index,
    );
    return activeValues.length === 1 && activeValues[0] === false;
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.filteredRetailers = {
      data: orderBy(this.retailers, this.state.sort),
      total: this.retailers.length,
    };
  }

  loadRetailers() {
    this.configService.getEntity('retailer/grid').subscribe(response => {
      this.retailers = response.data.retailers;
      this.filteredRetailers = process(this.retailers, this.state);
      this.filteredRetailers = {
        data: this.retailers.slice(
          this.state.skip,
          this.state.skip + this.state.take,
        ),
        total: this.retailers.length,
      };
    });
  }

  isActiveToggle(dataItem) {
    this.updateDataSource(dataItem, {is_active: !dataItem.is_active}).subscribe(resp => {
      dataItem.is_active = !dataItem.is_active;
    });
  }

  updateDataSource(data, item) {
    return this.http.put(`/retailer/${data.id}/${data.country.id}`, item);
  }

  cellClickHandler({
      sender,
      rowIndex,
      column,
      columnIndex,
      dataItem,
      isEdited,
      originalEvent
    }) {
      if (originalEvent.type == 'click') {
        if (!isEdited && column.field === 'website') {
          sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
        }

        if ([ 'coverImage', 'coverImageBordered', 'coverImageSlimCentered', 'coverImageSlimLeft' ].includes(column.field)) {
          this.openUploader(rowIndex, dataItem);
        }
      }
  }

  cellCloseHandler(args: any) {
    const {formGroup, dataItem} = args;

    if (!formGroup.valid) {
      args.preventDefault();
    }
    else if (formGroup.dirty) {
      const item = {...dataItem, ...formGroup.value};
      this.updateDataSource(dataItem, item).subscribe(resp => {
        dataItem.website = item.website;
      });
    }
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
      website: dataItem.website,
    });
  }

  openUploader(rowIndex: number, dataItem: any) {
    const dialogConfig = new MatDialogConfig();
    dataItem.rowIndex = rowIndex;

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      isRetailerLogo: true,
      retailer: dataItem,
      title1: 'DIALOGS_UPLOADER_RETAILER_LOGO_TITLE1',
      title2: 'DIALOGS_UPLOADER_RETAILER_LOGO_TITLE2',
      text1: 'DIALOGS_UPLOADER_RETAILER_LOGO_TEXT1'
    }

    this.dialog.open(UploadDialogComponent, dialogConfig);
  }

}
