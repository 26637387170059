<ng-container>

  <div *ngIf="!showEmailTemplate">
    <div class="project-detail-step">
      <div class="module-header">
        <!-- <shared-module-icon [type]="currentModule.alias" [status]="currentModule.version.status"></shared-module-icon> -->
        <!--  <div class="module-divider"></div> -->
        <h1 class="module-title">{{ currentPage?.title | translate }}</h1>
      </div>
    </div>

    <div class="module-sections">

      <section class="module-section"
        [ngClass]="{'is-collapsed': section.isDeactivated, 'is-fullwidth' : section.fullwidth}"
        *ngFor="let section of currentPage?.sections; index as i">

        <ng-container *ngIf="!section.repeat_section">
          <div class="project-detail-step" *ngIf="section.title !== false">
            <header class="section-title" matRipple>
              <h2>{{section.title | translate}}</h2>
            </header>
          </div>
          <div class="section-content">
            <div class="project-detail-step" *ngIf="section.text">
              <p class="section-text" [innerHTML]="section.text | translate"></p>
            </div>

          </div>
        </ng-container>

      </section>

    </div>
  </div>

  <div *ngIf="showEmailTemplate">

    <div class="template-container">
      <div class="inner-container">
        <img src="assets/email-template-icons/eBP_logo@2x.jpg" class="ebp-logo" />
        <p class="para-1">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusamus, sit. Molestiae fuga quasi
          voluptatem laudantium quis, voluptatum consequuntur quas porro. Odit mollitia atque consequuntur, quas
          inventore exercitationem. Consectetur, nesciunt pariatur?</p>
        <button class="etmp-ebp-button">CTA BUTTON</button>

        <img src="assets/email-template-icons/loreal_logo@2x.jpg" class="logo-1" />
        <img src="assets/email-template-icons/eBP_logoCompact@2x.jpg" class="logo-2" />
        <div class="multi-para">
          <p>
            eBP Excellence is a technology based solution to frame our strategic partnerships and business plans to the
            very best standard. Inspired by best practices around the globe, with instant and on-going updates, the tool
            is setting a global standard.Still fully customisable to local and client specific needs, to empower
            ownership and entrepreneurship.
          </p>
          <p>
            A collaboration platform synthesizing all business driving elements in one place, fostering cross-functional
            collaboration, reducing workload and enabling full focus on business acceleration.
          </p>
          <p>
            To ensure executional excellence and a qualitative as well as sustainable growth.
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
