import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Activation } from 'src/app/project/entities/activation';
import { ActivationService } from 'src/app/project/services/activation.service';
import { Project } from 'src/app/shared/models/project.model';
import { SkuSet } from 'src/app/shared/models/sku_set.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectUserRole } from '../../../../shared/enums';
import { SkuSetService } from '../sku-set-service.service';

@Component({
  selector: 'skuset-table',
  templateUrl: './skuset-table.component.html',
  styleUrls: [ './skuset-table.component.scss' ],
})
export class SkuSetTableComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private projectService: ProjectService,
    private skuSetService: SkuSetService,
    private activationService: ActivationService,
    public dialog: MatDialog,
  ) {}

  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<any>();

  currentProject: Project;
  skuSets: SkuSet[] = [];
  currentUserRole$: Observable<ProjectUserRole> = this.projectService
    .currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  ngOnInit(): void {
    this.subscription.add(
      this.projectService.currentProject.subscribe((p) => {
        if (p) {
          this.currentProject = p;
          this.skuSetService.loadSkuSets(this.currentProject.id);
        }
      }),
    );
    this.subscription.add(
      this.skuSetService.skuSets.subscribe((skuSets) => {
        if (skuSets) {
          this.skuSets = skuSets;

          this.skuSets.forEach((set) => {
            set = this.updateDiscountForSkuSet(set);
          });
          this.setupAnswers();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSkuSetCount() {
    return this.skuSets.filter((skuset) => !skuset.mono_from_grid).length;
  }

  removeSkuSet(index: number) {
    var skuSet = this.skuSets[index];
    if (skuSet.id) {
      this.skuSetService.deleteSkuSet(skuSet, this.currentProject);
      this.skuSets.splice(index, 1);
    } else {
      this.skuSets.splice(index, 1);
    }
  }

  addSkuSet() {
    var skuSet = new SkuSet();
    skuSet.ebp_id = this.currentProject.id;
    skuSet.activation = new Activation();
    skuSet.activation.status = true;
    this.skuSets.push(skuSet);
  }

  onExclusivityPeriodChange(data: any, skuSet: SkuSet, index: number) {
    const endsAt = new Date(skuSet.ends_at);
    if (
      !skuSet.ends_at ||
      data.year() != endsAt.getFullYear() ||
      data.month() != endsAt.getMonth() ||
      data.date() != endsAt.getDate()
    ) {
      const _skuSet = JSON.parse(JSON.stringify(skuSet));
      _skuSet.ends_at = data;
      this.onSkuSetChange(_skuSet, index);
    }
  }

  onLaunchDateChange(date: any, skuSet: SkuSet, index: number) {
    if (!date) {
      return;
    }
    if (!skuSet.ends_at) {
      skuSet.ends_at = date;
    }
    skuSet.launch_at = date;
    this.onSkuSetChange(skuSet, index);
  }

  onSkuSetChange(skuSet: SkuSet, index: number) {
    if (!skuSet.name) {
      return;
    }
    if (!skuSet.id) {
      this.skuSetService
      .createSkuSet(skuSet, this.currentProject)
      .subscribe((set) => {
        this.skuSets[index] = this.updateDiscountForSkuSet(set);
        this.onChange.emit();
      });
    } else {
      this.updateDiscountForSkuSet(skuSet); // update price before updating other wise it will not reflect on excel export
      this.skuSetService
      .updateSkuSet(skuSet, this.currentProject)
      .subscribe((set) => {
        this.skuSets[index] = this.updateDiscountForSkuSet(set);
        this.onChange.emit();
      });
    }
  }

  updateDiscountForSkuSet(set: SkuSet): SkuSet {
    if (set.products.length == 0) {
      set.discounted_price = 0;
      return set;
    }
    var discount = set.discount_percentage || 0;
    var total =
      set.products.length > 0
        ? set?.products
        .map((p) => parseFloat(p.price))
        .reduce((acc, curr) => acc + curr)
        : 0;
    set.discounted_price = (total * (100 - discount)) / 100;
    return set;
  }

  onActivationStatusChange(skuSet: SkuSet) {
    if (skuSet.activation?.id) {
      this.activationService.updateActivation(skuSet.activation);
      this.onChange.emit();
    }
  }

  onSelectedProductsChange(
    skuSet: SkuSet,
    product_ids: Array<number>,
    index: number,
  ) {
    var _skuSet = JSON.parse(JSON.stringify(skuSet));
    _skuSet.products = product_ids;
    if (!_skuSet.id) {
      this.skuSetService
      .createSkuSet(_skuSet, this.currentProject)
      .subscribe((response) => {
        this.skuSets[index] = this.updateDiscountForSkuSet(response);
        this.onChange.emit();
      });
    } else {
      this.skuSetService
      .updateSkuSet(_skuSet, this.currentProject)
      .subscribe((response) => {
        this.skuSets[index] = this.updateDiscountForSkuSet(response);
        this.skuSetService.updateSkuSet(this.skuSets[index], this.currentProject);
        this.onChange.emit();
      });
    }
  }

  onCollapseChange(skuSet: SkuSet, $event: any) {
    // this.component.answer.collapsedState[skuSet.id] = !this.component.answer.collapsedState[skuSet.id];
    // this.onChange.emit(this.component.answer.collapsedState);
  }

  setupAnswers() {
    this.component.answer = this.component.answer || {};
    this.component.answer.values = this.component.answer.values || {};
    this.component.answer.collapsedState = this.component.answer.collapsedState || {};
    this.skuSets.forEach((skuSet) => {
      this.component.answer.collapsedState[skuSet.id] = this.component.answer.collapsedState[skuSet.id] || false;
    });
  }

}
