import { Country } from './country.model';

export class EbpZone {
  zone: any;
  cluster: any;
  clusterId: number;
  country: Country;
  countryId: string;
  clustersData: Array<{ [key: string]: any }>;
}
