import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent implements OnInit {

  @Input()
  tooltip: string;

  @Input()
  excluded: boolean = false

  @Input()
  stepTitleIcon: boolean = false;

  showTooltip:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
