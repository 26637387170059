<section class="ebp-expandable-item" *ngIf="products && _selectedProducts">
    <ng-container *ngIf="currentProject.catalogue_country_id">
        <header (click)="collapse()">
            <shared-expand-icon [rotate]="!isCollapsed"></shared-expand-icon>
            <span *ngIf="isMono"><span [class.heroSku]="!normalItem">{{'M_SELECTION_SKUSET_TYPE_HERO_SKU' | translate}}</span> _&nbsp;</span>
            <span *ngIf="!isMono"><span [class.heroSku]="!normalItem">{{'M_SELECTION_SKUSET_TYPE_VIRTUAL_BUNDLE' | translate}}</span> _&nbsp;</span>
            <span>
                {{uniqueBrandCount}} {{'M_SELECTION_VIRTUAL_BUNDLES_PROD_BRAND' | translate}}, {{_selectedProducts.length}}
                {{'M_SELECTION_VIRTUAL_BUNDLES_VIRTUAL_BUNDLE_PRODUCTS' | translate}}
            </span>
        </header>
        <div class="ebp-expandable-content" [@collapse]="isCollapsed">
            <table class="ebp-expandable-table" >
                <thead>
                    <tr>
                        <th width="140">{{'M_TARGET_DIGITAL_SHELF_BRAND_TARGETS_ROWS_BRAND' | translate}}</th>
                        <th width="130">{{'M_SELECTION_VIRTUAL_BUNDLES_PROD_EAN' | translate}}</th>
                        <th width="160">{{'M_SELECTION_VIRTUAL_BUNDLES_PROD_NAME' | translate}}</th>
                        <th width="90">{{'M_SELECTION_VIRTUAL_BUNDLES_PROD_PRICE' | translate}} ({{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}})</th>
                        <th width="30"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of _selectedProducts">
                        <td>{{getBrandName(product.brand_id)}}</td>
                        <td>{{product.ean}}</td>
                        <td>{{product.name}}</td>
                        <td>{{product.price}} {{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</td>
                        <td class="no-padding v-align">
                            <ng-container *ngIf="((currentUserRole$ | async) == PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor) && !isMono">
                                <shared-exclude-icon (onAlertProceed)="remove(product.id)" [alert]="'alert_deletion'"></shared-exclude-icon>
                            </ng-container>
                        </td>
                    </tr>
                    <tr *ngIf="!isMono">
                        <td colspan="6" class="autocomplete-holder">
                            <shared-product-autocomplete [products]="products" [selectedProducts]="_selectedProducts" (selected)="add($event)">
                            </shared-product-autocomplete>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    <ng-container *ngIf="!currentProject.catalogue_country_id">  
        <header>
            <shared-expand-icon></shared-expand-icon>
            <span class="component-disabled">{{'M_SELECTION_SKUSET_DISABLED' | translate}}</span>
        </header>
    </ng-container>
</section>
