<div class="project-detail-step project-switch-list">
    <div class="content">
        <table class="table ebp-table">
            <thead>
                <tr>
                    <th *ngFor="let header of component.header" [width]="header.width">{{header.text  | translate}}</th>
                </tr>
            </thead>
            <tbody *ngIf="component.answer">
                <tr *ngFor="let item of component.answer.values; index as i">
                    <ng-container *ngIf="!item.custom">
                        <td class="no-border-right">{{item.text  | translate}}</td>
                        <td class="no-border-left">
                            <mat-slide-toggle [(ngModel)]="item.selected" (ngModelChange)="onDataChange()">
                            </mat-slide-toggle>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="item.custom">
                        <td class="no-border-right">
                            <mat-form-field>
                                <input type="text" [maxLength]="component.custom_item_max_chars" matInput [(ngModel)]="item.text" (change)="onDataChange()" autocomplete="off"
                                    (keydown.enter)="addItem()">
                            </mat-form-field>
                        </td>
                        <td class="no-border-left">
                            <shared-exclude-icon
                                *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor"
                                (onAlertProceed)="removeItemAtIndex(i)" [alert]="'alert_deletion'"></shared-exclude-icon>
                        </td>
                    </ng-container>
                </tr>
                <tr *ngIf="component.add_items && ((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor)">
                    <td colspan="2">
                        <shared-add-button (click)="addItem()"></shared-add-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
