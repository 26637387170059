<div class="shared-daterange-picker" *ngIf="currentProject">
    <mat-form-field appearance="{{appearanceNone ? 'none' : 'standard'}}" >
        <mat-date-range-input class="custom-calendar-icon"  [rangePicker]="picker"  [min]="minDate" [max]="maxDate" [disabled]="disabled">
            <input matStartDate [(ngModel)]="startDate">
            <input matEndDate [(ngModel)]="endDate" (dateChange)="change()" >
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon class="material-icons-sharp custom-date-toggle"> date_range</mat-icon>

        </mat-datepicker-toggle>
        <mat-date-range-picker #picker [startAt]="startDate" ></mat-date-range-picker>
    </mat-form-field>
</div>
