import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'shared-exclude-icon',
  templateUrl: './exclude-icon.component.html',
  styleUrls: ['./exclude-icon.component.scss'],
})
export class ExcludeIconComponent implements OnInit {
  constructor(
    public dialog: MatDialog
  ) {}

  
  
  @Input()
  status: string = null;
  
  @Input()
  active: boolean = false;

  @Input()
  validation: boolean = false;

  @Input()
  alert: string = "";

  @Output()
  onAlertProceed = new EventEmitter<any>();

  @Input()
  tooltip: string;

  ngOnInit(): void {}

  
  showAlert() {
    if (!this.alert) {
      this.onAlertProceed.emit()
      return
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: this.alert,
    };
    this.dialog
      .open(ConfirmDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((shouldDelete) => {
        if (shouldDelete) {
          this.onAlertProceed.emit()
        }
      });
  }

}
