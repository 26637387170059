import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-radio-nested',
  templateUrl: './radio-nested.component.html',
  styleUrls: ['./radio-nested.component.scss'],
})
export class RadioNestedComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      let responses = {};
      responses[this.component.name] = '';
      this.component.options.forEach((option) => {
        if (option.components) {
          option.components.forEach((c) => {
            responses[c.name] = '';
          });
        }
      });

      this.component.answer = { values: responses };
    }
  }

  onValueChange() {
    this.onChange.emit();
  }
}
