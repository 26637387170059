<div class="project-detail-step">
    <div class="content">
        <mat-radio-group [(ngModel)]="component.answer.values.selectedOption" (ngModelChange)="onValueChange()">
            <div class="option-group" *ngFor="let option of component.options">
                <mat-radio-button [value]="option.name" >{{option.label | translate}}</mat-radio-button>
                <div *ngIf="component.answer.values.selectedOption == option.name" class="option-content">
                    <div class="ebp-chips">
                        <button class="ebp-chip" *ngFor="let chip of option.chips" (click)="toggleChip(chip)" [ngClass]="{'is-selected': isSelected(chip.value)}">{{chip.label | translate}}</button>
                    </div>
                    <div *ngIf="isSelected('other')">
                        <mat-form-field>
                            <mat-label>{{option.chips[option.chips.length -1].value.placeholder | translate}}</mat-label>
                            <input type="text" matInput [(ngModel)]="component.answer.values.other" (ngModelChange)="onValueChange()" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </mat-radio-group>
    </div>
</div>
