import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: [ './cover.component.scss' ],
})
export class CoverComponent implements OnInit {
  constructor(private authService: AuthenticationService,
              private router: Router) {}

  public isAuthenticated = false;

  ngOnInit(): void {
    this.isAuthenticated = !!this.authService.currentUserValue;
    const returnUrl = localStorage.getItem('ebpReturnPath');
    if (returnUrl && this.isAuthenticated) {
      this.router.navigate([  `/${returnUrl}` ]);
      localStorage.removeItem('ebpReturnPath');
    }
  }

  login() {
    this.authService.login();
  }
}
