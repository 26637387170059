<div class="module-icon-wrapper" [ngSwitch]="type" [ngClass]="'status-fill-'+status">

    <ng-container *ngSwitchCase="'activation'">
        <svg version="1.1" id="icon-m-activation" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <g id="Group_284" transform="translate(-7070.95 -7192.645)">
                <path id="Path_2194" fill="#333333" d="M7072.451,7221.292L7072.451,7221.292v2.854h2.853c1.576,0,2.854-1.279,2.854-2.855
           c0.001-1.577-1.276-2.856-2.853-2.856s-2.854,1.277-2.855,2.854L7072.451,7221.292z" />
                <path id="Path_2195" fill="#333333" d="M7096.384,7194.145c-1.625,0-3.183,0.656-4.319,1.819l0,0l-6.325,6.329l-2.729,2.729h-8.575
           l4.287,4.29l-4.317,4.322l8.565,8.569l4.32-4.32l4.288,4.289v-8.58l2.728-2.729l6.325-6.329l0,0
           c1.164-1.137,1.819-2.694,1.819-4.32v-6.063L7096.384,7194.145z M7088.169,7211.111c-0.741,0.742-1.945,0.743-2.688,0
           c-0.742-0.742-0.743-1.947,0-2.689c0.742-0.743,1.945-0.743,2.688,0v0C7088.911,7209.165,7088.911,7210.368,7088.169,7211.111z
            M7092.083,7207.195c-0.741,0.744-1.945,0.745-2.688,0.002c-0.743-0.742-0.745-1.946-0.003-2.689
           c0.741-0.744,1.946-0.745,2.688-0.002c0.001,0.001,0.002,0.001,0.002,0.002C7092.825,7205.25,7092.825,7206.453,7092.083,7207.195
           L7092.083,7207.195z M7095.997,7203.28c-0.742,0.743-1.946,0.743-2.688,0s-0.743-1.947,0-2.689c0.741-0.743,1.945-0.743,2.688,0
           l0.001,0C7096.739,7201.333,7096.739,7202.538,7095.997,7203.28z" />
            </g>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'ambition'">
        <svg version="1.1" id="icon-m-vision" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <g id="Group_282" transform="translate(-39.781 -25.714)">
                <g id="Group_285" transform="translate(-7162.502 -7043.028)">
                    <rect id="Rectangle_12934" x="7215.522" y="7094.827" fill="#333333" width="6.514" height="1.621" />
                    <rect id="Rectangle_12935" x="7217.155" y="7098.128" fill="#333333" width="3.252" height="1.632" />
                    <path id="Path_2196" fill="#333333" d="M7218.776,7076.722c-3.581-0.007-6.49,2.89-6.497,6.471
			c-0.005,2.322,1.231,4.469,3.243,5.63v4.356h6.514v-4.356c3.102-1.79,4.166-5.755,2.376-8.857
			C7223.251,7077.953,7221.101,7076.715,7218.776,7076.722L7218.776,7076.722z" />
                    <rect id="Rectangle_12936" x="7217.97" y="7070.723" fill="#333333" width="1.621" height="3.646" />
                    <path id="Path_2197" fill="#333333"
                        d="M7227.038,7073.81l-2.572,2.575l1.152,1.147l2.578-2.574L7227.038,7073.81z" />
                    <rect id="Rectangle_12937" x="7227.64" y="7082.413" fill="#333333" width="3.645" height="1.623" />
                    <path id="Path_2198" fill="#333333"
                        d="M7210.518,7073.81l-1.148,1.148l2.58,2.574l1.154-1.147L7210.518,7073.81z" />
                    <rect id="Rectangle_12938" x="7206.282" y="7082.413" fill="#333333" width="3.646" height="1.623" />
                </g>
            </g>
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'selection'">
        <svg version="1.1" id="Component_236_2" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <g id="Group_968" transform="translate(-7389 -7348.804)">
                <rect id="Rectangle_14422" x="7394" y="7355" fill="#333333" width="6" height="6" />
                <rect id="Rectangle_14423" x="7394" y="7363" fill="#333333" width="6" height="6" />
                <rect id="Rectangle_14424" x="7394" y="7371" fill="#333333" width="6" height="6" />
                <rect id="Rectangle_14425" x="7402" y="7355" fill="#333333" width="6" height="6" />
                <rect id="Rectangle_14426" x="7402" y="7363" fill="#333333" width="6" height="6" />
                <rect id="Rectangle_14427" x="7402" y="7371" fill="#333333" width="6" height="6" />

                <rect id="Rectangle_14428" x="7411.902" y="7353.902"
                    transform="matrix(-0.5 0.866 -0.866 -0.5 17493.5859 4613.7441)" fill="#333333" width="6"
                    height="5.999" />
                <rect id="Rectangle_14429" x="7410" y="7363" fill="#333333" width="6" height="6" />
                <rect id="Rectangle_14430" x="7410" y="7371" fill="#333333" width="6" height="6" />
            </g>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'merchandising'">
        <svg version="1.1" id="icon-m-category" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Union_4" fill="#333333" d="M17.736,24.5c0-3.59,2.91-6.5,6.5-6.5s6.5,2.91,6.5,6.5s-2.91,6.5-6.5,6.5
	S17.736,28.09,17.736,24.5z M3.001,30V19h11v11H3.001z M8.157,15.05L16.263,2l8.105,13.05H8.157z" />
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'data'">
        <svg version="1.1" id="icon-m" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33" enable-background="new 0 0 33 33"
            xml:space="preserve">
            <g id="Group_283">
                <rect id="Rectangle_12917" x="5.5" y="19" fill="#333333" width="4" height="9" />
                <rect id="Rectangle_12918" x="11.5" y="5" fill="#333333" width="4" height="23" />
                <rect id="Rectangle_12933" x="17.5" y="13" fill="#333333" width="4" height="15" />
                <rect id="Rectangle_12919" x="23.5" y="8" fill="#333333" width="4" height="20" />
            </g>
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'legal'">
        <svg version="1.1" id="icon-m-legal" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Path_2209" fill="#333333" d="M16.5,3.667L6,8.333v7c0,6.477,4.477,12.532,10.5,14c6.021-1.471,10.5-7.525,10.5-14v-7
	L16.5,3.667z M14.7,22.383L9.684,17.35l1.95-1.954l3.066,3.077l7.724-7.75l1.957,1.953L14.7,22.383z" />
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'media'">
        <svg version="1.1" id="icon-m-media" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Path_2187" fill="#333333" d="M24.6,15.063v2.875H30v-2.875H24.6z M21.9,24.562c1.3,1.021,2.983,2.373,4.32,3.437
	c0.539-0.763,1.079-1.538,1.619-2.3c-1.336-1.064-3.023-2.415-4.32-3.45c-0.539,0.776-1.08,1.551-1.619,2.315V24.562z M27.84,7.3
	C27.3,6.538,26.76,5.762,26.221,5C24.883,6.064,23.2,7.415,21.9,8.45c0.539,0.762,1.08,1.538,1.619,2.3
	C24.816,9.715,26.5,8.378,27.84,7.3z M5.7,12.188c-1.537,0.054-2.742,1.338-2.7,2.875v2.875c-0.042,1.537,1.163,2.821,2.7,2.875
	h1.35v5.75h2.7v-5.75h1.35l6.75,4.312V7.875l-6.75,4.313H5.7z M21.225,16.5c0.014-1.815-0.719-3.556-2.024-4.816V21.3
	C20.508,20.047,21.24,18.311,21.225,16.5z" />
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'operationsandcc'">
        <svg version="1.1" id="icon-m-operations" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Path_2208" fill="#333333" d="M16.5,0.912L3,8.706v15.589l13.5,7.793L30,24.295V8.706L16.5,0.912z M25.283,9.612
	l-3.762,2.172l-8.785-5.071L16.5,4.542L25.283,9.612z M16.5,14.683L7.717,9.612l3.763-2.173l8.784,5.071L16.5,14.683z M6.145,12.335
	l8.783,5.071v10.142l-8.783-5.071V12.335z M18.072,27.548V17.406l8.783-5.071v10.142L18.072,27.548z" />
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'others'">
        <svg version="1.1" id="icon-m-other" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <g id="Group_288" transform="translate(-7483.502 -7073.5)">
                <path id="Path_2202" fill="#333333" d="M7495.502,7090c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5
		C7494.381,7087.503,7495.499,7088.621,7495.502,7090z" />
                <path id="Path_2203" fill="#333333" d="M7502.502,7090c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5
		S7502.502,7088.619,7502.502,7090z" />
                <path id="Path_2204" fill="#333333" d="M7509.502,7090c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5s1.119-2.5,2.5-2.5
		S7509.502,7088.619,7509.502,7090z" />
            </g>
        </svg>

    </ng-container>

    <ng-container *ngSwitchCase="'selection_offline'">
        <svg version="1.1" id="icon-m-selection" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Path_2211" fill="#333333" d="M25.825,17.055c-0.386-0.216-0.786-0.402-1.2-0.555c0.414-0.153,0.814-0.338,1.2-0.554
	C28.4,14.466,29.991,11.727,30,8.757c-2.586-1.481-5.765-1.481-8.352,0c-0.379,0.222-0.742,0.472-1.086,0.748
	C21.13,6.098,19.507,2.7,16.5,1c-3.005,1.699-4.628,5.094-4.064,8.5c-0.339-0.285-0.703-0.54-1.086-0.762
	c-2.586-1.481-5.765-1.481-8.351,0c-0.003,2.973,1.591,5.719,4.175,7.189c0.385,0.215,0.786,0.401,1.2,0.554
	c-0.414,0.153-0.815,0.338-1.2,0.554C4.598,18.516,3.007,21.258,3,24.229c2.586,1.482,5.765,1.482,8.351,0
	c0.38-0.221,0.743-0.471,1.086-0.748c-0.071,0.44-0.108,0.885-0.111,1.33c0.004,2.971,1.596,5.713,4.174,7.189
	c3.005-1.699,4.629-5.094,4.064-8.5c0.339,0.281,0.702,0.531,1.086,0.748c2.586,1.482,5.764,1.482,8.351,0
	c-0.009-2.97-1.601-5.709-4.175-7.189L25.825,17.055z M16.5,22.041c-3.06-0.014-5.529-2.507-5.515-5.567
	c0.015-3.06,2.507-5.529,5.567-5.515c3.049,0.015,5.514,2.491,5.514,5.541c-0.006,3.066-2.496,5.547-5.562,5.541
	C16.503,22.041,16.501,22.041,16.5,22.041z" />
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'services'">
        <svg version="1.1" id="icon-m-services" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <g id="Group_286" transform="translate(-7310.5 -7070)">
                <path id="Path_2199" fill="#333333" d="M7333.781,7072.012l-13.579-0.012c-1.472-0.014-2.679,1.164-2.7,2.636v23.728
		c0.022,1.472,1.228,2.649,2.7,2.636h13.576c1.478,0.019,2.692-1.158,2.72-2.636v-23.728
		C7336.47,7073.163,7335.256,7071.99,7333.781,7072.012z M7334.002,7096h-14v-19h14V7096z" />
                <path id="Path_2200" fill="#333333" d="M7327.002,7089.227c-1.506-0.001-2.727-1.223-2.726-2.729s1.222-2.727,2.728-2.726
		s2.727,1.222,2.727,2.728C7329.729,7088.006,7328.508,7089.226,7327.002,7089.227z M7327.002,7084.862
		c-0.904,0-1.636,0.734-1.636,1.638c0,0.904,0.734,1.636,1.638,1.636c0.904-0.001,1.635-0.732,1.636-1.636
		c0.002-0.903-0.729-1.637-1.632-1.639C7327.006,7084.862,7327.004,7084.862,7327.002,7084.862L7327.002,7084.862z" />
            </g>
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'target'">
        <svg version="1.1" id="Icon-m-target" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Path_2184" fill="#333333" d="M16.5,11.591c-2.711,0-4.909,2.198-4.909,4.909c0,2.711,2.198,4.909,4.909,4.909
	s4.909-2.198,4.909-4.909c0.001-2.71-2.196-4.909-4.907-4.909C16.501,11.591,16.5,11.591,16.5,11.591z M27.473,15.273
	c-0.573-5.126-4.619-9.173-9.746-9.745V3h-2.454v2.528c-5.126,0.572-9.172,4.619-9.745,9.745H3v2.456h2.528
	c0.573,5.125,4.619,9.172,9.745,9.744V30h2.456v-2.527c5.126-0.572,9.172-4.619,9.744-9.746H30v-2.454H27.473z M16.5,25.091
	c-4.745,0-8.591-3.847-8.591-8.591c0-4.745,3.846-8.591,8.591-8.591c4.744,0,8.591,3.846,8.591,8.591l0,0
	c0.003,4.741-3.837,8.588-8.579,8.591C16.508,25.091,16.504,25.091,16.5,25.091z" />
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'termsandcond'">
        <svg version="1.1" id="icon-m-terma-and-conditions" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="33px" height="33px" viewBox="0 0 33 33"
            enable-background="new 0 0 33 33" xml:space="preserve">
            <path id="Path_2210" fill="#333333" d="M16.5,3.667L6,8.333v7c0,6.475,4.478,12.53,10.5,14c6.021-1.471,10.5-7.525,10.5-14v-7
       L16.5,3.667z M17.75,21.883v1.689h-2.356v-1.701c-1.558-0.186-2.768-1.445-2.892-3.01h1.728c0.085,0.928,0.722,1.65,2.343,1.65
       c1.736,0,2.123-0.863,2.123-1.4c0-0.732-0.399-1.424-2.358-1.898c-2.194-0.527-3.695-1.432-3.695-3.247
       c0-1.517,1.227-2.507,2.752-2.837v-1.7h2.358v1.721c1.438,0.285,2.484,1.529,2.521,2.994h-1.738
       c-0.047-0.982-0.562-1.651-1.962-1.651c-1.329,0-2.122,0.6-2.122,1.455c0,0.747,0.573,1.226,2.357,1.689s3.694,1.227,3.694,3.458
       C20.5,20.703,19.282,21.592,17.75,21.883z" />
        </svg>
    </ng-container>

    <ng-container *ngSwitchCase="'settings'">
        <div class="material-icons grey5 big">settings</div>
    </ng-container>

    <ng-container *ngIf="status == 'card'">
        <div class="matrix">
            <!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 97 97" style="enable-background:new 0 0 97 97;" xml:space="preserve">
                <style type="text/css">
                    .st0{opacity:0.13;}
                    .st1{fill:#FEFEFE;}
                </style>
                <g id="Group_207" transform="translate(-1 -1)" class="st0">
                    <rect id="Surface" x="1" y="32" class="st1" width="97" height="1"/>
                    <rect id="Surface-2" x="1" y="66" class="st1" width="97" height="1"/>
                    <rect id="Surface-3" x="66" y="1" class="st1" width="1" height="97"/>
                    <rect id="Surface-4" x="32" y="1" class="st1" width="1" height="97"/>
                </g>
            </svg>
        </div>
    </ng-container>
</div>
