<div class="project-detail-step">
  <kendo-grid class="activities-editor-grid" [data]="filteredData" [filter]="state.filter" [filterable]="true"
              (dataStateChange)="dataStateChange($event)" [pageSize]="state.take" [pageable]="{
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      }" [skip]="state.skip" (pageChange)="pageChange()" *ngIf="defaultConfig"
              [columnMenu]="{ columnChooser: false }"
              [sortable]="true">
    <ng-template kendoGridToolbarTemplate>
            <div class="show-archived-elements" kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
              <mat-slide-toggle class="activity-switcher"
                                [checked]="shouldShowArchived"
                                (change)="showArchived()"
                                [title]="'Show Archived'">
              </mat-slide-toggle>
              <span class="slide-title">{{'M_ACTIVATION_ACTIVITIES_LABEL_SHOW_ARCHIVED_ELEMENTS' | translate}}</span>
            </div>
    </ng-template>

    <ng-container *ngFor="let columnSet of defaultConfig.column_sets">
      <ng-container *ngFor="let columnSubSet of columnSet.sets">

        <ng-container *ngFor="let actColumn of columnSubSet.columns;let i = index;">
          <kendo-grid-column [field]="actColumn.property" [headerClass]="'grid-header'"
                             [footerStyle]="{'padding-bottom':'11px;','padding-top':'11px;'}"
                             [sortable]="actColumn.sortable"
                             [width]="actColumn.width || '120'" [filterable]="actColumn.filter">

            <ng-template kendoGridHeaderTemplate let-column
                         let-columnIndex="columnIndex">{{actColumn.label | translate}}</ng-template>
            <ng-container [ngSwitch]="actColumn.type">

              <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'switch'">
                <mat-slide-toggle class="activity-switcher"
                                  [checked]="!dataItem[actColumn.property]"
                                  (change)="changeArchiveStatus($event, dataItem,actColumn.property)"
                                  ></mat-slide-toggle>
              </ng-template>

              <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'date'">
                <ng-container *ngIf="dataItem.created_by">
                  {{dataItem[actColumn.property] | kendoDate}}
                </ng-container>
              </ng-template>

              <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'custom_edit'">
                <span *ngIf="!dataItem?.is_default" class="material-icons cursor-pointer" (click)="openModal(dataItem)">edit</span>
              </ng-template>

              <ng-template kendoGridCellTemplate let-dataItem *ngSwitchDefault>
                <span class="activity-name"
                      [class.disabled]="dataItem?.is_default"
                      [class.active]="dataItem?.is_active">
                    {{dataItem[actColumn.property]}}
                  </span>
              </ng-template>
            </ng-container>

            <ng-template kendoGridFooterTemplate let-column let-columnIndex="columnIndex" *ngIf="i === 0">
              <shared-add-button
                  [className]="'activity-add'"
                  [label]="'M_ACTIVATION_ACTIVITIES_LABEL_ADD_NEW_ACTIVITY' | translate"
                  *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor"
                  (click)="openModal()"></shared-add-button>
            </ng-template>

          </kendo-grid-column>
        </ng-container>
      </ng-container>
    </ng-container>

    <kendo-grid-column-group *ngIf="noActiveColumn()" [headerClass]="'grid-header grid-main-header'">
      <kendo-grid-column [field]="" [headerClass]="'grid-header'" width="4000"></kendo-grid-column>
    </kendo-grid-column-group>

  </kendo-grid>
</div>




