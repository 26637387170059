<div class="project-detail-step">

  <div class="activation-calendar">
    <div class="calendar-info">
      <div class="calendar-info__header">
        <span class="calendar-info__header-title">{{'M_ACTIVATION_CALENDAR_FULL_CALENDAR_ACTIVATIONS' | translate}}</span>
      </div>
      <div class="calendar-activations">
        <ng-container *ngFor="let activation of activations;let activationIndex = index;">

          <div class="calendar-activations__item">
            <div  class="calendar-activations__item-info">
              <span class="activations-name">
                <span *ngIf="activation.sku_set.mono_from_grid;else normalName">
                  <span>{{'M_SELECTION_SKUSET_TYPE_HERO_SKU' | translate}}</span>
                  <br>
                  <span class="activations-name--normal">{{activation.sku_set.products[0].name}}</span>
                </span>
                <ng-template #normalName>
                  <span>{{'M_SELECTION_SKUSET_TYPE_VIRTUAL_BUNDLE_CAMPAIGN' | translate}}</span>
                  <br>
                  <span class="activations-name--normal">{{activation.sku_set.name}}</span>
                </ng-template>
              </span>
              <div class="activations-information">
                <div class="activations-information__details">
                  {{activation.additional_info.brands_categories}}.
                  <br>
                  {{activation.additional_info.hero_sku}} {{'M_SELECTION_SKUSET_TYPE_HERO_SKU' | translate}},
                  {{activation.additional_info.total_sku - activation.additional_info.hero_sku}} {{'SKU' | translate}}, {{'M_TARGET_TOTAL' | translate}} {{activation.additional_info.total_sku}} {{'SKUS' | translate}}
                </div>
                <div class="activations-information__icon" (click)="scrollToCampaign(activationIndex)">
                  <span class="material-icons ebp-interface-button">info</span>
                </div>
              </div>
            </div>
            <div class="calendar-activations__item-activities">
              <span class="activities-title">{{'M_ACTIVATION_ACTIVITIES_TITLE' | translate }}</span>
              <div class="activities-data"
                   [class.empty]="!activation.activation_events.length">
                <ng-container
                    *ngFor="let activity of activation.activation_events | orderByDate:'starts_at';let activityIndex = index;">
                  <div class="activities-data__item "
                       [ngClass]="!activityIndex ? 'activities-data__item--small activities-data__item--dark' :'activities-data__item--regular'">
                    {{activity.activityName}}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="calendar-header-holder">
      <div class="calendar-header" #calendarHeader>
        <div class="calendar-header__item" *ngFor="let calendarDate of calendarDates;let monthIndex = index;">
          <div class="calendar-header__item-title">{{calendarDate.date | date:"MMMM yyyy"}}</div>
          <div class="calendar-header__item-days">
            <ng-container *ngFor="let day of calendarDate.days;let i = index">
              <div class="calendar-day">
                {{day + 1}}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="calendar-navigations" *ngIf="activations.length">
        <span (click)="navigateToMonth(true)" class="calendar-navigation prev"></span>
        <span (click)="navigateToMonth()" class="calendar-navigation next"></span>
      </div>
    </div>
    <div class="calendar-data" #calendarData (scroll)="onScroll($event)">
      <ng-container *ngFor="let calendarDate of calendarDates;let monthIndex = index;">

        <div class="calendar-data__item" #calendarItem>


          <ng-container *ngFor="let activation of activations;let activationIndex = index;">
            <div class="calendar-data__item-content">
              <div class="content-placeholders">
                <ng-container *ngFor="let day of calendarDate.days;let i = index">
                  <div class="content-placeholders__item">
                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="!activation.activation_events.length">
                <div class="content-placeholders">
                  <ng-container *ngFor="let day of calendarDate.days;let i = index">
                    <div class="content-placeholders__calendar-item">
                    </div>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngFor="let activity of activation.activation_events;let activityIndex=index;">
                <div class="content-placeholders">
                  <ng-container *ngFor="let day of calendarDate.days;let i = index">

                    <div class="content-placeholders__calendar-item"
                         [class.content-placeholders__calendar-item--dark]="!activityIndex">

                      <ng-container *ngIf="activity.startMonth === activity.endMonth">
                        <div class="active-day" *ngIf="day+1 >= activity.startDay
                                                       && day+1 <= activity.endDay
                                                      && activity.startMonth == monthIndex+1">
                                <span *ngIf="day+1 == activity.startDay"
                                      class="material-icons ebp-interface-button">info</span>
                        </div>

                      </ng-container>
                      <ng-container *ngIf="activity.startMonth !== activity.endMonth">


                        <div class="active-day"
                             *ngIf="day+1 >= activity.startDay&& activity.startMonth == monthIndex+1">
                          <span *ngIf="day+1 == activity.startDay && !activityIndex"
                                class="material-icons ebp-interface-button">info</span>
                        </div>

                        <div class="active-day"
                             *ngIf="monthIndex+1 > activity.startMonth && monthIndex+1 < activity.endMonth">
                        </div>

                        <div class="active-day"
                             *ngIf="day+1 <= activity.endDay&& activity.endMonth == monthIndex+1">
                          <!--                          <span *ngIf="day+1 == activity.endDay && !activityIndex"-->
                          <!--                                class="material-icons ebp-interface-button">info</span>-->
                        </div>
                      </ng-container>


                    </div>

                  </ng-container>
                </div>
              </ng-container>

            </div>
          </ng-container>
        </div>
        <!-- loop -->
      </ng-container>
    </div>

  </div>
</div>
