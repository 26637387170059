
import { ProjectType } from '../enums';
import { Brand } from './brand.model';
import { Devision } from './devision.model';
import { Module } from './module.model';
import { Retailer } from './retailer.model';
import { User } from './user.model';

export class CreateProject {
  id: number;
  ebpType: ProjectType;
  type: string;
  year: number;
  owner_id: number;
  validator_id: number;

  devision_id: number;
  retailer_id: number;
  retailer: Retailer;
  clusters_ids: Array<string>;
  zones_ids: Array<string>;
  countries_ids: Array<string>;
  full_name: string;
  modules: Module[];

  owner: User;
  validator: User;
  zone_id: number;
  zone: any;
  zones: any;

  cluster_id: number;
  cluster: any;
  legal_country_id: string;
  legal_country: any;
  language_id: any;
  brands: Array<Brand>;
  divisions: Array<Devision>;
  division_ids: number[];
  currency_id: string;
  created_by: number;
  creator: User;
  created_at: Date;
  status: string;
  is_best_practice?: boolean;
  catalogue_country_id: string;
  catalogue_country: any;
  is_full_division?:boolean;
  geographical_setup: string;
  updater: User;
  editors: any;
}
