<div class="project-detail-step">
    <svg id="ebp-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="140px" height="190px" viewBox="0 0 140 190" enable-background="new 0 0 140 190"
        xml:space="preserve">
        <g>
            <g>
                <polygon fill="#333333" points="71.634,51.717 34.66,73.133 71.634,94.55 108.607,73.133 		" />
                <polygon fill="#333333" points="71.634,30.301 34.66,51.717 71.634,73.134 108.607,51.717 		" />
                <polygon fill="#FF005A" points="71.634,8.885 34.66,30.301 71.634,51.717 108.607,30.301 		" />
                <polygon fill="#00FFEB" points="71.634,30.301 53.146,41.009 71.634,51.717 90.121,41.009 		" />
                <polygon fill="#FFFFFF" points="71.634,51.717 53.146,62.425 71.634,73.134 90.121,62.425 		" />
            </g>
            <g>
                <g>
                    <path fill="#333333" d="M24.399,177.366H13.993c0.475,1.95,2.062,3.424,4.126,3.424c1.474,0,2.902-0.793,3.583-2.108h2.358
                    c-0.953,2.562-3.288,4.263-6.031,4.263c-3.583,0-6.395-3.106-6.395-6.62c0-3.628,2.766-6.599,6.439-6.599
                    c3.561,0,6.396,2.925,6.396,6.462C24.468,176.573,24.444,176.981,24.399,177.366z M18.051,171.88
                    c-2.042,0-3.923,1.608-4.059,3.673h8.116C21.973,173.488,20.091,171.88,18.051,171.88z" />
                    <path fill="#333333" d="M33.75,182.627l-3.106-4.468l-3.038,4.468h-2.745l4.354-6.439l-4.194-6.122h2.855l2.767,4.126
                    l2.743-4.126h2.858l-4.104,6.122l4.376,6.439H33.75z" />
                    <path fill="#333333"
                        d="M43.349,182.944c-3.741,0-6.44-2.947-6.44-6.62c0-3.674,2.744-6.599,6.462-6.599
                    c2.699,0,5.465,1.814,6.168,4.512h-2.607c-0.703-1.474-1.86-2.357-3.537-2.357c-2.585,0-4.128,1.973-4.128,4.444
                    c0,2.381,1.565,4.466,4.082,4.466c1.609,0,2.902-0.861,3.56-2.312h2.63C48.609,181.177,46.229,182.944,43.349,182.944z" />
                    <path fill="#333333" d="M63.744,177.366H53.337c0.477,1.95,2.064,3.424,4.127,3.424c1.474,0,2.902-0.793,3.582-2.108h2.359
                    c-0.953,2.562-3.289,4.263-6.032,4.263c-3.583,0-6.393-3.106-6.393-6.62c0-3.628,2.765-6.599,6.439-6.599
                    c3.559,0,6.394,2.925,6.394,6.462C63.813,176.573,63.791,176.981,63.744,177.366z M57.396,171.88
                    c-2.041,0-3.922,1.608-4.059,3.673h8.118C61.319,173.488,59.437,171.88,57.396,171.88z" />
                    <path fill="#333333" d="M66.13,182.627v-16.778h2.358v16.778H66.13z" />
                    <path fill="#333333" d="M71.486,182.627v-16.778h2.359v16.778H71.486z" />
                    <path fill="#333333" d="M88.954,177.366H78.547c0.477,1.95,2.063,3.424,4.127,3.424c1.474,0,2.902-0.793,3.582-2.108h2.358
                    c-0.951,2.562-3.287,4.263-6.031,4.263c-3.583,0-6.395-3.106-6.395-6.62c0-3.628,2.767-6.599,6.439-6.599
                    c3.56,0,6.395,2.925,6.395,6.462C89.022,176.573,89,176.981,88.954,177.366z M82.606,171.88c-2.042,0-3.924,1.608-4.06,3.673
                    h8.118C86.528,173.488,84.646,171.88,82.606,171.88z" />
                    <path fill="#333333" d="M100.097,182.627v-6.824c0-2.132-0.635-3.923-3.13-3.923c-2.312,0-3.31,1.791-3.31,3.899v6.848H91.3
                    v-12.562h2.198v1.338h0.046c0.907-1.201,2.222-1.678,3.673-1.678c1.792,0,3.469,0.703,4.445,2.244
                    c0.612,0.952,0.793,2.108,0.793,3.22v7.438H100.097z" />
                    <path fill="#333333"
                        d="M111.168,182.944c-3.74,0-6.438-2.947-6.438-6.62c0-3.674,2.744-6.599,6.463-6.599
                    c2.696,0,5.463,1.814,6.166,4.512h-2.607c-0.702-1.474-1.858-2.357-3.536-2.357c-2.586,0-4.127,1.973-4.127,4.444
                    c0,2.381,1.563,4.466,4.08,4.466c1.61,0,2.903-0.861,3.562-2.312h2.629C116.43,181.177,114.049,182.944,111.168,182.944z" />
                    <path fill="#333333" d="M131.563,177.366h-10.405c0.476,1.95,2.062,3.424,4.126,3.424c1.474,0,2.902-0.793,3.583-2.108h2.357
                    c-0.952,2.562-3.287,4.263-6.031,4.263c-3.582,0-6.395-3.106-6.395-6.62c0-3.628,2.767-6.599,6.439-6.599
                    c3.561,0,6.396,2.925,6.396,6.462C131.634,176.573,131.609,176.981,131.563,177.366z M125.217,171.88
                    c-2.041,0-3.922,1.608-4.059,3.673h8.116C129.139,173.488,127.257,171.88,125.217,171.88z" />
                </g>
                <g>
                    <path fill="#333333" d="M58.481,144.856H44.325c0.34,2.244,2.211,3.572,4.424,3.572c1.531,0,2.518-0.612,3.436-1.771h5.785
                    c-1.632,3.778-4.969,6.534-9.22,6.534c-5.514,0-10.006-4.492-10.006-10.003c0-5.479,4.357-10.14,9.902-10.14
                    c5.785,0,9.971,4.628,9.971,10.31C58.616,143.868,58.582,144.345,58.481,144.856z M48.681,137.811
                    c-2.042,0-3.846,1.191-4.289,3.233h8.576C52.525,139.002,50.723,137.811,48.681,137.811z" />
                    <path fill="#333333" d="M80.022,150.401c-2.585,2.212-4.968,2.178-8.234,2.178h-7.997v-25.18h7.758
                    c3.029,0,5.887,0.033,8.065,2.448c1.056,1.157,1.497,2.587,1.497,4.151c0,2.145-1.056,4.016-2.926,5.07
                    c2.756,0.954,4.15,2.996,4.15,5.854C82.337,146.931,81.588,149.074,80.022,150.401z M72.435,133.049h-2.484v3.911h2.451
                    c1.395,0,2.552-0.408,2.552-2.007C74.953,133.352,73.763,133.049,72.435,133.049z M73.184,142.338h-3.233v4.593h3.199
                    c1.599,0,3.028-0.544,3.028-2.415C76.178,142.71,74.681,142.338,73.184,142.338z" />
                    <path fill="#333333"
                        d="M101.825,142.507c-2.177,1.906-4.524,2.178-7.352,2.178h-2.789v7.895h-6.158v-25.18h8.235
                    c2.585,0,5.683,0.102,7.759,1.837c1.973,1.668,2.96,4.082,2.96,6.669C104.48,138.22,103.594,140.942,101.825,142.507z
                     M94.066,133.049h-2.382v5.987h2.621c2.108,0,4.015-0.477,4.015-3.028C98.32,133.388,96.177,133.049,94.066,133.049z" />
                </g>
            </g>
        </g>
    </svg>

    <p>A technology based solution to frame our strategic partnerships and business plans to the very best standard. Inspired by best practices around the globe, with instant and on-going updates, the tool is setting a global standard.Still fully customisable to local and client specific needs, to empower ownership and entrepreneurship.<br><br>A collaboration platform synthesizing all business driving elements in one place, fostering cross-functional collaboration, reducing workload and enabling full focus on business acceleration.<br><br>To ensure executional excellence and a qualitative as well as sustainable growth. All of this, in accordance with applicable laws, especially competition law which prohibits restricting our clients’ ability to determine freely their commercial policy, in particular reselling prices and promotions.</p>
    <button [routerLink]="['/dashboard']" class="ebp-button" *ngIf="isAuthenticated">{{'INDEX_LAUNCH_APP' | translate}}</button>
    <button (click)="login()" class="ebp-button" *ngIf="!isAuthenticated">Login</button>

    <svg id="loreal-logo" version="1.1" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="130px" height="30px"
        viewBox="335 57.374 130 30" enable-background="new 335 57.374 130 30" xml:space="preserve">
        <polyline id="polyline3" points="405.284,81.77 420.77,81.77 420.77,79.878 407.939,79.878 407.939,73.825 417.576,73.825 
	417.576,71.912 407.939,71.912 407.939,66.335 420.77,66.335 420.77,64.451 405.284,64.451 405.284,81.77 " />
        <polyline id="polyline5" points="413.332,61.651 411.235,64.006 417.816,61.651 413.332,61.651 " />
        <polyline id="polyline7" points="446.766,79.878 446.766,64.451 444.346,64.451 444.346,81.77 460,81.77 460,79.878 446.766,79.878 
	" />
        <path id="path9" d="M430.804,64.451l-8.806,17.319h2.977l2.253-4.482h10.562l2.271,4.482h2.946l-8.813-17.319H430.804
	 M428.342,75.116l4.149-8.272l4.198,8.272H428.342z" />
        <path id="path11" d="M396.881,74.527c3.968-1.059,4.353-4.011,4.333-5.066c-0.237-3.094-2.322-5.01-6.067-5.01h-10.916V81.77h2.488
	v-7.266h7.106l5.228,7.266h3.136C402.19,81.77,398.429,76.88,396.881,74.527 M394.808,72.458h-8.087V66.5h8.37
	c1.927,0,3.011,0.899,3.401,2.042c0.258,0.783,0.093,1.812-0.334,2.511C397.457,72.212,396.128,72.458,394.808,72.458z" />
        <path id="path13" d="M369.278,61.518c-7.024,0-11.812,4.953-11.812,10.947c0,6.311,5.289,10.766,11.812,10.766
	c6.519,0,11.805-4.392,11.805-10.766C381.083,66.471,376.237,61.518,369.278,61.518 M369.194,81.08c-4.86,0-8.864-3.905-8.864-8.609
	c0-4.698,3.815-8.791,9.052-8.791c5.107,0,8.862,4.092,8.862,8.791C378.244,77.175,374.051,81.08,369.194,81.08z" />
        <polyline id="polyline15"
            points="351.199,70.577 352.995,70.577 356.254,64.451 353.631,64.451 351.199,70.577 " />
        <polyline id="polyline17" points="342.422,79.878 342.422,64.451 340,64.451 340,81.77 355.654,81.77 355.654,79.878 
	342.422,79.878 " />
    </svg>

</div>