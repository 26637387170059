<div class="import-detail-dialog">


  <div class="import-detail-title">
    INFORMATION
  </div>

  <div class="import-detail-note" *ngIf="data?.report">
    <pre class="import-pre" [innerHTML]="data.report.data | json">
    </pre>
  </div>
</div>
