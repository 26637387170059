import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/shared/models/project.model';

@Component({
  selector: 'shared-ebp-info',
  templateUrl: './ebp-info.component.html',
  styleUrls: ['./ebp-info.component.scss']
})
export class EbpInfoComponent implements OnInit {


  @Input()
  currentProject: Project;

  legislation: any;

  constructor() { }

  ngOnInit(): void {
   
  }

}
