import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-step-status-button',
  templateUrl: './step-status-button.component.html',
  styleUrls: ['./step-status-button.component.scss']
})
export class StepStatusButtonComponent implements OnInit {

  @Input()
  className: string;

  @Input()
  buttonTitle: string;

  constructor() { }

  ngOnInit(): void {
  }


}
