import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Observable, Subscription } from 'rxjs';
import { Activity } from 'src/app/project/entities/activity';
import { ActivityService } from 'src/app/project/services/activity.service';
import { Project } from 'src/app/shared/models/project.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ActivityEditDialogComponent } from '../../../../shared/components/activity-edit-dialog/activity-edit-dialog.component';
import { ProjectUserRole } from '../../../../shared/enums';
import { StepComponent } from '../../../../shared/models/step-component.model';
import { ConfigService } from '../../../../shared/services/config.service';

@Component({
  selector: 'activities-editor',
  templateUrl: './activities-editor.component.html',
  styleUrls: [ './activities-editor.component.scss' ],
})
export class ActivitiesEditorComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Output() private updateStep = new EventEmitter<void>();

  @Input() private component: StepComponent;
  currentProject: Project;
  activities: Activity[];
  filteredActivities: Activity[] = [];
  shouldShowArchived = true;
  filteredData: GridDataResult;

  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  state: State = {

    take: 5,
    skip: 0,
    sort: [
      {
        dir: 'desc',
        field: 'updated_at',
      },
    ],
  };

  activeColumns = {};
  defaultConfig: any;

  constructor(
    private activityService: ActivityService,
    private projectService: ProjectService,
    private configService: ConfigService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.configService.activitiesGridConfig.subscribe((config) => {
        if (config) {
          this.setConfig(config);
        } else {
          this.configService.getActivitiesGridConfig();
        }
      }),
    );
    this.subscription.add(
      this.projectService.currentProject.subscribe((p) => {
        if (p) {
          this.currentProject = p;
          this.activityService.loadActivitiesForProject(p.id);
        }
      }),
    );
    this.subscription.add(
      this.activityService.activities.subscribe((activities) => {
        if (activities) {

          this.activities = activities.sort((a, b) => {
            return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
          });
          this.filteredActivities = this.filterActivities(activities);
          this.filteredData = process(this.filteredActivities, this.state);
          this.loadActivities();

        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  delete(index: number) {
    const activity = this.activities[index];
    if (activity.id) {
      this.activityService.deleteActivity(activity);
    } else {
      this.activities.splice(index);
    }
  }

  openModal(activityData = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'edit_custom_activity',
      ebpId: this.currentProject.id,
    };
    if (activityData) {
      dialogConfig.data.activitiy = activityData;
    }

    this.subscription.add(
      this.dialog
      .open(ActivityEditDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((activity) => {
        if (activity) {
          this.update(activity);
        }
      }));
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.filteredActivities = this.filterActivities(this.activities);
    this.filteredData = process(this.filteredActivities, this.state);
  }

  pageChange() {
    this.loadActivities();
  }

  loadActivities(): void {
    this.filteredActivities = this.filterActivities(this.activities);
    this.filteredData = {
      data: this.filteredActivities.slice(
        this.state.skip,
        this.state.skip + this.state.take,
      ),
      total: this.filteredActivities.length,
    };
  }

  setConfig(config: any) {
    config.column_sets.forEach((columnSet) => {
      this.activeColumns[columnSet.name] = config.active_columnsets.includes(
        columnSet.name,
      );
    });
    this.defaultConfig = config;
    if (this.component) {
      if (this.component.hasOwnProperty('show_columnset_toggles')) {
        this.defaultConfig.show_columnset_toggles = this.component.show_columnset_toggles;
      }
    }
  }

  changeArchiveStatus(ev: any, dataItem, property: string) {
    dataItem.is_active = !ev.checked;
    if (dataItem.id) {
      this.activityService.updateActivity(dataItem);
    }
    this.updateStep.emit();
  }

  showArchived() {
    this.shouldShowArchived = !this.shouldShowArchived;
    this.loadActivities();
  }

  noActiveColumn() {
    let activeValues = Object.values(this.activeColumns);
    activeValues = activeValues.filter(
      (item, index) => activeValues.indexOf(item) === index,
    );
    return activeValues.length === 1 && activeValues[0] === false;
  }

  filterActivities(data) {
    return data.filter((activity) => this.shouldShowArchived ? activity : activity.is_active);
  }

  update(dataItem) {
    if (dataItem.id) {
      this.activityService.updateActivity(dataItem);
    } else {
      this.activityService.createActivity(dataItem);
    }
    this.updateStep.emit();
  }

}
