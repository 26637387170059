<div class="project-detail-step" *ngIf="data">
    <div class="project-table-input-component">
        <table class="table ebp-table">
            <thead>
                  <tr>
                    <th rowspan="3">{{component?.headers[0]?.label | translate | replacePlaceholder:currentProject.replace}}</th>
                    <th colspan="2" class="border-btm">{{'M_TERMSANDCOND_OVERALL_TOTAL_TERMSANDCOND_TITLE'| translate}} {{ '\{\{YEAR\}\}'| translate | replacePlaceholder:currentProject.replace}}</th>
                  </tr>
                  <tr>
                    <th>{{component?.headers[1]?.label | translate | replacePlaceholder:currentProject.replace}}</th>
                    <th>{{component?.headers[2]?.label | translate | replacePlaceholder:currentProject.replace}}</th>
                  </tr>
            </thead>
            <tbody>
                <tr >
                    <td *ngFor="let header of component.headers; ">
                        {{
                            header.type === 'currency'
                            || header.type === 'percentage'
                                ?
                                (data.overall_total_termsandcond[header.name] | mask:'separator.2':',')
                                : data.overall_total_termsandcond[header.name]
                        }} {{units(header.type)}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
