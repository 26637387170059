<div class="project-detail-step">
    <div class="project-table-input-component" *ngIf="component.answer">
    <table class="table ebp-table">
        <thead>
            <tr>
                <th *ngFor="let header of component.headers; " [width]="header.width"> {{header.label |  translate | replacePlaceholder: currentProject.replace}}</th>
                <!-- {{header.label.en.replace("\{\{CURRENCY\}\}","€")}} -->
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of component.rows; let i = index">
                <td *ngFor="let cell of row; let k = index">
                    <ng-container *ngIf="!component.headers[k].editable">
                        <ng-container [ngSwitch]="cell.type">
                            <span *ngSwitchCase="'string'"> {{label(cell.label) | translate}}</span>
                            <span *ngSwitchCase="'currency'"> {{cell.label | translate | replacePlaceholder: currentProject.replace}}</span>
                            <span *ngSwitchCase="'percentage'"> {{ cell.label.toFixed(2) | translate }} %</span>
                            <span *ngSwitchCase="'integer'"> {{ cell.label.toFixed(2)  | translate}}</span>
                            <span *ngSwitchDefault> {{ label(cell.label)  | translate | replacePlaceholder: currentProject.replace}}</span>
                            <span class="info-icon-wrapper"><shared-info-icon class="float-left" *ngIf="cell?.guideline" [tooltip]="cell?.guideline | translate"></shared-info-icon></span>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="component.headers[k].editable">
                        <div [ngSwitch]="component.headers[k].type">
                            <ng-container *ngSwitchCase="'select'">
                                <div style="display:flex;">
                                    <mat-form-field appearance="standard" [class.shrink-width]="i == 0 && currentModule?.alias == 'data'">
                                        <mat-select [(ngModel)]="component.answer.values[i][k]"  (selectionChange)="onDirectChange(row,i,k)">
                                            <mat-option *ngFor="let option of cell.options" [value]="option.value">
                                                {{option.label | translate | replacePlaceholder: currentProject.replace}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span *ngIf="i == 0 && currentModule?.alias == 'data'" class="copy-all-btn">
                                        <shared-zap-icon [tooltip]="'DIALOGS_ALERT_ZAP_DATA_COPY_ALL_TOOLTIP' | translate" (click)="copyToColumn(component.answer.values, k, component.answer.values[i][k])"></shared-zap-icon>
                                    </span>
                                </div>
                               
                            </ng-container>
                            <ng-container *ngSwitchCase="'toggle'">
                                <mat-slide-toggle [(ngModel)]="component.answer.values[i][k]" (change)="onDirectChange(row,i,k)"></mat-slide-toggle>
                            </ng-container>
                            <ng-container *ngSwitchCase="'string'">
                                <mat-form-field>
                                    <textarea [maxLength]="cell.max_chars" style="overflow:hidden"  name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete"cdkTextareaAutosize matInput [(ngModel)]="component.answer.values[i][k]" (change)="onDirectChange(row,i,k)" ></textarea>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'percentage'">
                                <mat-form-field>
                                    <input class="text-right" type="text"  name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete" matInput [(ngModel)]="component.answer.values[i][k]" (change)="onDirectChange(row,i,k)"  mask="separator.3" thousandSeparator=",">
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'currency'">
                                <mat-form-field>
                                    <input class="text-right" matInput  name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete" type="text" [(ngModel)]="component.answer.values[i][k]" (change)="onDirectChange(row,i,k)"  mask="separator.3" thousandSeparator=",">
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'integer'">
                                <mat-form-field>
                                    <input class="text-right" name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete"  matInput type="text" [(ngModel)]="component.answer.values[i][k]" (change)="onDirectChange(row,i,k)"  mask="separator.3" thousandSeparator=",">
                                </mat-form-field>
                            </ng-container>
                          <!--   <div *ngSwitchDefault> missing: {{component.headers[k].type}}</div> -->
                        </div>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>
</div>
