<section class="ebp-expandable-item" *ngIf="activation.id">
    <header (click)="collapse()">
        <shared-expand-icon [rotate]="!isCollapsed"></shared-expand-icon>
        <span>{{activation.activation_events.length}} <span
                *ngIf="activation.activation_events.length != 1">{{'M_ACTIVATION_ACTIVITIES_TITLE' | translate}}</span><span
                *ngIf="activation.activation_events.length == 1">{{'M_ACTIVATION_CALENDAR_FULL_CALENDAR_ACTIVITY' | translate}}</span>
        </span>
    </header>
    <div class="ebp-expandable-content" *ngIf="!isCollapsed">
        <span (click)="navigateMonth(-1)" class="calendar-nav prev" *ngIf="activeMonth > 0">
        </span>
        <span (click)="navigateMonth(1)" class="calendar-nav next" *ngIf="activeMonth < 9"></span>
        <div class="ebp-calendar">
            <div class="months" [ngStyle]="{'transform': 'translateX(' + (activeMonth * -167) + 'px)'}">
                <div class="month" *ngFor="let month of months; let i = index"
                    [ngClass]="{'inactive': (activeMonth + 2 < i)}">
                    <h6 class="month-title">{{month | date: "MMMM yyyy"}}</h6>
                    <ng-container *ngFor="let event of activation.activation_events;let i = index">
                        <activation-event *ngIf="event.starts_at.getMonth() == month.getMonth()"
                            [activationEvent]="event"
                                          [activation]="activation"
                                          (onChange)="onEventChange()">

                        </activation-event>
                    </ng-container>
                    <shared-add-button *ngIf="!disableAddActivity" (click)="addActivationEventForMonth(month)" [label]="'M_ACTIVATION_CONFIGURATOR_TOOL_ADD_ACTIVITY' | translate">
                    </shared-add-button>
                </div>
            </div>
        </div>
    </div>
</section>
