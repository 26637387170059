import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { flatten } from 'lodash-es';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectType } from '../../../shared/enums';
import { Project } from '../../../shared/models/project.model';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ConfigService } from '../../../shared/services/config.service';
import { HttpCancelService } from '../../../shared/services/httpCancel.service';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: [ './project-detail.component.scss' ],
})
export class ProjectDetailComponent implements OnInit, OnDestroy {

  brandsLoaded = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private configService: ConfigService,
    private moduleService: ModuleService,
    private httpCancelService: HttpCancelService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.projectService.loadCurrentProject(params.id);
        this.moduleService.loadModulesForProject(params.id);
      }
    });
    this.projectService.currentProject.subscribe(async (currentProject: Project) => {
      if (currentProject?.notFound) {
        this.router.navigate([ '/404' ]);
        return;
      }

      if (currentProject) {
        this.moduleService.currentModule.subscribe((module => {

          const moduleAlias = module && module.alias ? module.alias : null;
          const currentUser = this.authenticationService.currentUserValue;
          this.projectService.setCurrentUserRole({
            userId: currentUser.id,
            ownerId: currentProject.owner_id,
            validatorId: currentProject.validator_id,
            editors: currentProject.editors,
          }, moduleAlias);

        }));
        let entityQuery;
        switch (currentProject.type) {
          case ProjectType.COUNTRY:
            entityQuery = `country_id=${currentProject.legal_country_id}`;
            break;
          case ProjectType.MULTI_COUNTRY:
            const countries = flatten(currentProject.countries.map(item => item.id))?.toString();
            entityQuery = `country_id=${countries}`;
            break;
          case ProjectType.ZONE:
            const zones = flatten(currentProject.zones.map(item => item.id))?.toString();
            entityQuery = `zone_id=${zones}`;
            break;
          default:
            entityQuery = null;
            break;
        }
        if (this.projectService.projectContainsFullDivision(currentProject) && !this.brandsLoaded) {
          const projectBrands = await this.configService.getEntity('brands', entityQuery).toPromise().then(response => response.data.brands);
          this.projectService.setCurrentProjectBrands({
            brands: projectBrands,
            divisionIds: currentProject.divisions.map(division => division.id),
          });
          this.brandsLoaded = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.projectService.clearProject();
    this.moduleService.resetProjectModules();
    this.httpCancelService.cancelPendingRequests();
  }

}
