import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ProjectUserRole } from '../../../shared/enums';
import { Events } from '../../../shared/enums/events';
import { Section } from '../../../shared/models/section.model';
import { Set } from '../../../shared/models/set.model';
import { Step } from '../../../shared/models/step.model';
import { ConfigService } from '../../../shared/services/config.service';
import { EventsService } from '../../../shared/services/events.service';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'app-project-module',
  templateUrl: './project-module.component.html',
  styleUrls: [ './project-module.component.scss' ],
})
export class ProjectModuleComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription = new Subscription();
  /* private moduleStatusInterval: any; */
  public currentProject: Project;
  public currentModule: Module;
  public lastModuleAlias = '';
  public ebpStatus: string = null;
  public ebpId: any = null;
  public moduleStatus: string = null;
  public forbiddenError = false;
  public projectModules: any;
  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  currentUserRole: ProjectUserRole;
  public activeModules: Module[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private eventsService: EventsService,
    public configService: ConfigService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.subscription.add(
      this.eventsService.on(Events.UPDATE_CURRENT_MODULE_DATA).subscribe((currentModule) => {
        this.currentModule.sections.map((section: Section, sectionIndex: number) => {
            section.sets.map((set: Set, setIndex: number) =>
              set.steps.map((step: Step, stepIndex: number) => {
             /*    if (!section.repeat_section) {
                  step.status = currentModule.sections[sectionIndex].sets[setIndex].steps[stepIndex].status;
                } else {
                  console.log('repeatSection diff');
                } */

                step.status = currentModule.sections[sectionIndex].sets[setIndex].steps[stepIndex].status;
                return step;
              }),
            );
            return section;
          },
        );
        this.currentModule.version.status = currentModule.version.status; // update module status aswell 
        this.moduleService.setCurrentModule(this.currentModule);
      }),
    );

    this.subscription.add(
      this.currentUserRole$.subscribe((role) => {
        this.currentUserRole = role;
      }),
    );
    this.moduleService.currentModuleErrorSource.next(0);
    this.subscription.add(
      this.eventsService.on('reloadStepsStatuses').pipe(
        debounceTime(3000),
        tap(() => this.updateStatus(),
        ),
      ).subscribe(),
    );
    this.subscription.add(
      this.moduleService.currentModuleError.subscribe(projectId => {
        if (projectId) {
          this.router.navigate([ `/403/${projectId}` ], {queryParams: {commercial: true}});
        }
      }),
    );

    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        if (project && !this.currentProject) {
          this.currentProject = project;
          this.route.params.subscribe((params) => {
              this.forbiddenError = false;
              /* if (this.moduleStatusInterval) {
                this.moduleStatusInterval.unsubscribe();
              } */
              this.moduleService.getCurrentModule(
                this.currentProject.id,
                params.module,
              );
            },
          );

        }
      }),
    );
    this.subscription.add(
      this.moduleService.currentModule.subscribe((module) => {
        this.currentModule = module;
        this.moduleStatus = module?.version?.status;
        if (
          this.currentModule &&
          this.lastModuleAlias != this.currentModule.alias
        ) {
          /*  this.moduleStatusInterval = interval(15000).subscribe(() => {
               this.updateStatus();
           }), */
          this.lastModuleAlias = this.currentModule.alias;
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          this.presentReadOnlyDialog();
        }
      }),
    );
    this.subscription.add(
      this.projectService.currentProject.subscribe((currentProject: Project) => {
        if (currentProject) {
          this.ebpStatus = currentProject != null ? currentProject.status : null;
          this.ebpId = currentProject != null ? currentProject.id : null;
        }
      }),
    );

    this.subscription.add(
      this.moduleService.projectModules.subscribe(modules => {
        this.projectModules = modules;

        if (this.projectModules && this.projectModules.length) {
          this.activeModules = this.projectModules.filter(module => module.version && module.version.is_active);
        }
        
        if (modules && this.lastModuleAlias) {
          this.moduleStatus = modules.filter((m) => m.alias == this.lastModuleAlias)[0]?.version?.status;
        }
      }),
    );
  }

  ngAfterViewInit() {
    this.projectService.setTopBarCTAEnabled(true); //enable topbar cta
   // this.presentReadOnlyDialog();
  }

  presentReadOnlyDialog() {
    setTimeout(() => { //wait the user role to set first.
      if (this.currentUserRole && this.currentUserRole === ProjectUserRole.viewer && !localStorage.getItem('close_alert_read_only_message')) {
        this.configService.dialogConfig.subscribe(configs => {
          if (!configs) {
            return;
          }
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            dialog_key: 'alert_read_only',
          };
          this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((res) => {
              if (!res) {
                localStorage.setItem('close_alert_read_only_message', JSON.stringify(true));
              }
            });
        });
      }
    }, 800);
  }
 
  updateStatus() {
    if (!this.currentModule) {
      return;
    }
    this.projectService.getStepStatuses(this.currentProject.id, this.currentModule.alias).subscribe((response) => {

      this.currentModule.sections.map((section: Section) => {
          section.sets.map((set: Set) =>
            set.steps.map((step: Step) => !section.repeat_section ?
              step.status = response.data.steps[step.name]
              : step.status = response.data.steps,
            ),
          );
          return section;
        },
      );
      this.currentModule.version.status = response.data.status;
      this.moduleService.setCurrentModule(this.currentModule);
      if (this.projectModules) {
        this.projectModules.map((projectModule) => {
          if (projectModule.alias === this.currentModule.alias) {
            projectModule.version.status = response.data.status;
          }
          return projectModule;
        });
        this.moduleService.setProjectModules(this.projectModules);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    //this.moduleStatusInterval.unsubscribe();
    this.moduleService.setCurrentModule(null); // reset the title on top bar
  }

  trackByFn(index, item) {
    return item.name;
  }

  
  isPrevModuleDisabled() {
    let currentModuleIndex = this.activeModules.findIndex((module) => module.alias == this.currentModule.alias);
    let prevModule = this.activeModules[currentModuleIndex - 1];

    return !prevModule ? true : false;
  }

  isNextModuleDisabled() {
    let currentModuleIndex = this.activeModules.findIndex((module) => module.alias == this.currentModule.alias);
    let nextModule = this.activeModules[currentModuleIndex + 1];

    return !nextModule ? true : false;
  }

  prevModule() {
    if (this.activeModules && this.activeModules.length > 0) {
      let currentModuleIndex = this.activeModules.findIndex((module) => module.alias == this.currentModule.alias);
      let prevModule = this.activeModules[currentModuleIndex - 1];

      if (prevModule)
        this.router.navigate(['/projects', this.currentProject.id, prevModule.alias]);
    }
  }

  nextModule() {
    if (this.activeModules && this.activeModules.length > 0) {
      let currentModuleIndex = this.activeModules.findIndex((module) => module.alias == this.currentModule.alias);
      let nextModule = this.activeModules[currentModuleIndex + 1];

      if (nextModule)
        this.router.navigate(['/projects', this.currentProject.id, nextModule.alias]);
    }
  }

}

