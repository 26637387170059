<div class="project-detail-step">
  <div class="content">
    <table class="table ebp-table" *ngIf="targetData">
      <thead>
      <tr>
        <ng-container *ngFor="let header of component.headers; let i = index">
          <th [width]="header.width"
              [ngClass]="{'text-right': i > 0}">{{header.label | translate | replacePlaceholder:currentProject.replace}}</th>
        </ng-container>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td *ngFor="let column of component.rows.columns">
          <div [ngSwitch]="column.name" class="text-right">
                            <span *ngSwitchCase="'year'" class="text-muted">
                                {{targetData[component.mode].total[currentProject.year] | mask:'separator.2':','}} {{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}
                            </span>
                            <span *ngSwitchCase="'year-1'" class="text-muted">
                                {{targetData[component.mode].total[currentProject.year-1] | mask:'separator.2':','}} {{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}
                            </span>
            <span *ngSwitchCase="'evol'" class="text-muted">
                                {{targetData[component.mode].total.evol}} %
                            </span>
            <span *ngSwitchDefault>
                                {{column.label | translate}}
                            </span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
