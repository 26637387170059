<div class="retailers-container">
  <div class="retailers-container--header">
    <shared-module-icon [type]="'settings'" [status]="null"></shared-module-icon>

    <div class="divider"></div>
    <h1 class="retailers-container--header_title">{{'CMS_RETAILERS_TITLE' | translate}}</h1>
  </div>
</div>
<section class="module-section ng-tns-c566-2 is-collapsed is-fullwidth">
  <div class="project-detail-step">
    <kendo-grid [data]="filteredRetailers"
                [filter]="state.filter"
                [filterable]="true"
                [sortable]="{
                    allowUnsort: true,
                    mode: 'single'
                  }"
                (sortChange)="sortChange($event)"
                [sort]="state.sort"
                [columnMenu]="true"
                (filterChange)="filterChange($event)"
                (dataStateChange)="dataStateChange($event)"
                (cellClick)="cellClickHandler($event)"
                (cellClose)="cellCloseHandler($event)"
                [pageSize]="state.take"
                [pageable]="{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true
                  }"
                [skip]="state.skip"
                (pageChange)="pageChange()"
                *ngIf="defaultConfig">
      <ng-template kendoGridToolbarTemplate>
        <!--        <div class="clear-filters-container">-->
        <!--          <input type="button" class="k-button" value="Clear Filters" (click)="clearFilters()"/>-->
        <!--        </div>-->
      </ng-template>
      <ng-container *ngFor="let columnSet of defaultConfig.column_sets">
        <ng-container *ngFor="let columnSubSet of columnSet.sets">

          <ng-container *ngFor="let actColumn of columnSubSet.columns;let i = index;">
            <kendo-grid-column [field]="actColumn.property" [headerClass]="'grid-header'"
                               [footerStyle]="{'padding-bottom':'11px;','padding-top':'11px;'}"
                               [sortable]="actColumn.sortable"
                               [width]="actColumn.width || '120'"
                               [filterable]="actColumn.filter">

              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">{{actColumn.label | translate}}
              </ng-template>

              <ng-container [ngSwitch]="actColumn.type">

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'coverImage'">
                  <img class="img-thumbnail upload-logo" [src]="dataItem.logos.coverImage ? logoBaseUrl + '/' + dataItem.logos.coverImage : '/assets/icons/add.svg'" />
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'coverImageBordered'">
                  <img class="img-thumbnail upload-logo" [src]="dataItem.logos.coverImageBordered ? logoBaseUrl + '/' + dataItem.logos.coverImageBordered : '/assets/icons/add.svg'" />
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'coverImageSlimCentered'">
                  <img class="img-thumbnail upload-logo" [src]="dataItem.logos.coverImageSlimCentered ? logoBaseUrl + '/' + dataItem.logos.coverImageSlimCentered : '/assets/icons/add.svg'" />
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'coverImageSlimLeft'">
                  <img class="img-thumbnail upload-logo" [src]="dataItem.logos.coverImageSlimLeft ? logoBaseUrl + '/' + dataItem.logos.coverImageSlimLeft : '/assets/icons/add.svg'" />
                </ng-template>

                <!--
                  <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'detail_button'">
                    <span class="material-icons cursor-pointer" (click)="openModal(dataItem)">info</span>
                  </ng-template>
                -->

                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'switch'">
                  <mat-slide-toggle [ngModel]="dataItem.is_active" (click)="isActiveToggle(dataItem); $event.preventDefault();"></mat-slide-toggle>
                </ng-template>
              </ng-container>
            </kendo-grid-column>
          </ng-container>
        </ng-container>
      </ng-container>

      <kendo-grid-column-group *ngIf="noActiveColumn()" [headerClass]="'grid-header grid-main-header'">
        <kendo-grid-column [field]="" [headerClass]="'grid-header'" width="4000"></kendo-grid-column>
      </kendo-grid-column-group>

    </kendo-grid>

  </div>
</section>
