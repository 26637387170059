import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Events } from '../../../shared/enums/events';
import { EventsService } from '../../../shared/services/events.service';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'project-progress-sidebar',
  templateUrl: './progress-sidebar.component.html',
  styleUrls: [ './progress-sidebar.component.scss' ],
})
export class ProgressSidebarComponent implements OnInit, OnDestroy {

  public currentProject: Project;
  public currentModules: Module[];
  public currentModule: Module;
  private subscription: Subscription = new Subscription();
  projectId: number;

  constructor(private projectService: ProjectService,
              private moduleService: ModuleService,
              private eventsService: EventsService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.pipe(
      tap((params) => {
        if (!params) {
          return;
        }
        this.projectId = params.id;
        this.subscription.add(
          this.projectService.currentProject.subscribe(project => {
            if (!project || !project?.id) {
              return;
            }

            this.currentProject = project;
            if (this.currentProject) {
              this.subscription.add(
                this.moduleService.projectModules.subscribe(modules => {
                  this.currentModules = modules;
                  if (this.currentProject && !this.currentProject.catalogue_country && this.currentModules) {
                    this.currentModules = this.currentModules.filter((module) => module && module.alias != 'selection');
                  }
                }),
              );
            }
          }),
        );
      }),
    ).subscribe();

    this.subscription.add(
      this.eventsService.on(Events.UPDATE_CURRENT_MODULE_DATA).subscribe((currentModule) => {
        this.currentModules.map((module) => {
          if (module.alias === currentModule.alias) {
            module.version = currentModule.version;
            module.progress = currentModule.progress;
          }
        });
      }),
    );

    this.subscription.add(
      this.eventsService.on(Events.UPDATE_ACTIVATION_MODULE_DATA).subscribe((currentModule) => {
        this.currentModules.map((module) => {
          if (module.alias === currentModule.alias) {
            module.version = currentModule.version;
            module.progress = currentModule.progress;
            module.version.status = 'edited'; //hard coded as we get null may be back end issue.
          }
        });
      }),
    );

    this.subscription.add(
      this.moduleService.currentModule.subscribe(
        (currentModule) => {
          this.currentModule = currentModule;
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
