import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { TextReplacePipe } from 'src/app/shared/pipes/text-replace.pipe';
import { ProjectService } from 'src/app/shared/services/project.service';
import { TermsAndConditionsService } from '../../services/terms-and-conditions.service';

@Component({
  selector: 'terms-condition-total-table',
  templateUrl: './terms-condition-total-table.component.html',
  styleUrls: ['./terms-condition-total-table.component.scss'],
})
export class TermsConditionTotalTableComponent implements OnInit, OnDestroy {
  private subscription  = new Subscription();

  constructor(
    private tocService: TermsAndConditionsService,
    private projectService: ProjectService,
    private textReplace: TextReplacePipe
  ) {}

  @Input()
  component: StepComponent;

  currentProject: Project;

  data: any;

  ngOnInit(): void {
    this.subscription.add(
    this.projectService.currentProject.subscribe((p) => {
      if (p) {
        this.currentProject = p;
        this.component.headers.forEach((header) => {
          header.label = header.label.replace('{{YEAR}}', p.year);
        });
      }
    })
    );
    this.subscription.add(
      this.tocService.tocData.subscribe((data) => {
        this.data = data;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  units(type) {
    switch (type) {
      case 'currency':
        return this.textReplace.transform('{{CURRENCY_SYMBOL}}', this.currentProject.replace);
      case 'percentage':
        return "%";
      default:
        return "";
    }
  }
}
