<div kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
	<div [title]="tooltip ? tooltip : ''">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" 
        viewBox="0 0 24 24" width="24px" fill="#000000" [ngClass]="{'has-tooltip': tooltip}"
			version="1.1" id="icon-info" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
			xml:space="preserve" (mouseover)="showTooltip = true" (mouseout)="showTooltip = false">
        <g><rect fill="none" height="24" width="24"/></g><g>
            <path d="M11,21h-1l1-7H7.5c-0.88,0-0.33-0.75-0.31-0.78C8.48,10.94,10.42,7.54,13.01,3h1l-1,7h3.51c0.4,0,0.62,0.19,0.4,0.66 C12.97,17.55,11,21,11,21z"/>
        </g></svg>
	</div>
</div>