<div class="shared-date-picker" *ngIf="currentProject">
    <mat-form-field [class.disabled]="disabled" appearance="standard">
        <input matInput class="custom-calendar-icon"
               [disabled]="disabled"
               [matDatepicker]="picker" [(ngModel)]="date" (ngModelChange)="onDateChange($event)"  [matDatepickerFilter]="myFilter" [min]="minDate" [max]="maxDate">
        <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon class="material-icons-sharp custom-date-toggle"> date_range</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
