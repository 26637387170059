import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacePlaceholder',
})
export class ReplacePlaceholderPipe implements PipeTransform {

  transform(value: string, placeHolders: { [key: string]: string }): string {

    if (!value || !placeHolders) return null;

    let response: string = value;

    try {
      for (const [key, val] of Object.entries(placeHolders)) {
        response = response?.replace(key, val);
      }
    } catch (e) {
      console.error('Translation error when translating: ' + JSON.stringify(response) + " Error: " + e);
    }

    return response;
  }

}
