<div class="project-detail-step">
    <table class="table ebp-table reset-table">
        <thead>
            <tr>
                <th class="sticky-element" width="240">{{'M_ACTIVATION_CONFIGURATOR_TOOL_CAMPAIGN_NAME' | translate}}</th>
                <th class="sticky-element" width="140">{{'M_ACTIVATION_CONFIGURATOR_TOOL_BRANDS_AND_CATEGORIES' | translate}}</th>
                <th class="sticky-element" width="100">{{'M_SELECTION_VIRTUAL_BUNDLES_VIRTUAL_BUNDLE_LAUNCH_DATE' | translate}}</th>
                <th class="sticky-element" width="100">{{'M_ACTIVATION_CONFIGURATOR_TOOL_ACTIVATION_PERIOD' | translate}}</th>
                <th class="sticky-element"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let activation of activations; let i = index">
                <ng-container *ngIf="activation.status">

                    <tr class="activation-row">
                        <td id="activation-{{i}}">
                            <ng-container *ngIf="!activation.sku_set.mono_from_grid">
                                <div class="sku-bold-title">{{'M_SELECTION_SKUSET_TYPE_VIRTUAL_BUNDLE_CAMPAIGN' | translate }}</div>
                                <mat-form-field class="pt-2">
                                    <input matInput autocomplete="off" [placeholder]="'M_ACTIVATION_CONFIGURATOR_TOOL_CAMPAIGN_NAME_PLACEHOLDER' | translate" [(ngModel)]="activation.sku_set.name" (change)="onNameChange(activation, i)" autofocus>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="activation.sku_set.mono_from_grid">
                                <div class="sku-bold-title">{{'M_SELECTION_SKUSET_TYPE_HERO_SKU' | translate }}</div>
                                <div class="sku-header">{{activation.sku_set.products[0].name }}</div>
                            </ng-container>
                        </td>
                        <td class="brands">
                            {{ brandsAndCategories(activation.sku_set)}}
                        </td>
                        <td>
                            <shared-date-picker
                                                [(date)]="activation.sku_set.launch_at"
                                                [disabled]="activation.sku_set.mono_from_grid"
                                                (changed)="onDateChange($event, activation)" *ngIf="activation.id"></shared-date-picker>
                        </td>
                        <td>
                            <shared-daterange-picker
                                                      [startDate]="activation?.activationPeriod?.startDate"
                                                     [appearanceNone]="true"
                                                     [disabled]="true"
                                [endDate]="activation?.activationPeriod?.endDate" *ngIf="activation.id">
                            </shared-daterange-picker>
                        </td>
                        <td>
                            <shared-exclude-icon class="lighter-bg" *ngIf="((currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor) && activation.id" (onAlertProceed)="removeActivation(i)"
                                                 [alert]="activation.sku_set.mono_from_grid ? 'mono_from_grid_activation_deletion_confirmation':'alert_activation_deletion'">
                            </shared-exclude-icon>
                        </td>
                    </tr>
                    <tr class="table-content">
                        <td colspan="5" class="no-padding" *ngIf="activation.id" >
                    
                            <ng-container *ngIf="!activation.sku_set.mono_from_grid">
                                <generic-toggle-table [featureType]="'brands'" [brands]="getFeatureIds(activation.brands)"
                                    (onChange)="onFeatureChange('brands', $event, activation)" [isCollapsed]="activation.brandsCollapsed"
                                    (onCollapseChange)="onCollapseChange(activation, $event, 'brand')">
                                </generic-toggle-table>
                            
                                <generic-toggle-table [featureType]="'categories'" [categories]="getFeatureIds(activation.product_categories)"
                                    (onChange)="onFeatureChange('categories', $event, activation)" [isCollapsed]="activation.categoriesCollapsed"
                                    (onCollapseChange)="onCollapseChange(activation, $event, 'categories')">
                                </generic-toggle-table>
                            
                            
                                <generic-toggle-table [featureType]="'sub_categories'"
                                    [sub_categories]="getFeatureIds(activation.product_sub_categories)"
                                    (onChange)="onFeatureChange('sub_categories', $event, activation)"
                                    [isCollapsed]="activation.subCategoriesCollapsed"
                                    (onCollapseChange)="onCollapseChange(activation, $event, 'subcategories')">
                                </generic-toggle-table>
                            </ng-container>
                            <bundler-product-list [selectedProducts]="activation.sku_set.products"
                                                  [normalItem]="true"
                                                  [isMono]="activation.sku_set.mono_from_grid"
                                (productsSelected)="onProductsSelected($event, activation)" (onCollapseChange)="onCollapseChange(activation, $event, 'products')"
                                [isCollapsed]="activation.productsCollapsed"></bundler-product-list>

                            <activation-calendar
                                [year]="currentProject.year"
                                [disableAddActivity]="(currentUserRole$ | async) !== PROJECT_USER_ROLE.owner && (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor"
                                [activation]="activation"
                                (activitiesChanged)="onActivitiesChanged()"
                            [isCollapsed]="activation.calendarCollapsed"
                            [activeMonth]="component?.answer?.collapsedState[activation.id]?.navigation || 0"
                            (onCollapseChange)="onCollapseChange(activation, $event, 'calendar')"></activation-calendar>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <tr class="table-add-item">
                <td colspan="5">
                    <shared-add-button *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor" [label]="'M_ACTIVATION_CONFIGURATOR_TOOL_ADD_CAMPAIGN' | translate" (click)="addActivation()"></shared-add-button>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr class="table-footer">
                <td colspan="5" align="right">
                    {{ activationLength() }} item<span *ngIf="activationLength() != 1">s</span>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
