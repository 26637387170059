import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { User } from './shared/models/user.model';
import { AuthenticationService } from './shared/services/authentication.service';
import { ConfigService } from './shared/services/config.service';
import { GenericHttpService } from './shared/services/generic-http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit {
  title = 'eJBP 0.1';

  user$: Observable<User> = this.authService.currentUser;
  languages: any;
  hideSidebar = false;

  constructor(
    private configService: ConfigService,
    private authService: AuthenticationService,
    public translate: TranslateService,
    private router: Router,
    private http: GenericHttpService,
  ) {
    this.authService.setUser();

  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideSidebar = !!event.url.includes('projects');
      }
    });
    this.translate.setDefaultLang('en');
    this.configService.getDialogConfig();

    this.http.get('/languages').subscribe((response) => {
      const langIds = response.data.languages.map(lang => lang.id);
      this.languages = response.data.languages;
      this.translate.addLangs(langIds);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    });

  }

}
