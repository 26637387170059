import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subscription } from 'rxjs';
import { ModuleService } from 'src/app/project/services/module.service';
import { Project } from 'src/app/shared/models/project.model';
import { ConfigService } from 'src/app/shared/services/config.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { environment } from '../../../../environments/environment';
import { DashboardFilters } from '../../../shared/enums/dashboardFilters';
import { DashboardLayoutType } from '../../../shared/enums/dashboardLayoutType';
import { DashboardType } from '../../../shared/enums/dashboardType';
import { Events } from '../../../shared/enums/events';
import { DashboardContainerModel } from '../../../shared/models/dashboardContainer.model';
import { DashboardFilterContainerModel } from '../../../shared/models/dashboardFilterContainer.model';
import { User } from '../../../shared/models/user.model';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { EventsService } from '../../../shared/services/events.service';
import { HttpCancelService } from '../../../shared/services/httpCancel.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription = new Subscription();
  user$: Observable<User> = this.authService.currentUser;
  years: number[];
  dashboardInfoKey = 'dashboardInfo';
  filtersRawData = {
    zones: [],
    clusters: [],
    divisions: [],
    retailers: [],
  };

  shouldShowCreateButton: boolean;
  message: any;
  hideWelcomeContainer = localStorage.getItem('hideWelcomeMessage') || false;
  filtersData = new Map<DashboardFilters, DashboardFilterContainerModel>(
    [
      [
        DashboardFilters.ZONES,
        {
          type: 'singleSelect',
          order: 1,
          filterKey: 'zone',
          label: 'DASHBOARD_FILTER_ZONE',
          filterAllLabel: 'DASHBOARD_FILTER_ZONE_ALL',
          data: [],
        },
      ],
      [
        DashboardFilters.CLUSTERS,
        {
          type: 'singleSelect',
          order: 2,
          label: 'DASHBOARD_FILTER_COUNTRY',
          filterAllLabel: 'DASHBOARD_FILTER_COUNTRY_ALL',
          filterKey: 'country',
          data: [],
        },
      ],
      [
        DashboardFilters.DIVISIONS,
        {
          type: 'multiSelect',
          order: 3,
          label: 'DASHBOARD_FILTER_DIVISION',
          filterAllLabel: 'DASHBOARD_FILTER_DIVISION_ALL',
          filterKey: 'division',
          data: [],
        },
      ],
      [
        DashboardFilters.RETAILERS,
        {
          type: 'singleSelect',
          order: 4,
          label: 'DASHBOARD_FILTER_RETAILER',
          filterAllLabel: 'DASHBOARD_FILTER_RETAILER_ALL',
          filterKey: 'retailer',
          data: [],
        },
      ],
    ],
  );
  loading: { [key in DashboardType]: boolean } = {
    normal: true,
    validation: true,
    best_practice: true,
  };
  pageSizes: number[] = [ 6, 9, 12 ];
  containers = new Map<DashboardType, DashboardContainerModel>([
    [
      DashboardType.NORMAL,
      {
        title: 'DASHBOARD_SECTION_EBPS',
        dataKey: DashboardType.NORMAL,
        filters: {},
        pagination: {},
        data: [],
        order: 1,
        layout: DashboardLayoutType.NORMAL,
        filtersData: {},
      },
    ],
    [
      DashboardType.VALIDATION,
      {
        title: 'DASHBOARD_SECTION_EBPS_TOBEVALIDATED',
        dataKey: DashboardType.VALIDATION,
        filters: {},
        pagination: {},
        data: [],
        order: 2,
        layout: DashboardLayoutType.NORMAL,
        filtersData: {},
      },
    ],
    [
      DashboardType.BEST_PRACTICE,
      {
        title: 'DASHBOARD_SECTION_EBPS_BESTPRACTICES',
        dataKey: DashboardType.BEST_PRACTICE,
        filters: {},
        pagination: {},
        data: [],
        order: 3,
        layout: DashboardLayoutType.NORMAL,
        filtersData: {},
      },
    ],
  ]);
  loadedEbpModules: any = {};
  DASHBOARD_TYPE = DashboardType;
  DASHBOARD_FILTERS = DashboardFilters;
  DASHBOARD_LAYOUTS = Object.values(DashboardLayoutType);

  private savedFilters = {};

  constructor(
    private projectService: ProjectService,
    private httpCancelService: HttpCancelService,
    private moduleService: ModuleService,
    private configService: ConfigService,
    private authService: AuthenticationService,
    private cookieService: CookieService,
    private eventsService: EventsService,
  ) { }

  ngOnInit(): void {
    const dashboardInfo = JSON.parse(localStorage.getItem(this.dashboardInfoKey));
    if (dashboardInfo) {
      this.containers = new Map(dashboardInfo);
    }
    this.shouldShowCreateButton = !environment.production;
    this.projectService.isLoading.subscribe(state => this.loading = state);

    this.savedFilters = this.loadSavedFilters();

    this.projectService.getYears().subscribe(response => {
      this.years = Object.keys(response.data.years).map(x => +x).sort().reverse();

      const defaultYear = this.years[0];

      this.projectService.setCurrentProject(null);
      this.moduleService.setCurrentModule(null);

      this.subscription.add(
        this.projectService.projects.subscribe(
          (items) => {
            for (const dashboardType in items) {
              const type = DashboardType[dashboardType.toUpperCase()];
              if (this.loading[type]) {
                const container = this.containers.get(type);
                this.loadEbpModules(items[container.dataKey].projects);
                const pagesObject = this.generatePages(items[container.dataKey].pagination, container.pagination);
                this.containers.set(container.dataKey, {
                  ...container,
                  data: items[container.dataKey].projects,
                  pagination: pagesObject,
                });
              }
            }
          },
        ),
      );

      this.loadFiltersData();

      this.containers.forEach((value, key) => {

        const filters = (this.savedFilters && this.savedFilters[key]) ? this.savedFilters[key] : {year: defaultYear};
        this.projectService.loadProjects({
          ...filters,
          page: 1,
          per_page: value.pagination?.perPage || this.pageSizes[0],
        }, key);

        this.containers.set(key, {
          ...value,
          filtersData: this.filtersData,
          filters,
        });
      });

      this.configService.dialogConfig.subscribe(config => {
        if (config) {
          this.message = config.filter(c => c.name == 'dashboard_welcome')[0];
        }
      });
    });

    this.projectService.setTopBarCTAEnabled(false);
    this.subscription.add(
      this.eventsService.on(Events.SCROLL_TO_BEST_PRACTICE).subscribe(() => {
        this.navigateToBestPractice();

      }),
    );
  }

  ngAfterViewInit(): void {
    const stepId = localStorage.getItem('dashboard-container');
    if (stepId && stepId.length) {
      this.navigateToBestPractice();
    }
  }

  navigateToBestPractice() {
    localStorage.removeItem('dashboard-container');

    setTimeout(() => {
      const el = document.getElementById('best_practice');
      if (el) {
        const y = el.getBoundingClientRect().top + window.pageYOffset - 20;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.httpCancelService.cancelPendingRequests();
    this.subscription.unsubscribe();
  }

  hide() {
    localStorage.setItem('hideWelcomeMessage', 'true');
    this.hideWelcomeContainer = true;
  }

  removeProject(projectId: any, type: DashboardType) {
    const container = this.containers.get(type);
    const filteredProjects = container.data.filter((project: Project) => project.id !== projectId);
    this.containers.set(type, {...container, data: filteredProjects});

    this.refreshDashboard(type);
  }

  async loadFiltersData() {
    // tslint:disable-next-line:forin
    for (const entityName in DashboardFilters) {
      this.subscription.add(
        this.configService.getEntity(DashboardFilters[entityName]).subscribe(
          (response) => {
            this.filtersRawData[DashboardFilters[entityName]] = response.data[DashboardFilters[entityName]];
            const entityData = this.filtersData.get(DashboardFilters[entityName]);
            entityData.data = response.data[DashboardFilters[entityName]];
            this.filtersData.set(DashboardFilters[entityName], entityData);
          },
        ),
      );
    }
  }

  changeLayout(dashboardType: DashboardType, type: DashboardLayoutType) {
    const dashboardContainer = this.containers.get(dashboardType);
    this.containers.set(dashboardType, {...dashboardContainer, layout: type});
    this.saveContainersInfo();
  }

  filterUpdate(dashboardType: DashboardType, filterKey: string) {

    const container = this.containers.get(dashboardType);
    const containerPagination = container.pagination;
    const containerFilters: any = container.filters;
    const containerFiltersData = container.filtersData;

    for (filterKey in containerFilters) {
      if (!containerFilters[filterKey]
        || Array.isArray(containerFilters[filterKey]) && !containerFilters[filterKey].length) {
        if (filterKey === 'zone') {
          const clustersContainer = containerFiltersData.get(DashboardFilters.CLUSTERS);
          clustersContainer.data = this.filtersRawData.clusters;
          containerFiltersData.set(DashboardFilters.CLUSTERS, clustersContainer);
        }
        delete containerFilters[filterKey];
        this.containers.set(dashboardType, {...container, filters: containerFilters});
      }
    }
    this.projectService.loadProjects({
      ...containerFilters,
      per_page: containerPagination.perPage,
      page: 1,
    }, dashboardType);

    if (filterKey === 'zone' && containerFilters[filterKey]) {
      const zoneId = containerFilters.zone;
      const filteredClusters = this.filtersRawData.clusters.filter(cluster => cluster.zone_id === zoneId);
      const clustersContainer = containerFiltersData.get(DashboardFilters.CLUSTERS);
      clustersContainer.data = filteredClusters;
      containerFiltersData.set(DashboardFilters.CLUSTERS, clustersContainer);
    }
    this.containers.set(dashboardType, {...container, filtersData: containerFiltersData});
    this.saveFilters(dashboardType, containerFilters);
  }

  changePage(dashboardType: DashboardType, page: number, perPage?: number) {
    if (this.loading[dashboardType]) {
      return;
    }
    const container = this.containers.get(dashboardType);
    const containerPagination = container.pagination;
    const containerFilters = container.filters;

    this.projectService.loadProjects({
      ...containerFilters,
      page,
      per_page: perPage ? perPage : containerPagination.perPage,
    }, dashboardType);

    this.containers.set(dashboardType, {
      ...container,
      pagination: {
        ...containerPagination,
        currentPage: page,
        perPage: perPage ? perPage : containerPagination.perPage,
      },
    });
    if (perPage) {
      this.saveContainersInfo();
    }
  }

  refreshDashboard(dashboardType: DashboardType) {
    if (this.loading[dashboardType]) {
      return;
    }

    const container = this.containers.get(dashboardType);
    const containerPagination = container.pagination;
    const containerFilters = container.filters;
    const page = container.pagination.currentPage;

    this.projectService.loadProjects({
      ...containerFilters,
      page,
      per_page: containerPagination.perPage,
    }, dashboardType);

    this.containers.set(dashboardType, {
      ...container,
      pagination: {
        ...containerPagination,
        currentPage: page,
        perPage: containerPagination.perPage,
      },
    });
  }

  generatePages(pagination: any, containerPagination) {
    return {
      perPage: !containerPagination.perPage ? this.pageSizes[0] : containerPagination.perPage,
      lastPage: pagination.last_page,
      total: pagination.total,
      currentPage: pagination.current_page,
      numbers: Array.from(Array(pagination.last_page).keys()),
    };
  }

  saveFilters(dashboardType: DashboardType, values: any) {
    this.savedFilters[dashboardType] = values;
    localStorage.setItem('saved_filters', JSON.stringify(this.savedFilters));
  }

  loadSavedFilters() {
    if (localStorage.getItem('saved_filters') != null) {
      return JSON.parse(localStorage.getItem('saved_filters'));
    }
    return {};
  }

  loadEbpModules(ebps: any[]) {
    ebps.forEach((ebp) => {
      if (!this.loadedEbpModules[ebp.id]) {
        this.subscription.add(
          this.projectService.loadEbpModules(ebp.id).subscribe(modules => {
            this.loadedEbpModules[ebp.id] = modules.data.modules;
          }),
        );
      }
    });
  }

  saveContainersInfo() {
    localStorage.setItem(this.dashboardInfoKey, JSON.stringify(Array.from(this.containers.entries())));
  }
}
