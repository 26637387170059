import { EventsService } from './../../../shared/services/events.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Events } from 'src/app/shared/enums/events';
import { Module } from 'src/app/shared/models/module.model';
import { ModuleService } from '../../services/module.service';

@Component({
  selector: 'project-module-management',
  templateUrl: './module-management.component.html',
  styleUrls: ['./module-management.component.scss'],
})
export class ModuleManagementComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private moduleService: ModuleService,
    private eventsService: EventsService
  ) { }

  public currentModule: Module;

  ngOnInit(): void {
    this.subscription.add(
      this.moduleService.currentModule.subscribe(
        (module) => {
          this.currentModule = module;
        }
      )
    );
  }
  

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
