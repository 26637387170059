import { EventsService } from './../../../shared/services/events.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Brand } from 'src/app/shared/models/brand.model';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { Step } from 'src/app/shared/models/step.model';
import { User } from 'src/app/shared/models/user.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ProjectUserRole } from '../../../shared/enums';
import { ModuleService } from '../../services/module.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Events } from 'src/app/shared/enums/events';

@Component({
  selector: 'project-step-title',
  templateUrl: './step-title.component.html',
  styleUrls: ['./step-title.component.scss'],
})
export class StepTitleComponent implements OnInit, OnDestroy {
  @Input()
  step: Step;

  @Input()
  brand: Brand;

  @Input()
  staticTitle: string;

  @Input()
  ebpStatus: string;

  @Input() stepStatus: string;
  public title: string;
  public showTooltip: boolean = false;
  public user: User;

  private module: Module;
  private project: Project;
  private subscription: Subscription = new Subscription();
  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  currentUserRole: any;
  PROJECT_USER_ROLE = ProjectUserRole;

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private dialog: MatDialog,
    private eventsService: EventsService
  ) { }

  ngOnInit(): void {
    if (this.staticTitle) {
      return;
    }

    this.subscription.add(
      this.currentUserRole$.subscribe((userRole) => {
        this.currentUserRole = userRole;
      }),
    );

    this.subscription.add(
      this.projectService.currentProject.subscribe(p => this.project = p),
    );
    this.subscription.add(
      this.moduleService.currentModule.subscribe(m => this.module = m),
    );
    this.subscription.add(
      this.userService.currentUser.subscribe(u => this.user = u),
    );

    this.title = this.brand
      ? this.step.title.replace('{{brand_name}}', this.brand.name + ' Opera (Salsify) ')
      : this.step.title;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmitStep() {
    this.step.is_saving = true;
    this.updateStepStatus('submit').toPromise().then(response => {
      if (response && response.status == 'success') {
        if (response.data && response.data.ebp_status && response.data.ebp_status && response.data.ebp_status.dialog === 'last_step_submit_ebp') {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            dialog_key: 'last_step_submit_ebp',
            currentProject: this.project,
            replaceText: {
              'term': 'VALIDATOR',
              'value': (this.project.validator && this.project.validator.display_name) ? this.project.validator.display_name : null
            }
          };

          this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.projectService.updateStatus(this.project, 'submit').toPromise().then(response => {

                  if (response && response.status == "success") {
                    const dialogConfig = new MatDialogConfig();

                    dialogConfig.data = {
                      dialog_key: 'submit_ebp_success',
                      replaceText: {
                        'term': 'VALIDATOR',
                        'value': (this.project.validator && this.project.validator.display_name) ? this.project.validator.display_name : null
                      }
                    };

                    this.dialog
                      .open(ConfirmDialogComponent, dialogConfig)
                      .afterClosed()
                      .subscribe((result) => {
                        this.updateStatusOnCTAUpdate(response.data.ebp.id);
                      });
                  }
                });
              } else {
                this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
              }

            });
        } else {
          this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
        }
      }
    });
  }

  onEditStep() {

    if (this.ebpStatus === 'validated') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        dialog_key: 'editing_a_validated_ebp_will_make_it_edited'
      };

      this.dialog
        .open(ConfirmDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.step.is_saving = true;
            this.updateStepStatus('edit').toPromise().then(response => {
              if (response && response.status == 'success') {
                this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
              }
            });
          }
        });
    } else {

      this.step.is_saving = true;
      this.updateStepStatus('edit').toPromise().then(response => {
        if (response && response.status == 'success') {
          this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
        }
      });

    }
  }

  updateStatusOnCTAUpdate(ebp_id: number) {
    
    this.moduleService.loadModulesForProject(ebp_id); // Refresh rejection count on top bar 
    this.moduleService.loadCurrentModule(ebp_id, this.module.alias).subscribe(response => { 
      this.eventsService.setValue(Events.UPDATE_CURRENT_MODULE_DATA, response.data.module); //refresh step status
    });
    this.projectService.refreshCurrentProject(ebp_id).subscribe(response => {
      this.eventsService.setValue(Events.UPDATE_CURRENT_PROJECT_STATUS, response.data.ebp);  //refresh ebp status
    });
    this.step.is_saving = false;
  }

  onExcludeOrInclude() {
    const data = {
      exclude: !this.step.exclude,
    };
    this.updateStep(data);
  }

  validateStep() {
    this.step.is_saving = true;
    this.updateStepStatus('validate').toPromise().then(response => {
      if (response && response.status == 'success') {
        if (response.data && response.data.ebp_status && response.data.ebp_status && response.data.ebp_status.dialog === 'last_step_validate_ebp') {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            dialog_key: 'last_step_validate_ebp',
            currentProject: this.project,
            replaceText: {
              'term': 'VALIDATOR',
              'value': (this.project.validator && this.project.validator.display_name) ? this.project.validator.display_name : null
            }
          };

          this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.projectService.updateStatus(this.project, 'validate').toPromise().then(response => {
                  if (response && response.status == "success") {
                    const dialogConfig = new MatDialogConfig();

                    dialogConfig.data = {
                      dialog_key: 'validate_ebp_success',
                      replaceText: {
                        'term': 'OWNER',
                        'value': (this.project.owner && this.project.owner.display_name) ? this.project.owner.display_name : null
                      }
                    };

                    this.dialog
                      .open(ConfirmDialogComponent, dialogConfig)
                      .afterClosed()
                      .subscribe((result) => {
                        this.updateStatusOnCTAUpdate(response.data.ebp.id);
                      });
                  }
                });
              } else {
                this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
              }

            });
        } else {
          this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
        }
      }
    });
  }

  rejectStep() {
    this.step.is_saving = true;
    this.updateStepStatus('reject').toPromise().then(response => {
      if (response && response.status == 'success') {
        this.updateStatusOnCTAUpdate(response.data.step.ebp_id);
      }
    });
  }

  updateStep(data: any) {
    this.moduleService.saveOrCreateStep(this.project.id, this.module.alias, this.step, data, false).subscribe(response => {
      this.moduleService.getCurrentModule(this.project.id, this.module.alias);
      this.moduleService.loadModulesForProject(response.data.step.ebp_id); // Refresh project sidebar progress
      this.moduleService.setStepIsSaving(false);
    });
  }

  updateStepStatus(status) {
    return this.moduleService.updateStepStatus(this.step, status,
      {
        ebp_id: this.project.id,
        module_alias: this.module.alias,
        step_name: (this.brand && this.brand.slug) ? this.step.name.replace('{{brand_alias}}', this.brand.slug) : this.step.name,
      },
    );
  }

}
