<article class="ebp-cover" *ngIf="currentProject">
  <header>
    <h1 class="ebp-title">eBP {{currentProject.year}}</h1>
  </header>
  <div class="ebp-parties">
    <div class="ebp-party" [ngStyle]="{ 'background-image': 'url(' + logoBaseUrl + '/' + currentProject.retailer.alias + '.jpg?' + timestamp + ')' }">
    </div>
    <div *ngIf="currentProject.divisions?.length == 1; else multiDiv" class="ebp-party" [ngStyle]="{'background-image': 'url(/assets/logos/loreal_'+ currentProject?.divisions[0]?.code+'.jpg)'}"></div>
    <ng-template #multiDiv><div class="ebp-party" [ngStyle]="{'background-image': 'url(/assets/logos/loreal.jpg)'}"></div></ng-template>
  </div>
  <shared-ebp-info [currentProject]="currentProject"></shared-ebp-info>

  <button class="ebp-button-start" (click)="openLatestActiveModule()">
    {{'EBP_START' | translate}}
  </button>
  <shared-status-icon [status]="currentProject?.status" [width]="44"></shared-status-icon>

  <app-project-status [project]="currentProject"></app-project-status>

  <div class="ebp-modules" *ngIf="modules">
    <shared-module-card *ngFor="let module of modules" [module]="module"></shared-module-card>
  </div>
</article>

<!-- Management Section -->
<project-section [section]="coverConfig.sections[0]" [ebpId]="currentProject?.id" *ngIf="coverConfig && coverConfig.sections[0]"></project-section>
