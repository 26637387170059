<div class="project-detail-step">
  <table class="table ebp-table ebp-small-table reset-table">
    <thead>
    <tr>
      <th class="sticky-element" *ngFor="let header of component.headers; let i = index" [width]="header.width"
          [attr.colspan]="i == 0 ? '2' : '1'">
        {{header.label | translate | replacePlaceholder:currentProject.replace}}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let section of sections">
      <tr class="subheader">
        <th [attr.colspan]="component.headers.length + 1">{{section.name}}</th>
      </tr>
      <ng-container *ngFor="let subrow of component.sub_rows">
        <tr class="has-spacer-cell">
          <td width="10"></td>
          <td [attr.colspan]="component.headers.length">
            <mat-slide-toggle [(ngModel)]="component.answer.values[section.id][subrow.name].active"
                              (change)="onToggleChange(section.id, subrow.name)">
              {{subrow.label | translate}}</mat-slide-toggle>
          </td>
        </tr>
        <ng-container *ngIf="component.answer.values[section.id][subrow.name].active">
          <tr *ngFor="let subsubrow of subrow.sub_sub_rows" class="has-spacer-cell">
            <td width="10"></td>
            <ng-container *ngFor="let header of component.headers">
              <td *ngIf="!header.name">{{subsubrow.label | translate}}</td>
              <td *ngIf="header.name" class="text-right">
                <mat-form-field appearance="standard">
                  <input type="text" matInput mask="separator.3" thousandSeparator="," autocomplete="off"
                         [allowNegativeNumbers]="true"
                         [(ngModel)]="component.answer.values[section.id][subrow.name][subsubrow.name][header.name]"
                         (ngModelChange)="onInputChange(component.answer.values[section.id][subrow.name][subsubrow.name], header.name)">
                  <span matSuffix>&nbsp;%</span>
                </mat-form-field>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    </tbody>
  </table>
</div>
