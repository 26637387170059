import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
})
export class RadioInputComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { values: { selected: null, other: null } };
    }
  }

  onValueChange() {
    this.onChange.emit();
  }
}
