import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderById',
})
export class OrderByIdPipe implements PipeTransform {

  transform<T>(elements: T[]): T[] {
    return elements.sort((a: any, b: any) => a.id - b.id);
  }

}
