import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfigService } from '../../../shared/services/config.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: [ './user-list.component.scss' ],
})
export class UserListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @ViewChildren('permissionSwitchers') permissionSwitchers!: QueryList<any>;

  activeColumns = {};
  users: any[] = [];
  permissionFiltersChecked = false;
  filter: CompositeFilterDescriptor;
  permissionValues = [ 'comm', 'global', 'acd', 'cpd', 'lld', 'ppd' ];
  permissionInputModels = {};
  filteredUsers: GridDataResult;
  defaultConfig: any;
  state: State = {
    take: 20,
    skip: 0,
    sort: [],
  };

  constructor(private configService: ConfigService,
              private userService: UserService,
              public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.configService.activitiesGridConfig.subscribe((config) => {
        if (config) {
          this.setConfig(config);
        } else {
          this.configService.getUserListGridConfig();
        }
      }),
    );

    this.loadUsers();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.filteredUsers = process(this.users, this.state);
  }

  pageChange() {
    this.loadUsers();
  }

  setConfig(config: any) {
    config.column_sets.forEach((columnSet) => {
      this.activeColumns[columnSet.name] = config.active_columnsets.includes(
        columnSet.name,
      );
    });
    this.defaultConfig = config;
    this.defaultConfig.show_columnset_toggles = true;
  }

  noActiveColumn() {
    let activeValues = Object.values(this.activeColumns);
    activeValues = activeValues.filter(
      (item, index) => activeValues.indexOf(item) === index,
    );
    return activeValues.length === 1 && activeValues[0] === false;
  }

  loadUsers() {
    this.configService.getEntity('users_list').subscribe(response => {
      this.users = this.modifyData(response.data.users);
      this.filteredUsers = process(this.users, this.state);
      this.filteredUsers = {
        data: this.users.slice(
          this.state.skip,
          this.state.skip + this.state.take,
        ),
        total: this.users.length,
      };
    });
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  switchChange(checked: boolean, field: string): void {
    const root = this.filter || {logic: 'and', filters: []};
    const [ filter ] = this.flatten(root).filter(x => x.field === field);

    if (!filter) {
      root.filters.push({
        field,
        operator: 'eq',
        value: checked,
      });
    } else {
      filter.value = checked;
    }
    this.filter = root;
    this.filteredUsers = process(this.users, {
        ...this.state,
        filter: root,
      },
    );
  }

  modifyData(users: any[]) {
    return users.map((user) => {
      const permissions = {};
      this.permissionValues.forEach((permissionKey) => {
        permissions[permissionKey] = user.permissions.indexOf(permissionKey) > -1;
      });
      user.mappedPermissions = permissions;
      return user;
    });
  }

  flatten = filter => {
    const filters = filter.filters;
    if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? this.flatten(curr) : [ curr ]), []);
    }
    return [];
  };

  clearFilters() {
    for (const permissionFilterKey in this.permissionInputModels) {
      this.permissionInputModels[permissionFilterKey] = false;
    }
    this.filter = {logic: 'and', filters: []};
    this.state.filter = this.filter;
    this.filteredUsers = process(this.users, this.state,
    );
  }

  superAdminToggle(item: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'alert_superadmin_toggle',
    };

    this.dialog
    .open(ConfirmDialogComponent, dialogConfig)
    .afterClosed()
    .subscribe((toggle) => {
      if (toggle) {
        this.userService.toggleSuperadmin(item.id).subscribe((res) => {
          item.is_superadmin = !item.is_superadmin;
        });
      }
    });
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    this.state.take = 20;
    this.state.skip = 0;
    this.filteredUsers = {
      data: orderBy(this.users, this.state.sort),
      total: this.users.length,
    };
  }
}
