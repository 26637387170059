import { Activity } from './activity';

export class ActivationEvent {
  id: number;
  activity?: Activity;
  activity_id?: number;
  activation_id?: number;
  starts_at?: Date;
  ends_at?: Date;
  activityName?: string;
  startMonth?: string;
  startDay?: string;
  endMonth?: string;
  endDay?: string;
}
