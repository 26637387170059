import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isString } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSource: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  API_URL: string = environment.api.base_url;

  constructor(
    public http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {
    this.currentUserSource = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSource.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSource.value;
  }

  login() {
    console.log('AuthService: login');
    this._get('/auth/login').subscribe((response) => {
      console.log('AuthService: response', response);
      if (response.status == 'success') {
        this.setUser();
      } else {
        window.location = response.data.login_url;
      }
    });
  }

  logout() {
    // Todo: Probably Api Call to logout in addition to remove local token
    this.cookieService.delete('ebpUser');
    this.cookieService.delete('ebpToken');
    this.currentUserSource.next(null);
    this.router.navigate([ '/' ]);
  }

  setUser() {
    var cookie = this.cookieService.get('ebpUser');
    if (!cookie) {
      return false;
    }
    var user: User = JSON.parse(unescape(cookie));
    for (let userKey in user) {
      if (isString(user[userKey])) {
        user[userKey] = user[userKey].replace(/\+/gi, ' ');
      }
    }
    user.token = this.cookieService.get('ebpToken');
    this.currentUserSource.next(user);
    return user;
  }

  private _get = (path: string, options?: any): Observable<any> => {
    let response = new Subject();

    this.http.post(this.API_URL + path, options).subscribe(
      (success) => {
        response.next(success);
      },
      (error) => {
        response.error(error);
      },
    );

    return response;
  };
}
