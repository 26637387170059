import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericHttpService } from './generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(private http: GenericHttpService) { }

  private productGridConfigSource = new BehaviorSubject<any>(null);
  public productGridConfig = this.productGridConfigSource.asObservable();
  productGridConfigRequested: boolean = false;

  private activitiesGridConfigSource = new BehaviorSubject<any>(null);
  public activitiesGridConfig = this.activitiesGridConfigSource.asObservable();
  activitiesGridConfigRequested = false;

  private importReportsGridConfigSource = new BehaviorSubject<any>(null);
  public importReportsGridConfig = this.importReportsGridConfigSource.asObservable();
  importReportsGridConfigRequested = false;

  private retailersGridConfigSource = new BehaviorSubject<any>(null);
  public retailersGridConfig = this.retailersGridConfigSource.asObservable();
  retailersGridConfigRequested = false;

  private translationsGridConfigSource = new BehaviorSubject<any>(null);
  public translationsGridConfig = this.translationsGridConfigSource.asObservable();
  translationsGridConfigRequested = false;

  private dialogConfigSource = new BehaviorSubject<any>(null);
  public dialogConfig = this.dialogConfigSource.asObservable();

  private coverConfigSource = new BehaviorSubject<any>(null);
  public coverConfig = this.coverConfigSource.asObservable();

  private defaultServiceOptionsSource = new BehaviorSubject<any>(null);
  public defaultServiceOptions = this.defaultServiceOptionsSource.asObservable();

  public isLoadingDefaultServiceOptions: boolean = false;

  public getProductGridConfig() {
    if (!this.productGridConfigRequested) {
      this.productGridConfigRequested = true;
      this.getConfig('product_grid').subscribe((response) => {
        this.productGridConfigSource.next(response['data'].product_grid);
      });
    }
  }

  public getActivitiesGridConfig() {
    if (!this.activitiesGridConfigRequested) {
      this.activitiesGridConfigRequested = true;
      this.getConfig('activities_grid').subscribe((response) => {
        this.activitiesGridConfigSource.next(response['data'].activities_grid);
      });
    }
  }

  public getUserListGridConfig() {
    if (!this.activitiesGridConfigRequested) {
      this.activitiesGridConfigRequested = true;
      this.getConfig('users_grid').subscribe((response) => {
        this.activitiesGridConfigSource.next(response['data'].users_grid);
      });
    }
  }

  public getImportReportsLogGridConfig() {
    if (!this.importReportsGridConfigRequested) {
      this.importReportsGridConfigRequested = true;
      this.getConfig('import_reports').subscribe((response) => {
        this.importReportsGridConfigSource.next(response['data'].import_reports);
      });
    }
  }
  public getRetailersGridConfig() {
    if (!this.retailersGridConfigRequested) {
      this.retailersGridConfigRequested = true;
      this.getConfig('retailers').subscribe((response) => {
        this.retailersGridConfigSource.next(response['data'].retailers);
      });
    }
  }

  public getTranslationsGridConfig() {
    if (!this.translationsGridConfigRequested) {
      this.translationsGridConfigRequested = true;
      this.getConfig('translations_grid').subscribe((response) => {
        this.translationsGridConfigSource.next(response['data'].translations_grid);
      });
    }
  }

  public getDialogConfig() {
    this.getConfig('dialog_texts').subscribe((response) => {
      this.dialogConfigSource.next(response['data'].dialog_texts);
    });
  }

  public getCoverConfig() {
    this.getConfig('ebp_cover').subscribe((response) => {
      this.coverConfigSource.next(response['data'].ebp_cover);
    });
  }

  public loadDefaultServiceOptions() {
    if (this.isLoadingDefaultServiceOptions) {
      return;
    }
    this.isLoadingDefaultServiceOptions = true;
    this.getConfig('services_default_options').subscribe(response => {
      this.defaultServiceOptionsSource.next(response.data.services_default_options);
      this.isLoadingDefaultServiceOptions = false;
    });
  }

  public getConfig(filename: string): Observable<any> {
    return this.http.get('/config/' + filename);
  }

  public getEntity(entity: string, query?: any): Observable<any> {
    const searchParams = new URLSearchParams(query);
    return this.http.get(`/${entity}?${searchParams}`);
  }
}
