import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'shared-module-icon',
  templateUrl: './module-icon.component.html',
  styleUrls: ['./module-icon.component.scss']
})
export class ModuleIconComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  status: string;

  constructor() { }

  ngOnInit(): void {
  }

}
