<div class="project-detail" *ngIf="config && project">
  <main>
    <article class="project-new">
      <header class="main-header ebp-creator">
        <h1 class="title" *ngIf="!isEditmode">{{'CREATE_EBP_TITLE' | translate}}</h1>
        <h1 class="title" *ngIf="isEditmode">{{'CREATE_EBP_EDIT_SETTINGS_TITLE' | translate}}</h1>

        <!--   <label class="m-top" *ngIf="isEditmode">{{'EBP_COVER_CREATED_BY' | translate}} {{ project.created_by }}, {{
          project.created_at | date: 'd MMM, y' }}
        </label> -->
        <shared-ebp-info [currentProject]="project"></shared-ebp-info>
      </header>

      <section class="section" [ngClass]="{ 'section-border-none': section?.hide_bottom_line}"
               *ngFor="let section of config.sections"
               [hidden]="(section.name === 'additional_data' && !currentUser.is_superadmin) || (section.name === 'zone_and_cluster' && ebpType == ebpTypes.GLOBAL)">
        <ng-container *ngIf="section.name === 'additional_data' && !currentUser.is_superadmin;else allowedSections">

        </ng-container>
        <ng-template #allowedSections>

          <header>
            <h2 class="subtitle" *ngIf="section?.title">{{section.title | translate}}</h2>
          </header>
          <ng-container *ngFor="let set of section.sets">
            <p class="section-text" *ngIf="section?.text">{{section?.text | translate}}</p>
            <div *ngFor="let step of set.steps" class="step">
              <ng-container *ngFor="let component of step.components">

                <ng-container [ngSwitch]="component.type">

                  <div class="ebp-owner" *ngSwitchCase="'ebp_owner'">
                    <label>{{component.mini_label | translate}}</label>
                    <ng-container *ngIf="project.owner;else currentUserOwner">
                      <p>
                        <span>{{project.owner.given_name}} {{project.owner.family_name}}</span>
                        <span *ngIf="project.owner?.country?.name_native"> -
                          {{project.owner?.country?.name_native}}</span>
                        <span *ngIf="project.owner.title"> - {{project.owner.title}}</span>
                      </p>

                    </ng-container>
                    <ng-template #currentUserOwner>
                      <p>
                        <span>{{currentUser.given_name}} {{currentUser.family_name}}</span>
                        <span *ngIf="project.owner?.country?.name_native"> -
                          {{project.owner?.country?.name_native}}</span>
                        <span *ngIf="currentUser.title"> - {{currentUser.title.replace('+', ' ')}}</span>
                      </p>
                    </ng-template>
                  </div>

                  <div *ngSwitchCase="'ebp_title'">
                    <div class="float-left">
                      <p class="ebp-title">
                        <span *ngIf="project.zone">{{project.zone.name | ebpTitle}}_</span>
                        <span class="placeholder" *ngIf="!project.zone">Zone&hellip;</span>
                        <span *ngIf="project.legal_country">{{project.legal_country.name_en | ebpTitle }}_</span>
                        <span class="placeholder" *ngIf="!project.legal_country">Country&hellip;</span>
                        <span *ngIf="project.divisions">{{project.divisions | ebpTitle: "code" }}_</span>
                        <span class="placeholder" *ngIf="!project.divisions">Division&hellip;</span>
                        <span *ngIf="project.retailer">{{project.retailer.name | ebpTitle}}_</span>
                        <span class="placeholder" *ngIf="!project.retailer">Retailer&hellip;</span>
                        <span *ngIf="project.year">{{project.year}}</span>
                        <span class="placeholder" *ngIf="!project.year">Year</span>.ebp
                      </p>
                    </div>

                    <div class="project-status float-left">
                      <shared-status-icon [status]="project.status" [width]="33"></shared-status-icon>
                    </div>
                  </div>

                  <div *ngSwitchCase="'ebp_type'">
                    <div style="margin-top:15px;">
                      <mat-radio-group [(ngModel)]="ebpType" [disabled]="isEditmode" (ngModelChange)="ebpTypeChange()">
                        <div class="option-group" *ngFor="let option of component.options">
                          <mat-radio-button [value]="option.key">
                            <span *ngIf="option.label">
                              {{option.label | translate}}
                            </span>
                          </mat-radio-button>
                        </div>
                      </mat-radio-group>
                    </div>
                  </div>


                  <div *ngSwitchCase="'zone_and_cluster_selection'">
                    <div *ngIf="ebpType != ebpTypes.GLOBAL">
                      <ng-container *ngFor="let zoneItem of ebpZoneAndCountry;let i = index;">

                        <ng-container *ngIf="ebpType === ebpTypes.MULTI_COUNTRY && ebpZoneAndCountry.length > 1">
                          <div class="d-flex align-items-center multi_country_mtop parent-exclude"
                               [ngClass]="{'m-diff': i > 0}">
                            <span>{{'CREATE_EBP_COUNTRY_ORDER_TITLE' | translate}} {{i + 1}}</span>
                            <shared-exclude-icon [tooltip]="'asd'" class="ma-left"
                                                 *ngIf="!isEditmode && ebpZoneAndCountry.length > 2"
                                                 (onAlertProceed)="deleteZoneAndCountry(i)"></shared-exclude-icon>
                          </div>
                        </ng-container>

                        <mat-form-field appearance="standard">
                          <mat-label>{{component.zone_placeholder | translate}}</mat-label>
                          <mat-select [disabled]="isEditmode" [(ngModel)]="zoneItem.zone"
                                      (ngModelChange)="zoneChanged($event,zoneItem)" required>

                            <mat-option>
                              <ngx-mat-select-search #searchTextZone
                                                     noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                     placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option [disabled]="ebpType != ebpTypes.ZONE && zone.status == 'offline'" [value]="zone"
                                        *ngFor="let zone of zones | orderBy: 'name' | filter:searchTextZone.value">
                              {{ zone.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="!zoneItem.zone">{{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="standard" *ngIf="zoneItem.zone && ebpType != ebpTypes.ZONE">
                          <mat-label>{{component.cluster_placeholder | translate}}</mat-label>
                          <mat-select [disabled]="isEditmode" [(ngModel)]="zoneItem.clusterId"
                                      (ngModelChange)="clusterChanged($event,zoneItem)" required>

                            <mat-option>
                              <ngx-mat-select-search #searchTextCluster
                                                     noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                     placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option [disabled]="cluster.status == 'offline'" [value]="cluster.id"
                                        *ngFor="let cluster of zoneItem.clustersData | orderBy: 'name' | filter:searchTextCluster.value">
                              {{ cluster.name }}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="!zoneItem.cluster">{{requiredErrMsg?.labels?.text_1 | translate}}
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="standard" *ngIf="zoneItem.cluster && ebpType != ebpTypes.ZONE">
                          <mat-label>{{component.country_placeholder | translate}}</mat-label>
                          <mat-select [disabled]="isEditmode" [(ngModel)]="zoneItem.countryId"
                                      (ngModelChange)="countryChanged($event,zoneItem)" required>

                            <mat-option>
                              <ngx-mat-select-search #searchTextCountry
                                                     noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                     placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                            </mat-option>

                            <mat-option [disabled]="country.status == 'offline' || isCountryTaken(country)"
                                        [value]="country.id"
                                        *ngFor="let country of zoneItem.cluster.countries | orderBy: 'name_en' | filter:searchTextCountry.value">
                              {{ country.name_en }}</mat-option>
                          </mat-select>

                          <mat-error *ngIf="!zoneItem.country">{{requiredErrMsg?.labels?.text_1 | translate}}
                          </mat-error>
                        </mat-form-field>

                      </ng-container>

                      <div *ngIf="ebpType === ebpTypes.MULTI_COUNTRY && !isEditmode" class="add-zone-country">
                        <shared-add-button [label]="'CREATE_EBP_ADD_EXTRA_COUNTRY' | translate"
                                           (click)="addNewZoneAndCountry()">
                        </shared-add-button>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'ebp_catalogue'">
                    <mat-form-field appearance="standard" *ngIf="catalogueCountries && catalogueCountries.length">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [disabled]="isEditmode && hasCatalogueSet" [(ngModel)]="project.catalogue_country_id"
                                  (ngModelChange)="catalogueCountryChanged($event)">
                        <mat-option>
                          <ngx-mat-select-search #searchTextCatalogue
                                                 noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [value]="null">-</mat-option>
                        <mat-option [value]="country.id"
                                    *ngFor="let country of catalogueCountries  | orderBy: 'name_en' | filter:searchTextCatalogue.value">
                          {{ country.name_en }}</mat-option>
                      </mat-select>

                    </mat-form-field>

                    <label class="error-chips mat-error"
                           *ngIf="!catalogueCountries || !catalogueCountries.length">{{component?.still_empty_placeholder |
                        translate}}</label>

                  </div>

                  <div *ngSwitchCase="'ebp_legislation'">
                    <mat-form-field appearance="standard"
                                    *ngIf="selectedClusterCountries && selectedClusterCountries.length">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [disabled]="isEditmode" [(ngModel)]="project.legal_country_id"
                                  (ngModelChange)="countryChanged($event)" required>

                        <mat-option>
                          <ngx-mat-select-search #searchTextLegislation
                                                 noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="country.id"
                                    *ngFor="let country of selectedClusterCountries  | orderBy: 'name_en' | filter:searchTextLegislation.value">
                          {{ country.name_en }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="!project.legal_country">{{requiredErrMsg?.labels?.text_1 | translate}}
                      </mat-error>
                    </mat-form-field>

                    <label class="error-chips mat-error"
                           *ngIf="!selectedClusterCountries || !selectedClusterCountries.length">{{component?.still_empty_placeholder
                        | translate}}</label>

                  </div>

                  <div *ngSwitchCase="'ebp_currency'">
                    <mat-form-field appearance="standard">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [disabled]="project.catalogue_country_id" [(ngModel)]="project.currency_id" required>
                        <mat-option>
                          <ngx-mat-select-search #searchTextCurrency
                                                 noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>
                        <ng-container
                            *ngFor="let currency of currencies  | orderBy: 'code' | filter:searchTextCurrency.value">
                          <mat-option [value]="currency.code" *ngIf="currency.is_enabled">
                            {{currency.code | uppercase}} – {{ currency?.name }} – {{ currency?.symbol }}</mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error *ngIf="!project.currency_id">{{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>
                    </mat-form-field>
                    <!-- <label class="error-chips mat-error" *ngIf="!currencies || !currencies.length">{{component?.still_empty_placeholder | translate}}</label> -->
                  </div>

                  <div *ngSwitchCase="'ebp_language'">
                    <mat-form-field appearance="standard">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [disabled]="isEditmode" [(value)]="project.language_id" required>
                        <mat-option>
                          <ngx-mat-select-search #searchTextLanguage
                                                 noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="language.id"
                                    *ngFor="let language of languages | orderBy: 'name_en' | filter:searchTextLanguage.value">
                          {{ language.name_en }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="!project.language_id">{{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'ebp_partner'">
                    <mat-form-field appearance="standard" *ngIf="retailers && retailers.length">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [disabled]="isEditmode" [(ngModel)]="project.retailer_id" required
                                  (ngModelChange)="onRetailerChange($event)">
                        <mat-option>
                          <ngx-mat-select-search #searchTextRetailer
                                                 noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="retailer.id"
                                    *ngFor="let retailer of retailers | orderBy: 'name' | filter:searchTextRetailer.value">
                          {{retailer.name}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="!project.retailer">{{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>
                    </mat-form-field>

                    <label class="error-chips mat-error"
                           *ngIf="!retailers || !retailers.length">{{component?.still_empty_placeholder | translate}}</label>
                  </div>

                  <div *ngSwitchCase="'ebp_division_and_brands'">
                    <mat-label>{{component.division_placeholder | translate}}</mat-label>
                    <fieldset>
                      <div class="ebp-chips">
                        <button *ngFor="let division of divisions" (click)="toggleDivision(division)" class="ebp-chip"
                                [ngClass]="{'is-selected' : division.is_selected}">{{division.code}}</button>

                        <mat-error class="mat-error error-chips" *ngIf="!project.divisions?.length">
                          {{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>
                      </div>
                    </fieldset>

                    <div class="division-toggle mt-4 mb-3">
                      <mat-slide-toggle class="switcher"
                                        [checked]="entireDivisionIsSelected"
                                        (change)="entireDivisionToggle( $event)">
                        <span>{{"CREATE_EBP_FULL_DIVISIONS" | translate}}</span>
                      </mat-slide-toggle>
                      <p class="mt-4 error-chips">{{"CREATE_EBP_FULL_DIVISIONS_TEXT" | translate}}</p>

                    </div>
                    <div class="mt-2" [@collapse]="!project.divisions?.length" *ngIf="!entireDivisionIsSelected">
                      <mat-label>{{component.brands_placeholder | translate}}</mat-label>
                      <div class="ebp-chips">
                        <button *ngFor="let brand of availableBrands" (click)="toggleBrand(brand)" class="ebp-chip"
                                [ngClass]="{'is-selected' : brand.is_selected}">{{brand.name}}</button>
                      </div>
                      <mat-error class="mat-error error-chips" *ngIf="!project.brands?.length">
                        {{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>

                      <label class="error_label" *ngIf="project.brands?.length == brandSelectionLimit">
                        {{component?.brand_selection_limit_reached | translate}}</label>

                    </div>
                  </div>

                  <div *ngSwitchCase="'ebp_year'">
                    <mat-form-field appearance="standard">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [disabled]="isEditmode" [(ngModel)]="project.year" (ngModelChange)="ebpYearChange()"
                                  required>
                        <mat-option>
                          <ngx-mat-select-search #searchTextYear noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>

                        <ng-container *ngFor="let year of years ">
                          <mat-option [value]="year" *ngIf="year && year.toString().startsWith(searchTextYear.value)">
                            {{ year }}
                          </mat-option>
                        </ng-container>
                      </mat-select>
                      <mat-error *ngIf="!project.year">{{requiredErrMsg?.labels?.text_1 | translate}}</mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'ebp_validator'">
                    <mat-form-field appearance="standard">
                      <mat-label>{{component.placeholder | translate}}</mat-label>
                      <mat-select [(ngModel)]="project.validator_id" required
                                  (ngModelChange)="validatorChanged($event)">
                        <mat-option>
                          <ngx-mat-select-search #searchTextValidator
                                                 noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                 placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="validatorUser.id" [disabled]="isValidatorTaken(validatorUser)"
                                    *ngFor="let validatorUser of validatorUsers | orderBy: 'display_name' | filter:searchTextValidator.value">
                          {{validatorUser?.display_name}}<span
                            *ngIf="validatorUser?.display_name && validatorUser?.country?.name_native"> -
                          </span>{{validatorUser?.country?.name_native}}<span
                            *ngIf="validatorUser?.country?.name_native && validatorUser?.title"> -
                          </span>{{validatorUser?.title}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="!project.validator_id">{{requiredErrMsg?.labels?.text_1 | translate}}
                      </mat-error>
                    </mat-form-field>
                    <!--  <shared-add-button class="invite_block" [label]="'CREATE_EBP_INVITE_VALIDATOR' | translate">
                    </shared-add-button> -->
                  </div>

                  <div *ngSwitchCase="'ebp_is_best_practice'">
                    <mat-checkbox [(ngModel)]="project.is_best_practice">
                      {{component.label | translate}}
                    </mat-checkbox>
                  </div>

                  <div *ngSwitchCase="'ebp_modules'">

                    <p class="placeholder-module">{{'CREATE_EBP_MODULES_PLACEHOLDER' | translate }}</p>
                    <div class="whole-container" *ngFor="let module of modules"
                         [class.mask]="!isModuleSelected(module)">
                      <div class="module-container">
                        <div class="module-header-container">
                          <span>{{module.name | translate}}</span>
                        </div>
                        <div class="module-icon-input">
                          <div class="module-card"
                               [ngClass]="{'is-online' : isModuleOnline(module) , 'is-selected' : isModuleSelected(module)}">
                            <div class="icon-box">
                              <shared-module-icon [type]="module.alias" [status]="'card'">
                              </shared-module-icon>
                            </div>
                          </div>
                          <div class="controls-container">
                            <div class="controls">
                              <div class="control-item" kendoTooltip tooltipClass="kendoTooltipOverride"
                                   position="right">
                                <mat-slide-toggle class="activity-switcher" [disabled]="!isModuleOnline(module)"
                                                  [checked]="isModuleSelected(module)"
                                                  (change)="toggleModule(module, $event)"
                                                  [title]="'EBP_MODULE_SWITCH_TEXT' | translate">
                                </mat-slide-toggle>
                              </div>
                              <div class="control-item slider-p" *ngIf="isModuleOnline(module)"
                                   [class.mask]="!module.progress">
                                <project-module-progressbar [progress]="module?.progress"></project-module-progressbar>
                              </div>
                              <div class="control-item" *ngIf="isModuleOnline(module)" [class.mask]="!module.version">
                                <shared-status-icon [status]="module?.version?.status"
                                                    [width]="33"></shared-status-icon>
                              </div>
                            </div>
                            <div class="status" *ngIf="isModuleOnline(module)">
                              <div class="status-item"><span
                                  *ngIf="isModuleSelected(module)">{{'EBP_MODULE_ACTIVE' | translate}}</span> <span
                                  *ngIf="!isModuleSelected(module)">{{'EBP_MODULE_INACTIVE' | translate}}</span></div>
                              <div class="status-item" [class.mask]="!module.progress">
                                <span class="title">{{'CREATE_EBP_STEPS_PROGRESS' | translate}} </span>
                                <span
                                    *ngIf="module.progress">{{module?.progress?.edited + module?.progress?.submitted + module?.progress?.rejected}}
                                  <span> {{'CREATE_EBP_STEPS_PROGRESS_OF' | translate}} </span>{{module?.progress?.total}}</span>
                                <span *ngIf="!module.progress"> - </span>
                              </div>
                              <div class="status-item" [class.mask]="!module.version">
                                <span class="title">{{'CREATE_EBP_MODULE_STATUS' | translate}} </span>
                                <span class="uppercase"
                                      *ngIf="module?.version?.status">{{module?.version?.status}}</span>
                                <span class="uppercase"
                                      *ngIf="module.version && !module.version.status">{{'STATUS_INPROGRESS' | translate}}</span>
                                <span *ngIf="!module.version"> - </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="!isModuleOnline(module)">
                        <div class="mt-4">
                          <span class="mat-error error-chips" *ngIf="entireDivisionIsSelected"> {{'EBP_MODULE_UNAVAILABLE_BECAUSEOF_FULLDIVISIONEBP_TEXT' | translate}}</span>
                          <span class="mat-error error-chips" *ngIf="!entireDivisionIsSelected"> {{'EBP_MODULE_UNAVAILABLE_BECAUSEOF_MISSINGCATALOGUE_TEXT' | translate}}</span>
                          
                        </div>
                      </div>
                      <div class="module-label-text" *ngIf="isModuleSelected(module)">
                        <span>{{'CREATE_EBP_EDITORS_TEXT' | translate}}</span></div>

                      <div class="select-container" *ngIf="isModuleSelected(module)">
                        <div *ngFor="let ebpEditor of ebpEditors;let i = index;">
                          <div class="editor-container parent-exclude" *ngIf="ebpEditor.module.alias == module.alias">
                            <mat-form-field appearance="standard">
                              <mat-label>{{'CREATE_EBP_EDITOR_PLACEHOLDER' | translate}}</mat-label>

                              <mat-select [(ngModel)]="ebpEditor.editorId">

                                <mat-option>
                                  <ngx-mat-select-search #searchTextEditor
                                                         noEntriesFoundLabel="{{'NO_MATCH_FOUND' | translate}}"
                                                         placeholderLabel="{{'SEARCH' | translate}}"></ngx-mat-select-search>
                                </mat-option>

                                <mat-option [value]="user.id" [disabled]="isEditorTaken(user, module)"
                                            *ngFor="let user of editorUsers | orderBy: 'display_name' | filter:searchTextEditor.value">
                                  {{user?.display_name}}<span *ngIf="user?.display_name && user?.country?.name_native"> -
                                  </span>{{user?.country?.name_native}}<span
                                    *ngIf="user?.country?.name_native && user?.title"> -
                                  </span>{{user?.title}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <shared-exclude-icon (onAlertProceed)="removeEditor(i)" [alert]="'alert_editor_deletion'">
                            </shared-exclude-icon>
                          </div>
                        </div>
                      </div>

                      <div class="add-editor" *ngIf="isModuleSelected(module)">
                        <shared-add-button [label]="'CREATE_EBP_EDITOR_ADD_NEW'| translate"
                                           (click)="addNewEditor(module)">
                        </shared-add-button>
                      </div>

                    </div>

                    <mat-error *ngIf="!project.modules?.length">{{requiredErrMsg?.labels?.text_1 | translate}}
                    </mat-error>
                  </div>

                  <div *ngSwitchCase="'cta'">
                    <button class="ebp-button-submit" (click)="createProject()"
                            [disabled]="isFormInvalid() || saveLoading">
                      <span *ngIf="!project.id">{{'DASHBOARD_CTA_CREATE_NEW_EBP' | translate}}</span>
                      <span *ngIf="project.id">{{'CREATE_EBP_SAVE_EBP_SETTINGS' | translate}}</span>
                    </button>
                  </div>

                  <!-- <debug [title]="'Component'" [content]="component"></debug> -->
                </ng-container>

              </ng-container>
            </div>
          </ng-container>
        </ng-template>

      </section>
      <!-- <debug [title]="'Project Details'" [content]="project"></debug> -->
    </article>
  </main>
</div>

<ng-container *ngIf="isLoading">
  <div class="spinner">
    <mat-spinner [strokeWidth]="4" [diameter]="200"></mat-spinner>
  </div>
</ng-container>

<ng-container *ngIf="saveLoading"> <!-- called when the settings are saved -->
  <div class="spinner-dark">
    <mat-spinner [strokeWidth]="4" [diameter]="200"></mat-spinner>
  </div>
</ng-container>

