import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectUserRole } from '../../enums';
import { StepComponent } from '../../models/step-component.model';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'shared-input-repeater',
  templateUrl: './input-repeater.component.html',
  styleUrls: ['./input-repeater.component.scss'],
})
export class InputRepeaterComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { values: [""] };
    }
  }

  addItem() {
    this.component.answer.values.push('');
  }

  removeItem(index){
    this.component.answer.values.splice(index, 1);
    this.update();
  }


  onValueChange(data: any, index: number) {
    this.component.answer.values[index] = data.target.value
    this.update()
  }

  update(){

    this.onChange.emit();
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
