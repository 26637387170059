import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activation } from 'src/app/project/entities/activation';
import { Activity } from 'src/app/project/entities/activity';
import { ActivationEvent } from 'src/app/project/entities/activation-event';
import { ActivityService } from 'src/app/project/services/activity.service';
import * as moment from 'moment';

@Component({
  selector: 'activation-calendar',
  templateUrl: './activation-calendar.component.html',
  styleUrls: ['./activation-calendar.component.scss'],
})
export class ActivationCalendarComponent implements OnInit {
  constructor(private activityService: ActivityService) {}

  @Input()
  year: number;

  @Input()
  disableAddActivity: boolean;

  @Input()
  activation: Activation;

  @Output()
  activitiesChanged = new EventEmitter<ActivationEvent[]>();

  months: Array<Date> = [];

  @Input()
  activeMonth: number = 0;

  @Output()
  onNavigationChange = new EventEmitter<number>();

  @Input()
  isCollapsed: boolean = false;

  @Output()
  onCollapseChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    for (let i = 0; i < 12; i++) {
      var date = new Date(this.year, i, 1);
      this.months.push(date);
    }
    this.activation.activation_events.forEach(e => {
      e.starts_at = new Date(e.starts_at);
      e.ends_at = new Date(e.ends_at)
    })
  }

  navigateMonth(diff: number) {
    this.activeMonth = this.activeMonth + diff;
    this.onNavigationChange.emit(this.activeMonth);
  }

  addActivationEventForMonth(date: Date) {
    const monthDate = moment(date);
    const monthStart = new Date(monthDate.startOf('month').format('YYYY-MM-DD hh:mm'));
    const monthEnd = new Date(monthDate.endOf('month').format('YYYY-MM-DD hh:mm'));

    var activationEvent = new ActivationEvent();
    activationEvent.activity = new Activity();
    activationEvent.starts_at = monthStart;
    activationEvent.ends_at = monthEnd;
    this.activation.activation_events.push(activationEvent);
  }

  onActivityChange($event: Activity) {
    console.log('onActivityChange', $event);
    // this.activitiesChanged.emit(this.activities);
  }

  onEventChange(){
    this.activitiesChanged.emit()
  }

  collapse(){
    this.isCollapsed = !this.isCollapsed;
    this.onCollapseChange.emit(this.isCollapsed)
  }

}
