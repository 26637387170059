import { ConfigService } from './../../services/config.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'shared-inpage-step-dialog',
  templateUrl: './inpage-step-dialog.component.html',
  styleUrls: ['./inpage-step-dialog.component.scss']
})
export class InpageStepDialogComponent implements OnInit {
  @Output()
  onHideInPageDialog = new EventEmitter<any>();
  shouldShowDialog: boolean = true;

  @Input()
  dialog_key : any = null;

  public text: any;

  constructor(private configService : ConfigService){

  }

  ngOnInit(): void {
    this.configService.dialogConfig.subscribe(config => {
      if (config.filter(c => c.name == this.dialog_key).length == 0) this.dialog_key = "alert_deletion"
      this.text = config.filter(c => c.name == this.dialog_key)[0]
    })
  }



  hideDialog() {
    //this.shouldShowDialog = false;
    this.onHideInPageDialog.emit();
  }
}
