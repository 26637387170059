import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textReplace',
})
export class TextReplacePipe implements PipeTransform {
  
  transform(value: string, replaceables: Object): string {
    let response: string = value;
    for (const [key, value] of Object.entries(replaceables)) {
      response = response?.replace( key, value);
    }
    return response;
  }
}
