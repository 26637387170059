<div class="project-detail-step">
    <div class="content">
        <div class="columns">
            <div class="column">
                <h3>{{component.title | translate}}</h3>
                <p>{{component.comment |translate}}</p>
            </div>
            <div class="column">
                <mat-form-field apperance="standard">
                    <mat-label>{{component.placeholder_1 | translate}}</mat-label>
                    <input type="text"  matInput autocomplete="off" [(ngModel)]="component.answer.value[0]" [maxlength]="component.max_chars" (ngModelChange)="onValueChange()">
                </mat-form-field>
            </div>
            <div class="column" *ngIf="component.double">
                <mat-form-field>
                    <mat-label>{{component.placeholder_2 | translate}}</mat-label>
                    <input type="text"  matInput autocomplete="off" [(ngModel)]="component.answer.value[1]" [maxlength]="component.max_chars" (ngModelChange)="onValueChange()">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
