import { TranslateModule } from '@ngx-translate/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ProjectRoutingModule } from './project-routing.module';
import { ComponentTitleComponent } from './components/component-title/component-title.component';
import { StepTitleComponent } from './components/step-title/step-title.component';
import { TableInputComponent } from './components/table-input/table-input.component';
import { TableCatalogueComponent } from './components/table-catalogue/table-catalogue.component';
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectModuleComponent } from './components/project-module/project-module.component';
import { ProgressSidebarComponent } from './components/progress-sidebar/progress-sidebar.component';
import { ProjectCoverComponent } from './components/project-cover/project-cover.component';
import { SharedModule } from '../shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { ProjectStepComponent } from './components/project-step/project-step.component';
import { TableWithGraphComponent } from './components/table-with-graph/table-with-graph.component';

import { RadioWithChipsComponent } from './components/radio-with-chips/radio-with-chips.component';
import { TableStringListComponent } from './components/table-string-list/table-string-list.component';
import { ChartsModule } from 'ng2-charts';
import { ProjectSetComponent } from './components/project-set/project-set.component';
import { RadioListComponent } from './components/radio-list/radio-list.component';
import { ChipsListComponent } from './components/chips-list/chips-list.component';
import { SelectSimpleComponent } from './components/select-simple/select-simple.component';
import { MatSelectModule } from '@angular/material/select';
import { UpAndDownloaderComponent } from './components/up-and-downloader/up-and-downloader.component';

import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductGridComponent } from './components/product-grid/product-grid.component';
import { RadioNestedComponent } from './components/radio-nested/radio-nested.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

import { ToggleComponent } from './components/toggle/toggle.component';
import { ToggleInputComponent } from './components/toggle-input/toggle-input.component';
import { ToggleTableComponent } from './components/toggle-table/toggle-table.component';
import { ProjectSectionComponent } from './components/project-section/project-section.component';
import { ModuleManagementComponent } from './components/module-management/module-management.component';
import { ProjectManagementComponent } from './components/project-management/project-management.component';
import { TableTargetComponent } from './components/table-target/table-target.component';
import { ActivationTableComponent } from './components/bundler-components/activation-table/activation-table.component';
import { SkuSetTableComponent } from './components/bundler-components/skuset-table/skuset-table.component';
import { BundlerExtraOptionsComponent } from './components/bundler-components/sub-components/extra-options/extra-options.component';
import { BundlerProductListComponent } from './components/bundler-components/sub-components/product-list/product-list.component';
import { ServicesTableComponent } from './components/bundler-components/services-table/services-table.component';
import { DigitalShelfComponent } from './components/bundler-components/digital-shelf/digital-shelf.component';
import { InputWithCommentComponent } from './sub-components/input-with-comment/input-with-comment.component';
import { RadioInputComponent } from './sub-components/radio-input/radio-input.component';
import { TermsConditionTableComponent } from './sub-components/terms-condition-table/terms-condition-table.component';
import { CustomTocItemsComponent } from './sub-components/terms-condition-table/custom-toc-items/custom-toc-items.component';
import { TOCRadioListComponent } from './sub-components/terms-condition-radio-list/terms-radio-list.component';
import { BrandToggleTableComponent } from './components/bundler-components/sub-components/brand-toggle-table/brand-toggle-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TermsConditionTotalTableComponent } from './sub-components/terms-condition-total-table/terms-condition-total-table.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ActivityAutocompleteComponent } from './components/bundler-components/sub-components/activation-calendar/activity-autocomplete/activity-autocomplete.component';
import { ActivationCalendarComponent } from './components/bundler-components/sub-components/activation-calendar/activation-calendar.component';
import { ActivationEventComponent } from './components/bundler-components/sub-components/activation-calendar/activation-event/activation-event.component';
import { ActivitiesEditorComponent } from './components/bundler-components/activities-editor/activities-editor.component';
import { TargetTotalTableComponent } from './sub-components/target-total-table/target-total-table.component';
import { NgxMaskModule } from 'ngx-mask';
import { InPageDialogComponent } from './sub-components/in-page-dialog/in-page-dialog.component';
import { ActivationFullCalendarComponent } from './sub-components/activation-full-calendar/activation-full-calendar.component';


import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserListComponent } from './components/user-list/user-list.component';
import { ImportsReportLogComponent } from './components/imports-report-log/imports-report-log.component';
import { TranslationsComponent } from './components/translations/translations.component';
import { EbpInfoComponent } from './components/ebp-info/ebp-info.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RetailersListComponent } from './components/retailers-list/retailers-list.component';
import { GenericToggleTableComponent } from './components/bundler-components/sub-components/generic-toggle-table/generic-toggle-table.component';

@NgModule({
  declarations: [
    ComponentTitleComponent,
    StepTitleComponent,
    TableInputComponent,
    TableCatalogueComponent,
    ProjectDetailComponent,
    ProjectEditComponent,
    ProjectModuleComponent,
    ProgressSidebarComponent,
    ProjectCoverComponent,
    ProjectStepComponent,
    TableWithGraphComponent,
    ToggleComponent,
    ToggleInputComponent,
    ToggleTableComponent,
    RadioWithChipsComponent,
    TableStringListComponent,
    ProjectSetComponent,
    RadioListComponent,
    ChipsListComponent,
    SelectSimpleComponent,
    UpAndDownloaderComponent,
    ProductGridComponent,
    RadioNestedComponent,
    ProjectSectionComponent,
    ModuleManagementComponent,
    ProjectManagementComponent,
    TableTargetComponent,
    SkuSetTableComponent,
    ActivationTableComponent,
    BundlerExtraOptionsComponent,
    BundlerProductListComponent,
    ServicesTableComponent,
    DigitalShelfComponent,
    InputWithCommentComponent,
    RadioInputComponent,
    TermsConditionTableComponent,
    CustomTocItemsComponent,
    TOCRadioListComponent,
    BrandToggleTableComponent,
    TermsConditionTotalTableComponent,
    ActivityAutocompleteComponent,
    ActivationCalendarComponent,
    ActivationEventComponent,
    ActivitiesEditorComponent,
    TargetTotalTableComponent,
    InPageDialogComponent,
    ActivationFullCalendarComponent,
    UserListComponent,
    ImportsReportLogComponent,
    TranslationsComponent,
    EbpInfoComponent,
    RetailersListComponent,
    GenericToggleTableComponent
  ],
  imports: [
    CommonModule,
    ProjectRoutingModule,
    MatTooltipModule,
    MatRippleModule,
    SharedModule,
    FormsModule,
    MatExpansionModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    ChartsModule,
    GridModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCheckboxModule,
    DragDropModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forChild(),
    IntlModule,
    InputsModule,
    NgCircleProgressModule.forRoot(),
    TranslateModule,
    NgxMatSelectSearchModule,
    BrowserModule,
    BrowserAnimationsModule,
    TooltipModule,
  ],
})
export class ProjectModule {}
