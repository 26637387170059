import { Component, OnInit, Input } from '@angular/core';
import { StepComponent } from '../../models/step-component.model';

@Component({
  selector: 'shared-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  _component: StepComponent;
  content: any;
  settings: any;

  @Input()
  set component(component:StepComponent){
    this._component = component
    this.content = JSON.parse(component.content)
    this.settings = JSON.parse(component.settings)
  }

  constructor() { }

  ngOnInit(): void {
  }

}
