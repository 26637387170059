import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Step } from 'src/app/shared/models/step.model';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  constructor(private http: GenericHttpService) {}

  public loadModulesForProject(id: number) {
    this.http.get('/ebp/' + id + '/modules').subscribe(response => {
      this.projectModulesSource.next(response.data.modules);
    });
  }

  private projectModulesSource = new BehaviorSubject<any>(null);
  public projectModules = this.projectModulesSource.asObservable();

  public setProjectModules(state) {
    this.projectModulesSource.next(state);
  }

  private currentModuleSource = new BehaviorSubject<any>(null);
  public currentModule = this.currentModuleSource.asObservable();

  public currentModuleErrorSource = new BehaviorSubject<any>(null);
  public currentModuleError = this.currentModuleErrorSource.asObservable();

  public setCurrentModule(state) {
    this.currentModuleSource.next(state);
  }

  public getCurrentModule(projectId: number, moduleAlias: string) {
    this.loadCurrentModule(projectId, moduleAlias)
    .subscribe((response) => {
      if (response.status == 'success') {
        this.setCurrentModule(response.data.module);
        this.currentModuleErrorSource.next(0);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status === 403) {
        this.setCurrentModule(null);
        this.currentModuleErrorSource.next(projectId);
      }
    });
  }

  public loadCurrentModule(projectId: number, moduleAlias: string) {
    return this.http
    .get('/ebp/' + projectId + '/modules/' + moduleAlias);
  }

  public resetProjectModules() {
    this.projectModulesSource.next(null);
  }

  private stepIsSavingSource = new BehaviorSubject<boolean>(false);
  public stepIsSaving = this.stepIsSavingSource.asObservable();

  public setStepIsSaving(state: boolean) {
    this.stepIsSavingSource.next(state);
  }

  public updateStep(id: number, answers: any, updateComponentValue: boolean): Observable<any> {
    var data = updateComponentValue ? {payload: answers} : answers;
    return this.http.put('/step/' + id, data);
  }

  public updateStepStatus(step, status, updateData) {
    if (step.id) {
      return this.http.get(`/step/${step.id}/${status}`);
    } else {
      return this.http.post(`/step/${status}`, updateData, { markComplete : true});
    }
  }

  public saveStep(
    epbId: number,
    moduleAlias: string,
    stepname: string,
    answers: any,
    updateComponentValue: boolean = true,
  ): Observable<any> {
    let data = {
      ebp_id: epbId,
      module_alias: moduleAlias,
      step_name: stepname,
      payload: updateComponentValue ? answers : [],
    };
    if (!updateComponentValue) {
      data = Object.assign(data, answers);
    } // sets STATUS and EXCLUDE
    return this.http.post('/step', data);
  }

  public saveOrCreateStep(
    ebpId: number,
    moduleAlias: string,
    step: Step,
    answers: any,
    updateComponentValue: boolean = true,
  ): Observable<any> {
    this.setStepIsSaving(true);
    if (step.id) {
      return this.updateStep(step.id, answers, updateComponentValue);
    } else {
      return this.saveStep(ebpId, moduleAlias, step.name, answers, updateComponentValue);
    }
  }

  public toggleStepState(stepId, data) {
    return this.http.post(`/step/${stepId}/toggle`, {payload: data});
  }

  public updateModuleStatus(ebpId: number, moduleAlias: string, status: string) {
    var data = {
      version: {status: status},
    };
    this.http
    .put('/ebp/' + ebpId + '/modules/' + moduleAlias, data)
    .subscribe((response) => {
      this.getCurrentModule(ebpId, moduleAlias);
    });
  }

  public updateModuleStatusFromCard(ebpId: number, moduleAlias: string, status: string) {
    var data = {
      version: {status: status},
    };
    // module-card subscribes to this response for update but does not Set Current Modules
    return this.http.put('/ebp/' + ebpId + '/modules/' + moduleAlias, data);
  }

  public getModuleByAlias(projectId: number, moduleAlias: string) {
    return this.http.get('/ebp/' + projectId + '/modules/' + moduleAlias);
  }

  public updateToggleState(ebpId: number, activationId: number, data: any): Observable<any> {
    return this.http
      .put('/activation/' + ebpId + '/' + activationId, data);
  }

 
}
