<div kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
    <div [title]="tooltip ? tooltip : ''">
        <svg version="1.1" id="Icon_-_checkcircle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
        xml:space="preserve" [ngClass]="['status-fill-'+status, excluded ? 'is-excluded' : '']" >
        <path id="Icon_Check_Circle_Purple" fill="#333333" d="M10.2,16.5L5.7,12l1.26-1.26l3.24,3.241l6.84-6.84L18.3,8.4L10.2,16.5z M12,3
        c-4.971,0-9,4.029-9,9s4.029,9,9,9s9-4.029,9-9C20.986,7.035,16.965,3.014,12,3z" />
    </svg>
    </div>
</div>
