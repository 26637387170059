<div class="project-detail-step" *ngIf="project && project.id">
  <div class="content">
    <!--   <debug title="Project Status" [content]="project.status" [isCollapsed]="true"></debug>
        <debug title="currentUserRole$" [content]="currentUserRole$" [isCollapsed]="true"></debug> -->

    <div class="module-status-row">
      <div class="module-status" [class]="project.status ? project.status : 'null'">
        <span *ngIf="!project.status">{{'EBP_COVER_STATUS' | translate}} {{'STATUS_INPROGRESS' | translate }}</span>
        <span *ngIf="project.status">{{'EBP_COVER_STATUS' | translate}} <span class="text-uppercase"> {{project.status | statusLabel | translate}}</span></span>
      </div>
      <shared-status-icon class="status-ico" [status]="project.status" width="44" ></shared-status-icon>
    </div>

    <ng-container *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor">
      <ng-container *ngIf="project.status == 'edited' || !project.status">
        <p class="mb-0" [innerHTML]="component.owner?.subline?.edited | translate | replacePlaceholder:project.replace"></p>

        <button class="module-action-button submit mt-5 mb-4" (click)="submitEBP()" >
          <span>{{'EBP_SUBMIT_CTA' | translate | replacePlaceholder:project.replace}}</span>

          <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
              style="enable-background:new 0 0 38 24;" xml:space="preserve">
              <style type="text/css">
                  .st0 {
                      fill: none;
                  }

                  .st1 {
                      fill: #ffffff;
                  }
              </style>
              <path id="Path_2483" class="st0" d="M0,0h38v24H0V0z" />
              <g>
                  <path class="st1" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
              M12,11L4,6h16L12,11z" />
                  <polygon class="st1" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
              </g>
          </svg>
        </button>
        <p [innerHTML]="component.owner?.actions?.submit | translate | replacePlaceholder:project.replace"></p>
      </ng-container>

      <ng-container *ngIf="project.status == 'submitted'">
        <p class="subline-margin">{{'EBP_COVER_SUBMITTED_BY' | translate}} {{project.owner?.display_name}} {{'EBP_COVER_EDITED_ON' | translate}} {{project.submitted_at
          | date:'yyyy.MM.dd HH:mm' }}</p>
        <p class="subline-margin" [innerHTML]="component.owner?.subline?.submitted | translate"></p>
        <button class="module-action-button edit mt-5 mb-4" (click)="editEBP()">
          <span>{{'EBP_EDIT_CTA' | translate | replacePlaceholder:project.replace}}</span>
        </button>
        <p>{{'EBP_EDIT_TEXT' | translate | replacePlaceholder:project.replace}}</p>
      </ng-container>

      <ng-container *ngIf="project.status == 'validated'">
        <p [innerHTML]="component.owner?.subline?.validated | translate | replacePlaceholder:project.replace"></p>
        <button class="module-action-button edit mt-5 mb-4" (click)="editEBP()">
          <span>{{'EBP_EDIT_CTA' | translate | replacePlaceholder:project.replace}}</span>
        </button>
        <p>{{'EBP_EDIT_TEXT' | translate | replacePlaceholder:project.replace}}</p>

      </ng-container>

     <!--  <ng-container *ngIf="project.status == 'rejected'">
        <p [innerHTML]="component.owner?.subline?.rejected | translate | replacePlaceholder:project.replace"></p>
        <button class="module-action-button edit mt-5 mb-4" (click)="editEBP()">
          <span>{{'EBP_EDIT_CTA' | translate | replacePlaceholder:project.replace}}</span>
        </button>
        <p>{{'EBP_EDIT_TEXT' | translate | replacePlaceholder:project.replace}}</p>

      </ng-container> -->

    </ng-container>

    <ng-container *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.validator">

      <ng-container *ngIf="project.status == 'edited' || !project.status">
        <p class="mb-0" [innerHTML]="component.validator?.subline?.edited | translate | replacePlaceholder:project.replace"></p>
      </ng-container>

      <ng-container *ngIf="project.status == 'submitted'">
        <p [innerHTML]="component.validator?.subline?.submitted | translate | replacePlaceholder:project.replace"></p>
        <button class="module-action-button validate mt-5 mb-4" (click)="validateEBP()">
          <span>{{'EBP_VALIDATE_CTA' | translate | replacePlaceholder:project.replace}}</span>
          <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
          style="enable-background:new 0 0 38 24;" xml:space="preserve">
              <style type="text/css">
                  .st0 {
                      fill: none;
                  }

                  .st1 {
                      fill: #ffffff;
                  }
              </style>
              <path id="Path_2483" class="st0" d="M0,0h38v24H0V0z" />
              <g>
                  <path class="st1" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
              M12,11L4,6h16L12,11z" />
                  <polygon class="st1" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
              </g>
          </svg>
      </button>
        <p class="mt-0" [innerHTML]="component.validator?.actions?.submit | translate | replacePlaceholder:project.replace"></p>
      </ng-container>

      <ng-container *ngIf="project.status == 'validated'">
        <p [innerHTML]="component.validator?.subline?.validated | translate | replacePlaceholder:project.replace"></p>
      </ng-container>

      <ng-container *ngIf="project.status == 'rejected'">
        <p [innerHTML]="component.validator?.subline?.rejected | translate | replacePlaceholder:project.replace"></p>
        <button class="module-action-button validate mt-5 mb-4" (click)="validateEBP()">
          <span>{{'EBP_VALIDATE_CTA' | translate | replacePlaceholder:project.replace}}</span>
          <svg width="35" version="1.1" id="email_send" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 24"
          style="enable-background:new 0 0 38 24;" xml:space="preserve">
              <style type="text/css">
                  .st0 {
                      fill: none;
                  }

                  .st1 {
                      fill: #ffffff;
                  }
              </style>
              <path id="Path_2483" class="st0" d="M0,0h38v24H0V0z" />
              <g>
                  <path class="st1" d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V8l8,5l8-5V18z
              M12,11L4,6h16L12,11z" />
                  <polygon class="st1" points="33,12 29,8 29,11 25,11 25,13 29,13 29,16 	" />
              </g>
          </svg>
      </button>
        <p class="mt-0" [innerHTML]="component.validator?.actions?.validate | translate | replacePlaceholder:project.replace"></p>
      </ng-container>

    </ng-container>
  </div>
  <div class="ebp-large-button">
    <a [routerLink]="['/projects/edit', project.id ]" *ngIf="(currentUserRole$ | async) === PROJECT_USER_ROLE.owner || (currentUserRole$ | async) === PROJECT_USER_ROLE.editor">
      <span>{{'EBP_EDIT_SETTINGS_CTA' | translate | replacePlaceholder:project.replace}}</span>
      <div>
        <span class="material-icons">settings</span>
      </div>
    </a>
    <a *ngIf="(currentUserRole$ | async) !== PROJECT_USER_ROLE.owner && (currentUserRole$ | async) !== PROJECT_USER_ROLE.editor" class="disabled">
      <span>{{'EBP_EDIT_SETTINGS_CTA' | translate | replacePlaceholder:project.replace}}</span>
      <div>
        <span class="material-icons">settings</span>
        <shared-info-icon class="float-right" [tooltip]="component?.guideline | translate"></shared-info-icon>
      </div>
    </a>
  </div>
</div>
