import { Component, Input, OnInit } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'in-page-dialog',
  templateUrl: './in-page-dialog.component.html',
  styleUrls: [ './in-page-dialog.component.scss' ],
})
export class InPageDialogComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
  ) {}

  @Input() component: StepComponent;
  shouldShowDialog = true;

  ebpId: number;

  ngOnInit(): void {
    this.projectService.currentProject.subscribe((p) => {
      if (p) {
        this.ebpId = p.id;
        this.shouldShowDialog = !localStorage.getItem(`hide_${this.ebpId}_${this.component.name}`);
      }
    });
  }

  hideDialog() {
    this.shouldShowDialog = false;
    localStorage.setItem(`hide_${this.ebpId}_${this.component.name}`, 'true');
  }
}
