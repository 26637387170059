import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorpageTemplateComponent } from '../shared/components/errorpage-template/errorpage-template.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { CoverComponent } from './components/cover/cover.component';

const routes: Routes = [
  {
    path: 'start',
    component: CoverComponent,
  },
  {
    path: 'auth/response',
    component: AuthenticationComponent
  },
  {
    path: '403',
    component: ErrorpageTemplateComponent
  },
  {
    path: '403/:pid',
    component: ErrorpageTemplateComponent
  },
  {
    path: '404',
    component: ErrorpageTemplateComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoverRoutingModule {}
