<ng-container>
  <div class="container">

    <ng-container [ngSwitch]="message?.title_icon">
      <svg *ngSwitchCase="'warning'" version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg"
           width="32px" height="32px" x="0px" y="0px" viewBox="0 0 24 24"
           style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <style type="text/css">
          .st0 {
            fill: #333333;
          }
        </style>
        <path class="st0" d="M1,21.5h22l-11-19L1,21.5z M13,18.5h-2v-2h2V18.5z M13,14.5h-2v-4h2V14.5z"/>
      </svg>

      <svg *ngSwitchCase="'confirmation'" version="1.1" id="Icon_-_checkcircle" xmlns="http://www.w3.org/2000/svg"
           width="32px" height="32px" x="0px" y="0px" viewBox="0 0 24 24"
           style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <style type="text/css">
          .st0 {
            fill: #333333;
          }
        </style>
        <path id="Icon_Check_Circle_Purple" class="st0" d="M10.7,17l-4.5-4.5l1.3-1.3l3.2,3.2l6.8-6.8l1.3,1.3L10.7,17z M12.5,3.5
    c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C21.5,7.5,17.5,3.5,12.5,3.5z"/>
      </svg>
    </ng-container>

    <h1 class="title">{{message?.labels.title  | translate }}</h1>
    <p class="text">
      {{(commercialError ? message?.labels.text_commercial_restriction : message?.labels.text_1)  | translate}}
    </p>

    <ng-container>
      <button class="ebp-button button-transparent" cdkFocusInitial (click)="goToEbpCoverPage()"
              *ngIf="message?.labels.cta_goto_ebpcover"><span>{{message?.labels.cta_goto_ebpcover  | translate}}</span>
      </button>

      <button class="ebp-button button-transparent" cdkFocusInitial (click)="goToDashboard()"
              *ngIf="message?.labels.cta_goto_dashboard"><span>{{message?.labels.cta_goto_dashboard  | translate}}</span>
      </button>
    </ng-container>
  </div>
</ng-container>
