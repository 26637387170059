import { Module } from './../../../shared/models/module.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectUserRole } from '../../../shared/enums';
import { ProjectService } from '../../../shared/services/project.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModuleService } from '../../services/module.service';
@Component({
  selector: 'project-table-input',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.scss'],
})
export class TableInputComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();
  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;

  currentProject: Project;
  currentModule: Module;

  constructor(private projectService: ProjectService, private moduleService: ModuleService, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.projectService.currentProject.subscribe((project) => {
      if (project) {
        this.currentProject = project;
      }
    });

    this.moduleService.currentModule.subscribe((module) => {
      if (module) {
        this.currentModule = module;
      }
    });

    this.validateAnswer();
    if (this.component.rows.length && this.component.rows.length - this.component.answer.values.length > 0) {
      const missedValues = new Array(this.component.rows.length - this.component.answer.values.length).fill([]);
      if (missedValues.length) {
        missedValues.forEach((t) => {
          this.component.answer.values.push([]);
        });
      }
    }
    // define rows based on headers definition if now row override is present
    this.component.rows.forEach((row, rowIndex) => {
      this.component.headers.forEach((header, headerIndex) => {
        if (
          !this.component.rows[rowIndex][headerIndex] ||
          Object.keys(this.component.rows[rowIndex][headerIndex]).length == 0
        ) {
          this.component.rows[rowIndex][headerIndex] = header;
        }
      });
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onValueChange(data: any, i: number, k: number) {
    this.component.answer.values[i][k] = data.target.value;
    this.onChange.emit();
  }

  onDirectChange(row: Array<any>, i: number, k: number) {
    if (row[0] && row[0].id) {
      this.component.answer.values[i][0] = row[0].id;
    }

    this.onChange.emit();
  }

  label(label: any) {
    return typeof label == 'object' ? label.en : label;
  }

  validateAnswer() {
    let defaultAnswer = this.defaultAnswer();
    if (!this.component.answer) {
      this.component.answer = defaultAnswer;
      return;
    }

  }

  defaultAnswer() {
    let a: Array<Array<any>> = [];
    for (let i = 0; i < this.component.rows?.length; i++) {
      let b: Array<number> = [];
      for (let k = 0; k < this.component.headers.length; k++) {
        b.push(null);
      }
      a.push(b);
    }
    return { values: a };
  }

  copyToColumn(totalRows: Array<any>, cellIndex: number, value: string ){
    //console.log(totalRows, columnIndex, value);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialog_key: 'alert_zap_data_copy_all_confirm',
    };

    this.dialog
    .open(ConfirmDialogComponent, dialogConfig)
    .afterClosed()
    .subscribe((result) => {
      if (!result) {
        return;
      }

      if (value) {
        for (let i = 0; i < totalRows.length; i++) {
            totalRows[i][cellIndex] = value;
        }
        this.onChange.emit(); //update only if values are different
      }

    });
  }
}
