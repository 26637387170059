<div class="project-detail-step">
    <div class="content">
        <div class="module-status-row">
            <div class="module-status" [class]="currentModule?.version?.status ? currentModule?.version?.status :'null'">
                {{'MODULE_STATUS_BAR' | translate}}
                <span *ngIf="!currentModule?.version?.status" class="text-uppercase">{{'STATUS_INPROGRESS' | translate}}</span>
                <span *ngIf="currentModule?.version?.status" class="text-uppercase">{{currentModule?.version?.status | statusLabel | translate}}</span>
            </div>
            <shared-status-icon [status]="currentModule?.version?.status" width="44"></shared-status-icon>
        </div>
    </div>
</div>
