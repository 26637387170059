import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericHttpService } from 'src/app/shared/services/generic-http.service';
import { Activation } from '../entities/activation';
import { ActivationEvent } from '../entities/activation-event';
import { Activity } from '../entities/activity';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: GenericHttpService) {}

  private activitiesSource = new BehaviorSubject<any>(null);
  public activities = this.activitiesSource.asObservable();

  loadActivitiesForProject(projectId: number) {
    var url = ['/activity', projectId].join('/');
    this.http.get(url).subscribe((response) => {
      this.activitiesSource.next(response.data.activities);
    });
  }

  createActivationEvent(event: ActivationEvent, activation: Activation) {

    const _event = JSON.parse(JSON.stringify(event));
    _event.activation_id = activation.id;
    _event.activity_id = event.activity.id;
    delete _event.activity;
    return this.http.post('/activation_event', _event);
  }

  updateActivationEvent(event: ActivationEvent) {
    return this.http.put('/activation_event/' + event.id, event);
  }

  deleteActivationEvent(event: ActivationEvent) {
    return this.http.delete('/activation_event/' + event.id);
  }

  createActivity(activity: Activity) {
    const url = ['/activity', activity.ebp_id].join('/');
    this.http.post(url, activity).subscribe((response) => {
      this.loadActivitiesForProject(activity.ebp_id);
    });
  }

  deleteActivity(activity: Activity) {
    const url = ['/activity', activity.ebp_id, activity.id].join('/');
    this.http.delete(url).subscribe((response) => {
      this.loadActivitiesForProject(activity.ebp_id);
    });
  }

  updateActivity(activity: Activity) {
    const url = ['/activity', activity.ebp_id, activity.id].join('/');
    this.http.put(url, activity).subscribe((response) => {
      this.loadActivitiesForProject(activity.ebp_id);
    });
  }
}
