<app-top-bar></app-top-bar>
<ng-container *ngIf="user$ | async as user">
  <div>
    <app-navigation  [user]="user" [languages] = "languages"  ></app-navigation>
  </div>
</ng-container>

<div class="content">
  <div class="sidebar sidebar-left" [hidden]="hideSidebar"></div>
  <div class="w-100">
    <router-outlet></router-outlet>
  </div>
</div>
