<div class="ebp-dialog">
    <div class="header">
        <ng-container *ngIf="duplicationPhase != 'success'">
            <div class="material-icons orange">add_to_photos</div>
            <h2 class="orange" mat-dialog-title>{{ configData.labels?.title | translate }}</h2>
        </ng-container>

        <ng-container *ngIf="duplicationPhase == 'success'">
            <div class="material-icons orange">add_to_photos</div>
            <h2 class="orange" mat-dialog-title>{{ configData.labels?.ebp_duplication_completed | translate }}</h2>
        </ng-container>
    </div>

    <div class="body">
        <ng-container *ngIf="duplicationPhase == 'form'">
            <div class="section">
                <p>{{ configData.labels?.ebp_source | translate }}</p>
                <p>{{ data.ebp.full_name }}</p>
            </div>

            <div class="section mt15">
                <mat-form-field appearance="standard" class="orange">
                    <h4 class="orange">{{ configData.labels?.new_ebp_year | translate }}</h4>
                    <mat-select [(ngModel)]="duplicateData.newEbpYear" required (selectionChange)="updateNewEbpName($event)">
                        <mat-option [value]="(currentYear + i)" *ngFor="let i of [1, 2, 3, 4, 5]">{{ currentYear + i }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="section">
                <p class="orange big" [innerHTML]="configData.labels?.new_ebp_note | translate"></p>
            </div>

            <div class="section">
                <mat-form-field appearance="standard" class="orange">
                    <h4 class="dark">{{ configData.labels?.new_ebp_target_previous_year | translate | replacePlaceholder: yearPlaceholders }}</h4>
                    <mat-select [(ngModel)]="duplicateData.targetPreviousYear" required>
                        <mat-option [value]="'-'">-</mat-option>
                        <mat-option *ngIf="duplicateData.newEbpYear" [value]="( duplicateData.newEbpYear - 1)">{{ duplicateData.newEbpYear - 1 }}</mat-option>
                        <mat-option *ngIf="duplicateData.newEbpYear" [value]="( duplicateData.newEbpYear )">{{ duplicateData.newEbpYear }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="section">
                <mat-form-field appearance="standard" class="orange">
                    <h4 class="dark">{{ configData.labels?.new_ebp_target_current_year | translate | replacePlaceholder: yearPlaceholders }}</h4>
                    <mat-select [(ngModel)]="duplicateData.targetCurrentYear" required>
                        <mat-option [value]="'-'">-</mat-option>
                        <mat-option *ngIf="duplicateData.newEbpYear" [value]="( duplicateData.newEbpYear - 1)">{{ duplicateData.newEbpYear - 1 }}</mat-option>
                        <mat-option *ngIf="duplicateData.newEbpYear" [value]="( duplicateData.newEbpYear )">{{ duplicateData.newEbpYear }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="section mt25 orange">
                <p>{{ configData.labels?.new_ebp_result | translate }}</p>
                <p>{{ newEbpName }}</p>
            </div>

            <div class="section">
                <mat-form-field appearance="standard" class="orange">
                    <h4 class="orange">{{ configData.labels?.new_ebp_validator | translate }}</h4>
                    <mat-select [(ngModel)]="duplicateData.newValidatorId" required>
                        <mat-option>
                            <ngx-mat-select-search #searchTextValidator noEntriesFoundLabel="{{ 'NO_MATCH_FOUND' | translate }}" placeholderLabel="{{ 'SEARCH' | translate }}"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option [value]="user.id" *ngFor="let user of validatorsList | orderBy: 'display_name' | filter:searchTextValidator.value">
                            {{user?.display_name}}<span *ngIf="user?.display_name && user?.country?.name_native"> - </span>{{user?.country?.name_native}}<span *ngIf="user?.country?.name_native && user?.title"> - </span>{{user?.title}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!duplicateData.newValidatorId">{{ requiredErrMsg?.labels?.text_1 | translate }}</mat-error>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="duplicationPhase == 'duplicating'">
            <div class="section">
                <p>{{ configData.labels?.ebp_duplication_in_progress | translate }}</p>
                <mat-spinner [strokeWidth]="4" [diameter]="40"></mat-spinner>
            </div>
        </ng-container>

        <ng-container *ngIf="duplicationPhase == 'success'">
            <div class="section">
                <p class="orange">{{ newEbp.full_name }}</p>
            </div>
        </ng-container>

        <ng-container *ngIf="duplicationPhase == 'error'">
            <div class="section">
                <p>{{ configData.labels?.ebp_duplication_error | translate }}</p>
            </div>
        </ng-container>
    </div>

    <div class="actions mt20">
        <ng-container *ngIf="duplicationPhase == 'form'">
            <button class="ebp-button button-transparent orange full-width" [disabled]="isFormInvalid()" (click)="startDuplication()">
                <span>{{ configData.labels?.cta_duplicate_ebp_start | translate }}</span>
            </button>

            <button class="ebp-button button-transparent mt60" [mat-dialog-close]="'back_to_settings'">
                <span>{{ configData.labels?.cta_back_to_settings | translate }}</span>
            </button>

            <button class="ebp-button button-transparent" [mat-dialog-close]="false">
                <span>{{ configData.labels?.cta_cancel | translate }}</span>
            </button>
        </ng-container>

        <ng-container *ngIf="duplicationPhase == 'success'">
            <button class="ebp-button button-transparent" [mat-dialog-close]="false" [routerLink]="[ '/projects', newEbp.id ]">
                <span>{{ configData.labels?.cta_ebp_open | translate }}</span>
            </button>

            <button class="ebp-button button-transparent" [mat-dialog-close]="false" [routerLink]="[ '/projects/edit', newEbp.id ]">
                <span>{{ configData.labels?.cta_ebp_edit | translate }}</span>
            </button>

            <button class="ebp-button button-transparent" [mat-dialog-close]="false" [routerLink]="[ '/dashboard' ]">
                <span>{{ configData.labels?.cta_ebp_dashboard | translate }}</span>
            </button>
        </ng-container>

        <ng-container *ngIf="duplicationPhase == 'error'">
            <button class="ebp-button button-transparent" [mat-dialog-close]="false">
                <span>{{ configData.labels?.cta_cancel | translate }}</span>
            </button>
        </ng-container>
    </div>
</div>
