import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ProjectUserRole } from '../../../shared/enums';

@Component({
  selector: 'project-toggle-table',
  templateUrl: 'toggle-table.component.html',
  styleUrls: ['toggle-table.component.scss'],
})
export class ToggleTableComponent implements OnInit,OnDestroy{
  private subscription  = new Subscription();

  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  currentProject: Project;

  currentUserRole$: Observable<ProjectUserRole> = this.projectService.currentUserRole;
  PROJECT_USER_ROLE = ProjectUserRole;

  constructor(private projectService: ProjectService) {}

  ngOnInit(): void {
    this.subscription.add(
    this.projectService.currentProject.subscribe((p) => {
      if (p) {
        this.currentProject = p;

        if (this.component.items.entity) {
          switch (this.component.items.entity) {
            case 'ProductCategory':
              this.setupAnswersFromProductCategories();
              break;
            case 'Brands':
              this.setupAnswerFromBrands();
              break;
          }
          return;
        }

        if (!this.component.answer) {
          this.component.answer = { values: this.component.items };
        } else {
          var alreadyTransferedItems = this.component.answer.values.map(
            (v) => v.text
          );
          this.component.items.forEach((item) => {
            if (!alreadyTransferedItems.includes(item.text)) {
              this.component.answer.values.push(item);
            }
          });
        }
      }
    })
    )
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setupAnswersFromProductCategories() {

    var productCategories = (this.component.answer?.values) ? this.component.answer.values : [];
    this.currentProject.brands.forEach((b) =>
      b.product_categories.forEach((category) => {
        if (!productCategories.map((i) => i.id).includes(category.id)) {
          productCategories.push({
            id: category.id,
            text: category.name,
            selected: false,
          });
        }
      })
    );
    this.component.answer = this.component.answer || {};
    this.component.answer.values = productCategories;
  }

  setupAnswerFromBrands() {
    const brands = (this.component.answer?.values) ? this.component.answer.values : [];
    this.currentProject.brands.forEach((b) => {
      if (!brands.map((brand) => brand.id).includes(b.id))
        brands.push({
          id: b.id,
          text: b.name,
          selected: false,
        });
    });
    this.component.answer = this.component.answer || {};
    this.component.answer.values = brands;
  }

  removeItemAtIndex(index: number) {
    this.component.answer.values.splice(index, 1);
    this.onDataChange();
  }

  addItem() {
    if (!this.component.add_items) {
      return;
    }
    this.component.answer.values.push({
      text: '',
      custom: true,
      selected: true,
    });
  }

  onDataChange() {
    this.onChange.emit();
  }
}
