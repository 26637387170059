import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-toggle-input',
  templateUrl: './toggle-input.component.html',
  styleUrls: [ './toggle-input.component.scss' ],
})
export class ToggleInputComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  inputIsDisabled = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = {values: {selected: '', value: ''}};
    }
    if (this.component.answer?.values?.selected && this.component?.changeInputState) {
      this.inputIsDisabled = true;
    }
  }

  onValueChange() {
    this.onChange.emit();
  }

  slideToggle() {
    if (this.component?.changeInputState) {
      this.inputIsDisabled = !this.inputIsDisabled;
    }
  }
}
