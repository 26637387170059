import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { collapseAnimation } from 'angular-animations';

@Component({
  selector: 'app-generic-pages',
  templateUrl: './generic-pages.component.html',
  styleUrls: ['./generic-pages.component.scss'],
  animations: [collapseAnimation()],
})
export class GenericPagesComponent implements OnInit {
  public currentPage: any;

  public showEmailTemplate: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    const page = this.route.snapshot.params.page

    if (page == 'emailtemplate') {
      this.showEmailTemplate = true;
      return;
    }

    this.projectService
      .loadCurrentPageTemplate(page)
      .subscribe((response) => {
        if (response.status == 'success') {
          this.currentPage = response.data[page];
        } else {
          console.log('Error fetching page');
        }
      });
  }
}
