import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { collapseAnimation } from 'angular-animations';
import { cloneDeep, chunk, uniqWith, isEqual} from 'lodash-es';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ConfigService } from '../../../../../shared/services/config.service';

@Component({
  selector: 'generic-toggle-table',
  templateUrl: './generic-toggle-table.component.html',
  styleUrls: ['./generic-toggle-table.component.scss'],
  animations: [
    collapseAnimation(),
  ]
})
export class GenericToggleTableComponent implements OnInit {
  private subscription: Subscription = new Subscription();


  @Input()
  featureType: string;

  @Input()
  brands: number[] = [];

  @Input()
  categories: number[] = [];

  @Input()
  sub_categories: number[] = [];

  @Output()
  onChange = new EventEmitter<any>();

  @Input()
  isCollapsed = false;

  @Output()
  onCollapseChange = new EventEmitter<boolean>();

  currentProject: Project = null;

  availableFeatures: Array<any>;
  chunkedFeatures: Array<Array<any>>;

  constructor(private projectService: ProjectService,
              public configService: ConfigService,
  ) {}

  ngOnInit(): void {

      this.subscription.add(
      this.projectService.currentProject.subscribe(async (p) => {
        if (!p) {
          return;
        }
        this.currentProject = p;

        let brands = JSON.parse(JSON.stringify(this.currentProject?.brands));
        if (this.featureType == 'brands') {
          this.availableFeatures = brands.slice();
        } else if (this.featureType == 'categories') {
          let categories = [].concat.apply([], brands.slice().map(x => x.product_categories));  // categories
          this.availableFeatures = uniqWith(categories,isEqual);
        } else if (this.featureType == 'sub_categories') {
          let sub_categories = [].concat.apply([], brands.slice().map(x => x.product_sub_categories)); // sub_categories
          this.availableFeatures = uniqWith(sub_categories,isEqual);
        }

        this.chunkFeatures();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  chunkFeatures() {
    const populatedFeatures = cloneDeep(this.availableFeatures);

    switch (this.featureType) {
      case  'brands':
        if (this.brands) {
          populatedFeatures.map(b => {
            b.is_selected = this.brands.indexOf(b.id) > -1;
            return b;
          });
        }
      break;

      case  'categories':
        if (this.categories) {
          populatedFeatures.map(c => {
            c.is_selected = this.categories.indexOf(c.id) > -1;
            return c;
          });
        }
      break;

      case  'sub_categories':
        if (this.sub_categories) {
          populatedFeatures.map(sc => {
            sc.is_selected = this.sub_categories.indexOf(sc.id) > -1;
            return sc;
          });
        }
      break;

    }
    
    populatedFeatures.sort((a, b) => a.name.localeCompare(b.name));
    this.chunkedFeatures = chunk(populatedFeatures, 2);
  }

  onOptionChange(data: any) {
    let selectedFeature = [];
    this.chunkedFeatures.forEach((featureSection) => {
      featureSection.forEach(feature => {
        if (feature.is_selected) {
          selectedFeature.push(feature);
        }
      });
    });
    this.onChange.emit(selectedFeature);
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.onCollapseChange.emit(this.isCollapsed);
  }

}