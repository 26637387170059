<section class="ebp-expandable-item">
  <header (click)="collapse()">
    <shared-expand-icon [rotate]="!isCollapsed"></shared-expand-icon>
    <span>{{brands?.length}} {{'M_SELECTION_VIRTUAL_BUNDLES_PROD_BRAND' | translate}} </span>
  </header>
  <div class="ebp-expandable-content" [@collapse]="(isCollapsed && currentProject != null)">
    <table class="ebp-expandable-table">
      <tbody>
      <ng-container *ngFor="let brandSection of chunkedBrands">
        <div class="d-inline-flex w-100" style="gap:18px;">

          <ng-container *ngFor="let brand of brandSection">
            <div  [ngStyle]="{'width.%': brandSection.length == 1 ? 48.5:50} ">

              <div class="d-inline-flex w-100 justify-content-between p-2 brand-column-item">
                <div class="brand-column-item-name" [class.disabled-brand]="!brand.subCategoryExists">
                  {{brand.name}}
                </div>
                <div  class="brand-column-item-checkbox">
                  <mat-slide-toggle [(ngModel)]="brand.is_selected" [disabled]="!brand.subCategoryExists"
                                    (change)="onOptionChange($event)">

                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      </tbody>
    </table>
  </div>
</section>
