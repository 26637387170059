import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'input-with-comment',
  templateUrl: './input-with-comment.component.html',
  styleUrls: ['./input-with-comment.component.scss'],
})
export class InputWithCommentComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = { value: [] };
      this.component.answer.value.push(
        this.component.default_value_1 ? this.component.default_value_1 : null
      );
      this.component.answer.value.push(
        this.component.default_value_2 ? this.component.default_value_2 : null
      );
      this.onChange.emit()
    }
  }

  onValueChange() {
    this.onChange.emit();
  }
}
