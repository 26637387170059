import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'app-ebp-settings-dialog',
    templateUrl: './ebp-settings-dialog.component.html',
    styleUrls: [ './ebp-settings-dialog.component.scss' ],
})
export class EbpSettingsDialogComponent implements OnInit {
    data: any;
    configData: any = {};

    constructor(private dialog: MatDialog, private configService: ConfigService, @Inject(MAT_DIALOG_DATA) data) {
        this.data = data;
    }

    ngOnInit(): void {
        this.configService.dialogConfig.subscribe(config => {
            this.configData = config.find(c => c.name == this.data.dialog_key);
            console.log(this.configData);
        });
    }

}
