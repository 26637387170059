import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asList'
})
export class AsListPipe implements PipeTransform {

  transform(value: Array<any>, key:string = 'name'): string {
    return value.map(item => item[key]).join(", ");
  }

}
