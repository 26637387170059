<div kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
    <div class="ebp-interface-button exclude-icon" (click)="showAlert()"  [ngClass]="'exclude-status-'+ status"  [title]="tooltip ? tooltip : ''">
        <svg version="1.1" id="Icon_Cancel_Filled" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
            style="enable-background:new 0 0 24 24;" xml:space="preserve" width="24px" height="24px"
            [ngClass]="{'is-active': active, 'is-validation' : validation}">
            <path id="Icon_Cancel_Dark" class="st1" fill="#585858" d="M16.5,15.2l-1.3,1.3L12,13.3l-3.2,3.2l-1.3-1.3l3.2-3.2L7.5,8.8l1.3-1.3l3.2,3.2l3.2-3.2
        l1.3,1.3L13.3,12L16.5,15.2z M12,3c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S17,3,12,3z" />
        </svg>
    </div>
</div>
