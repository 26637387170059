import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Activation } from 'src/app/project/entities/activation';
import { ActivationEvent } from 'src/app/project/entities/activation-event';
import { Activity } from 'src/app/project/entities/activity';
import { ActivityService } from 'src/app/project/services/activity.service';

@Component({
  selector: 'activation-event',
  templateUrl: './activation-event.component.html',
  styleUrls: [ './activation-event.component.scss' ],
})
export class ActivationEventComponent implements OnInit {
  constructor(private activityService: ActivityService) {}

  @Input()
  activationEvent: ActivationEvent;

  @Input()
  activation: Activation;

  @Output()
  onChange = new EventEmitter<ActivationEvent>();

  ngOnInit(): void {
  }

  onDateChange(data: any) {
    if (data.startDate) {
      this.activationEvent.starts_at = new Date(moment(data.startDate).format('YYYY-MM-DD'));
    }
    if (data.endDate) {
      this.activationEvent.ends_at = new Date(moment(data.endDate).format('YYYY-MM-DD'));
    }
    this.update();
  }

  onActivityChange($event: Activity) {
    this.activationEvent.activity = $event;
    this.activationEvent.activity_id = $event.id;
    this.update();
  }

  update() {
    if (!this.activationEvent.activity?.id && !this.activationEvent?.activity_id) {
      return;
    }
    if (!this.activationEvent.id) {
      this.activityService
      .createActivationEvent(this.activationEvent, this.activation)
      .subscribe((response) => {
        this.emit();
      });
    } else {
      this.activityService
      .updateActivationEvent(this.activationEvent)
      .subscribe((response) => {
        this.emit();
      });
    }
  }

  delete() {
    this.activityService
    .deleteActivationEvent(this.activationEvent)
    .subscribe((response) => {
      this.emit();
    });
  }

  emit() {
    this.onChange.emit(this.activationEvent);
  }
}
