import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventsService {

  public data: any = {};
  public change: EventEmitter<any> = new EventEmitter();
  private events: Map<string, EventEmitter<any>> = new Map();

  constructor() {
  }

  setValue(property: any, value: any): void {
    this.data[property] = value;
    if (this.events.has(property)) {
      this.events.get(property).emit(value);
    }
  }

  getValue(property: string, $default: any = null) {
    if (this.data.hasOwnProperty(property)) {
      return this.data[property];
    }
    return $default;
  }

  on(property: any): EventEmitter<any> {
    if (!this.events.has(property)) {
      this.events.set(property, new EventEmitter);
    }
    return this.events.get(property);
  }

}
