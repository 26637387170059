<div class="users-container">
  <div class="users-container--header">
    <shared-module-icon [type]="'settings'" [status]="null"></shared-module-icon>

    <div class="divider"></div>
    <h1 class="users-container--header_title">{{'CMS_TRANSLATIONS_TITLE' | translate}}</h1>
  </div>
</div>
<section class="module-section ng-tns-c566-2 is-collapsed is-fullwidth">
  <div class="d-flex justify-content-between mx-auto filter-section">
    <mat-form-field  appearance="standard" class="filter-select">
      <mat-label class="filter-label">{{'CMS_SOURCE_LANGUAGE' | translate}}</mat-label>
      <mat-select class="mat-select" [(ngModel)]="sourceLanguage" (selectionChange)="targetLanguageChanged($event)">
        <mat-option *ngFor="let lang of sourceLanguages" [value]="lang">
          {{lang?.name_en}} <span *ngIf="lang?.name">-</span> {{lang?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard" class="filter-select">
      <mat-label class="filter-label">{{'CMS_TARGET_LANGUAGE' | translate}}</mat-label>

      <mat-select class="mat-select" [(ngModel)]="targetLanguage" (selectionChange)="targetLanguageChanged($event)">
        <mat-option *ngFor="let lang of targetLanguages" [value]="lang">
          {{lang?.name_en}} <span *ngIf="lang?.name">-</span> {{lang?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="project-detail-step">
    <kendo-grid [data]="filteredTranslations"
                [filter]="state.filter"
                [filterable]="true"
                (filterChange)="filterChange($event)"
                (dataStateChange)="dataStateChange($event)"
                [pageSize]="state.take"
                [sortable]="{
                    allowUnsort: true,
                    mode: 'single'
                  }"
                (sortChange)="sortChange($event)"
                [sort]="state.sort"
                [pageable]="{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true
                  }"
                [skip]="state.skip"
                (pageChange)="pageChange()"
                *ngIf="defaultConfig">
      <ng-template kendoGridToolbarTemplate>
        <!--        <div class="clear-filters-container">-->
        <!--          <input type="button" class="k-button" value="Clear Filters" (click)="clearFilters()"/>-->
        <!--        </div>-->
      </ng-template>
      <ng-container *ngFor="let columnSet of defaultConfig.column_sets">
        <ng-container *ngFor="let columnSubSet of columnSet.sets">

          <ng-container *ngFor="let actColumn of columnSubSet.columns;let i = index;">
            <kendo-grid-column [field]="actColumn.property" [headerClass]="'grid-header'"
                               [footerStyle]="{'padding-bottom':'11px;','padding-top':'11px;'}"
                               [sortable]="actColumn.sortable"
                               [width]="actColumn.width || '120'"
                               [filterable]="actColumn.filter">

              <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                {{actColumn.label | translate
                  | textReplace: {"\{\{source_language\}\}": sourceLanguage?.name}
                  | textReplace: {"\{\{target_language\}\}": targetLanguage?.name}
                }}
              </ng-template>

              <ng-container [ngSwitch]="actColumn.type">


                <ng-template kendoGridCellTemplate let-dataItem *ngSwitchCase="'sourceLanguage'">
                  {{dataItem[sourceLanguage.id]}}
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex
                             *ngSwitchCase="'targetLanguage'">
                  <mat-form-field class="w-100" appearance="fill">
                    <textarea [disabled]="dataItem.is_locked"
                        [(ngModel)]="dataItem[targetLanguage.id]"
                              (focusout)="saveItem(dataItem)"
                              matInput placeholder="Add text"></textarea>
                  </mat-form-field>
                </ng-template>
              </ng-container>


            </kendo-grid-column>
          </ng-container>
        </ng-container>
      </ng-container>

      <kendo-grid-column-group *ngIf="noActiveColumn()" [headerClass]="'grid-header grid-main-header'">
        <kendo-grid-column [field]="" [headerClass]="'grid-header'" width="4000"></kendo-grid-column>
      </kendo-grid-column-group>

    </kendo-grid>

  </div>
</section>

