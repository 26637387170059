import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Pipe({
  name: 'orderByKey',
})
export class OrderByKeyPipe implements PipeTransform {

  transform<T>(elements: T[], key: string, desc = false): T[] {
    return elements.sort((a: any, b: any) => {
      if (!desc) {
        return get(a, key) - get(b, key);
      } else {
        return get(b, key) - get(a, key);
      }
    });
  }

}
