<div class="project-detail-step" style="width:720px;">
    <div class="project-table-with-graph-component" *ngIf="isComponentReady && values">
        <table class="table ebp-table">
            <thead>
                <tr class="graph-row">
                    <th width="180"></th>
                    <th colspan="6" class="np" height="200" width="420" class="graph">
                        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" style="max-height:120px;"
                            [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
                        </canvas>
                    </th>
                    <th width="70"></th>
                </tr>
                <tr class="year-row">
                    <th width="180"></th>
                    <th width="130" colspan="2" *ngFor="let column of displayColumns">{{column}}</th>
                    <th width="70"></th>
                </tr>
                <tr class="label-row">
                    <th></th>
                    <ng-container *ngFor="let year of displayColumns; let k = index">
                        <th>{{this.component?.headers[1]?.label | translate | replacePlaceholder:
                            project.replace}}</th>
                        <th class="evol">
                            <span *ngIf="k > 0 || component.timespan == 'future'">
                                {{'M_AMBITION_CHART_TABLE_EVOL' | translate}}
                            </span>
                        </th>
                    </ng-container>
                    <th>{{'M_AMBITION_CHART_TABLE_CAGR' | translate}}</th>
                </tr>
                <tr class="total-row">
                    <th class="text-right">{{'M_AMBITION_CHART_TABLE_TOTAL' | translate}}</th>
                    <ng-container *ngFor="let year of displayColumns; let k = index">
                        <th colspan="2" style="border-right:1px solid white">
                            <div class="d-flex">
                            <div style="width:80%; font-size:15px;"
                                *ngIf="values['totals'][year]" class="truncate value dark">
                                {{ values['totals'][year].value| mask:'separator.3':','}}
                            </div>
                                <div
                                    *ngIf="values['totals'][year] && (k > 0 || component.timespan == 'future')"
                                    class="truncate evol precent">{{ values['totals'][year].evol | percent}}
                                </div>
                            </div>
                        </th>
                    </ng-container>
                    <th *ngIf="values['cagr'][component.timespan]">{{values['cagr'][component.timespan]['total'] |
                        percent}}</th>
                </tr>
            </thead>

            <tbody>
                <ng-container *ngIf="!project.is_full_division">
                    <ng-container *ngFor="let division of project.divisions; let i = index">
                        <tr>
                            <td class="v-align-mid">
                                <div class="details">
                                    <span class="name no-circle">{{division.code}}</span>
                                </div>
                            </td>
                            <ng-container *ngFor="let year of displayColumns, let k = index">
                                <td colspan="2" style="border-right:1px solid white;">
                                    <div class="d-flex" >
                                    <div style="width:80%;font-size:15px;" *ngIf="values['divisions'][division.id] && values['divisions'][division.id][year]" class="truncate value dark">
                                        {{values['divisions'][division.id][year].value}}
                                    </div>
                                    <div *ngIf="k > 0 || component.timespan == 'future' " class="truncate evol precent dark-small">
                                        {{ values['divisions'][division.id][year].evol | percent }}
                                    </div>
                                    </div>
                                </td>
                            </ng-container>
                            <td>
                            <span *ngIf="values['cagr'][component.timespan]" class="cagr dark-small">
                                {{values['cagr'][component.timespan][division.id] | percent}}
                            </span>
                            </td>
                        </tr>
                        <tr *ngFor="let brand of division.brands; let i = index">
                            <td class="v-align-mid">
                                <div class="details">
                                    <span class="circle" [style.background]="barChartColors[i]"></span>
                                    <span class="name">{{brand.name}}</span>
                                </div>
                            </td>
                            <ng-container *ngFor="let year of displayColumns, let k = index">
                                <td colspan="2" style="border-right:1px solid white;">
                                    <div class="d-flex align-items-center">
                                        <div style="width:80px;">
                                        <mat-form-field *ngIf="values[brand.id] && values[brand.id][year]">
                                            <input matInput [(ngModel)]="values[brand.id][year].value" type="text"
                                                   mask="separator.3" thousandSeparator="," (change)="onDataChange()"
                                                   autocomplete="off">
                                        </mat-form-field>
                                        </div>
                                        <div style="border-right:0;" *ngIf="k > 0 || component.timespan == 'future' " class="truncate evol precent">
                                            {{ values[brand.id][year].evol | percent }}
                                        </div>

                                    </div>

                                </td>
                            </ng-container>
                            <td>
                            <span *ngIf="values['cagr'][component.timespan]" class="cagr">
                                {{values['cagr'][component.timespan][brand.id] | percent}}
                            </span>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="project.is_full_division">
                    <tr *ngFor="let division of project.divisions; let i = index">
                        <td class="v-align-mid">
                            <div class="details">
                                <span class="circle" [style.background]="barChartColors[i]"></span>
                                <span class="name">{{division.code}}</span>
                            </div>
                        </td>
                        <ng-container *ngFor="let year of displayColumns, let k = index">
                            <td>
                                <mat-form-field *ngIf="values['divisions'][division.id] && values['divisions'][division.id][year]">
                                    <input matInput [(ngModel)]="values['divisions'][division.id][year].value" type="text"
                                        mask="separator.3" thousandSeparator="," (change)="onDataChange()"
                                        autocomplete="off">
                                </mat-form-field>
                            </td>
                            <td class="evol">
                                <span *ngIf="k > 0 || component.timespan == 'future' " class="truncate precent">{{
                                    values['divisions'][division.id][year].evol | percent }}</span>
                            </td>
                        </ng-container>
                        <td>
                            <span *ngIf="values['cagr'][component.timespan]" class="cagr">
                                {{values['cagr'][component.timespan][division.id] | percent}}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
