import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { collapseAnimation } from 'angular-animations';
import { cloneDeep, chunk } from 'lodash-es';
import { Subscription } from 'rxjs';
import { Brand } from 'src/app/shared/models/brand.model';
import { Project } from 'src/app/shared/models/project.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ConfigService } from '../../../../../shared/services/config.service';

@Component({
  selector: 'bundler-brand-toggle-table',
  templateUrl: './brand-toggle-table.component.html',
  styleUrls: [ './brand-toggle-table.component.scss' ],
  animations: [
    collapseAnimation(),
  ],
})
export class BrandToggleTableComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input()
  brands: number[] = [];

  @Input()
  subCategories: string;

  @Output()
  onChange = new EventEmitter<any>();

  @Input()
  isCollapsed = false;
  @Output()
  onCollapseChange = new EventEmitter<boolean>();

  currentProject: Project = null;

  availableBrands: Array<Brand>;
  chunkedBrands: Array<Array<Brand>>;

  constructor(private projectService: ProjectService,
              public configService: ConfigService,
  ) {}

  ngOnInit(): void {

    this.subscription.add(
      this.projectService.currentProjectBrands.subscribe((data) => {
        if (!data) {
          return;
        }
        const filteredBrands = data.brands.filter((brand) => data.divisionIds.indexOf(brand.division_id) > -1);
        this.availableBrands = filteredBrands.map(brand => {
          brand.subCategoryExists = true;
          return brand;
        });
        this.chunkBrands();
      }),
    );

    this.subscription.add(
      this.projectService.currentProject.subscribe(async (p) => {
        if (!p) {
          return;
        }
        this.currentProject = p;
        this.availableBrands = JSON.parse(JSON.stringify(this.currentProject?.brands));
        const subCategoriesArr = this.subCategories && this.subCategories.length ? this.subCategories.split(',') : [];

        if (this.projectService.projectContainsFullDivision(p)) {
          return;
        }
        this.availableBrands.map((availableBrand) => {
          let subCategoryExists = false;
          const brandSubCategories = availableBrand.product_sub_categories.map((sCategory) => sCategory.alias);
          subCategoriesArr.forEach((subCategory) => {
            if (brandSubCategories.indexOf(subCategory) > -1) {
              subCategoryExists = true;
            }
          });
          availableBrand.subCategoryExists = subCategoryExists;
          return availableBrand;
        });
        this.chunkBrands();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  chunkBrands() {
    const populatedBrands = cloneDeep(this.availableBrands);
    if (this.brands) {
      populatedBrands.map(b => {
        b.is_selected = this.brands.indexOf(b.id) > -1;
        return b;
      });

    }
    populatedBrands.sort((a, b) => a.name.localeCompare(b.name));
    this.chunkedBrands = chunk(populatedBrands, 2);
  }

  onOptionChange(data: any) {
    this.brands = [];
    this.chunkedBrands.forEach((brandSection) => {
      brandSection.forEach(brand => {
        if (brand.is_selected) {
          this.brands.push(brand.id);
        }
      });
    });
    this.onChange.emit(this.brands);
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    this.onCollapseChange.emit(this.isCollapsed);
  }

}
