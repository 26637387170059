import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-check-icon',
  templateUrl: './check-icon.component.html',
  styleUrls: ['./check-icon.component.scss']
})
export class CheckIconComponent implements OnInit {

  constructor() { }

  @Input()
  tooltip: string;

  @Input()
  status: string = ""

  @Input()
  excluded: boolean = false


  ngOnInit(): void {
  }

}
