<div class="project-detail-step">
  <div class="content">

    <mat-form-field appearance="standard">
      <mat-label>{{component.placeholder | translate}}</mat-label>
      <mat-select [(ngModel)]="component.answer.values" (ngModelChange)="onValueChange()">
        <mat-option *ngFor="let option of component.options" [value]="option.value">
          {{option.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
