import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Module } from 'src/app/shared/models/module.model';
import { Project } from 'src/app/shared/models/project.model';
import { StepComponent } from 'src/app/shared/models/step-component.model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Events } from '../../../shared/enums/events';
import { EventsService } from '../../../shared/services/events.service';
import { ModuleService } from '../../services/module.service';
import { TermsAndConditionsService } from './../../services/terms-and-conditions.service';
import { TargetService } from './target.service';

@Component({
  selector: 'project-table-target',
  templateUrl: './table-target.component.html',
  styleUrls: [ './table-target.component.scss' ],
})
export class TableTargetComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @Input()
  component: StepComponent;

  @Output() onChange = new EventEmitter<any>();

  @Output() onCollapseRow = new EventEmitter<any>();

  public rows: Array<any>;
  public currentProject: Project;
  public currentModule: Module;

  public config: any = {};
  public values: any;

  public updateTimer: any;

  public columns: Array<any>;

  public quarters: Array<any> = [
    {key: 'quarter-1', name: 'Quarter 1'},
    {key: 'quarter-2', name: 'Quarter 2'},
    {key: 'quarter-3', name: 'Quarter 3'},
    {key: 'quarter-4', name: 'Quarter 4'},
  ];

  public months: Array<any> = [
    {key: 'jan', name: 'January'},
    {key: 'feb', name: 'February'},
    {key: 'mar', name: 'March'},
    {key: 'apr', name: 'April'},
    {key: 'may', name: 'May'},
    {key: 'jun', name: 'June'},
    {key: 'jul', name: 'July'},
    {key: 'aug', name: 'August'},
    {key: 'sep', name: 'September'},
    {key: 'oct', name: 'October'},
    {key: 'nov', name: 'November'},
    {key: 'dec', name: 'December'},
  ];

  constructor(
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private targetService: TargetService,
    private eventsService: EventsService,
    private tocService: TermsAndConditionsService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.moduleService.currentModule.subscribe((m) => {
        if (m) {
          this.currentModule = m;
          if (this.currentModule.sections[1].sets[0].steps[0]) {
            this.currentModule.sections[1].sets[0].steps[0].components.forEach(
              (c) => {
                this.config[c.name] = c?.answer?.values;
              },
            );
          }
          this.loadValues();
        }
      }),
    );
    this.subscription.add(
      this.projectService.currentProject.subscribe((p) => {
        if (p) {
          this.currentProject = p;

          this.columns = [
            this.currentProject.year - 1,
            this.currentProject.year,
            'evol',
          ];

          if (this.component.rows.method == 'brandsList') {
            this.config['entity'] = 'brands';
            this.rows = this.currentProject.brands.map((brand) => {
              return {
                id: brand.id,
                value: brand.alias,
                label: brand.name,
              };
            });
          }

          if (this.component.rows.method == 'divisionsList') {
            this.config['entity'] = 'divisions';
            this.rows = this.currentProject.divisions.map((division) => {
              return {
                id: division.id,
                value: division.alias,
                label: division.name,
              };
            });
          }

          this.setupAnswers();
          this.loadValues();
        }
      }),
    );
    this.subscription.add(
      this.targetService.data.subscribe((data) => {
        if (data) {
          this.values = data[this.component.mode];
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  label(label: string) {
    return label;
    // return label
    // .replace('{{YEAR}}', this.currentProject.year.toString())
    // .replace('{{YEAR-1}}', (this.currentProject.year - 1).toString());
  }

  loadValues() {
    if (!this.currentProject || !this.config.target_definition_interval) {
      return;
    }
    this.targetService.getValues(
      this.currentProject.id,
      'bottom-up',
      this.config.target_definition_interval,
    );
  }

  onTotalChange(brand_id: number, year: string) {
    this.eventsService.setValue(Events.STEP_DATA_SAVING, true);
    const payload = {
      section: this.config.entity.slice(0, -1),
      brand_id,
      type: this.component.mode,
      year,
      annual: this.values[this.config.entity][brand_id][year],
      timespan: 'annually',
    };
    this.targetService
    .updateTopDownValues(this.currentProject.id, payload)
    .subscribe((repsonse) => {
      this.loadValues();
      this.onChange.emit();
      this.eventsService.setValue(Events.STEP_DATA_SAVING, false);

      this.tocService.loadTocData(this.currentProject.id);
    });
  }

  updateTargetValue(entityID: number, year: number, field: string) {
    this.eventsService.setValue(Events.STEP_DATA_SAVING, true);

    const payload: any = {};
    payload[field] = this.values[this.config.entity][entityID][year][field];
    payload.year = year;
    payload.type = this.component.mode;
    payload.timespan = this.config.target_definition_interval;
    if (this.config.entity == 'brands') {
      payload.section = 'brand';
      payload.brand_id = entityID;
    } else {
      payload.section = 'division';
      payload.division_id = entityID;
    }
    this.targetService.updateValues(
      this.currentProject.id,
      'bottom-up',
      payload,
    );
    // this.onChange.emit();
  }

  collapseRow(id: string) {
    this.component.answer.collapsedState[id] = !this.component.answer.collapsedState[id];
    this.onCollapseRow.emit(this.component.answer.collapsedState);
  }

  setupAnswers() {
    this.component.answer = this.component.answer || {};
    this.component.answer.collapsedState = this.component.answer.collapsedState || {};
    this.rows.forEach(row => {
      this.component.answer.collapsedState[row.id] = this.component.answer.collapsedState[row.id] || false;
    });
  }

  navigateToStep(stepId: string) {
    let el = document.getElementById(stepId);
    const y = el.getBoundingClientRect().top + window.pageYOffset - 220;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

}
