import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Activity } from '../../../project/entities/activity';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-activity-edit-dialog',
  templateUrl: './activity-edit-dialog.component.html',
  styleUrls: [ './activity-edit-dialog.component.scss' ],
})
export class ActivityEditDialogComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.data = data;
  }

  data: any;
  configData: any = {};
  activity: Activity;

  ngOnInit(): void {
    this.activity = this.data.activitiy || new Activity();
    if (!this.data.activitiy) {
      this.activity.is_active = true;
      this.activity.ebp_id = this.data.ebpId;
    }
    
    this.configService.dialogConfig.subscribe(config => {
      this.configData = config.filter(c => c.name === this.data.dialog_key)[0];
    });
  }

  archiveActivity(ev: any) {
    this.activity.is_active = !ev.checked;
  }
}
