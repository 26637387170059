<div class="project-detail-step">
  <div class="content">
    <!-- <debug [content]="values"></debug> -->
    <table class="table ebp-table ebp-small-table reset-table">
      <thead>
      <tr>
        <th class="sticky-element" *ngFor="let header of component.headers; let i = index" [width]="header.width"
            [ngClass]="{'text-right': i > 0}"
            [attr.colspan]="(i == 0) ? 2 : 1">
          {{ (header.label | translate)  | replacePlaceholder :currentProject.replace}}
        </th>
      </tr>
      </thead>
      <tbody *ngIf="rows && config && values && config.target_definition_interval">

      <ng-container *ngIf="config.target_definition_interval =='annually'">
        <tr *ngFor="let row of rows; let i = index">
          <td colspan="2">
            {{row.label | translate }}
          </td>
          <td *ngFor="let column of columns">
            <ng-container *ngIf="config.entity == 'brands'">
              <mat-form-field *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                <input matInput autocomplete="off" type="text"
                       (change)="onTotalChange(row.id, column)"
                       [allowNegativeNumbers]="true"
                       [(ngModel)]="values[this.config.entity][row.id][column]"
                       mask="separator.3"
                       thousandSeparator=",">
                <span
                    matSuffix>&nbsp;{{ column == "evol" ? "%" : ('\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace)}}</span>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="config.entity != 'brands'">
                              <span *ngIf="column == 'evol';else not_evol" class="text-muted">
                                <ng-container
                                    *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                                {{values[this.config.entity][row.id][column]}}
                                </ng-container>
                                <span matSuffix class="text-muted">&nbsp;%</span>
                              </span>
              <ng-template #not_evol>
                <span class="text-muted" *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                  {{values[this.config.entity][row.id][column] | mask:'separator.2':','}}
                </span>
                <span matSuffix
                      class="text-muted">&nbsp;{{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</span>
              </ng-template>
            </ng-container>
          </td>
        </tr>
      </ng-container>

      <ng-container *ngIf="config.target_definition_interval != 'annually'">
        <ng-container *ngFor="let row of rows; let i = index">
          <tr class="brand-row">
            <td colspan="2">
              {{row.label | translate}}
            </td>
            <td *ngFor="let column of columns" class="text-muted">
                              <span *ngIf="column == 'evol';else not_evol">
                                <ng-container
                                    *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                                  {{values[this.config.entity][row.id][column].total }}
                                </ng-container>
                                <span matSuffix>&nbsp;%</span>
                              </span>
              <ng-template #not_evol>
                <ng-container *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                  {{values[this.config.entity][row.id][column].total | mask:'separator.2':','}}
                </ng-container>

                <span
                    matSuffix>&nbsp;{{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</span>
              </ng-template>
            </td>
          </tr>

          <ng-container *ngIf="config.target_definition_interval =='quarterly'">
            <tr class="expandable-header">
              <td class="td-border-0" width="10" (click)="collapseRow(row.id)">
                <shared-expand-icon [rotate]="!component.answer?.collapsedState[row.id]"></shared-expand-icon>
              </td>
              <td colspan="4">
                {{'M_TARGET_LABEL_QUARTERLY_DETAILS' | translate}}
              </td>
            </tr>
            <ng-container *ngIf="!component.answer?.collapsedState[row.id]">
              <tr *ngFor="let quarter of quarters" class="expandable-content">
                <td></td>
                <td>{{quarter.name}}</td>
                <ng-container *ngIf="config.entity == 'divisions'">
                  <td *ngFor="let column of columns" class="text-muted">
                                            <span *ngIf="column == 'evol';else not_evol">
                                              <ng-container *ngIf="values  && values['divisions'] && values['divisions'][row.id]">
                                              {{values['divisions'][row.id][column][quarter.key]}}
                                                </ng-container>
                                              <span matSuffix>&nbsp;%</span>
                                            </span>
                    <ng-template #not_evol>
                      <span *ngIf="values  && values['divisions'] && values['divisions'][row.id]">
                        {{values['divisions'][row.id][column][quarter.key] | mask:'separator.2':','}}
                      </span>
                      <span
                          matSuffix>&nbsp;{{ '\{\{CURRENCY_SYMBOL\}\}' | translate| replacePlaceholder: currentProject.replace}}</span>
                    </ng-template>
                  </td>
                </ng-container>
                <ng-container *ngIf="config.entity == 'brands'">
                  <td *ngFor="let column of columns">
                    <mat-form-field apperance="standard"
                                    *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                      <input matInput type="text" autocomplete="off"
                             (change)="updateTargetValue(row.id, column, quarter.key)" [allowNegativeNumbers]="true"
                             [(ngModel)]="values[this.config.entity][row.id][column][quarter.key]"
                             mask="separator.3"
                             thousandSeparator=",">
                      <span
                          matSuffix>&nbsp;{{ column == "evol" ? "%" : ('\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace)}}</span>
                    </mat-form-field>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="config.target_definition_interval =='monthly'">
            <tr class="expandable-header">
              <td width="10" class="td-border-0" (click)="collapseRow(row.id)">
                <shared-expand-icon [rotate]="!component.answer?.collapsedState[row.id]"></shared-expand-icon>
              </td>
              <td colspan="4">
                {{'M_TARGET_LABEL_MONTHLY_DETAILS' | translate}}
              </td>
            </tr>
            <ng-container *ngIf="!component.answer?.collapsedState[row.id]">
              <tr *ngFor="let month of months" class="expandable-content">
                <td></td>
                <td>{{month.name}}</td>
                <td *ngFor="let column of columns">

                  <ng-container *ngIf="config.entity == 'divisions'">
                      <span *ngIf="column == 'evol';else not_evol" class="text-muted">
                                              <ng-container
                                                  *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                                                   {{values[this.config.entity][row.id][column][month.key] }}

                                              </ng-container>
                                              <span matSuffix class="text-muted">&nbsp;%</span>
                                            </span>
                    <ng-template #not_evol>
                      <span *ngIf="values && this.config.entity && values[this.config.entity][row.id]"
                            class="text-muted">
                        {{values[this.config.entity][row.id][column][month.key] | mask:'separator.2':','}}
                      </span>
                      <span matSuffix
                            class="text-muted">&nbsp;{{ '\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace}}</span>
                    </ng-template>

                  </ng-container>

                  <ng-container *ngIf="config.entity == 'brands'">
                    <mat-form-field *ngIf="values && this.config.entity && values[this.config.entity][row.id]">
                      <input matInput type="text" autocomplete="off"
                             (change)="updateTargetValue(row.id, column, month.key)" [allowNegativeNumbers]="true"
                             [(ngModel)]="values[this.config.entity][row.id][column][month.key]" mask="separator.3"
                             thousandSeparator=",">
                      <span
                          matSuffix>&nbsp;{{ column == "evol" ? "%" : ('\{\{CURRENCY_SYMBOL\}\}' | translate | replacePlaceholder: currentProject.replace)}}</span>
                    </mat-form-field>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>

        </ng-container>
      </ng-container>

      </tbody>
    </table>

    <div *ngIf="!config.target_definition_interval">
      <shared-inpage-step-dialog [dialog_key]="'alert_undefined_timegranularity'"
                                 (onHideInPageDialog)="navigateToStep('target_configuration')"></shared-inpage-step-dialog>
    </div>
  </div>
</div>
