<div class="project-detail-step">
    <div class="content">
        <p>{{component?.guideline | translate}}</p>
        <mat-radio-group [(ngModel)]="component.answer?.values.selected" (ngModelChange)="onValueChange()">
            <div class="option-group" *ngFor="let option of component.options">
                <mat-radio-button [value]="option.value">
                    <span *ngIf="option.label">
                        {{option.label | translate}}
                    </span>
                </mat-radio-button>
            </div>
        </mat-radio-group>
        <mat-form-field *ngIf="component.answer?.values.selected =='other'">
            <input type="text" matInput [placeholder]="component.options[component.options.length -1].placeholder | translate" autocomplete="off"
            [(ngModel)]="component.answer.values.other" (ngModelChange)="onValueChange()">
        </mat-form-field>
    </div>
</div>
