<div kendoTooltip tooltipClass="kendoTooltipOverride" position="right">
	<div [title]="tooltip ? tooltip : ''">
		<svg [ngClass]="{'has-tooltip': tooltip, 'is-excluded': excluded, 'is-step-title-infoicon' : stepTitleIcon}"
			version="1.1" id="icon-info" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
			xml:space="preserve" (mouseover)="showTooltip = true" (mouseout)="showTooltip = false">
			<path id="Path_2213" fill="#585858" d="M12,2.999c-4.971,0-9,4.029-9,9s4.029,9,9,9s9-4.029,9-9l0,0C21,7.028,16.971,2.999,12,2.999
	   z M12.287,5.329c0.761-0.022,1.396,0.576,1.418,1.337c0.001,0.034,0,0.067-0.001,0.101c0.02,0.798-0.611,1.461-1.41,1.481
	   c-0.036,0.001-0.072,0-0.108-0.001c-0.742,0.041-1.377-0.527-1.418-1.27c-0.003-0.057-0.002-0.113,0.001-0.169
	   c-0.006-0.812,0.647-1.474,1.458-1.48C12.247,5.328,12.267,5.329,12.287,5.329z M14.655,18.329c-0.706-0.041-1.5-0.06-2.43-0.06
	   c-0.909,0-1.759,0.019-2.431,0.06c-0.178-0.2-0.186-0.5-0.018-0.709l0.385-0.04c0.667-0.062,0.75-0.243,0.75-1.661v-3.058
	   c0-0.812,0-1.054-0.385-1.299l-0.284-0.183c-0.143-0.178-0.143-0.431,0-0.609c1.037-0.289,2.042-0.682,3-1.173
	   c0.145-0.007,0.283,0.062,0.362,0.184c-0.039,0.606-0.06,1.5-0.06,2.409v3.726c0,1.418,0.078,1.6,0.748,1.66l0.386,0.041
	   C14.848,17.828,14.838,18.131,14.655,18.329L14.655,18.329z" />
		</svg>
	</div>
</div>