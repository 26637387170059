import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { groupBy, sortBy ,cloneDeep} from 'lodash-es';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Module } from 'src/app/shared/models/module.model';
import { Events } from '../../../shared/enums/events';
import { Brand } from '../../../shared/models/brand.model';
import { Project } from '../../../shared/models/project.model';
import { SkuSet } from '../../../shared/models/sku_set.model';
import { StepComponent } from '../../../shared/models/step-component.model';
import { ConfigService } from '../../../shared/services/config.service';
import { EventsService } from '../../../shared/services/events.service';
import { ProjectService } from '../../../shared/services/project.service';
import { SkuSetService } from '../../components/bundler-components/sku-set-service.service';
import { Activation } from '../../entities/activation';
import { Activity } from '../../entities/activity';
import { ActivationService } from '../../services/activation.service';
import { ActivityService } from '../../services/activity.service';
import { ModuleService } from './../../services/module.service';

interface calendarDate {
  days: number[];
  date: string;
}

@Component({
  selector: 'activation-full-calendar',
  templateUrl: './activation-full-calendar.component.html',
  styleUrls: [ './activation-full-calendar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationFullCalendarComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @ViewChild('calendarData', {static: true}) calendarDataElement: ElementRef;
  @ViewChild('calendarHeader', {static: true}) calendarHeaderElement: ElementRef;
  @ViewChildren('calendarItem') calendarDataItems: QueryList<ElementRef>;

  @Input() component: StepComponent;

  currentProject: Project;
  currentModule: Module;
  activations: Activation[] = [];
  activities: Activity[];
  brands: Brand[];
  calendarDates: calendarDate[];
  scrollPosition = 0;
  calendarActivationItemsHeight = {};

  constructor(private projectService: ProjectService,
              private activationService: ActivationService,
              private configService: ConfigService,
              private activityService: ActivityService,
              private cdr: ChangeDetectorRef,
              private eventsService: EventsService,
              private moduleService: ModuleService,
              private skuSetService: SkuSetService) { }

  ngOnInit(): void {
    this.subscription.add(
      this.eventsService.on(Events.UPDATE_ACTIVATIONS).subscribe((activations) => {
        if (activations) {
          this.prepareActivations(activations, true);
          this.generateCalendarDates(this.currentProject.year);
        }
      }),
    );

    this.subscription.add(
      this.moduleService.currentModule.subscribe((module) => {
        if (module) {
          this.currentModule = module;
        }
      }),
    );

    this.subscription.add(
      this.projectService.currentProject.subscribe((project) => {
        if (project) {
          this.currentProject = project;
        }
      }),
    );

    this.loadBrands();

    this.subscription.add(
      this.activityService.activities.subscribe((activities) => {
        if (activities) {
          this.activities = activities;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadBrands() {
    this.subscription.add(
      this.configService.getEntity('brands').subscribe((response) => {
        this.brands = response.data.brands;
        this.loadActivations();
        this.generateCalendarDates(this.currentProject.year);
      }),
    );
  }

  loadActivations() {
    this.subscription.add(
      this.activationService
      .getActivations(this.currentProject.id)
      .subscribe((response) => {
        this.prepareActivations(response.data.activations);
        this.cdr.markForCheck();
      }),
    );
  }

  prepareActivations(activationsData, update = false) {
    const activations = cloneDeep(activationsData);
    this.activations = activations.filter(activation => activation.status);
    this.activations.forEach((activation) => {
      activation.additional_info = this.prepareAdditionalInfo(activation.sku_set);

      activation.activation_events = activation.activation_events.map((activity) => {
        const activityData = this.activities.find((act) => act.id === activity.activity_id);
        const dayDataProps = this.generateCalendarDayProp(activity.starts_at, activity.ends_at);
        return {
          ...activity,
          activityName: activityData.name,
          ...dayDataProps,
        };
      });
      if (activation.activation_events.length) {
        const activationsStart = activation.activation_events.sort((a, b) => moment(a.starts_at).diff(moment(b.starts_at)))[0];
        const activationsEnd = activation.activation_events.sort((a, b) => moment(b.ends_at).diff(moment(a.ends_at)))[0];
        const dayDataProps = this.generateCalendarDayProp(activationsStart.starts_at, activationsEnd.ends_at);
        activation.activation_events.unshift(
          {
            activityName: `All activities:
                ${moment(activationsStart.starts_at).format('DD.MM.YYYY')} to ${moment(activationsEnd.ends_at).format('DD.MM.YYYY')}`,
            ...dayDataProps,
            starts_at: activationsStart.starts_at,
            id: Math.floor(Math.random() * 1000),
          },
        );
      }

      if (!update) {

        this.skuSetService
        .getSkuSet(this.currentProject, activation.sku_set.id)
        .subscribe((resp) => {
          activation.sku_set = resp.data.sku_set;
        });
      }

    });

    const groupedActivations = groupBy(this.activations, 'sku_set.mono_from_grid');
    const heroActivations = sortBy(groupedActivations[true]
      , [ (item) => {
        return [ item.sku_set.products[0].brand.name.toLowerCase(), item.sku_set.products[0].name.toLowerCase() ];
      } ]);
    const bundleActivations = sortBy(groupedActivations[null], [ 'created_at' ]);
    this.activations = [ ...heroActivations, ...bundleActivations ];
    this.cdr.markForCheck();
  }

  generateCalendarDates(currentYear: number) {
    const months = Array.from(Array(12).keys());
    this.calendarDates = months.map((month) => {
      const date = moment(`${currentYear}-${month + 1}`, 'YYYY-MM');
      return {
        days: Array.from(Array(date.daysInMonth()).keys()),
        date: date.format('YYYY-MM-DD'),
      };
    });
  }

  prepareAdditionalInfo(skuSet: SkuSet) {
    let heroSkuCount = 0;
    const uniqueBrandIds = skuSet.products
    .map((product) => {
      if (product.is_hero) {
        heroSkuCount += 1;
      }
      return this.brands.filter((brand) => brand.id === product.brand_id)[0]
        .name;
    })
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();
    return {
      brands_categories: uniqueBrandIds.join(', '),
      total_sku: skuSet.products.length,
      hero_sku: heroSkuCount,
    };
  }

  navigateToMonth(prev = false) {
    const defaultScrollPosition = 578;

    if (!this.scrollPosition && prev) {
      return;
    }
    let nextScrollElementRect: any;
    const elementPositions = this.calendarDataItems.map((dataItem) => {
      return dataItem.nativeElement.getBoundingClientRect();
    });
    if (prev) {
      elementPositions.sort((a, b) => b.x - a.x);
    }
    for (let i = 0; i < elementPositions.length; i++) {
      const position = elementPositions[i];
      const firstPosition = (position.x + this.scrollPosition).toFixed(1);

      if (prev && firstPosition < this.scrollPosition + defaultScrollPosition) {
        nextScrollElementRect = {...position, x: firstPosition};
        break;
      }
      if (!prev && firstPosition > this.scrollPosition + defaultScrollPosition) {
        nextScrollElementRect = {...position, x: firstPosition};
        break;
      }
    }

    this.calendarDataElement.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth',
        left: nextScrollElementRect.x - defaultScrollPosition,
      },
    );
  }

  onScroll(scrollEvent: Event) {
    this.scrollPosition = scrollEvent.target['scrollLeft'];
    this.calendarHeaderElement.nativeElement.scrollLeft = this.scrollPosition;

  }

  scrollToCampaign(activationIndex: number) {
    const elementId = `activation-${activationIndex}`;
    const yOffset = -100;
    const element = document.getElementById(elementId);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  generateCalendarDayProp(start, end) {
    const startDate = moment(start, 'YYYY/MM/DD');
    const endDate = moment(end, 'YYYY/MM/DD');
    const startMonth = startDate.format('M');
    const startDay = startDate.format('D');
    const endMonth = endDate.format('M');
    const endDay = endDate.format('D');
    return {
      startMonth,
      startDay,
      endMonth,
      endDay,
    };
  }

}
