import { Country } from './country.model';

export class User {
    id: number;
    displayname: string;
    title: string;
    name: string;
    given_name: string;
    family_name: string;
    initials: string;
    emailaddress: string;
    groups: Array<string>;
    department: string;
    token: string;
    display_name?: string;
    isValidator: boolean = false;
    country?: Country;
    is_superadmin?: boolean;
}
