import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent implements OnInit {

  @Input()
  content:any;

  @Input()
  title:string;

  @Input()
  isCollapsed:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
