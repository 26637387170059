import { Component, Input, OnInit } from '@angular/core';
import { ModuleProgress } from 'src/app/shared/models/module-progress.model';

@Component({
  selector: 'project-module-progressbar',
  templateUrl: './module-progressbar.component.html',
  styleUrls: ['./module-progressbar.component.scss'],
})
export class ModuleProgressbarComponent implements OnInit {
  constructor() {}

  @Input()
  progress: ModuleProgress;

  @Input()
  isVertical: boolean = false;

  ngOnInit(): void {}
}
