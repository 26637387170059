import { Component, OnInit, Input } from '@angular/core';
import { Project } from '../../models/project.model';
import { ProjectService } from '../../services/project.service';
import { Router } from '@angular/router';
import { Module } from '../../models/module.model';
import { ModuleService } from 'src/app/project/services/module.service';

@Component({
  selector: 'shared-module-card',
  templateUrl: './module-card.component.html',
  styleUrls: ['./module-card.component.scss'],
})
export class ModuleCardComponent implements OnInit {
  @Input()
  module: Module;

  @Input()
  selectMode: boolean = false;

  currentProject: Project;

  constructor(
    private projectService: ProjectService,
    private moduleService: ModuleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectService.currentProject.subscribe(
      (project) => (this.currentProject = project)
    );
  }

  editModule() {
    if (this.module.status != 'online' || !this.module.version.is_active) {
      return;
    }
    if (this.currentProject) {
      this.router.navigate([
        '/projects',
        this.currentProject.id,
        this.module.alias,
      ]);
    }
  }
}
