import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ebclickOutside]',
})
export class ClickOutsideDirective {
  private allowClose = true;

  constructor(private _elementRef: ElementRef) {
  }

  @Output() ebclickOutside = new EventEmitter<void>();


  @HostListener('document:click', [ '$event.target' ]) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.ebclickOutside.emit();
    }
  }
}
