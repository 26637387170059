import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepComponent } from 'src/app/shared/models/step-component.model';

@Component({
  selector: 'project-radio-with-chips',
  templateUrl: './radio-with-chips.component.html',
  styleUrls: ['./radio-with-chips.component.scss'],
})
export class RadioWithChipsComponent implements OnInit {
  @Input()
  component: StepComponent;

  @Output()
  onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    if (!this.component.answer) {
      this.component.answer = {
        values: {
          selectedOption: this.component.options[0].name,
          selectedChips: [],
          other: '',
        },
      };
    }
  }

  toggleChip(chip: any) {
    let value = typeof chip.value == 'string' ? chip.value : 'other';
    let index = this.component.answer.values.selectedChips.indexOf(value);
    if (index == -1) {
      this.component.answer.values.selectedChips.push(value);
    } else {
      this.component.answer.values.selectedChips.splice(index, 1);
    }
    this.onValueChange()
  }

  isSelected(name:any){
    if (typeof name === 'string' || name instanceof String) { 
      return this.component.answer.values.selectedChips.indexOf(name) != -1
    } else {
      return this.component.answer.values.selectedChips.indexOf('other') != -1
    }
  }

  onValueChange(){
    this.onChange.emit()
  }
}
