<div class="project-detail-step">

    <div *ngFor="let option of component.options">
        <mat-radio-group [(ngModel)]="component.answer.values[component.name]" (ngModelChange)="onValueChange()">
            <div class="main-option-group">
                <mat-radio-button [value]="option.value">{{option.label | translate}}</mat-radio-button>
            </div>
        </mat-radio-group>
        <ng-container *ngIf="component.answer.values[component.name] == option.value && option.components">
            <div class="sub-option-group" *ngFor="let subgroup of option.components">
                <p>{{subgroup.label | translate}}</p>
                <mat-radio-group [(ngModel)]="component.answer.values[subgroup.name]" (ngModelChange)="onValueChange()">
                    <div *ngFor="let option of subgroup.options">
                        <mat-radio-button [value]="option.value">{{option.label | translate}}</mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </ng-container>
    </div>
</div>