import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalPages',
})
export class TotalPagesPipe implements PipeTransform {

  transform(currentPage: number, perPage: number, total: number): string {
    const to = (currentPage * perPage) > total ? total : currentPage * perPage;
    const from = (currentPage * perPage) - perPage;
    return ` ${!from ? 1 : from} - ${to}`;
  }
}
